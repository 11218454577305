import { v4 as uuid } from "uuid";
import { ArrowLeft, ArrowRoundTrip, RenderSVG } from "../../../assets/icons";
import config from "../../../config.json";
import { DEFAULT_VALUES, INDEX } from "../../../constants";

const { ZERO, TWO, ONE } = DEFAULT_VALUES;
const { LAST } = INDEX;

const JourneySummary = ({ journey = [] }) => {
  const { departureDate } = journey[journey.length - ONE];

  const departureDates = journey.map((flight) => flight.departureDate)


  const lastJourney = journey.at(LAST);
  const { returnDate } = lastJourney;

  const journeyCities = journey
    .flatMap((journey) => [journey.originCode.cityName,
    journey.destCode.cityName]);

  return (
    <div className="flex flex-center 2xl:gap-4 gap-2 xs:flex-wrap flex-wrap justify-center me-auto 2xl:text-lg lg:text-md text-sm text-contrast-900">
      {journeyCities.map(
        (city, index) =>
          city && (
            <span key={uuid()} className="flex items-center">
              {index !== ZERO && index % TWO !== ZERO && (
                <RenderSVG
                  Svg={returnDate ? ArrowRoundTrip : ArrowLeft}
                  width={returnDate ? "14" : "18"}
                  height={returnDate ? "14" : "18"}
                  alt="Left Arrow"
                  className="mr-3 text-contrast-900"
                  stroke={config.contrast}
                />
              )}
              {index > ZERO && index % TWO === ZERO && (
                <div className="flex flex-row">
                  <span className="hidden xs:block whitespace-pre">•  </span>
                  <span className="whitespace-pre">
                    {departureDates[(index / TWO) - ONE]}
                    {returnDate && <span> - {returnDate}</span>}
                  </span>
                </div>
              )}
              {index > ZERO && index % TWO === ZERO && <span className="mx-2">|</span>}
              <span>{city}</span>
            </span>
          )
      )}
      <span className="hidden xs:block">•</span>
      <span className="whitespace-pre">
        {departureDate}
        {returnDate && <span> - {returnDate}</span>}
      </span>
    </div>
  );
};

export default JourneySummary;
