const getAmadeusCancellationReq = (bookingInfo) => {
    const { pnr, tavaBookingId, ticketingJSON, id } = bookingInfo;
    const rawTicketNumbers = JSON.parse(
      ticketingJSON
    ).ticketJSON.ticketsnumber.map((ticket) => ticket.number);
    const ticketNumbers = rawTicketNumbers.map((ele) => {
      return ele.replaceAll("-", "");
    });
    const body = {
        ticketJson: {
          ticketNumbers,
          status: "CONFIRMED",
          marketIataCode: "IN",
        },
        pnr,
        tavaBookingId,
        id,
      };

    return body ;
};

export default getAmadeusCancellationReq;
