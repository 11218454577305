import { DEFAULT_VALUES } from "../constants";

const { ONE } = DEFAULT_VALUES;

const getStopLabel = (stops, t) => {
  if (!+stops) return t("flightResults.filters.stops.nonStop");
  else
    return `${+stops} ${
      +stops > ONE
        ? t("flightResults.filters.stops.stops")
        : t("flightResults.filters.stops.stop")
    }`;
};

export default getStopLabel;
