import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";
import { useCollapse } from "react-collapsed";
import {
  selectSelectedFlightOptions,
  selectSelectedTripType,
  setCurrentFlightType,
  setSelectedFlightInfo,
  setSelectedFlightOptions,
} from "./index";
import {
  selectActiveSortOrder,
  selectFilteredFlights,
  selectSearchFilters,
} from "../../components/organisms/Search";
import { selectSelectedFlightId } from "./flightResults.selectors";
import { selectCountryInfo } from "../Profile";
import MultiCityCard from "./MultiCityCard";
import RoundTripCard from "./RoundTripCard";
import { setSelectedModal } from "../../components/organisms/Modal";
import { MODALS } from "../../components/organisms/AppModals";
import {
  DEFAULT_VALUES,
  CURRENCY_SYMBOLS,
  TRIP_TYPES,
  DEFAULT_CURRENCY_CODE,
} from "../../constants";

const { ZERO, EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING, ONE } = DEFAULT_VALUES;
const { MULTI_CITY } = TRIP_TYPES;
const { INR } = CURRENCY_SYMBOLS;
const { FLIGHT_OPTIONS_MODAL } = MODALS;

const PackageCard = ({ flights, currentFlightType }) => {
  const dispatch = useDispatch();
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const tripType = useSelector(selectSelectedTripType);
  const filteredFlights = useSelector(selectFilteredFlights);
  const activeSortOrder = useSelector(selectActiveSortOrder);
  const selectedFlightId = useSelector(selectSelectedFlightId);
  const selectedFlightOptions = useSelector(selectSelectedFlightOptions)
  const selectedSearchFilters = useSelector(selectSearchFilters);

  const [flightIndex, setFlightIndex] = useState(ZERO);
  const [totalPassengersCount, setTotalPassengersCount] = useState(ZERO)

  useEffect(() => {
    let selectedFlightIndex = ZERO;
    if (selectedFlightOptions[currentFlightType]) {
      const selectedFlightIdx = flights.findIndex(
        (flight) =>
          flight.flightId === selectedFlightOptions[currentFlightType]?.flightId
      );
      if (selectedFlightIdx !== -1) selectedFlightIndex = selectedFlightIdx;
    }

    setFlightIndex(selectedFlightIndex);
  }, [activeSortOrder, filteredFlights, selectedFlightId, selectedFlightOptions, currentFlightType]);

  useEffect(() => {
    if (!isEmpty(selectedSearchFilters)) {
        const { passengersCount } = selectedSearchFilters;
        const totalPassengers = Object.values(passengersCount).reduce((total, count) => total + Number(count), ZERO);
        setTotalPassengersCount(totalPassengers);
    }
  }, [selectedSearchFilters])

  const {
    getCollapseProps: getFlightDetailsCollapseProps,
    getToggleProps: getFlightDetailsToggleProps,
    isExpanded: isFlightsDetailsExpanded,
  } = useCollapse({
    duration: 200,
    defaultExpanded: false,
  });

  const { price, flightId, carbonEmissionByCabin } =
    flights[flightIndex] || EMPTY_OBJECT;
  const totalPrice = get(price, "totalPrice", ZERO);

  const carbonEmissionsArray = Array.isArray(carbonEmissionByCabin)
    ? carbonEmissionByCabin
    : EMPTY_ARRAY;
  const hasHighCO2Emission = carbonEmissionsArray.some(
    (emission) => emission.quantity > maxQuantityOfCabinCO2EmissionInPound
  );

  const isSelectedFlight =
    get(selectedFlightOptions, `${currentFlightType}.flightId`, EMPTY_STRING) ===
    flightId;

  const currency = get(selectedCountryInfo, "currency.symbol", INR);

  const currencyCode = get(
    selectedCountryInfo,
    "currency.code",
    DEFAULT_CURRENCY_CODE
  );

  const handleSelectPackage = (flights) => {
    dispatch(setCurrentFlightType(currentFlightType));
    dispatch(setSelectedFlightInfo({ packages: flights }));
    if (Array.isArray(flights) && flights?.length > ONE) dispatch(setSelectedModal(FLIGHT_OPTIONS_MODAL));
    else
      dispatch(
        setSelectedFlightOptions({
          [currentFlightType]: Array.isArray(flights) ? flights[0] : flights,
        })
      );
  };

  const flightData = {
    getFlightDetailsCollapseProps,
    getFlightDetailsToggleProps,
    isFlightsDetailsExpanded,
    flights,
    totalPrice,
    hasHighCO2Emission,
    isSelectedFlight,
    currency,
    currencyCode,
    handleSelectPackage,
    flightIndex,
    setFlightIndex,
  };

  return (
    <>
      {tripType === MULTI_CITY ? (
        <MultiCityCard flightData={flightData} totalPassengersCount={totalPassengersCount} />
      ) : (
        <RoundTripCard flightData={flightData} totalPassengersCount={totalPassengersCount} />
      )}
    </>
  );
};

export default PackageCard;
