import { useSelector } from "react-redux";
import { get } from "lodash";
import { selectHotelInfo, selectPricePolicyInfo } from "../HotelInfo";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import { getHotelStayDates } from "../../helper"
import { DEFAULT_VALUES } from "../../constants"

const { ZERO, ONE, EMPTY_OBJECT } = DEFAULT_VALUES

const NIGHT = "Night";
const GUEST = "Guest";
const ROOM = "Room";

const getGuestsCount = (guests = []) => {
    return guests.reduce(
        (total, guestObject) =>
            total + guestObject["noOfAdults"] + guestObject["noOfChild"],
        ZERO
    );
};

const StayDateInfo = () => {

    const selectedHotelInfo = useSelector(selectHotelInfo);
    const pricePolicyInfo = useSelector(selectPricePolicyInfo);
    const searchFilters = useSelector(selectHotelSearchFilters);

    const hotelInfo = get(pricePolicyInfo, "blockRoomResult", EMPTY_OBJECT);
    const { noOfRooms = ZERO, roomGuests = [] } = searchFilters || {};

    const { checkIn = "", checkOut = "", totalDays = ZERO } = getHotelStayDates(selectedHotelInfo, hotelInfo)

    const noOfNights = totalDays + ONE || ONE;
    const nightLabel = noOfNights > ONE ? NIGHT + "s" : NIGHT;
    const guestLabel = getGuestsCount(roomGuests) > ONE ? GUEST + "s" : GUEST;
    const roomLabel = noOfRooms > ONE ? ROOM + "s" : ROOM;

    return (
        <div className="p-2 sm:p-4 bg-primary-100/30">
            <div className="grid grid-cols-12 gap-2 sm:gap-4">
                <div className="col-span-12 md:col-span-8 2xl:col-span-8">
                    <div className="flex justify-between items-center gap-2 sm:gap-6">
                        <div>
                            <span className="text-contrast-500 font-medium text-[9px] sm:text-xs">
                                Check In
                            </span>
                            <h5 className="text-xs sm:text-sm md:text-sm text-contrast-900 mb-1">
                                {checkIn ? (
                                    <>
                                        {checkIn.day}{" "}
                                        <strong className="text-sm">
                                            {`${checkIn.date} ${checkIn.month} `}
                                        </strong>
                                        {checkIn.year}
                                    </>
                                ) : (
                                    "..."
                                )}
                            </h5>
                        </div>
                        <div className="icon shrink-0">
                            <div className="bg-contrast-200 font-medium text-contrast-700 rounded-2xl px-3 py-0.5 text-[10px]">
                                {noOfNights} {nightLabel}
                            </div>
                        </div>
                        <div>
                            <span className="text-contrast-500 font-medium text-[9px] sm:text-xs">
                                Check Out
                            </span>
                            <h5 className="text-[10px] sm:text-xs md:text-sm  text-contrast-900 mb-1">
                                {checkOut ? (
                                    <>
                                        {checkOut.day}{" "}
                                        <strong className="text-xs sm:text-sm">
                                            {`${checkOut.date} ${checkOut.month} `}
                                        </strong>
                                        {checkOut.year}
                                    </>
                                ) : (
                                    "..."
                                )}
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-4 2xl:col-span-4 flex gap-4">
                    <div className="flex-1 text-end">
                        <h5 className="text-[9px] sm:text-xs text-contrast-800 font-bold mb-1">
                            {noOfNights} {nightLabel} |{" "}
                            {getGuestsCount(roomGuests)} {guestLabel} |{" "}
                            {noOfRooms} {roomLabel}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StayDateInfo
