import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useCollapse } from "react-collapsed";
import { get, isEmpty } from "lodash";
import { selectFlightPriceReq } from "../../../FlightResults";
import { EditIcon, RenderSVG } from "../../../../assets/icons";
import Decks from "./Decks";
import { selectSpecialServices } from "../index";
import { getSegmentCityName, getSegmentsTitle } from "../../../../helper";
import Tabs from "../../../../components/organisms/Tabs";
import RenderSeatPreference from "./RenderSeatPreference";
import { DEFAULT_VALUES } from "../../../../constants";
import logEvent from "../../../../utils/GATracker";

const { EMPTY_ARRAY, EMPTY_STRING, ZERO, ONE } = DEFAULT_VALUES;

const SeatSelection = ({
  selectionProgress,
  setSelectionProgress,
}) => {
  const { t } = useTranslation();
  const tabsRef = useRef(null);

  const handleExternalTabSelection = (tabId) => {
    if (tabsRef.current) tabsRef.current.setSelectedTabId(tabId);
  };

  const { getCollapseProps, getToggleProps, setExpanded, isExpanded } =
    useCollapse({
      duration: 400,
      defaultExpanded: false,
    });
  const { travelerSelection, seatSelection } = selectionProgress;

  const specialServices = useSelector(selectSpecialServices);
  const flightPriceReq = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;

  const [isCompleted, setIsCompleted] = useState(false);
  const [seatTabs, setSeatTabs] = useState(EMPTY_ARRAY);

  useEffect(() => {
    setExpanded(travelerSelection && !seatSelection);
    setIsCompleted(travelerSelection && seatSelection);
  }, [travelerSelection, seatSelection]);

  const getFlightNumber = (specialServiceIdx, seatDynamicIdx) =>
    get(
      specialServices,
      `${specialServiceIdx}.data.seatDynamic.${seatDynamicIdx}.FlightNumber`,
      EMPTY_STRING
    );

  const seatDynamicLastIdx = (specialServiceIdx) =>
    get(specialServices, `${specialServiceIdx}.data.seatDynamic`, EMPTY_ARRAY)
      .length - ONE;

  const getNextFlightNumber = (parentIndex, index) => {
    const isLastSegment = parentIndex === specialServices.length - ONE;
    const isLastSeatDynamic = index === seatDynamicLastIdx(parentIndex);

    if (isLastSegment && isLastSeatDynamic) return ZERO;
    else if (isLastSeatDynamic) return getFlightNumber(parentIndex + ONE, ZERO);
    else return getFlightNumber(parentIndex, index + ONE);
  };

  useEffect(() => {
    if (!specialServices) return;
    let seatSelectionTabs = [];
    specialServices.forEach(
      (
        { data: { seatDynamic = [], seatPreference = [] }, isLCC, resultIndex },
        parentIndex
      ) => {
        if (isLCC && !isEmpty(seatDynamic)) {
          const tabs = seatDynamic.map(({ FlightNumber, rowSeats }, index) => ({
            id: FlightNumber,
            title: getSegmentCityName(get(rowSeats, "0.seats")),
            element: (
              <div className="flex flex-wrap gap-6 justify-center items-end">
                <Decks
                  key={FlightNumber}
                  deck={rowSeats}
                  flightId={FlightNumber}
                  selectionProgress={selectionProgress}
                  setSelectionProgress={setSelectionProgress}
                  nextFlightNumber={getNextFlightNumber(parentIndex, index)}
                  handleExternalTabSelection={handleExternalTabSelection}
                />
              </div>
            ),
          }));
          seatSelectionTabs = [...seatSelectionTabs, ...tabs];
        } else if (!isLCC && !isEmpty(seatPreference)) {
          const allSegmentTitle = getSegmentsTitle(flightPriceReq, resultIndex);
          const requiredTitle = allSegmentTitle.join(", ");
          const tab = {
            id: resultIndex,
            title: requiredTitle,
            element: (
              <>
                <div className="text-md px-4 py-2">
                  {t("seatSelection.selectSeatPreference")}
                </div>
                <RenderSeatPreference seatPreferenceArray={seatPreference} />
              </>
            ),
          };
          seatSelectionTabs = [...seatSelectionTabs, tab];
        }
      }
    );
    if (!isEmpty(seatSelectionTabs)) seatSelectionTabs[ZERO].default = true;
    setSeatTabs(seatSelectionTabs);
  }, [specialServices, flightPriceReq, selectionProgress]);

  return (
    <div className="mt-4">
      <div
        className={classNames(
          "text-gray-900 text-lg font-bold p-6 border bg-white border-gray-200 flex justify-between",
          {
            "rounded-lg": !isExpanded,
            "rounded-t-lg": isExpanded,
            "opacity-60": !isCompleted && !isExpanded,
            "opacity-100": isCompleted || isExpanded,
          }
        )}
      >
        {t("seatSelection.title")}
        <button
          {...getToggleProps()}
          onClick={() => {
            logEvent(`seat_selection_section_reopened`)
            setSelectionProgress({ ...selectionProgress, seatSelection: false })
          }}
        >
          {isCompleted && (
            <RenderSVG
              Svg={EditIcon}
              className="w-6 h-6"
              alt="Toggle dropdown"
            />
          )}
        </button>
      </div>
      <div {...getCollapseProps()}>
        <div className="bg-white border border-contrast-200 rounded-b-lg">
          {isEmpty(specialServices) ? (
            <div className="w-full min-h-[65vh] flex justify-center items-center font-semibold text-contrast-500">
              {t("seatSelection.errorLoading")}
            </div>
          ) : (
            !isEmpty(seatTabs) && <Tabs ref={tabsRef} tabs={seatTabs} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SeatSelection;
