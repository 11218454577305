import MyTrips from "./MyTrips";
import * as bookingActions from "./booking.actions";
import * as selectors from "./booking.selector";
import * as slice from "./booking.reducer";

export const {
  deleteBookingById,
  processRefund,
  getTBORefundCharges,
  getAmadeusRefundCharges,
  processPayment,
  getUserBookingInfo,
  cancelHotelBooking,
} = bookingActions;

export const {
  selectTBOCancellationCharges,
  selectAmadeusCancellationCharges,
  selectProcessPaymentRes,
  selectUserBookingInfo,
  selectIsGenerateVoucherInitiated,
  selectSelectedBooking,
} = selectors;

export const {
  name,
  reducer,
  actions: {
    setSelectedBooking,
    setUserBookingInformation,
    setIsGenerateVoucherInitiated,
  },
} = slice;

export default MyTrips;
