import airlineSuppliers from "../assets/json/airlineInfo.json";
import { DEFAULT_VALUES } from "../constants";

const { EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const defaultAirlineDetails = {
  iata: EMPTY_STRING,
  airlineName: EMPTY_STRING,
  linkToWebCheckin: EMPTY_STRING,
  websiteLink: EMPTY_STRING,
  airlineSupportLink: EMPTY_STRING,
  customerServicePhone: EMPTY_STRING,
};

export const getValidatingAirlineCodes = (itineraries) =>
  Array.from(
    new Set(
      itineraries.reduce((itineraryCarrierCodes, itinerary) => {
        const allSegmentCarrierCodes = itinerary.segments.reduce(
          (segmentCarrierCodes, segment) => [
            ...segmentCarrierCodes,
            segment.carrierCode,
          ],
          []
        );
        return [...itineraryCarrierCodes, ...allSegmentCarrierCodes];
      }, [])
    )
  );

export const getAirlineDetailsByCode = (code) => ({
  ...defaultAirlineDetails,
  ...(airlineSuppliers[code] || EMPTY_OBJECT),
});
