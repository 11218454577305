import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Spinner, { SPINNER_NAMES } from "../../organisms/Spinner";
import FareBreakDownSkeleton from "../../organisms/AppSkeletons/FareBreakDownSkeleton";
import { DEFAULT_VALUES } from "../.././../constants";

const { EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { PRICE } = SPINNER_NAMES;

const DetailsCard = ({
  values = EMPTY_OBJECT,
  valuesPrefix = EMPTY_STRING,
  cardPosition,
}) => {
  const { t } = useTranslation();
  const { heading, shouldShow = true, data = EMPTY_OBJECT } = values;

  return (
    shouldShow && (
      <div
        className={classNames(
          "flex flex-col gap-4 text-base text-contrast-900 p-6",
          { "border-t border-contrast-200": cardPosition }
        )}
      >
        <div className={`font-bold !text-xl`}>{t(heading)}</div>
        <Spinner
          name={PRICE}
          showSkeleton={true}
          loaderComponent={<FareBreakDownSkeleton />}
        >
          {Object.entries(data).map(([title, value]) => (
            <div
              key={title}
              className="flex w-full items-start justify-between py-[4px] font-normal capitalize"
            >
              <div className="flex-1">{t(title)}</div>
              <div className="font-medium">
                {valuesPrefix}
                {value}
              </div>
            </div>
          ))}
        </Spinner>
      </div>
    )
  );
};

export default DetailsCard;
