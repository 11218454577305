import { getAirlineDetailsByCode } from "./getAirlineDetails";
import { getAirportInfoByIata } from "./getAirportInfoByIata";

export const getUpdatedItineraryDetails = (itineraries) => {
  const updatedItineraries = itineraries.map((itinerary) => {
    const updatedSegment = itinerary.segments.map((segment) => {
      const { arrival, departure, carrierCode } = segment;
      const { airlineSupportLink, customerServicePhone, linkToWebCheckin } =
        getAirlineDetailsByCode(carrierCode);
      const arrivalCityName =
        getAirportInfoByIata(arrival.iataCode).cityName || arrival.iataCode;
      const departureCityName =
        getAirportInfoByIata(departure.iataCode).cityName || departure.iataCode;
      return {
        ...segment,
        arrival: { ...arrival, cityName: arrivalCityName },
        departure: { ...departure, cityName: departureCityName },
        airlineSupportLink,
        customerServicePhone,
        linkToWebCheckin,
      };
    });
    return { ...itinerary, segments: updatedSegment };
  });
  return updatedItineraries;
};
