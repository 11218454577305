import * as sessionActions from "./session.actions";
import * as slice from "./session.reducers";
import * as selectors from "./session.selectors";

export const { fetchSessionInfo, updateSessionInfo } = sessionActions;
export const {
  name,
  reducer,
  actions: { setSessionFlag },
} = slice;

export const { selectSessionFlag } = selectors;
