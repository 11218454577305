import { REGEX } from "../constants";

const {CHARACTER_AFTER_WHITESPACE} = REGEX;

const capitalizeFirstLetterOfWord = (value) =>
  value
    .toLowerCase()
    .replace(CHARACTER_AFTER_WHITESPACE, (firstLetter) => firstLetter.toUpperCase());

export default capitalizeFirstLetterOfWord;
