import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch } from "@material-tailwind/react";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { Filter, RenderSVG } from "../../assets/icons";
import {
  selectSelectedDrawer,
  setSelectedDrawer,
} from "../../components/organisms/Drawer";
import { DRAWERS } from "../../components/organisms/AppDrawers/drawer.constants";
import FiltersDrawer from "../../components/organisms/AppDrawers/FiltersDrawers/FiltersDrawer";
import { selectSelectedTripType, selectFiltersCount } from "./index";
import { selectFlights } from "../../components/organisms/Search";
import { actions as flightActions } from "../../components/organisms/Search/search.reducer";
import { actions as flightResultActions } from "./flightResults.reducer";
import {
  TRIP_TYPES,
  DEFAULT_VALUES,
  DEFAULT_FLIGHT_SEARCH_OPTIONS,
  INITIAL_FILTERS,
} from "../../constants";
import logEvent from "../../utils/GATracker";

const { SHOW_FILTERS_DRAWER } = DRAWERS;
const { ONE_WAY, MULTI_CITY } = TRIP_TYPES;
const { ZERO, EMPTY_ARRAY } = DEFAULT_VALUES;
const { setActiveFilters, setFlightSearchOptions, setFilteredFlights } =
  flightActions;
const { setIsPrePackagedFlights } = flightResultActions;
const MAX_CARDS_TO_SHOW = 3;

const FlightHeader = ({
  showPackages,
  setShowPackages,
  currentFlightType,
  isDisabledFilter,
  calculateFiltersLength,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tripType = useSelector(selectSelectedTripType);
  const flights = useSelector(selectFlights) || {};
  const selectedDrawer = useSelector(selectSelectedDrawer);
  const filtersCount = useSelector(selectFiltersCount);

  const [showDrawer, setShowDrawer] = useState(false);

  const { packages = [], isolated = {} } = flights;
  const shouldShowFilter =
    get(packages, "0.itineraries", EMPTY_ARRAY).length > MAX_CARDS_TO_SHOW;

  useEffect(() => {
    (tripType === ONE_WAY || tripType === MULTI_CITY) && setShowDrawer(false);
  }, [tripType]);

  useEffect(() => {
    dispatch(setFilteredFlights(flights));
  }, [flights]);

  const handlePrePackageSelection = useCallback(() => {
    setShowPackages(!showPackages);
    dispatch(setIsPrePackagedFlights(!showPackages));
    dispatch(setActiveFilters(INITIAL_FILTERS));
    dispatch(setFlightSearchOptions(DEFAULT_FLIGHT_SEARCH_OPTIONS));
    dispatch(setFilteredFlights(flights));
  }, [showPackages, flights]);

  return (
    <>
      <div className="flex justify-between gap-3 ml-auto">
        {!isEmpty(packages) && !isEmpty(isolated) && (
          <>
            <Switch
              color="indigo"
              onClick={handlePrePackageSelection}
              readOnly
              checked={showPackages}
              className="h-full w-full !bg-primary-300 !bg-none checked:!bg-primary-900 !rounded-full"
              containerProps={{
                className: "w-11 h-6 ",
              }}
              circleProps={{
                className: "before:hidden left-0.5",
              }}
            />
            <div className="pr-4 py-2 text-contrast-700 text-sm font-medium">
              Pre-Packaged Flights
            </div>
          </>
        )}
      </div>
      {!!filtersCount && (
        <button
          className="py-2 px-4 ml-2 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-600 font-medium"
          onClick={() => {
            logEvent("reset_filters_btn_clicked");
            dispatch(setActiveFilters(INITIAL_FILTERS));
            !selectedDrawer && dispatch(setFilteredFlights(flights));
          }}
          type="button"
        >
          <RenderSVG Svg={Filter} alt="Filter Icon" />
          {t("flightResults.resetFilter")}
        </button>
      )}
      <button
        className={classNames(
          "py-2 px-4 ml-2 flex items-center gap-2 rounded-md disabled:cursor-not-allowed disabled:opacity-75 bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-600 font-medium",
          {
            "xl:hidden":
              tripType === ONE_WAY ||
              (tripType === MULTI_CITY && shouldShowFilter) ||
              !tripType,
          }
        )}
        onClick={() => {
          logEvent("show_filters_btn_clicked");
          setShowDrawer(true);
          dispatch(setSelectedDrawer(SHOW_FILTERS_DRAWER));
        }}
        disabled={isDisabledFilter || (isEmpty(packages) && isEmpty(isolated))}
        type="button"
      >
        <RenderSVG Svg={Filter} alt="Filter Icon" />
        {`${t("flightResults.filter")} (${calculateFiltersLength() || ZERO})`}
      </button>

      {showDrawer && (
        <FiltersDrawer
          currentFlightType={currentFlightType}
          setShow={setShowDrawer}
          show={showDrawer}
          showPackages={showPackages}
        />
      )}
    </>
  );
};

export default FlightHeader;
