import AdminNavigationDrawer from "./AdminNavigationDrawer";
import { DRAWERS } from "./drawer.constants";

const { SHOW_ADMIN_NAVIGATION_DRAWER } = DRAWERS;

const renderDrawer = (name) => ({
    [SHOW_ADMIN_NAVIGATION_DRAWER]: <AdminNavigationDrawer />
}[name || null]);

export default renderDrawer;
