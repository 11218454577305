const moment = require("moment-timezone");
import { getAirportInfoByIata } from "./getAirportInfoByIata";
import { getFormattedDate } from "./getDateTime";

export const getTotalTimeDifference = (
  startIata,
  startDate,
  startTime,
  endIata,
  endDate,
  endTime
) => {
  const startDateTime = `${getFormattedDate(startDate)}T${startTime}`;
  const endDateTime = `${getFormattedDate(endDate)}T${endTime}`;

  const startTimezoneStr =
    getAirportInfoByIata(startIata)?.timeZone || "Asia/Kolkata";
  const endTimezoneStr =
    getAirportInfoByIata(endIata)?.timeZone || "Asia/Kolkata";

  const startUTCDate = moment.tz(startDateTime, startTimezoneStr);
  const endUTCDate = moment.tz(endDateTime, endTimezoneStr);

  const durationInMinutes = endUTCDate.diff(startUTCDate, "minutes");

  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  return `${hours}H ${minutes}M`;
};
