import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import { RenderSVG, NoRooms } from "../../assets/icons";

const NoHotelResults = ({ totalHotels }) => {
  const { t } = useTranslation();
  const hotelSearchFilters = useSelector(selectHotelSearchFilters);
  const { HotelCode = "" } = hotelSearchFilters || {};

  return totalHotels ? (
    <div className="flex flex-col gap-3 min-h-[50vh] items-center justify-center">
      <div className="text-primary-700 text-4xl mb-4 font-semibold uppercase leading-tight tracking-tight">
        {t("hotelResults.tooManyFilters")}
      </div>
      <div className="text-contrast-900 text-md font-semibold">
        {t("hotelResults.removeFilters")}
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-3 min-h-[50vh] items-center justify-center ">
      <div>
        <RenderSVG
          Svg={NoRooms}
          className="text-white"
          alt="Search Icon"
          width={80}
          height={80}
        />
      </div>
      <div className="text-primary-600 font-semibold uppercase leading-tight tracking-tight text-xl">
        {HotelCode ? "No Rooms Available" : t("hotelResults.noHotels")}
      </div>
      <div className="text-contrast-700 text-lg text-md font-semibold">
        {t("hotelResults.tryAgain")}
      </div>
    </div>
  );
};

export default NoHotelResults;
