import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import {
  selectSelectedNonLCCSeats,
  selectSelectedTravelers,
  setSelectedNonLCCSeats,
} from "../index";
import { Minus, Plus, RenderSVG } from "../../../../assets/icons";
import { DEFAULT_VALUES, TRAVELER_TYPE, INDEX } from "../../../../constants";

const { ZERO, ONE, EMPTY_ARRAY } = DEFAULT_VALUES;
const { HELD_INFANT } = TRAVELER_TYPE;
const { LAST } = INDEX;

const getCountOfSelectedPreference = (selectedSeatPreference = [], Code) =>
  selectedSeatPreference.filter((item) => item.Code === Code).length;

const RenderSeatPreference = ({ seatPreferenceArray = EMPTY_ARRAY }) => {
  const dispatch = useDispatch();
  const selectedTravelers = useSelector(selectSelectedTravelers);
  const selectedSeatPreference = useSelector(selectSelectedNonLCCSeats);

  const maxNoOfSelection = selectedTravelers.filter(
    (item) => item.travelerType !== HELD_INFANT
  ).length;
  const toolTipContent = `You cannot select more than ${maxNoOfSelection} seat preferences`;

  const handleSelection = (data, isAdd) => {
    const { Code, resultIndex } = data;

    let updatedSelection = [
      ...(selectedSeatPreference[resultIndex] || EMPTY_ARRAY),
    ];
    if (isAdd) updatedSelection = [...updatedSelection, data];
    else {
      const itemIndex = updatedSelection.findIndex(
        (item) => item.Code === Code
      );
      if (itemIndex !== LAST) updatedSelection.splice(itemIndex, ONE);
    }

    dispatch(setSelectedNonLCCSeats({ [resultIndex]: updatedSelection }));
  };

  const shouldDisable = (resultIndex) => {
    const totalSeatPreferenceSelected =
      selectedSeatPreference[resultIndex]?.length;
    return totalSeatPreferenceSelected >= maxNoOfSelection;
  };

  return (
    <div>
      {seatPreferenceArray.map(({ Description, Code, resultIndex }) => (
        <div key={Code} className="flex justify-between p-6 border-b">
          <div>{Description}</div>
          {shouldDisable(resultIndex) && (
            <Tooltip
              id="seat-pref-tooltip"
              className="!bg-primary-600 !rounded-lg !z-50"
            />
          )}
          <div className="flex">
            <button
              className="disabled:cursor-not-allowed"
              onClick={() => handleSelection({ Code, resultIndex }, ZERO)}
            >
              <RenderSVG Svg={Minus} height="15" alt="Minus" />
            </button>
            <div>
              {getCountOfSelectedPreference(
                selectedSeatPreference[resultIndex],
                Code
              )}
            </div>
            <button
              className="disabled:cursor-not-allowed"
              disabled={shouldDisable(resultIndex)}
              data-tooltip-id="seat-pref-tooltip"
              data-tooltip-content={toolTipContent}
              onClick={() => handleSelection({ Code, resultIndex }, ONE)}
            >
              <RenderSVG Svg={Plus} height="15" alt="Plus" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RenderSeatPreference;
