import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawer, { selectSelectedDrawer } from "../../Drawer";
import { actions } from "../../Drawer/drawers.reducers";
import { DRAWER_SIZE, POSITIONS } from "../../../../constants";
import FlightFilters from "../../../../screens/FlightResults/FlightFilters";
import { selectCurrentFlightType } from "../../../../screens/FlightResults";

const { setSelectedDrawer } = actions;
const { MD } = DRAWER_SIZE;
const { LEFT } = POSITIONS;

const FiltersDrawer = ({
  setShow = () => {},
  show,
  showPackages,
}) => {
  const dispatch = useDispatch();
  const selectedDrawer = useSelector(selectSelectedDrawer);
  const currentFlightType = useSelector(selectCurrentFlightType);

  const handleClose = useCallback(() => {
    dispatch(setSelectedDrawer(null));
    setShow(false);
  }, [dispatch]);
  const getDrawerHeading = () => "flightResults.filter";
  return (
    <Drawer
      position={LEFT}
      size={MD}
      heading={getDrawerHeading()}
      selectedDrawer={selectedDrawer}
      handleClose={handleClose}
      shouldOpen={show}
    >
      <FlightFilters
        currentFlightType={currentFlightType}
        showPackages={showPackages}
      />
    </Drawer>
  );
};

export default FiltersDrawer;
