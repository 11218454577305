import { DEFAULT_VALUES, TRIP_TYPES } from "../constants";
import { getCityNameByIata } from "./index";

const fields = ["excludedAirlines", "includedAirlines", "maxPrice"];
const fieldsToExlude = ["endUserIp", "TokenId"];
const { ZERO, ONE, TWO, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;
const { ROUND_TRIP } = TRIP_TYPES;
const PASSENGER_COUNTS = "passengersCount";
const JOURNEYS = "journeys";
const TRIP_TYPE = "tripType";
const ADULT_QUERY_KEY = "A";
const CHILD_QUERY_KEY = "C";
const INFANT_QUERY_KEY = "I";
const CHILD_AGE_QUERY_KEY = "CA";
const ADULT = "adult";
const CHILDREN = "children";
const INFANTS = "infants";
const CHECK_IN_DATE = "checkInDate";
const CHECK_OUT_DATE = "checkOutDate";
const ROOM_GUESTS = "roomGuests";
const NO_OF_ADULTS = "noOfAdults";
const NO_OF_CHILD = "noOfChild";
const CHILD_AGE = "childAge";
const ISDIRECTFLIGHT = "isDirectFlight";

const getName = (key) => {
  switch (key) {
    case ADULT_QUERY_KEY:
      return ADULT;
    case CHILD_QUERY_KEY:
      return CHILDREN;
    case INFANT_QUERY_KEY:
      return INFANTS;
    default:
      return;
  }
};

const getGuestsCount = (keysAndValues) =>
  keysAndValues.reduce((guestsCount, pair) => {
    const key = pair.split("-")[ZERO];
    const value = pair.split("-")[ONE];

    switch (key) {
      case ADULT_QUERY_KEY:
        return { ...guestsCount, [NO_OF_ADULTS]: Number(value) };
      case CHILD_QUERY_KEY:
        return { ...guestsCount, [NO_OF_CHILD]: Number(value) };
      case CHILD_AGE_QUERY_KEY:
        return { ...guestsCount, [CHILD_AGE]: value ? value.split(".") : [] };
    }
  }, {});

const getDecodedValues = ({ params, key }) => {
  const fieldValues = params[key]
    .split("_")
    .filter((each) => each !== EMPTY_STRING);
  switch (key) {
    case PASSENGER_COUNTS: {
      const values = fieldValues.reduce((obj, each) => {
        const keysAndValue = each.split("-");
        return { ...obj, [getName(keysAndValue[ZERO])]: keysAndValue[ONE] };
      }, {});
      return values;
    }
    case JOURNEYS: {
      if (params[TRIP_TYPE] === ROUND_TRIP) {
        return [
          {
            originCode: {
              iata: fieldValues[ZERO].split("-")[ZERO],
              cityName: getCityNameByIata(fieldValues[ZERO].split("-")[ZERO]),
            },
            destCode: {
              iata: fieldValues[ZERO].split("-")[ONE],
              cityName: getCityNameByIata(fieldValues[ZERO].split("-")[ONE]),
            },
            departureDate: fieldValues[ZERO].split("-")
              .slice(TWO)
              .join("-")
              .split("T")[ZERO],
            departureTime: EMPTY_STRING,
            returnDate: fieldValues[ONE].split("-")
              .slice(TWO)
              .join("-")
              .split("T")[ZERO],
          },
        ];
      } else {
        const values = fieldValues.reduce((arr, each) => {
          const keysAndValue = each.split("-");

          return [
            ...arr,
            {
              originCode: {
                iata: keysAndValue[ZERO],
                cityName: getCityNameByIata(keysAndValue[ZERO]),
              },
              destCode: {
                iata: keysAndValue[ONE],
                cityName: getCityNameByIata(keysAndValue[ONE]),
              },
              departureDate: keysAndValue.slice(TWO).join("-"),
              departureTime: EMPTY_STRING,
              returnDate: EMPTY_STRING,
            },
          ];
        }, []);
        return values;
      }
    }
    case ISDIRECTFLIGHT:
      return params[key] === "true";
    case ROOM_GUESTS:
      return fieldValues.reduce((entireFieldValue, fieldString) => {
        const keysAndValues = fieldString.split("~");
        return [...entireFieldValue, getGuestsCount(keysAndValues)];
      }, []);
    default:
      return params[key];
  }
};

export const getObjectFromQueryParams = (queryString) => {
  const urlParams = new URLSearchParams(queryString);
  const params = Object.fromEntries(urlParams);
  const data = Object.entries(params).reduce((obj, [key]) => {
    const requestObject = {
      ...obj,
      [key]: getDecodedValues({
        params,
        key,
      }),
    };
    return queryString.includes(JOURNEYS)
      ? {
          ...requestObject,
          includedAirlines: EMPTY_ARRAY,
          excludedAirlines: EMPTY_ARRAY,
          maxPrice: EMPTY_STRING,
        }
      : requestObject;
  }, {});
  return data;
};

const getFormattedValues = ({ filters, key }) => {
  switch (key) {
    case PASSENGER_COUNTS:
      return Object.entries(filters[key]).reduce((string, [key, value]) => {
        return string + `${key.charAt(ZERO).toUpperCase()}-${value}_`;
      }, "");
    case JOURNEYS:
      return filters[JOURNEYS].reduce((string, obj) => {
        const { originCode, destCode, returnDate, departureDate } = obj;
        const formattedDepartureDate = departureDate.split("T")[ZERO];

        if (filters[TRIP_TYPE] === ROUND_TRIP)
          return (
            string +
            `${originCode?.iata}-${destCode?.iata}-${formattedDepartureDate}_${
              destCode?.iata
            }-${originCode?.iata}-${returnDate.split("T")[ZERO]}_`
          );
        else
          return (
            string +
            `${originCode?.iata}-${destCode?.iata}-${formattedDepartureDate}_`
          );
      }, EMPTY_STRING);
    case CHECK_IN_DATE: 
    case CHECK_OUT_DATE: {
      const [day, month, year] = filters[key].split("/");
      return `${year}-${month}-${day}`;
    }
    case ROOM_GUESTS:
      return filters[key].reduce((string, obj) => {
        const { noOfAdults, noOfChild = ZERO, childAge } = obj;
        return (
          string +
          `A-${noOfAdults}~C-${noOfChild}~CA-${
            childAge ? `~CA-${childAge.join(".")}` : ""
          }_`
        );
      }, EMPTY_STRING);
    default:
      return filters[key];
  }
};

export const getQueryParams = (object) => {
  const filters = Object.fromEntries(
    Object.entries(object).filter(
      ([key, value]) => value !== EMPTY_STRING && value !== undefined && !fields.includes(key)
    )
  );

  const formattedObject = Object.keys(filters).reduce((obj, key) => {
    return !fieldsToExlude.includes(key)
      ? { ...obj, [key]: getFormattedValues({ filters, key }) }
      : obj;
  }, {});

  return Object.keys(formattedObject)
    .map((key) => {
      return `${key}=${encodeURIComponent(formattedObject[key])}`;
    })
    .join("&");
};
