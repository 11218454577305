import { getLoaderUrl } from "../../helper";

const FlightLoader = () => (
  <img
    src={getLoaderUrl("flightResult.gif")}
    className="h-48 w-48 mr-4"
    alt="spinner-icon"
  />
);

export default FlightLoader;
