import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { DEFAULT_VALUES } from "../../../../constants";
import HotelCard from "./HotelCard";

const { ZERO, TWELVE } = DEFAULT_VALUES;
const { REACT_APP_MAPBOX_ACCESS_TOKEN = "" } = process.env;

mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = ({ hotels, handleSelectHotel, activeHotel, onBoundsChange, setIsAnimating }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const markersRef = useRef({});
  const [tooltipPosition, setTooltipPosition] = useState({
    left: ZERO,
    top: ZERO,
  });
  const [hoveredHotel, setHoveredHotel] = useState(null);

  useEffect(() => {
    const bounds = new mapboxgl.LngLatBounds();

    hotels.forEach(({ latitude, longitude }) => {
      bounds.extend([longitude, latitude]);
    });
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      zoom: TWELVE,
      attributionControl: false,
    });

    map.fitBounds(bounds, {
      padding: 40,
    });

    setIsAnimating(true);

    map.on("moveend", () => {
      setIsAnimating(false);
    });

    mapRef.current = map;

    hotels.forEach((hotel) => {
      const markerElement = document.createElement("div");
      markerElement.className = "custom-marker";
      markerElement.dataset.id = hotel.hotelCode;

      const markerInnerElement = document.createElement("div");
      markerInnerElement.className = "custom-marker-inner";

      markerElement.appendChild(markerInnerElement);

      const marker = new mapboxgl.Marker(markerElement)
        .setLngLat([hotel.longitude, hotel.latitude])
        .addTo(map);

      markersRef.current[hotel.hotelCode] = markerElement;

      marker.getElement().addEventListener("click", () => {
        handleSelectHotel(hotel);
      });

      marker.getElement().addEventListener("mouseenter", () => {
        const point = map.project([hotel.longitude, hotel.latitude]);
        setTooltipPosition({
          left: point.x,
          top: point.y,
        });
        setHoveredHotel(hotel);
      });

      marker.getElement().addEventListener("mouseleave", () => {
        setHoveredHotel(null);
      });
    });
    const handleMapMove = () => {
      const bounds = map.getBounds();
      onBoundsChange(bounds);
    };

    map.on("moveend", handleMapMove);

    return () => {
      map.off("moveend", handleMapMove);
      map.remove();
    };
  }, []);

  useEffect(() => {
    Object.values(markersRef.current).forEach((markerElement) => {
      markerElement.classList.remove("marker-popup");
    });

    if (activeHotel) {
      const markerElement = markersRef.current[activeHotel.hotelCode];
      if (markerElement) {
        markerElement.classList.add("marker-popup");
      }
    }
  }, [activeHotel]);

  useEffect(() => {
    if (mapRef.current) {
      const mapContainer = mapRef.current.getContainer();
      const mapLogo = mapContainer.querySelector(".mapboxgl-ctrl-logo");
      if (mapLogo) mapLogo.style.display = "none";
    }
  }, [mapRef]);

  return (
    <div className='w-full h-full relative newest' ref={mapContainerRef}>
      {hoveredHotel && (
        <div
          id={`tooltip-${hoveredHotel.hotelCode}`}
          className='absolute rounded transform -translate-x-1/2 translate-y-[10%]'
          style={{
            left: `${tooltipPosition.left}px`,
            top: `${tooltipPosition.top}px`,
          }}
        >
          <HotelCard hotel={hoveredHotel} isToolTip={true} />
        </div>
      )}
    </div>
  );
};

export default Map;
