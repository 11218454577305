import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useCollapse } from "react-collapsed";
import { get, isEmpty } from "lodash";
import classNames from "classnames";
import {
  ArrowLeft,
  ChevronDown,
  ChevronUp,
  RenderSVG,
} from "../../../assets/icons";
import {
  getAirlineIconUrlByCarrierCode,
  getCityNameByIata,
  getDayDiffrence,
  getDayFromDate,
  getFormattedLongDate,
  getStopLabel,
} from "../../../helper";
import FlightJourneyDetails from "../../../screens/FlightResults/FlightJourneyDetails";
import { BOOKING_STATUS, DEFAULT_VALUES } from "../../../constants";

const { ZERO, ONE, EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY } = DEFAULT_VALUES;
const { CONFIRMED } = BOOKING_STATUS;


const FlightInfo = ({ pnrList, itinerary, index, bookingStatus = [] }) => {
  const { t } = useTranslation();
  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse({
    duration: 200,
  });

  const { duration, segments = EMPTY_ARRAY } = itinerary;
  const departure = get(segments, "0.departure", EMPTY_OBJECT);
  const arrival = get(
    segments,
    `${segments.length - ONE}.arrival`,
    EMPTY_OBJECT
  );
  const journeyStartDate = departure.date;
  const journeyStartDay = journeyStartDate && getDayFromDate(journeyStartDate);
  const daysRemaining =
    journeyStartDate && getDayDiffrence(new Date(), journeyStartDate);

  const carrierCode = get(segments, "0.carrierCode", EMPTY_STRING);
  const numberOfStops = segments.length - ONE;
  const stopsLabel = getStopLabel(numberOfStops, t);

  const isBookingConfirmed = !isEmpty(bookingStatus) && (bookingStatus[index] === CONFIRMED || bookingStatus[index] === 1)
  const TODAY = "Today";
  const TOMORROW = "Tomorrow";
  const getFormattedRemainingDays = (daysRemaining) => {
    if (daysRemaining === ZERO) return TODAY;
    if (daysRemaining === ONE) return TOMORROW;
    const absDays = Math.abs(daysRemaining);
    const dayWord = (days) => (days === ONE ? "day" : "days");
    return daysRemaining > ZERO
      ? `In ${daysRemaining} ${dayWord(daysRemaining)}`
      : `${absDays} ${dayWord(absDays)} ago`;
  };
  return (
    <div key={journeyStartDate}>
      <div
        className={classNames("p-4 bg-primary-100/30", {
          "border-t border-contrast-300 border-dashed": index,
          "bg-red-100/30": index < pnrList?.length && !pnrList?.[index],
        })}
      >
        <div className={classNames("flex sm:justify-between",{
          "justify-around ":pnrList?.length
        })}>
          <p className="mb-2 text-[10px] font-semibold text-contrast-700">
            {getFormattedRemainingDays(daysRemaining)} |{" "}
            {getFormattedLongDate(journeyStartDate, {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}{" "}
            | {journeyStartDay}
          </p>
          <div>
            {index === ZERO && pnrList?.length === ONE && (
              <div
                className={classNames(
                  "text-[10px] mb-2 sm:mb-0 sm:text-xs text-contrast-400 bg-primary-50 border border-primary-600 inline-flex rounded-2xl overflow-hidden",
                  { "bg-red-50 border border-red-600": !pnrList[index] && !isBookingConfirmed }
                )}
              >
                {(pnrList[index] && isBookingConfirmed) ? (
                  <span>
                    <span className="px-2 py-0.5 bg-primary-600 text-white">
                      PNR
                    </span>
                    <span className="font-bold text-contrast-900 px-1 py-0.5">
                      {pnrList[index]}
                    </span>
                  </span>
                ) : (
                  <span className="font-bold text-red-700 px-3 py-0.5">
                    Booking Failed!
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 sm:gap-4 mb-4">
          <div className="col-span-12 md:col-span-8 2xl:col-span-8">
            <div className="sm:flex flex-around gap-1 sm:gap-3 ">
              <div className="logo">
                <img
                  src={getAirlineIconUrlByCarrierCode(carrierCode)}
                  width="30"
                  className="rounded border border-contrast-300"
                  alt="airline logo"
                />
              </div>
              <div className="flex justify-around sm:gap-2 sm:gap-6 sm:justify-between">
                <div className="">
                  <h5 className="text-xs xs:text-sm font-bold text-contrast-900 mb-1">
                    {getCityNameByIata(departure.iataCode)} (
                    {departure.iataCode})
                  </h5>
                  <div className="text-[10px] xs:text-xs text-contrast-600 flex gap-2">
                    <span>{departure.time}</span>
                    <span className="inline-block w-24">
                      {departure.airportName}
                    </span>
                  </div>
                </div>
                <div className="hidden xs:flex shrink-0 opacity-50">
                  <RenderSVG Svg={ArrowLeft} width="20" />
                </div>
                <div className="">
                  <h5 className="text-xs xs:text-sm font-bold text-contrast-900 mb-1">
                    {getCityNameByIata(arrival.iataCode)} ({arrival.iataCode})
                  </h5>
                  <div className="text-xs xs:text-xs flex flex-col text-contrast-600 flex gap-2">
                    <span>{arrival.time}</span>
                    <span className="inline-block w-24">
                      {arrival.airportName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-4 2xl:col-span-4 flex gap-4">
            <div className="flex-1 text-start">
              <h5 className="text-xs text-contrast-800 font-bold mb-1">
                {duration} | {stopsLabel}
              </h5>
              {index < pnrList?.length && pnrList?.length > ONE && (
                <div
                  className={classNames(
                    "text-[10px] sm:text-xs text-contrast-400 bg-primary-50 border border-primary-600 inline-flex rounded-2xl overflow-hidden",
                    { "bg-red-50 border border-red-600": !pnrList[index] && !isBookingConfirmed }
                  )}
                >
                  {(pnrList[index] && isBookingConfirmed) ? (
                    <span>
                      <span className="px-2 py-0.5 bg-primary-600 text-white">
                        PNR
                      </span>
                      <span className="font-bold text-contrast-900 px-1 py-0.5">
                        {pnrList[index]}
                      </span>
                    </span>
                  ) : (
                    <span className="font-bold text-red-700 px-2 py-0.5">
                      Booking Failed!
                    </span>
                  )}
                </div>
              )}
            </div>
            <button
              className="flex p-2 cursor-pointer shrink-0"
              {...getToggleProps()}
            >
              <RenderSVG
                Svg={isExpanded ? ChevronUp : ChevronDown}
                className="text-contrast-800"
                width="20"
              />
            </button>
          </div>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="py-4 px-4 border-t border-contrast-300 border-dashed bg-white">
          <FlightJourneyDetails segments={itinerary.segments} isBookingPage />
        </div>
      </div>
    </div>
  );
};

export default memo(FlightInfo);
