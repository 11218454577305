import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDrawer: null,
};

const slice = createSlice({
  name: "drawers",
  initialState,
  reducers: {
    setSelectedDrawer: (state, action) => {
      state.selectedDrawer = action.payload;
    },
  },
});

export default slice;

export const { name, reducer, actions } = slice;
