import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { DEFAULT_VALUES, SKELETON_COLOR } from "../../../constants";

const { ONE } = DEFAULT_VALUES;
const { BASE_COLOR, HIGHTLIGHT__COLOR} = SKELETON_COLOR;

const PriceBreakdownSkeleton = () => {
  const skeletonRows = Array.from({ length: 4 }, (_, index) => (
    <div key={index} className="w-full flex gap-5">
      <div className="w-[60%]">
        <Skeleton count={ONE} height={30} width={"100%"} />
      </div>
      <div className="w-[40%]">
        <Skeleton count={ONE} height={30} width={"100%"} />
      </div>
    </div>
  ));

  return (
    <SkeletonTheme baseColor={BASE_COLOR} highlightColor={HIGHTLIGHT__COLOR}>
      <div>{skeletonRows}</div>
    </SkeletonTheme>
  );
};

export default PriceBreakdownSkeleton;
