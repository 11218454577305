import { Link } from "react-router-dom";
import { ROUTES, DEFAULT_VALUES, BOOKING_STATUS } from "../../../constants";
import { flightBookingData } from "./mockData";

const { ADMIN_BOOKINGS } = ROUTES;
const { ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { CONFIRMED, PENDING, REJECTED } = BOOKING_STATUS;
const tableHeaders = [
  { key: "#", label: "Number" },
  { key: "country", label: "Country" },
  { key: "total", label: "Total" },
  { key: "paid", label: "Paid" },
  { key: "status", label: "Status" },
  { key: "createdAt", label: "Created At" },
];

const getStatusStyle = (statusLabel) => {
  switch (statusLabel) {
    case CONFIRMED:
      return "bg-blue-100 text-blue-500";
    case PENDING:
      return "bg-yellow-200 text-yellow-500";
    case REJECTED:
      return "bg-red-200 text-red-500";
    default:
      return EMPTY_STRING;
  }
};

const RecentBooking = () => {

  return (
    <>
      <div className="flex justify-between items-center mx-2 mb-6">
        <h2 className="text-xl font-medium">Recent Bookings</h2>
        <Link
          className="text-base text-blue-500 font-medium underline"
          to={ADMIN_BOOKINGS}
        >
          View All
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-center rounded-lg">
          <thead className="bg-gray-100">
            <tr>
              {tableHeaders.map((header) => (
                <th key={header.key} className="py-4 px-4 font-medium">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {flightBookingData.map(
              (
                { country, description, total, paid, status, createdAt, id },
                index
              ) => {
                const statusStyle = getStatusStyle(status.label);

                return (
                  <tr
                    key={id}
                    className="cursor-pointer hover:bg-gray-100 text-sm border-b border-dashed border-gray-300 whitespace-nowrap"
                  >
                    <td className="py-2 min-w-[140px]">{index + ONE}</td>
                    <td className="py-2 min-w-[140px]">
                      {country}
                      <br /> {description}
                    </td>
                    <td className="py-4 font-medium min-w-[140px]">{total}</td>
                    <td className="py-4 min-w-[140px]">{paid}</td>
                    <td
                      className={`py-4 min-w-[140px] text-center text-sm font-medium ${statusStyle}`}
                    >
                      {status.label}
                    </td>
                    <td className="py-4 min-w-[140px]">{createdAt}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RecentBooking;
