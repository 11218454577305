import { Booking, EarningIcon, PendingIcon, ServiceIcon } from "../../../assets/icons";

export const flightBookingData = [
  {
    id: 1,
    country: "New York",
    description: "Discover America",
    total: "$130",
    paid: "$0",
    status: { label: "Pending" },
    createdAt: "04/04/2022 08:16",
  },
  {
    id: 2,
    country: "America",
    description: "Discover America",
    total: "$130",
    paid: "$0",
    status: { label: "Confirmed" },
    createdAt: "04/04/2022 08:16",
  },
  {
    id: 3,
    country: "New Zealand",
    description: "Discover America",
    total: "$130",
    paid: "$0",
    status: { label: "Rejected" },
    createdAt: "04/04/2022 08:16",
  },
  {
    id: 4,
    country: "India",
    description: "Discover India",
    total: "$130",
    paid: "$100",
    status: { label: "Confirmed" },
    createdAt: "04/04/2022 08:16",
  },
  {
    id: 5,
    country: "New York",
    description: "Discover America",
    total: "$130",
    paid: "$0",
    status: { label: "Pending" },
    createdAt: "04/04/2022 08:16",
  },
  {
    id: 6,
    country: "New York",
    description: "Discover America",
    total: "$130",
    paid: "$0",
    status: { label: "Confirmed" },
    createdAt: "04/04/2022 08:16",
  },
  {
    id: 7,
    country: "New York",
    description: "Discover America",
    total: "$130",
    paid: "$0",
    status: { label: "Rejected" },
    createdAt: "04/04/2022 08:16",
  },
  {
    id: 8,
    country: "New York",
    description: "Discover America",
    total: "$130",
    paid: "$0",
    status: { label: "Confirmed" },
    createdAt: "04/04/2022 08:16",
  },
];

export const cardsData = [
  {
    id: 1,
    title: "Pending",
    amount: "$12,800",
    description: "Total pending",
    icon: PendingIcon,
  },
  {
    id: 2,
    title: "Earnings",
    amount: "$14,200",
    description: "Total earnings",
    icon: EarningIcon,
  },
  {
    id: 3,
    title: "Bookings",
    amount: "$84,100",
    description: "Total bookings",
    icon: Booking,
  },
  {
    id: 4,
    title: "Services",
    amount: "$22,786",
    description: "Total services",
    icon: ServiceIcon,
  },
];
