import { Cross, RenderSVG } from "../../assets/icons";
import { ROUTES } from "../../constants";
import logEvent from "../../utils/GATracker";

const { HOME } = ROUTES;

const SessionExpiredModal = () => {
  const handleGoToHome = () => {
    logEvent(`session_expired_return_to_home_btn_clicked`)
    window.location.assign(HOME);
  }

  return (
    <div
      className={
        "fixed bg-blue-gray-900 inset-0 z-[999] overflow-auto transition-opacity duration-300 no-scrollbar items-center px-4 flex"
      }
    >
      <div
        className={
          "bg-white border w-full mx-auto rounded-md overflow-hidden my-6 sm:w-[600px] h-fit"
        }
      >
        <div className="flex items-center px-10 pb-6 pt-6 border-b border-blue-gray-300">
          <div>
            <h6
              className={"font-bold text-xl sm:text-2xl text-contrast-900 mb-1"}
            >
              Session Expired!
            </h6>
            <p className="text-sm text-blue-gray-500">
              Opps! Your session has expired.
            </p>
          </div>
          <button
            type="button"
            className="ml-auto rounded-md text-contrast-900 hover:scale-110 duration-200"
            onClick={() => handleGoToHome()}
          >
            <RenderSVG Svg={Cross} alt="Cross Icon" className="w-8 h-6" />
          </button>
        </div>
        <div className="flex items-center justify-center py-8 px-7">
          <p className="text-blue-gray-800">
            Your session has expired due to inactivity. Please visit the
            Homepage and start a new Flight Search to continue with a delightful
            booking experience. Safe travels!
          </p>
        </div>

        <div className="flex justify-center flex-col item-center py-6 bg-blue-gray-50/70 border-t border-blue-gray-300">
          <button
            className="text-white font-medium rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 text-sm py-2 items-center self-center"
            onClick={handleGoToHome}
          >
            <span className="py-4 px-4">Return to Homepage </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
