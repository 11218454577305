import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import classNames from "classnames";
import { priceFormatter } from "../../../../helper";
import {
  CURRENCY_SYMBOLS,
  DEFAULT_CURRENCY_CODE,
  DEFAULT_VALUES,
  FLIGHTS_RESULT_TYPE,
  MINI_FARE_RULES_CHARGES,
  MINI_FARE_RULES_TYPES,
} from "../../../../constants";
import {
  selectCurrentFlightType,
  setSelectedFlightOptions,
  selectSelectedFlightOptions,
} from "../../../../screens/FlightResults";
import { CheckGreen, Minus, RenderSVG } from "../../../../assets/icons";
import { setSelectedModal } from "../../Modal";
import logEvent from "../../../../utils/GATracker";

const { ZERO, EMPTY_ARRAY } = DEFAULT_VALUES;
const { PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;

const { NO_REFUND, FULL_REFUND } = MINI_FARE_RULES_CHARGES;
const { CANCELLATION } = MINI_FARE_RULES_TYPES;

const getChargedFees = (rule) => {
  const feeValue = rule?.Details;

  if (feeValue === NO_REFUND || feeValue.toLowerCase() === FULL_REFUND)
    return feeValue;
  else return feeValue.match(/\d+/) ? feeValue.match(/\d+/)[0] : null;
};

const getUniqueKeyValueForFareRule = (fareRule) => `${fareRule?.Type}_${fareRule?.From}_${fareRule?.To}_${fareRule?.Unit}`

const FlightOptionCard = ({
  flight,
  currency = DEFAULT_CURRENCY_CODE,
  flightOptionsCount,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentFlightType = useSelector(selectCurrentFlightType);
  const selectedFlightOptions = useSelector(selectSelectedFlightOptions);

  const { baggage } = flight || {};
  const { weight, noOfBags, cabinBaggage } = baggage;
  const totalPrice = get(flight, "price.totalPrice", ZERO);
  const miniFareRules = get(flight, "miniFareRules[0]", EMPTY_ARRAY);

  const handleFlightSelect = (flight) => {
    if (currentFlightType === PACKAGE_RESULT)
      dispatch(
        setSelectedFlightOptions({
          [currentFlightType]: flight,
        })
      );
    else
      dispatch(
        setSelectedFlightOptions({
          ...selectedFlightOptions,
          [currentFlightType]: flight,
        })
      );
    dispatch(setSelectedModal(null));
  };

  return (
    <div
      className={classNames(
        "rounded-lg shadow-md flex flex-col border col-span-12 border-gray-200 bg-white",
        {
          "lg:col-span-12": flightOptionsCount === 1,
          "lg:col-span-6": flightOptionsCount === 2,
          "lg:col-span-4": flightOptionsCount > 2,
        }
      )}
    >
      <div className="px-4 py-3 border-b border-gray-200 flex gap-2">
        <div>
          <h4 className="text-lg font-bold text-black mb-1">
            {CURRENCY_SYMBOLS[currency]}
            {priceFormatter(totalPrice)}{" "}
          </h4>
        </div>
      </div>
      <div className="px-4 py-5 flex flex-col gap-8 flex-1">
        <div>
          {(weight || noOfBags || cabinBaggage) && (
            <div className="font-bold text-sm mb-4 text-gray-900">
              {t("flightResults.baggage")}
            </div>
          )}
          <ul className="flex flex-col gap-3">
            {(weight || noOfBags) && (
              <li className="flex items-center gap-2">
                <div className="bg-teal-100 flex-shrink-0 grid place-content-center w-4 h-4 rounded-full">
                  <RenderSVG Svg={CheckGreen} width="8" />
                </div>
                <span className="text-xs font-medium text-gray-700">
                  {t("flightResults.baggageAllowed")}: {weight || noOfBags}
                </span>
              </li>
            )}

            {cabinBaggage && (
              <li className="flex items-center gap-2">
                <div className="bg-teal-100 flex-shrink-0 grid place-content-center w-4 h-4 rounded-full">
                  <RenderSVG Svg={CheckGreen} width="8" />
                </div>
                <span className="text-xs font-medium text-gray-700">
                  {cabinBaggage} {t("flightResults.checkInBaggages")}
                </span>
              </li>
            )}
          </ul>
        </div>

        {miniFareRules?.length > 0 && (
          <div>
            <div className="font-bold text-sm mb-4 text-gray-900">
              {t("flightResults.flexibility")}
            </div>
            <ul className="flex flex-col gap-3">
              {miniFareRules.map((fareRule) => {
                const chargedFee = getChargedFees(fareRule);
                const isCancellationRule = fareRule.Type?.toLowerCase() === CANCELLATION;

                return (
                  <div key={getUniqueKeyValueForFareRule(fareRule)}>
                    {chargedFee && fareRule.From && fareRule.Unit && (
                      <li className="flex gap-2">
                        <div
                          className={classNames(
                            "flex-shrink-0 grid place-content-center w-4 h-4 rounded-full",
                            {
                              "bg-amber-100":
                                chargedFee === NO_REFUND || Number(chargedFee),
                              "bg-teal-100":
                                chargedFee.toLowerCase() === FULL_REFUND ||
                                Number(chargedFee) === 0,
                            }
                          )}
                        >
                          {chargedFee === NO_REFUND || Number(chargedFee) ? (
                            <RenderSVG Svg={Minus} width="8" />
                          ) : (
                            <RenderSVG Svg={CheckGreen} width="8" />
                          )}
                        </div>
                        <span className="text-xs font-medium text-gray-700">
                          {chargedFee === NO_REFUND && (
                            <>
                              {isCancellationRule
                                ? t("flightResults.noRefundCancellation")
                                : t("flightResults.noRefundDateChange")}
                            </>
                          )}
                          {(chargedFee.toLowerCase() === FULL_REFUND ||
                            Number(chargedFee) === 0) && (
                            <>
                              {isCancellationRule
                                ? t("flightResults.freeCancellation")
                                : t("flightResults.freeDateChange")}{" "}
                            </>
                          )}
                          {Number(chargedFee) > 0 && (
                            <>
                              {isCancellationRule
                                ? t("flightResults.cancellationFeeStartsAt")
                                : t("flightResults.dateChangeFeeStartsAt")}{" "}
                              {CURRENCY_SYMBOLS[currency]}
                              {priceFormatter(chargedFee)}{" "}
                            </>
                          )}
                          ({fareRule.From}
                          {fareRule.To && <>-{fareRule.To}</>} {fareRule.Unit}{" "}
                          {t("flightResults.beforeDeparture")})
                        </span>
                      </li>
                    )}
                  </div>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <div className="border-t border-gray-200 px-4 py-5 flex gap-3">
        <button
          className="w-full py-2 px-4 flex-1 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium"
          onClick={() => {
            logEvent(`flight_selected_from_options_card`)
            handleFlightSelect(flight)
          }}
        >
          {t("flightResults.selectFlight")}
        </button>
      </div>
    </div>
  );
};

export default FlightOptionCard;
