import { isEmpty, get } from "lodash";
import { removeLeadingZerosFromDate } from "./dateFormatter";
import { DEFAULT_VALUES, MINI_FARE_RULES_CHARGES, MINI_FARE_RULES_TYPES } from "../constants";

const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { CANCELLATION } = MINI_FARE_RULES_TYPES
const { NO_REFUND, FULL_REFUND } = MINI_FARE_RULES_CHARGES
const MIN_VALID_FLIGHT_OPTION_COUNT = 2;

const getFeeValueFromString = (stringFormat) =>
  stringFormat.match(/\d+/) ? stringFormat.match(/\d+/)[0] : null;

const getTargetFeeValue = (fareRules) => {
  let prevCharge = get(fareRules, "0.Details", EMPTY_STRING);

  for (const fareRule of fareRules) {
    const { Details: details } = fareRule;

    if (details === NO_REFUND || details.toLowerCase() === FULL_REFUND) {
      prevCharge = details;
      break;
    } else {
      const charge = getFeeValueFromString(details);
      const target = getFeeValueFromString(prevCharge);

      if (Number(charge) < Number(target)) prevCharge = charge;
    }
  }

  return prevCharge;
};

const verifyIsValidFareRules = (fareRules = []) =>
  fareRules.filter(
    ({ Details, Unit, From, Type = EMPTY_STRING }) =>
      Details && Unit && From && Type.toLowerCase() === CANCELLATION
  );

const getFilteredValidOptions = (combinedFlights) => {
  // filtered flights having fare rules:
  const validFareRulesFlights = combinedFlights.filter((flight) => {
    const miniFareRules = get(flight, "miniFareRules.0", []);
    const validFareRules = verifyIsValidFareRules(miniFareRules);
    return validFareRules.length;
  });

  if (validFareRulesFlights.length < MIN_VALID_FLIGHT_OPTION_COUNT) return validFareRulesFlights;

  const groupedByPrice = validFareRulesFlights.reduce(
    (accumulatedFlights, flight) => {
      const flightPrice = get(flight, "price.totalPrice", 0);
      const flightFareRules = get(flight, "miniFareRules.0", []);
      const flightCancellationsInfo = flightFareRules.filter(
        (fareRule) =>
          get(fareRule, "Type", EMPTY_STRING).toLowerCase() === CANCELLATION
      );
      const flightCancellationUnit = get(
        flightCancellationsInfo,
        "0.Unit",
        EMPTY_STRING
      ).toLowerCase();

      // grouping the flight options on the basis of total price and cancellation unit.
      if (!accumulatedFlights[`${flightPrice}_${flightCancellationUnit}`]) {
        accumulatedFlights[`${flightPrice}_${flightCancellationUnit}`] = [
          flight,
        ];
        return accumulatedFlights;
      }
      else {
        const targetCharge = getTargetFeeValue(flightCancellationsInfo);

        // if new option has No Refund cancellation, exclude it.
        // if new option has Full Refund cancellation, make it the primary option of the group.
        if (targetCharge === NO_REFUND) return accumulatedFlights;
        if (targetCharge.toLowerCase() === FULL_REFUND) {
          accumulatedFlights[`${flightPrice}_${flightCancellationUnit}`] = [
            flight,
          ];
          return accumulatedFlights;
        }

        const prevFareRules = get(
          accumulatedFlights[`${flightPrice}_${flightCancellationUnit}`][0],
          "miniFareRules.0",
          []
        );
        const prevCancellationsInfo = prevFareRules.filter(
          (fareRule) =>
            get(fareRule, "Type", EMPTY_STRING).toLowerCase() === CANCELLATION
        );
        const prevCharge = getTargetFeeValue(prevCancellationsInfo);

        // if previously stored option in the group has No Refund cancellation, make the new option as primary of the group.
        // if previously stored option in the group has Full Refund cancellation, discard the new option.
        if (prevCharge === NO_REFUND) {
          accumulatedFlights[`${flightPrice}_${flightCancellationUnit}`] = [
            flight,
          ];
          return accumulatedFlights;
        }
        if (prevCharge.toLowerCase() === FULL_REFUND) return accumulatedFlights;

        // compare the lowest cancellation fee in new option and the previously stored option in the group.
        if (
          Number(getFeeValueFromString(targetCharge)) <
          Number(getFeeValueFromString(prevCharge))
        ) {
          accumulatedFlights[`${flightPrice}_${flightCancellationUnit}`] = [
            flight,
          ];
          return accumulatedFlights;
        } else return accumulatedFlights;
      }
    },
    {}
  );

  const uniqueFlights = Object.values(groupedByPrice);
  return uniqueFlights.flat();
};

const groupDuplicateFlights = (flights = []) => {
  const uniqueFlightsMap = new Map();

  flights.forEach((flight) => {
    let flightKey = "";
    const { itineraries = [] } = flight[ZERO];

    itineraries.forEach((itinerary) => {
      const { segments = [] } = itinerary;
      const firstSegment = segments[ZERO];
      const lastSegment = segments[segments.length - ONE];
      const {
        carrierName,
        departure: { time: departureTime, date: departureDate },
        flightNumber,
      } = firstSegment;
      const {
        arrival: { time: arrivalTime, date: arrivalDate },
      } = lastSegment;
      const formattedCarrierName =
        carrierName?.toLowerCase().trim() || EMPTY_STRING;
      const formattedDepartureDate = removeLeadingZerosFromDate(departureDate);
      const formattedArrivalDate = removeLeadingZerosFromDate(arrivalDate);

      flightKey += `${formattedCarrierName}_${flightNumber}_${departureTime}_${formattedDepartureDate}_${arrivalTime}_${formattedArrivalDate}`;
    });

    if (uniqueFlightsMap.has(flightKey)) {
      const flightOptionsSet = uniqueFlightsMap.get(flightKey);
      const filteredValidOptions = getFilteredValidOptions([
        ...flightOptionsSet,
        ...flight,
      ]);
      if (filteredValidOptions.length)
        uniqueFlightsMap.set(flightKey, filteredValidOptions);
    } else {
      uniqueFlightsMap.set(flightKey, flight);
    }
  });

  const duplicateFlightsArray = Array.from(uniqueFlightsMap.values());
  duplicateFlightsArray.forEach((flightsArray) => {
    flightsArray.sort((a, b) => a.price.totalPrice - b.price.totalPrice);
  });

  return duplicateFlightsArray;
};

export const handleGroupedFlights = (flights) => {
  const { isolated = {}, packages = [] } = flights;
  if (!isEmpty(isolated)) {
    const outbound = groupDuplicateFlights(isolated.outbound);
    const inbound = groupDuplicateFlights(isolated.inbound);
    return { isolated: { outbound, inbound }, packages: [] };
  } else if (!isEmpty(packages)) {
    const updatedFlight = groupDuplicateFlights(packages);
    return { packages: updatedFlight };
  }
  return {};
};
