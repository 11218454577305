import { createSlice } from "@reduxjs/toolkit";
import get from "lodash/get";
import { regenerateAccessToken } from "./auth.actions";
import { setToLocalStorage } from "../../helper";
import { AUTH_DATA } from "../../constants";

const { AUTH } = AUTH_DATA;

const initialState = { authInfo: null };

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo(state, action) {
      state.authInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(regenerateAccessToken.fulfilled, (state, action) => {
      if (!action.payload) return;
      const tokens = get(action, "payload.output.response.token", {});
      const newAccessToken = tokens.accessToken;
      const newRefreshToken = tokens.refreshToken;
      const updatedAuthDetails = state.authInfo && {
        ...state.authInfo,
        accessToken: newAccessToken,
        refreshToken: newRefreshToken,
      };
      state.authInfo = updatedAuthDetails;
      setToLocalStorage(AUTH, updatedAuthDetails);
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
