import { createAsyncThunk } from "@reduxjs/toolkit";
import get from "lodash/get";
import asyncAction from "../../infrastructure/asyncAction";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { REQUEST_METHODS, DEFAULT_VALUES } from "../../constants";

const { PRICE } = SPINNER_NAMES;
const { POST } = REQUEST_METHODS;
const { EMPTY_STRING } = DEFAULT_VALUES;

export const fetchFlightPrices = createAsyncThunk(
  "get-price",
  async (requestBody, thunkArgs) => {
    const params = {
      url: "price",
      methodType: POST,
      errorMessage: "Failed to load price.",
      ...thunkArgs,
      spinner: PRICE,
    };

    return await Promise.all(
      requestBody.map((request) =>
        asyncAction({
          body: JSON.stringify(request),
          ...params,
          source: request.source,
        })
      )
    );
  }
);
