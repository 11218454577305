import { isEmpty } from "lodash";

export const getAllHotelsImages = (images = {}) => {
  if (isEmpty(images)) return "";
  const thumbnails = [];

  for (let key in images) {
    if (images[key].fullImage && Array.isArray(images[key].fullImage))
      thumbnails.push(...images[key].fullImage);
  }

  return thumbnails;
};
