import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCollapse } from "react-collapsed";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { convertedPricesFromStrings } from "../../../helper";
import { ChevronDown, ChevronUp, RenderSVG } from "../../../assets/icons";
import {
  selectPromoCodes,
  selectSelectedPromoCode,
  setPromoCode,
} from "../../../screens/Booking/FlightBookings";
import { DEFAULT_VALUES } from "../../../constants";

const { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, ZERO, THREE } = DEFAULT_VALUES;

const PromoCodeValidationMessage = ({ isPromoCodeValid, promoCode, t }) => (
  <div
    className={classNames(
      "text-xs mx-1 mt-1",
      { "text-green-500": isPromoCodeValid },
      { "text-red-500": !isPromoCodeValid }
    )}
  >
    {isPromoCodeValid
      ? t("travelerInfo.promoCard.successMessage", { promoCode })
      : t("travelerInfo.promoCard.errorMessage")}
  </div>
);

const PromoCodeCard = ({ isHotel = false }) => {
  const { getToggleProps, getCollapseProps, isExpanded } = useCollapse({
    duration: 300,
    defaultExpanded: true,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promoCodesRes = useSelector(selectPromoCodes);
  const selectedPromoCode = useSelector(selectSelectedPromoCode);
  const [promoCodeInput, setPromoCodeInput] = useState(EMPTY_STRING);
  const [showValidationMsg, setShowValidationMsg] = useState(false);
  const [promoCodesToRender, setPromoCodesToRender] = useState(EMPTY_ARRAY);
  const [promoCodes, setPromoCodes] = useState(EMPTY_ARRAY);

  const isPromoCodeValid = promoCodes.some(
    ({ tava_code }) => tava_code.toLowerCase() === promoCodeInput.toLowerCase()
  );

  useEffect(() => {
    if (!isEmpty(promoCodesRes)) {
      const { output: promoCodes = EMPTY_ARRAY } = promoCodesRes;
      setPromoCodes(promoCodes);
      setPromoCodesToRender(promoCodes.slice(ZERO, THREE));
    }
  }, [promoCodesRes]);

  useEffect(() => {
    if (!showValidationMsg) return;
    setTimeout(() => setShowValidationMsg(false), 2000);
  }, [showValidationMsg]);

  const handlePromocode = (code) => {
    setShowValidationMsg(true);

    if (code.toLowerCase() === selectedPromoCode?.tava_code?.toLowerCase())
      dispatch(setPromoCode(EMPTY_OBJECT));
    else {
      const selectedCodeIndex = promoCodes.findIndex(
        ({ tava_code }) => tava_code.toLowerCase() === code.toLowerCase()
      );
      selectedCodeIndex >= ZERO &&
        dispatch(setPromoCode(promoCodes[selectedCodeIndex]));
      if (selectedCodeIndex >= promoCodesToRender.length) {
        let updatedPromoCodes = [...promoCodes];
        [updatedPromoCodes[ZERO], updatedPromoCodes[selectedCodeIndex]] = [
          updatedPromoCodes[selectedCodeIndex],
          updatedPromoCodes[ZERO],
        ];
        setPromoCodes(updatedPromoCodes);
        setPromoCodesToRender(updatedPromoCodes.slice(ZERO, THREE));
      }
    }
  };

  return (
    !isEmpty(promoCodes) && (
      <div className="bg-white rounded-xl border border-gray-200 p-4">
        <div className="flex justify-between">
          <h4 className="font-bold text-gray-900">
            {t("travelerInfo.promoCard.heading")}
          </h4>
          {isHotel && (
            <div {...getToggleProps()}>
              <span className="flex gap-2 items-center text-sm text-primary-600">
                <RenderSVG
                  Svg={isExpanded ? ChevronUp : ChevronDown}
                  className="w-4 h-4 text-black"
                  alt={isExpanded ? "Up Icon" : "Down Icon"}
                />
              </span>
            </div>
          )}
        </div>
        <div {...(isHotel ? getCollapseProps() : {})}>
          <div className="form-group my-6">
            <label className="block text-sm font-medium mb-1 text-gray-900">
              {t("travelerInfo.promoCard.label")}
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                className="form-control block w-full text-sm py-2 px-3 border-gray-300 rounded-lg placeholder:text-blue-gray-500"
                placeholder={t("travelerInfo.promoCard.placeholder")}
                value={promoCodeInput}
                onChange={(e) => setPromoCodeInput(e.target.value)}
              />
              <button
                className="py-[10px] px-4 rounded-md bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-600 shadow-sm text-sm text-white font-medium disabled:cursor-not-allowed disabled:opacity-70"
                onClick={() => handlePromocode(promoCodeInput)}
                disabled={!promoCodeInput}
              >
                {t("travelerInfo.promoCard.apply")}
              </button>
            </div>
            <div>
              {promoCodeInput && showValidationMsg && (
                <PromoCodeValidationMessage
                  isPromoCodeValid={isPromoCodeValid}
                  promoCode={promoCodeInput}
                  t={t}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {promoCodesToRender.map(
              ({ tava_id, tava_code, tava_description }) => (
                <div key={tava_id}>
                  <label className="cursor-pointer relative block">
                    <input
                      type="checkbox"
                      className="peer hidden"
                      name="promo"
                      value={tava_code}
                      checked={selectedPromoCode?.tava_code === tava_code}
                      onChange={() => handlePromocode(tava_code)}
                      onClick={() => setPromoCodeInput(EMPTY_STRING)}
                    />
                    <div className="w-4 h-4 shrink-0 rounded-full grid place-content-center  border-[5px] absolute left-3 top-1/2 -translate-y-1/2 peer-checked:border-indigo-600"></div>
                    <div className="flex flex-col gap-1 border-2 p-4 rounded-lg pl-10 peer-checked:bg-blue-50 peer-checked:border-indigo-600 peer-checked:text-indigo-600">
                      <h6 className="text-sm font-semibold flex uppercase">
                        {tava_code}
                      </h6>
                      <span className="text-xs flex">{`${convertedPricesFromStrings(
                        tava_description
                      )}`}</span>
                    </div>
                  </label>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default PromoCodeCard;
