import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_LANGUAGE_CODE, SUPPORTED_LANGUAGES } from "../../../constants";
import { getFromLocalStorage, setToLocalStorage } from "../../../helper";
import { selectCountryInfo } from "../../../screens/Profile";
import { actions } from "../../../screens/Profile/profile.reducer";

const { setCountryInfo } = actions;

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const { t, i18n } = useTranslation();

  const storedLanguageCode =
    getFromLocalStorage("userLanguage") || DEFAULT_LANGUAGE_CODE;

  const handleLanguageChange = (code, name) => {
    const language = {
      name,
      code,
    };
    setToLocalStorage("userLanguage", language);
    i18n.changeLanguage(code);
    dispatch(setCountryInfo({ ...selectedCountryInfo, language }));
  };

  return (
    <div className="w-auto sm:w-40">
      <h4 className="text-contrast-900 font-semibold text-base mb-6 whitespace-nowrap overflow-x-hidden">
        {t("navbar.select.language")}
      </h4>
      <ul className="flex flex-col gap-4">
        {SUPPORTED_LANGUAGES.map(({ code, name }) => (
          <li className="py-0.5" key={code}>
            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="radio"
                className="text-primary-600 focus:ring-0"
                name={name}
                checked={storedLanguageCode.code === code}
                onChange={() => handleLanguageChange(code, name)}
              />
              <span className="text-sm font-medium text-contrast-700">
                {name}
              </span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSelector;
