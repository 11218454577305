import reducer from "./hotelInfo.reducer";

export const selectSlice = (state) => state[reducer.name];
export const selectHotelInfo = (state) => selectSlice(state).hotelInfo;
export const selectHotelInfoReqBody = (state) =>
  selectSlice(state).hotelInfoReqBody;
export const selectSelectedRoomInfo = (state) =>
  selectSlice(state).selectedRoomInfo;
export const selectPricePolicyReqBody = (state) =>
  selectSlice(state).pricePolicyReqBody;
export const selectPricePolicyInfo = (state) =>
  selectSlice(state).pricePolicyInfo;
export const selectSelectedHotelViewMore = (state) => selectSlice(state).selectedHotelViewMore;

