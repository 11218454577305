import { memo } from "react";
import { uniqueId } from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  getLayoverDuration,
  getAirportInfoByIata,
  getTotalTimeDifference,
} from "../../helper";
import { DEFAULT_VALUES } from "../../constants";

const { ZERO, ONE } = DEFAULT_VALUES;

const FlightJourneyDetails = ({ segments, isBookingPage = false }) => {
  const { t } = useTranslation();
  const layoverDuration = getLayoverDuration(segments);
  let durationIndex = ZERO;

  return (
    <div>
      {segments.map(
        ({
          carrierCode,
          carrierName,
          departure,
          arrival,
          aircraftCode,
          flightNumber,
        }) => {
          const currentLayover = layoverDuration[durationIndex];
          durationIndex += ONE;
          return (
            <div key={uniqueId("result_")} className="mb-3">
              <div>
                <div className="flex flex-col mb-2">
                  <div className="relative pl-7">
                    <div className="h-full flex flex-col shrink-0 ">
                      <div className="w-[3px] bg-contrast-200 h-1/2 rounded-t-lg absolute bottom-0 left-1"></div>
                      <div className="border-[3px] bg-white border-contrast-200 w-3 h-3 rounded-full absolute top-1/2 -translate-y-1/2 left-0"></div>
                    </div>
                    <h4
                      className={classNames(
                        "text-base xs:text-lg font-semibold text-contrast-900",
                        { "text-sm": isBookingPage }
                      )}
                    >
                      {departure.time} •&nbsp;
                      {getAirportInfoByIata(departure.iataCode).airportName} (
                      {getAirportInfoByIata(departure.iataCode).cityName})
                    </h4>
                  </div>
                  <div className="py-1 relative pl-7">
                    <div className="h-full flex flex-col shrink-0 ">
                      <div className="w-[3px] bg-contrast-200 h-full rounded-t-lg absolute bottom-0 left-1"></div>
                    </div>
                    <p className="text-xs  text-contrast-600">
                      {t("flightResults.travelTime")}:{" "}
                      {getTotalTimeDifference(
                        departure.iataCode,
                        departure.date,
                        departure.time,
                        arrival.iataCode,
                        arrival.date,
                        arrival.time
                      )}
                    </p>
                  </div>
                  <div className="relative pl-7">
                    <div className="h-full flex flex-col shrink-0">
                      <div className="w-[3px] bg-contrast-200 h-1/2 rounded-t-lg absolute top-0 left-1"></div>
                      <div className="border-[3px] bg-white border-contrast-200 w-3 h-3 rounded-full absolute top-1/2 -translate-y-1/2 left-0"></div>
                    </div>
                    <h4
                      className={classNames(
                        "text-lg font-semibold text-contrast-900",
                        { "text-sm": isBookingPage }
                      )}
                    >
                      {arrival.time} •&nbsp;
                      {getAirportInfoByIata(arrival.iataCode).airportName} (
                      {getAirportInfoByIata(arrival.iataCode).cityName})
                    </h4>
                  </div>
                </div>
                <p className="text-xs text-contrast-600 pl-7">
                  {carrierName} {flightNumber} •&nbsp;
                  {carrierCode} {aircraftCode}
                </p>
              </div>
              {currentLayover && (
                <div className="border-b border-t border-contrast-200 py-4 px-7 my-4">
                  <h4
                    className={classNames("text-contrast-600 font-semibold", {
                      "text-md": isBookingPage,
                    })}
                  >
                    {currentLayover} •&nbsp;(
                    {getAirportInfoByIata(arrival.iataCode).cityName})
                  </h4>
                </div>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default memo(FlightJourneyDetails);
