import get from "lodash/get";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Spinner, { SPINNER_NAMES } from "../Spinner";
import { DEFAULT_VALUES, SKELETON_COLOR } from "../../../constants";

const { FETCH_USER_BOOKING_INFO, GENERATE_VOUCHER } = SPINNER_NAMES;
const { EMPTY_STRING } = DEFAULT_VALUES;
const { BASE_COLOR, HIGHTLIGHT__COLOR} = SKELETON_COLOR;

const HotelBookingInfoSkeleton = ({
  isGenerateVoucherInitiated,
  selectedBooking,
}) => {
  const { HotelName: hotelName = EMPTY_STRING } = get(
    selectedBooking,
    "bookingReqJson",
    {}
  );

  return (
    <>
      <div className="lg:block hidden w-full">
        <SkeletonTheme baseColor={BASE_COLOR} highlightColor={HIGHTLIGHT__COLOR}>
          <div className="relative w-full z-0">
            <Skeleton
              width={"100%"}
              height={180}
              duration={2}
              borderRadius={0}
            />
            <div className="absolute top-16 left-12 z-10 text-xl text-contrast-900">
              {isGenerateVoucherInitiated ? (
                <>
                  Please wait, Confirming your booking with{" "}
                  <span className="font-bold">{hotelName}</span>
                </>
              ) : (
                "Please wait, while we get your booking information..."
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-8 w-full p-10 container mx-auto px-8 relative top-[-100px]">
            <div className="col-span-12 xl:col-span-8 flex flex-col gap-4">
              <div className="bg-white rounded-lg border border-dashed border-gray-300 shadow-sm">
                <div className="px-4 py-3 border-b border-dashed border-gray-300">
                  <Skeleton height={30} />
                </div>
                <div className="flex gap-6 px-4 py-3 border-b border-dashed border-gray-300 justify-between">
                  <div className="flex-1">
                    <Skeleton count={1} height={100} />
                  </div>
                </div>
                <div className="flex gap-6 px-4 py-3 justify-between">
                  <div className="flex-1">
                    <Skeleton count={1} height={150} />
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-xl border border-gray-300 overflow-hidden">
                <div className="px-6 py-4 border-b border-gray-300 bg-gray-50">
                  <Skeleton height={30} />
                </div>
                <div className="p-6">
                  <div className="border border-gray-200 divide-y divide-gray-200">
                    <div className="px-4 py-4">
                      <Skeleton count={1} height={70} />
                    </div>
                    <div className="px-4 py-4">
                      <Skeleton count={1} height={100} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg border border-gray-300 shadow-sm overflow-hidden">
                <div className="px-4 py-3 border-b border-gray-300">
                  <Skeleton height={30} />
                </div>
                <div className="p-3">
                  <Skeleton count={1} height={300} />
                </div>
              </div>
            </div>
            <div className="col-span-12 xl:col-span-4 xl:mt-0 mt-2">
              <div className="border border-gray-300 rounded-lg p-3 bg-white">
                <div className="py-2">
                  <Skeleton count={1} height={40} />
                </div>
                <div className="py-1">
                  <Skeleton count={1} height={30} />
                </div>
                <div className="py-1">
                  <Skeleton count={1} height={30} />
                </div>
                <div className="py-1">
                  <Skeleton count={1} height={30} />
                </div>
              </div>
            </div>
          </div>
        </SkeletonTheme>
      </div>
      <div className="lg:hidden mt-[200px]">
        <Spinner
          name={[FETCH_USER_BOOKING_INFO, GENERATE_VOUCHER]}
          message={
            isGenerateVoucherInitiated
              ? "Confirming your booking..."
              : "Loading booking..."
          }
          spinnerClassName="w-full"
          size="w-10 h-10"
        />
      </div>
    </>
  );
};

export default HotelBookingInfoSkeleton;
