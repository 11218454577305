import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import ProfileTravelers from "./ProfileTravelers";
import config from "../../config.json";

const { brandName } = config;

const Profile = () => {
  const { t } = useTranslation();

  return (
    <div className="h-full w-full bg-contrast-50 py-[25px]">
      <Helmet>
        <title>{brandName} | Profile</title>
      </Helmet>
      <div className="px-8 mx-auto max-w-4xl py-10 flex flex-col justify-start items-start gap-6">
        <div className="pb-6 border-b border-contrast-200 flex justify-start gap-2 w-full">
          <p className="text-contrast-900 text-3xl font-bold leading-9 ">
            {t("profilePage.myProfile")}
          </p>
        </div>
        <ProfileTravelers />
      </div>
    </div>
  );
};

export default Profile;
