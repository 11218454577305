import FlightBooking from "./FlightBooking";
import * as bookingActions from "./flightBookings.actions";
import * as slice from "./flightBookings.reducer";
import * as selectors from "./flightBookings.selectors";

export const {
  initiateBooking,
  initiatePayment,
  bookCallback,
  reissuanceCallback,
  fetchSpecialServices,
  getPromoCodes,
  updateBookingStatus,
  getPaymentMethod,
  fetchFlightPollingResponse,
} = bookingActions;

export const {
  name,
  reducer,
  actions: {
    setBookingTravelers,
    setSelectedTravelers,
    setselectedLCCSeats,
    setSelectedLCCMeals,
    setCancellationStatus,
    setSelectedLCCBaggages,
    setSelectedNonLCCSeats,
    setSelectedNonLCCMeals,
    setPromoCode,
    setTotalSSRPrice,
    clearSelectedValues,
    setSpecialServices,
    setBookCallbackResponse,
    setSeatPreferences,
    setFlightBooking,
    setGstDetails,
    setAddressDetails,
    setContactDetails,
    setFlightBookingReqBody,
    setSelectedPaymentMethod
  },
} = slice;

export const {
  selectSelectedTravelers,
  selectSpecialServices,
  selectSelectedLCCSeats,
  selectFlightBookingInfo,
  selectCancellationStatus,
  selectBookCallBackResponse,
  selectPromoCodes,
  selectSelectedPromoCode,
  selectSelectedLCCMeals,
  selectSelectedNonLCCMeals,
  selectSelectedLCCBaggages,
  selectTotalSSRPrice,
  selectBookingTravelers,
  selectSSRDefaultValuesLCC,
  selectSelectedNonLCCSeats,
  selectGSTDetails,
  selectAddressDetails,
  selectContactDetails,
  selectFlightBookingReqBody,
  removeInvalidPax,
  selectPaymentMethod,
  selectFlightPollingResponse,
} = selectors;

export default FlightBooking;
