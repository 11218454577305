import HotelBookingResult from "./HotelBookingResult";
import * as actions from "./hotelBooking.actions";
import * as slice from "./hotelBooking.reducer";
import * as selectors from "./hotelBooking.selector";

export const {
  initiateHotelBooking,
  hotelBookCallback,
  abortHotelBooking,
  generatePaymentVoucher,
  fetchHotelPollingResponse,
} = actions;
export const {
  name,
  reducer,
  actions: {
    setLeadGuestInfo,
    setOtherGuests,
    setHotelBookReqBody,
    setHotelBookRes,
    setHotelBookCallbackRes,
  },
} = slice;

export const {
  selectLeadGuestInfo,
  selectOtherGuests,
  selectHotelBookReqBody,
  selectHotelBookRes,
  selectHotelBookCallbackRes,
  selectHotelPollingResponse,
} = selectors;

export default HotelBookingResult;
