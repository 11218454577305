import { some, get, isEmpty } from "lodash";
import { CACHE_KEYS, DEFAULT_VALUES, TRAVELER_TYPE } from "../../constants";
import { getFromSessionStorage } from "../../helper";

const { ADULT, CHILD, HELD_INFANT } = TRAVELER_TYPE;
const { ONE, ZERO, EMPTY_ARRAY, EMPTY_STRING } = DEFAULT_VALUES;
const { FLIGHT_PRICE_REQUEST_BODY } = CACHE_KEYS;
const REFUND_CATEGORY_NUMBER = "33";
const REISSUE_CATEGORY_NUMBER = "31";
const SEGMENT_REFERENCE = "ST";
const priceRequest = getFromSessionStorage(FLIGHT_PRICE_REQUEST_BODY);
const travelersDetails = get(
  priceRequest,
  "[0].price.travelerDetails",
  EMPTY_ARRAY
);

const countAdults = (travelers) => {
  if (isEmpty(travelers)) return ONE;
  else
    return travelers.filter((travel) => travel.travelerType === ADULT).length;
};

const combinedMonetaryInfo = (data) => {
  const combinedMonetaryInfo = data?.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.monetaryInfo.monetaryDetails);
  }, []);
  return combinedMonetaryInfo;
};

const mapSegmentReference = (segments) => {
  const mapSingleSegment = (segment) => ({
    type: segment.reference.type,
    value: segment.reference.value,
  });

  if (Array.isArray(segments)) {
    return segments
      .filter((segment) => segment.reference.type === SEGMENT_REFERENCE)
      .map(mapSingleSegment);
  }

  return [mapSingleSegment(segments)];
};

const getKey = (paxRef) => {
  const hasAdultReference = some(paxRef, (reference) =>
    reference.includes("PA-")
  );
  const hasInfantReference = some(paxRef, (reference) =>
    reference.includes("PI-")
  );
  const adultCount = countAdults(travelersDetails);

  const isAdult = () =>
    paxRef.some((item) => {
      const numberInString = parseInt(item.split("-")[1], 10);
      return +numberInString <= adultCount;
    });

  if (isAdult() && hasAdultReference) return ADULT;
  else if (!isAdult() && hasAdultReference) return CHILD;
  else if (hasInfantReference) return HELD_INFANT;
};

const PassengerReferences = (paxRef) => {
  const references = !Array.isArray(paxRef.passengerReference)
    ? [paxRef.passengerReference]
    : paxRef.passengerReference;
  const formattedReferences = references.map(
    (ref) => `${ref.type}-${ref.value}`
  );
  return formattedReferences;
};

const updateExistingEntry = (
  existingEntry,
  appliedPenalities,
  appliedRestrictions,
  key
) => {
  appliedPenalities.forEach((infoItem) => {
    const indicator = infoItem.typeQualifier;
    const amount = infoItem.amount;
    const currency = infoItem.currency;

    const existingMonetaryInfo = existingEntry.monatoryInfo.find(
      (entry) => entry.indicator === indicator
    );

    if (existingMonetaryInfo) {
      existingMonetaryInfo[key.toLowerCase()] = amount;
    } else {
      existingEntry.monatoryInfo.push({
        indicator,
        currency,
        [key.toLowerCase()]: amount,
      });
    }
  });

  appliedRestrictions.forEach((infoItem) => {
    const indicator = infoItem.indicator;
    const action = infoItem.action === "0" ? "No" : "Yes";

    const existingrestrictionAppInfo = existingEntry.restrictionAppInfo.find(
      (entry) => entry.indicator === indicator
    );

    if (existingrestrictionAppInfo) {
      existingrestrictionAppInfo[key.toLowerCase()] = action;
    } else {
      existingEntry.restrictionAppInfo.push({
        indicator,
        [key.toLowerCase()]: action,
      });
    }
  });
};

const mergeFareRulesForEachPaxType = (fareRulesArray) => {
  const combinedData = [];

  fareRulesArray.forEach((segments) => {
    segments?.forEach((segment) => {
      segment?.fareRules.forEach((fareRule) => {
        const category = get(
          fareRule,
          "mnrCatInfo.descriptionInfo.number",
          EMPTY_STRING
        );
        const origin = get(fareRule, "origin", EMPTY_STRING);
        const destination = get(fareRule, "destination", EMPTY_STRING);
        const key = get(fareRule, "key", EMPTY_STRING);
        const appliedPenalities = Array.isArray(fareRule.mnrMonInfoGrp)
          ? combinedMonetaryInfo(fareRule.mnrMonInfoGrp)
          : get(
              fareRule,
              "mnrMonInfoGrp.monetaryInfo.monetaryDetails",
              EMPTY_ARRAY
            );
        const appliedRestrictions = get(
          fareRule,
          "mnrRestriAppInfoGrp.mnrRestriAppInfo.statusInformation",
          EMPTY_ARRAY
        );
        const dateInfo = get(
          fareRule,
          "mnrDateInfoGrp.dateInfo.dateAndTimeDetails",
          EMPTY_ARRAY
        );

        const existingEntry = combinedData.find(
          (entry) =>
            entry.category === category &&
            entry.origin === origin &&
            entry.destination === destination
        );

        if (existingEntry) {
          updateExistingEntry(
            existingEntry,
            appliedPenalities,
            appliedRestrictions,
            key
          );
        } else {
          const newEntry = {
            category,
            origin,
            destination,
            monatoryInfo: appliedPenalities.map((infoItem) => ({
              indicator: infoItem.typeQualifier,
              currency: infoItem.currency,
              [key.toLowerCase()]: infoItem.amount,
            })),
            restrictionAppInfo: appliedRestrictions.map((infoItem) => ({
              indicator: infoItem.indicator,
              [key.toLowerCase()]: infoItem.action === "0" ? "No" : "Yes",
            })),
            dateInfo,
          };
          combinedData.push(newEntry);
        }
      });
    });
  });
  return combinedData;
};

const mapfareComponentInfo = (fareComponentInfo) => {
  const mapSingleFareComponent = (item, refId) => ({
    refId,
    origin: item.originAndDestination.origin,
    destination: item.originAndDestination.destination,
    segments: mapSegmentReference(item.segmentRefernce),
  });

  if (Array.isArray(fareComponentInfo)) {
    return fareComponentInfo.map((item, index) =>
      mapSingleFareComponent(item, index + ONE)
    );
  }

  return [mapSingleFareComponent(fareComponentInfo, ONE)];
};

const getMonetoryDetailsForeachSegment = ({
  fareComponents,
  MNRRulesInfoGrp,
}) => {
  let mergedSegmentRules = {};
  let formattedFareComponents = fareComponents;
  if (!Array.isArray(fareComponents))
    formattedFareComponents = [fareComponents];
  formattedFareComponents.forEach((item) => {
    mergedSegmentRules[item.refId] = item;
  });
  return MNRRulesInfoGrp.filteredMNRRulesInfoGrp.reduce(
    (formattedValues, item) => {
      const refDetails = item.mnrFCInfoGrp.refInfo.referenceDetails;
      const refIDs = Array.isArray(refDetails) ? refDetails : [refDetails];
      refIDs.forEach((refD) => {
        const { origin, destination } = mergedSegmentRules[refD.value];
        const existingIndex = formattedValues.findIndex(
          (value) =>
            origin === value.origin && destination === value.destination
        );

        const key = getKey(MNRRulesInfoGrp.passegengersRef);
        const newFareRule = {
          key,
          ...item,
          ...mergedSegmentRules[refD.value],
          passegengersRef: MNRRulesInfoGrp.passegengersRef,
        };

        if (existingIndex >= ZERO) {
          formattedValues[existingIndex].fareRules.push(newFareRule);
        } else {
          formattedValues.push({
            origin,
            destination,
            fareRules: [newFareRule],
          });
        }
      });

      return formattedValues;
    },
    []
  );
};

const transformRulesToMap = (inputData, journeyId) => {
  return inputData.reduce((formattedArray, fareRule) => {
    const {
      origin,
      destination,
      category,
      monatoryInfo,
      restrictionAppInfo,
      dateInfo,
    } = fareRule;

    const existingEntry = formattedArray.find(
      (entry) => entry.origin === origin && entry.destination === destination
    );

    const monetaryDetails = monatoryInfo?.map(
      ({ indicator, adult, child, infant, currency }) => ({
        indicator,
        adult,
        child,
        infant,
        currency,
      })
    );

    const restrictionsInfo = restrictionAppInfo?.map(
      ({ indicator, adult, child, infant }) => ({
        indicator,
        adult,
        child,
        infant,
      })
    );

    if (!existingEntry) {
      const newEntry = {
        origin,
        destination,
        reissue: {
          category: null,
          monetaryDetails: EMPTY_ARRAY,
          restrictionsInfo: EMPTY_ARRAY,
          dateInfo: EMPTY_ARRAY,
        },
        refund: {
          category: null,
          monetaryDetails: EMPTY_ARRAY,
          restrictionsInfo: EMPTY_ARRAY,
          dateInfo: EMPTY_ARRAY,
        },
        journeyId,
      };
      formattedArray.push(newEntry);
    }

    const targetEntry =
      existingEntry || formattedArray[formattedArray.length - 1];

    if (category === REISSUE_CATEGORY_NUMBER) {
      targetEntry.reissue.category = category;
      targetEntry.reissue.monetaryDetails = monetaryDetails;
      targetEntry.reissue.restrictionsInfo = restrictionsInfo;
      targetEntry.reissue.dateInfo = dateInfo;
    } else if (category === REFUND_CATEGORY_NUMBER) {
      targetEntry.refund.category = category;
      targetEntry.refund.monetaryDetails = monetaryDetails;
      targetEntry.refund.restrictionsInfo = restrictionsInfo;
      targetEntry.refund.dateInfo = dateInfo;
    }

    return formattedArray;
  }, []);
};

export const fareRulesMapper = (fareRulesAPIData, journeyId) => {
  const getFilteredRulesInfo = (mnrRulesInfoGrp) =>
    mnrRulesInfoGrp.filter(
      (item) =>
        item.mnrCatInfo.descriptionInfo.number === REISSUE_CATEGORY_NUMBER ||
        item.mnrCatInfo.descriptionInfo.number === REFUND_CATEGORY_NUMBER
    );

  const processFareRulesData = (mnrByPricingRecord) => {
    const formattedfareComponentsInfo = mapfareComponentInfo(
      mnrByPricingRecord.fareComponentInfo
    );

    const filteredMNRRulesInfoGrp = getFilteredRulesInfo(
      mnrByPricingRecord.mnrRulesInfoGrp
    );

    const paxRefs = PassengerReferences(mnrByPricingRecord.paxRef);
    return getMonetoryDetailsForeachSegment({
      fareComponents: formattedfareComponentsInfo,
      MNRRulesInfoGrp: {
        filteredMNRRulesInfoGrp,
        passegengersRef: paxRefs,
      },
    });
  };

  if (Array.isArray(fareRulesAPIData.mnrByPricingRecord)) {
    const fareRulesData =
      fareRulesAPIData.mnrByPricingRecord.map(processFareRulesData);

    const MergedData = mergeFareRulesForEachPaxType(fareRulesData);

    return transformRulesToMap(MergedData, journeyId);
  } else {
    const MergedData = mergeFareRulesForEachPaxType([
      processFareRulesData(fareRulesAPIData.mnrByPricingRecord),
    ]);

    return transformRulesToMap(MergedData, journeyId);
  }
};
