import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { REQUEST_METHODS } from "../../constants";

const { FETCH_SESSION } = SPINNER_NAMES;
const { GET, PATCH } = REQUEST_METHODS;

export const fetchSessionInfo = createAsyncThunk(
  "fetch-session-details",
  async (id, thunkArgs) =>
    await asyncAction({
      url: `/session/${id}`,
      methodType: GET,
      spinner: FETCH_SESSION,
      abortOnPageChange: false,
      errorMessage: "Failed to get the session details.",
      ...thunkArgs,
    })
);

export const updateSessionInfo = createAsyncThunk(
  "update-session-info",
  async ({ sessionParams, requestBody }, thunkArgs) =>
    await asyncAction({
      url: `/session/${sessionParams}`,
      methodType: PATCH,
      body: requestBody,
      abortOnPageChange: false,
      errorMessage: "Failed to update the session details.",
      ...thunkArgs,
    })
);
