import FlightResults from "./FlightResults";
import * as flightResultsActions from "./flightResults.actions";
import * as slice from "./flightResults.reducer";
import * as selectors from "./flightResults.selectors";

export const { fetchFlightPrices } = flightResultsActions;
export const {
  name,
  reducer,
  actions: {
    setSelectedFlightInfo,
    setCurrentFlightType,
    setFlightPriceInfo,
    resetFlightPriceInfo,
    setFlightPriceReq,
    setSelectedTripType,
    setRoundTripResultFormat,
    setIsInternational,
    setIsPrePackagedFlights,
    setFlightResults,
    setSelectedFlightIndexInfo,
    setSelectedFlightOptions,
    setSelectedFlightId,
    setFiltersCount,
  },
} = slice;
export const {
  selectFlightInfo,
  selectFlightPriceInfo,
  selectShouldShowSpinner,
  selectCurrentFlightType,
  selectFlightPriceReq,
  selectSelectedTripType,
  selectRoundTripResultsFormat,
  selectIsInternationalFlight,
  selectIsPrePackagedFlights,
  selectSelectedFlightIndexInfo,
  selectSelectedFlightOptions,
  selectSelectedFlightId,
  selectFiltersCount,
} = selectors;

export default FlightResults;
