import { useCallback, useEffect } from 'react'

const useClickOutside = (ref, setState) => {
    const handler = useCallback(() => setState(false), [])
    useEffect(() => {
        let startedInside = false
        let startedWhenMounted = false

        const listener = (event) => {
            if (startedInside || !startedWhenMounted) return
            if (!ref.current || ref.current.contains(event.target)) return

            handler(event)
        }

        const validateEventStart = (event) => {
            startedWhenMounted = ref.current
            startedInside = ref?.current?.contains(event.target)
        }

        document.addEventListener('mousedown', validateEventStart)
        document.addEventListener('touchstart', validateEventStart)
        document.addEventListener('click', listener)

        return () => {
            document.removeEventListener('mousedown', validateEventStart)
            document.removeEventListener('touchstart', validateEventStart)
            document.removeEventListener('click', listener)
        }
    }, [ref, handler])
}

export default useClickOutside
