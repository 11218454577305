import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { get, isEmpty } from "lodash";
import * as Yup from "yup";
import {
  getFormattedDateTime,
  getPrimaryPaxValidationSchema,
  getPanValidationSchema,
  getPassportValidationSchema,
  getRequiredErrorMessage,
  renderFieldError,
} from "../../helper";
import Asterisk from "../../components/atoms/Asterisk";
import { PhoneNumberInput } from "../../components/atoms/PhoneNumberInput";
import RenderPassportFields from "./RenderPassportFields";
import { setLeadGuestInfo } from "../HotelBooking";
import { selectTravelerInfo } from "../Profile/profile.selector";
import { DEFAULT_VALUES, REGEX } from "../../constants";
import GuestPrimaryDetails from "./GuestPrimaryDetails";

const { EMPTY_STRING, EMPTY_ARRAY, ZERO } = DEFAULT_VALUES;
const { EMAIL, PHONE_NUMBER } = REGEX;

const initialFormValues = {
  profileDetails: {
    title: "Mr",
    firstName: EMPTY_STRING,
    middleName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    isChild: false,
    email: EMPTY_STRING,
    phoneNumber: EMPTY_STRING,
    phoneCode: EMPTY_STRING,
  },
  passportDetails: {
    passportNumber: EMPTY_STRING,
    issueCountry: { isoCode: EMPTY_STRING },
    issuedDate: getFormattedDateTime(new Date())[ZERO],
    expiryDate: getFormattedDateTime(new Date())[ZERO],
  },
  panCardDetails: {
    panCardNumber: EMPTY_STRING,
    lastName: EMPTY_STRING,
    isChild: false
  },
};

const LeadGuestInfo = ({
  leadGuestFormRef,
  isPANRequired,
  isPassportRequired,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTravelerInfo = useSelector(selectTravelerInfo);
  const [initialValues, setInitialValues] = useState(initialFormValues);

  useEffect(() => {
    const travelerInfo = !isEmpty(selectedTravelerInfo)
      ? selectedTravelerInfo
      : EMPTY_ARRAY;
    if (travelerInfo !== EMPTY_ARRAY) {
      const primaryTraveler = travelerInfo.find(({ isPrimary }) => isPrimary);
      const phone = get(
        primaryTraveler,
        "profileDetails.phoneNumber",
        EMPTY_STRING
      );
      const updatedValues = {
        ...initialValues,
        profileDetails: {
          title: get(primaryTraveler, "profileDetails.title", EMPTY_ARRAY),
          firstName: get(
            primaryTraveler,
            "profileDetails.firstName",
            EMPTY_STRING
          ),
          middleName: get(
            primaryTraveler,
            "profileDetails.middleName",
            EMPTY_STRING
          ),
          lastName: get(
            primaryTraveler,
            "profileDetails.lastName",
            EMPTY_STRING
          ),
          isChild: false,
          email: get(primaryTraveler, "profileDetails.email", EMPTY_STRING),
          phoneCode: phone.substring(ZERO, phone.indexOf("-")),
          phoneNumber: phone.replace("+", "").replace("-", ""),
        },
        panCardDetails: {
          panCardNumber: get(primaryTraveler, "panCardDetails.panCardNumber", EMPTY_STRING),
          lastName: get(primaryTraveler, "panCardDetails.lastName", EMPTY_STRING),
          isChild: false
        },
      };
      setInitialValues(updatedValues);
    }
  }, []);

  const baseValidationSchema = {
    profileDetails: Yup.object().shape({
      ...getPrimaryPaxValidationSchema(t),
      email: Yup.string()
        .matches(EMAIL, t("profilePage.errors.emailFormat"))
        .required(getRequiredErrorMessage("profilePage.email", t)),
      phoneNumber: Yup.string()
        .matches(PHONE_NUMBER, t("profilePage.errors.phone"))
        .required(getRequiredErrorMessage("profilePage.phone", t)),
    }),
  };
  const passportValidationSchema = getPassportValidationSchema();
  const panValidationSchema = getPanValidationSchema();

  const validationSchema = Yup.object().shape({
    ...baseValidationSchema,
    ...(isPassportRequired && passportValidationSchema),
    ...(isPANRequired && panValidationSchema.fields),
  });

  const handleSaveGuestDetails = (values) => {
    dispatch(setLeadGuestInfo(values));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSaveGuestDetails}
      enableReinitialize
      innerRef={leadGuestFormRef}
    >
      {({
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
      }) => (
        <Form>
          <div className="grid grid-cols-12 gap-2 mb-2 sm:gap-6 sm:mb-4">
            <GuestPrimaryDetails />
            {isPassportRequired && <RenderPassportFields />}
            {isPANRequired && (
              <div className="col-span-12 sm:col-span-6 md:col-span-5">
                <div className="form-group">
                  <label
                    htmlFor="panCardNumber"
                    className="block form-control text-sm font-medium mb-1 text-contrast-900"
                  >
                    PAN Number
                    <Asterisk />
                  </label>
                  <Field
                    id="panCardNumber"
                    type="text"
                    name="panCardDetails.panCardNumber"
                    className="form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500"
                    placeholder="PAN Number"
                    onChange={(e) => {
                      const uppercasedValue = e.target.value.toUpperCase();
                      setFieldValue(
                        "panCardDetails.panCardNumber",
                        uppercasedValue
                      );
                    }}
                  />
                </div>
                <div>
                  {renderFieldError(
                    "panCardDetails.panCardNumber",
                    errors,
                    touched
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col text-contrast-90 justify-between border-t border-contrast-300 py-2 text-[12px] gap-1 sm:py-4 sm:text-[15px] sm:gap-2 ">
            <div className="text-contrast-900">
              <div className="font-semibold">Contact Information</div>
              <div className="font-light text-[9px] sm:text-xs">
                (Booking details will be sent to this)
              </div>
            </div>
            <div className="grid grid-cols-6 gap-2 sm:gap-6">
              <div className="form-group col-span-6 md:col-span-2 lg:col-span-2">
                <div className="form-group">
                  <label
                    htmlFor="phoneNumber"
                    className="block font-medium mb-1 text-contrast-900 text-xs sm:text-sm"
                  >
                    Phone Number
                    <Asterisk />
                  </label>
                  <PhoneNumberInput
                    id="phoneNumber"
                    name="profileDetails.phoneNumber"
                    values={values}
                    phoneCodePath="profileDetails.phoneCode"
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    handleOnBlur={handleSubmit}
                    placeholder={"Phone Number"}
                  />
                  <div>
                    {renderFieldError(
                      "profileDetails.phoneNumber",
                      errors,
                      touched
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-span-6 md:col-span-2 lg:col-span-2">
                <div className="form-group">
                  <label
                    htmlFor="email"
                    className="block font-medium mb-1 text-contrast-900 text-xs sm:text-sm"
                  >
                    Email Address
                    <Asterisk />
                  </label>
                  <Field
                    id="email"
                    type="email"
                    name="profileDetails.email"
                    placeholder="Email"
                    className="form-control block w-full text-sm py-1.5 px-3 border-contrast-300 rounded-lg"
                    onBlur={handleSubmit}
                    onChange={(e) => {
                      const lowercasedValue = e.target.value.toLowerCase();
                      setFieldValue("profileDetails.email", lowercasedValue);
                    }}
                  />
                  <div>
                    {renderFieldError("profileDetails.email", errors, touched)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LeadGuestInfo;
