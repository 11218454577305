import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { ArrowLeft, Cross, RenderSVG } from "../../../../assets/icons";
import {
  getAirlineIconUrlByCarrierCode,
  getCityNameByIata,
  getDayFromDate,
  getFormattedLongDate,
} from "../../../../helper";
import { setSelectedModal } from "../../Modal";
import {
  selectCurrentFlightType,
  selectFlightInfo,
} from "../../../../screens/FlightResults";
import { selectCountryInfo } from "../../../../screens/Profile";
import FlightOptionCard from "./FlightOptionCard";
import config from "../../../../config.json";
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_VALUES,
  FLIGHTS_RESULT_TYPE,
} from "../../../../constants";

const { ZERO } = DEFAULT_VALUES;
const { PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;

const RenderFlightOptions = ({ flightOptions = [], flightType, currency }) => {
  const { t } = useTranslation();

  const formattedFlightOptions = Array.isArray(flightOptions)
    ? flightOptions
    : [flightOptions];

  const tripItinerariesInfo = formattedFlightOptions[ZERO]
    ? formattedFlightOptions[ZERO].itineraries.map((itinerary, index) => {
        const {
          carrierCode,
          carrierName,
          departure: {
            time: departureTime,
            date: departureDate,
            iataCode: departureIataCode,
          },
        } = itinerary.segments[ZERO] || {};
        const {
          arrival: { time: arrivalTime, iataCode: arrivalIataCode },
        } = itinerary.segments.at(-1) || {};

        return {
          id: `itinerary_${index}`,
          carrierCode,
          carrierName,
          departureTime,
          departureDate,
          departureIataCode,
          arrivalTime,
          arrivalIataCode,
        };
      })
    : [];

  return (
    <div key={flightType}>
      <div
        className={classNames("flex flex-col gap-2", {
          "my-6": flightType !== PACKAGE_RESULT,
          "mb-6": flightType === PACKAGE_RESULT,
        })}
      >
        <div className="flex flex-wrap gap-2 text-gray-500">
          <span className="text-gray-900 font-bold text-sm">Trip - </span>
          <div>
            {!isEmpty(tripItinerariesInfo) &&
              tripItinerariesInfo.map(
                ({
                  id,
                  departureIataCode,
                  arrivalIataCode,
                  carrierCode,
                  carrierName,
                  departureDate,
                  departureTime,
                  arrivalTime,
                }) => (
                  <div
                    className="flex flex-col sm:flex-row items-start sm:items-center gap-2 mb-1"
                    key={id}
                  >
                    <div className="inline-flex gap-2 items-center">
                      <img
                        src={getAirlineIconUrlByCarrierCode(carrierCode)}
                        width="20"
                        alt="carrier-icons"
                      />
                      <span className="text-xs text-gray-500">
                        {carrierName}
                      </span>
                    </div>
                    <span className="hidden sm:inline">▪</span>{" "}
                    <div className="inline-flex gap-2">
                      <span className="text-xs text-gray-500">
                        {getCityNameByIata(departureIataCode)}
                      </span>
                      <RenderSVG
                        Svg={ArrowLeft}
                        width="14"
                        height="14"
                        stroke={config.contrast}
                      />
                      <span className="text-xs text-gray-500">
                        {getCityNameByIata(arrivalIataCode)}
                      </span>
                    </div>
                    <span className="hidden sm:inline">▪</span>{" "}
                    <span className="text-xs text-gray-500">
                      {getDayFromDate(departureDate)},{" "}
                      {getFormattedLongDate(departureDate, {
                        weekday: "short",
                        day: "2-digit",
                        month: "short",
                        year: "2-digit",
                      })}
                    </span>
                    <span className="hidden sm:inline">▪</span>{" "}
                    <span className="text-xs text-gray-500">
                      {t("flightResults.roundTripCard.departure")} at{" "}
                      {departureTime} - {t("sorting.arrival.header")} at{" "}
                      {arrivalTime}
                    </span>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 mb-4">
        {formattedFlightOptions?.map((flight) => (
          <FlightOptionCard
            key={flight?.flightId}
            flight={flight}
            currency={currency}
            flightType={flightType}
            flightOptionsCount={formattedFlightOptions.length}
          />
        ))}
      </div>
    </div>
  );
};

export const FlightOptionsModal = () => {
  const dispatch = useDispatch();
  const selectedFlightsInfo = useSelector(selectFlightInfo);
  const countryInfo = useSelector(selectCountryInfo);
  const currentFlightType = useSelector(selectCurrentFlightType);

  const currentFlightOptions = get(
    selectedFlightsInfo,
    `${
      currentFlightType === PACKAGE_RESULT
        ? PACKAGE_RESULT
        : `${currentFlightType}.flights`
    }`,
    []
  );
  const currency = get(countryInfo, "currency.code", DEFAULT_CURRENCY_CODE);

  const handleClose = () => dispatch(setSelectedModal(null));

  return (
    <div className="fixed bg-gray-900/80 w-full h-full start-0 top-0 z-50 p-3 sm:p-6 overflow-auto no-scrollbar">
      <div className="modal-dialog mx-auto bg-white rounded-lg shadow-md max-w-[1000px] overflow-hidden">
        <div className="modal-header flex gap-4 items-center p-6 border-b border-gray-200">
          <h4 className="flex-1 text-sm font-semibold  text-gray-900">
            <span className="uppercase text-base font-bold text-primary-700">
              More Fare Options
            </span>{" "}
            available for your trip
          </h4>
          <button
            className="text-blue-gray-600 ms-auto hover:opacity-80"
            onClick={handleClose}
          >
            <RenderSVG Svg={Cross} className="text-contrast-black" />
          </button>
        </div>
        <div className="p-4 bg-gray-50">
          <RenderFlightOptions
            flightOptions={currentFlightOptions}
            flightType={PACKAGE_RESULT}
            currency={currency}
          />
        </div>
      </div>
    </div>
  );
};
