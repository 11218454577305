import { Tooltip } from "react-tooltip";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  RenderSVG,
  Dashboard,
  PhoneIcon,
  TicketSlash,
  HotelTicket,
} from "../../../assets/icons";
import {
  ROUTES,
  SEARCH_SECTION,
  CONTACT_EMAIL,
  BOOKING_STATUS,
} from "../../../constants";
import {
  getUserBookingInfo,
  setIsGenerateVoucherInitiated,
} from "../../../screens/MyTrips";
import config from "../../../config.json";

const {CONFIRMED} =BOOKING_STATUS
const { logo } = config;
const { USER_BOOKINGS, BOOKING_INFO_ROUTE, HOTEL_CANCELLATION, CONTACT_US, BOOKINGS } = ROUTES;
const { HOTEL } = SEARCH_SECTION;

const BookingLinks = ({
  isSpinnerActive,
  tavaBookingId,
  isVoucherBooking,
  showNavigateToMyTripOption,
  hotelBookingStatus
}) => {
  const isBookingSuccessful = hotelBookingStatus.toUpperCase() === CONFIRMED;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigateToUserBookings = () => {
    if (!isVoucherBooking) {
      dispatch(setIsGenerateVoucherInitiated(false));
      dispatch(
        getUserBookingInfo({
          queryParams: {
            division: HOTEL,
            id: tavaBookingId,
          },
        })
      );
      navigate(
        BOOKING_INFO_ROUTE.replace(":division", HOTEL).replace(
          ":id",
          tavaBookingId
        )
      );
    } else navigate(USER_BOOKINGS);
  };

  return (
    <div className="col-span-12 xl:col-span-4 print:hidden">
      <div className="border border-gray-300 rounded-lg mb-6 bg-white">
        <ul className="flex flex-col text-sm divide-y">
          <li className="flex items-center gap-2 px-4 py-3">
            <h4 className="font-bold text-sm xs:text-base text-contrast-900">
              Manage your trip &amp; get all the help 
            </h4>
          </li>
          <li className="flex items-center gap-2 px-4 py-3">
            <Link
              to={CONTACT_US}
              className="flex gap-2 items-center text-sm font-medium text-[#4f46e5]"
            >
              <RenderSVG
                Svg={PhoneIcon}
                className="w-[20px] h-[20px]"
                alt="phone-icon"
              />

              <span>Contact Us</span>
            </Link>
          </li>
          {isBookingSuccessful && (
            <li className="flex items-center gap-2 px-4 py-3">
              <Link
                className="flex gap-2 items-center text-sm font-medium text-[#4f46e5]"
                to={HOTEL_CANCELLATION.replace(":bookingId", tavaBookingId)}
                disabled={isSpinnerActive}
              >
                <RenderSVG
                  Svg={TicketSlash}
                  className="w-[20px] h-[20px]"
                  alt="ticket-slash-icon"
                />
                <span>Cancel Booking</span>
              </Link>
            </li>
          )}
          {isBookingSuccessful && (
            <li className="flex items-center gap-2 px-4 py-3">
              <button
                className="flex gap-2 items-center text-sm font-medium text-[#4f46e5] disabled:cursor-not-allowed"
                disabled={isSpinnerActive}
                onClick={() => window.print()}
              >
                <RenderSVG
                  Svg={HotelTicket}
                  alt="ticket-icon"
                  className="w-[20px] h-[20px]"
                  fill="none"
                />
                <span>Download Ticket</span>
              </button>
            </li>
          )}
          {isBookingSuccessful && showNavigateToMyTripOption && (
            <li className="flex items-center gap-2 px-4 py-3">
              <Link
                className="flex gap-2 items-center text-sm font-medium text-[#4f46e5]"
                to={BOOKINGS}
              >
                <RenderSVG
                  Svg={Dashboard}
                  className="w-[20px] h-[20px]"
                  alt="dashboard-icon"
                />
                <span>More in My Trips</span>
              </Link>
            </li>
          )}

          {isBookingSuccessful && showNavigateToMyTripOption && (
            <li className="flex items-center gap-2 px-4 py-3 bg-indigo-50/50">
              <button
                className="py-3 px-4 rounded-md bg-[#4f46e5] hover:bg-[#4338ca] active:bg-[#4f46e5] shadow-sm text-sm text-white font-medium w-full disabled:cursor-not-allowed"
                disabled={isSpinnerActive}
                onClick={() => handleNavigateToUserBookings()}
              >
                Go to My Trips
              </button>
            </li>
          )}
        </ul>
      </div>
      <div className="border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6">
        <div className="p-6">
          <h4 className="font-bold text-contrast-900 text-sm xs:text-base mb-5">
            {t("bookingResult.contactUs")}
          </h4>
          <div className="flex items-center gap-3 ">
            <div className="logo">
              <img
                src={logo}
                width="30"
                className="rounded border border-contrast-300"
                alt="tavatrip logo"
              />
            </div>
            <div className="flex-1">
              <p className="text-xs text-contrast-600 sm:mb-1">
                {t("bookingResult.customerSupport")}
              </p>
              <h5 className="text-sm font-semibold text-primary-600 underline">
                <a target="_blank" href="mailto:support@tavatrip.com">
                  {CONTACT_EMAIL}
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingLinks;
