import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Menu, RenderSVG } from "../../../assets/icons";
import { setSelectedDrawer } from "../../../components/organisms/Drawer";
import { DRAWERS } from "../../../components/organisms/AppDrawers/drawer.constants";
import config from "../../../config.json";

const { primaryColor } = config;

const { SHOW_ADMIN_NAVIGATION_DRAWER } = DRAWERS;

const AdminPageHeader = ({ title }) => {
  const dispatch = useDispatch();

  const openNavigationDrawer = useCallback(() => {
    dispatch(setSelectedDrawer(SHOW_ADMIN_NAVIGATION_DRAWER));
  }, [dispatch]);

  return (
    <div className="flex items-center p-4">
      <button
        type="button"
        className="py-2 pr-2 lg:hidden"
        onClick={openNavigationDrawer}
      >
        <RenderSVG Svg={Menu} alt="Menu icon" stroke={primaryColor} />
      </button>
    </div>
  );
};

export default AdminPageHeader;
