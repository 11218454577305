import HotelInfo from "./HotelInfo";
import * as actions from "./hotelInfo.actions";
import * as slice from "./hotelInfo.reducer";
import * as selectors from "./hotelInfo.selector";

export const { getHotelInfo } = actions;
export const {
  name,
  reducer,
  actions: { setHotelInfo, setHotelInfoReqBody, setSelectedRoomInfo, setPricePolicyReqBody, setPricePolicyInfo, setSelectedHotelViewMore },
} = slice;
export const {
  selectHotelInfo,
  selectHotelInfoReqBody,
  selectSelectedRoomInfo,
  selectPricePolicyReqBody,
  selectPricePolicyInfo,
  selectSelectedHotelViewMore,
} = selectors;

export default HotelInfo;
