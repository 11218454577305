import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLeftSidebar from "../../../../screens/Admin/Widgets/AdminLeftSidebar";
import Drawer, { selectSelectedDrawer, setSelectedDrawer } from "../../Drawer";
import { DRAWER_SIZE, POSITIONS } from "../../../../constants";

const { SM } = DRAWER_SIZE;
const { LEFT } = POSITIONS;

const AdminNavigationDrawer = () => {
  const dispatch = useDispatch();
  const selectedDrawer = useSelector(selectSelectedDrawer);

  const handleClose = useCallback(
    () => dispatch(setSelectedDrawer(null)),
    [dispatch]
  );

  return (
    <Drawer
      position={LEFT}
      size={SM}
      selectedDrawer={selectedDrawer}
      handleClose={handleClose}
    >
      <AdminLeftSidebar />
    </Drawer>
  );
};

export default AdminNavigationDrawer;
