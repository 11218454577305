import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { get } from "lodash";
import {
  DateSelector,
  FlightClass,
  LocationPicker,
  TravelersCount,
} from "../../molecules";
import { Close, CloseDark, RenderSVG } from "../../../assets/icons";
import config from "../../../config.json";
import {
  ROUTES,
  LOCATION_TYPE,
  DATE_TYPE_TITLES,
  TRIP_TYPES,
  DEFAULT_VALUES,
  JOURNEY_DEFAULT_VALUES,
  SEARCH_SECTION,
} from "../../../constants";
import ErrorMessage from "../../atoms/ErrorMessage";

const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { HOME } = ROUTES;
const { TO, FROM } = LOCATION_TYPE;
const { DEPARTURE } = DATE_TYPE_TITLES;
const { ONE_WAY } = TRIP_TYPES;
const { FLIGHT } = SEARCH_SECTION;
const MINIMUM_MULTI_CITY = 2;
const MAXIMUM_MULTI_CITY = 5;
const JOURNEY = "journey";

const getUpdatedErrorAndTouched = (values, filterValueIndex) =>
  values.filter((value, index) => index !== filterValueIndex);

const getJourneyKeyValue = (journey) => get(journey, "originCode.iata", EMPTY_STRING);

const MultiCitySearch = ({ handleLocationChange, footer }) => {
  const { values, setErrors, setFieldValue, setTouched, errors, touched } =
    useFormikContext();
  const location = useLocation();
  const { t } = useTranslation();
  const isHomePage = location.pathname === HOME;

  const handleDeleteCity = (index) => {
    const updatedJourneyValues = values.journey.filter(
      (value, journeyIndex) => journeyIndex !== index
    );
    setFieldValue(JOURNEY, updatedJourneyValues);
    setErrors((errors) => ({
      ...errors,
      journey: getUpdatedErrorAndTouched(errors, index),
    }));
    setTouched((touched) => ({
      ...touched,
      journey: getUpdatedErrorAndTouched(touched, index),
    }));
  };

  const handleAddCity = () => {
    const { destCode, departureDate } = values.journey.at(-ONE);
    const updatedJourneyValues = [
      ...values.journey,
      {
        ...JOURNEY_DEFAULT_VALUES,
        departureDate,
        originCode: destCode,
        destCode: EMPTY_STRING,
      },
    ];
    setFieldValue(JOURNEY, updatedJourneyValues);
  };

  const renderFieldError = (name) =>
    get(errors, name, false) &&
    get(touched, name, false) && (
      <ErrorMessage errorMessage={get(errors, name)} />
    );

  return (
    <div>
      <div className="flex flex-col gap-6">
        {values.journey.map((journey, index) => (
          <div key={getJourneyKeyValue(journey)} className="grid grid-cols-12 gap-3 2xl:gap-4">
            <div className="col-span-12 md:col-span-7 lg:col-span-5 grid grid-cols-2 gap-4">
              <LocationPicker
                name={`journey[${index}].originCode`}
                handleLocationChange={handleLocationChange}
                type={FROM}
                source={FLIGHT}
              />
              <LocationPicker
                name={`journey[${index}].destCode`}
                handleLocationChange={handleLocationChange}
                index={index}
                type={TO}
                source={FLIGHT}
              />
            </div>
            <div className="lg:col-span-2 col-span-12 md:col-span-4">
              <div className="flex shadow-sm divide-x divide-gray-300">
                <DateSelector
                  showPrices={false}
                  placeholder={DEPARTURE}
                  name={`journey[${index}].departureDate`}
                  tripType={ONE_WAY}
                  showCalendarIcon={true}
                  minDate={
                    index > ZERO
                      ? new Date(values.journey[index - ONE].departureDate)
                      : new Date()
                  }
                  height="50px"
                  inputClasses={"!rounded-md"}
                  fareCalendarPrices={{
                    origin: values.journey[index].originCode.iata,
                    destination: values.journey[index].destCode.iata,
                  }}
                />
              </div>
              {index === values.journey.length - ONE && (
                <div>{renderFieldError("journey[0].departureDate")}</div>
              )}
            </div>
            {!index && (
              <>
                <FlightClass />
                <div className="col-span-12 md:col-span-5 lg:col-span-2">
                  <TravelersCount />
                </div>
              </>
            )}
            {values.journey.length > MINIMUM_MULTI_CITY && (
              <div
                className={classNames(
                  "col-span-12 flex pt-2 md:col-span-1 lg:justify-end",
                  {
                    "lg:col-span-5 lg:flex lg:justify-end": index,
                  }
                )}
              >
                <span
                  onClick={() => handleDeleteCity(index)}
                  className="cursor-pointer"
                >
                  {isHomePage ? (
                    <RenderSVG
                      Svg={Close}
                      width="38"
                      height="38"
                      alt="Close Icon"
                    />
                  ) : (
                    <RenderSVG
                      Svg={CloseDark}
                      width="38"
                      height="38"
                      alt="Close icon"
                      stroke={config.primaryColor}
                      color="white"
                    />
                  )}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-4 sm:flex-row sm:justify-between items-center mt-6">
        <button
          type="button"
          className={classNames(
            "py-3 px-4 h-full w-full sm:w-fit flex items-center gap-2 rounded-md justify-center text-sm font-medium text-white bg-primary-600 disabled:bg-contrast-400 disabled:text-white",
            {
              "bg-secondary-600": isHomePage,
            }
          )}
          onClick={handleAddCity}
          disabled={values.journey.length >= MAXIMUM_MULTI_CITY}
        >
          <span>{t("searchSection.addFlight")}</span>
        </button>
        <div className="w-full sm:w-fit">
        {footer}
        </div>
      </div>
    </div>
  );
};

export default MultiCitySearch;
