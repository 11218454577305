import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { TRIP_TYPES } from "../../constants";
import { RenderSVG, ChevronUp, ChevronDown } from "../../assets/icons";
import { selectFilteredFlights } from "../../components/organisms/Search/search.selectors";
import { useTranslation } from "react-i18next";
import RenderFlightChart from "./RenderFilghtChart";
import logEvent from "../../utils/GATracker";

const { ROUND_TRIP } = TRIP_TYPES;

const FlightsChart = ({ showPackages, tripType }) => {
  const { t } = useTranslation();
  const filteredFlights = useSelector(selectFilteredFlights);

  const [isGraphVisible, setIsGraphVisible] = useState(false);
  const [isTransitionComplete, setIsTransitionComplete] = useState(false);
  const isRoundTrip = tripType === ROUND_TRIP;

  const renderGraphButton = () => (
    <button
      className="flex items-center bg-gray-50 hover:bg-white border border-gray-600 hover:border-gray-800 px-4 py-2 rounded-full shadow-sm"
      onClick={() => {
        logEvent(isGraphVisible ? `hide_price_graph_clicked` : `view_price_graph_clicked`)
        setIsGraphVisible(!isGraphVisible)
      }}
    >
      <RenderSVG
        Svg={isGraphVisible ? ChevronUp : ChevronDown}
        className="w-4 h-4 text-gray-600 hover:text-gray-800 cursor-pointer shrink-0"
      />

      <div className="text-gray-600 hover:text-gray-800 font-semibold pl-1 cursor-pointer">
        {isGraphVisible
          ? t("flightResults.hideGraph")
          : t("flightResults.viewGraph")}
      </div>
    </button>
  );

  const renderGraphContent = () => {
    const { isolated = {}, packages = [] } = filteredFlights;
    const areIsolatedFlights = isRoundTrip && !showPackages;
    const flights = areIsolatedFlights ? isolated : packages;

    return (
      <div
        className={classNames(
          "hidden lg:block bg-gray-50 border border-gray-200 p-2 rounded-xl h-full w-full mx-auto mb-2",
          {
            "mx-1": areIsolatedFlights,
            "ml-px": !areIsolatedFlights,
          } 
        )}
      >
        <div className="flex items-center gap-2">
          <span className="text-2xl font-semibold p-1 py-3 text-primary-900">
            {t("flightResults.graphTitle")}
          </span>
          {!isRoundTrip && (
            <div className="font-semibold">
              {"("}
              {flights.length} Flights {")"}
            </div>
          )}
          {isGraphVisible && (
            <div className="ms-auto flex justify-center mt-2">
              {renderGraphButton()}
            </div>
          )}
        </div>
        <div
          className={classNames(
            "w-full mx-4 ml-1 relative transition-max-height duration-500 ease-in-out overflow-hidden",
            {
              "max-h-[2000px]": isGraphVisible && !isTransitionComplete,
              "max-h-40": !isGraphVisible,
              "overflow-visible max-h-full":
                isTransitionComplete && isGraphVisible,
            }
          )}
          onTransitionEnd={() => setIsTransitionComplete(isGraphVisible)}
        >
          {!isGraphVisible && (
            <div className="absolute inset-0 top-8 bg-gradient-to-b from-transparent to-gray-100">
              <div className="absolute flex justify-center bottom-4 right-0 left-0">
                {!isTransitionComplete && renderGraphButton()}
              </div>
            </div>
          )}
          {areIsolatedFlights ? (
            <>
              <RenderFlightChart
                flights={flights.outbound}
                isInbound={false}
                tripType={tripType}
                showPackages={showPackages}
              />
              <RenderFlightChart
                flights={flights.inbound}
                isInbound={true}
                tripType={tripType}
                showPackages={showPackages}
              />
            </>
          ) : (
            <RenderFlightChart
              flights={flights}
              isInbound={true}
              tripType={tripType}
              showPackages={showPackages}
              isPackage={showPackages}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    !isEmpty(filteredFlights) && (
      <div className="mx-auto w-full">{renderGraphContent()}</div>
    )
  );
};

export default memo(FlightsChart);
