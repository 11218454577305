import { useMemo } from "react";
import get from "lodash/get";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { selectSelectedLCCBaggages } from "../index";
import { selectFlightPriceReq } from "../../../FlightResults";
import { Minus, Plus, RenderSVG } from "../../../../assets/icons";
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_VALUES,
  TRAVELER_TYPE,
} from "../../../../constants";
import { showSSRPrice } from "../../../../helper";
import { selectCountryInfo } from "../../../Profile";

const { ZERO, ONE, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;
const { HELD_INFANT } = TRAVELER_TYPE;

const countOfCurrentBaggage = (code, baggages = EMPTY_ARRAY) =>
  baggages.filter((baggage) => baggage.Code === code).length;

const getAvailableBaggages = (flightPriceReq) =>
  get(flightPriceReq, "0.price.travelerDetails", EMPTY_ARRAY).filter(
    ({ travelerType }) => travelerType !== HELD_INFANT
  ).length;

const BaggageCard = ({ data, handleBaggageSelection }) => {
  const { t } = useTranslation();
  const { FlightNumber, Weight, Code, Price } = data;
  const selectedLCCBaggages = useSelector(selectSelectedLCCBaggages);
  const flightPriceReq = useSelector(selectFlightPriceReq);
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const currentFlightBaggages = get(
    selectedLCCBaggages,
    FlightNumber,
    EMPTY_ARRAY
  );

  const totalAvailableBaggages = useMemo(
    () => getAvailableBaggages(flightPriceReq),
    [flightPriceReq]
  );
  const currencyCode = get(
    selectedCountryInfo,
    "currency.code",
    DEFAULT_CURRENCY_CODE
  );
  const baggagesCount = countOfCurrentBaggage(Code, currentFlightBaggages);

  const isAddButtonDisabled =
    totalAvailableBaggages <= currentFlightBaggages.length;

  const getErrorMessage = () =>
    isAddButtonDisabled
      ? `You can choose only ${totalAvailableBaggages} baggage${
          totalAvailableBaggages > ONE ? "s" : EMPTY_STRING
        }`
      : EMPTY_STRING;

  return (
    <div>
      <div key={Code} className="my-1">
        <div className="my-1 p-3 border-b flex justify-between items-center">
          <div className="flex flex-col">
            <div>
              {t("baggageSelection.additional")} {Weight}kg
            </div>
            <div className="text-sm">{showSSRPrice(Price, currencyCode)}</div>
          </div>
          <div className="relative">
            <Tooltip
              id="baggage-tooltip"
              className="!bg-contrast-500 !rounded-lg"
            />
            <div className="h-full flex gap-1 border border-1 items-center justify-center p-1 rounded-md">
              {!!baggagesCount && (
                <button
                  disabled={!baggagesCount}
                  onClick={() => handleBaggageSelection(data, ZERO)}
                >
                  <RenderSVG Svg={Minus} height="15" alt="minus" />
                </button>
              )}
              {!!baggagesCount && (
                <div className="cursor-default">{baggagesCount}</div>
              )}
              <button
                className={classNames("", {
                  "flex items-center justify-center text-xs gap-1 px-2 py-1":
                    !baggagesCount,
                  "cursor-not-allowed": isAddButtonDisabled,
                })}
                onClick={() =>
                  !isAddButtonDisabled && handleBaggageSelection(data, ONE)
                }
                data-tooltip-id="baggage-tooltip"
                data-tooltip-content={getErrorMessage()}
              >
                {baggagesCount ? (
                  <RenderSVG Svg={Plus} height="15" alt="plus" />
                ) : (
                  t("travelerInfo.add")
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaggageCard;
