import axios from "axios";
import classNames from "classnames";
import { useState } from "react";
import { Loader } from "../../components/organisms/Spinner/Spinner";
import { getCityNameByIata, getQueryParams } from "../../helper";
import { DEFAULT_USER_IP_ADDRESS, ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetFlights } from "../../components/organisms/Search";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get } from "lodash";
import { selectCountryInfo } from "../Profile";

const { REACT_APP_OPEN_AI_TOKEN } = process.env;
const { FLIGHT_RESULTS } = ROUTES;

const Intelligence = () => {
  const [prompt, setPrompt] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedCountryInfo = useSelector(selectCountryInfo) || {};
  const { ip = DEFAULT_USER_IP_ADDRESS } = selectedCountryInfo;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(apiResponse);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 500); // Reset isCopied state after 0.5 seconds
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const handleClick = () => {
    const parsedJSON = JSON.parse(apiResponse);
    if (!parsedJSON.FlightsJSON)
      return toast(
        "Error: JSON Not Generated Properly, Please try again later!",
        {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          type: "error",
        }
      );
    const request = updateJourneyCities(parsedJSON.FlightsJSON);
    const query = getQueryParams(request);
    dispatch(resetFlights());
    navigate(`${FLIGHT_RESULTS}?${query}`);
  };

  async function generateJSONFromText(text) {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo-0125",
          messages: [
            {
              role: "user",
              content: `I have a search engine for travel. 
              The user has inputed the following search query: ${text}.
              Convert search query into JSON & return only JSON with no extra word. 
              Follow below instructions to prepare JSON - 
              Add from as originCode, to as destCode, on as departureDate.
              Always use YYYY-MM-DD date format.
              Always use future dates.
              Use current year (2024 or 2025) if not specified.
              If no dates specified, use next day as departureDate.
              If its a round trip or return trip, but not added returnDate, add next day of departureDate as returnDate.
              Add fareType key with default value as 'Regular', if user specify any use that, only possible values are Student, ArmedForce and SeniorCitizen.
              If user specify need direct flights, add isDirectFlight with true, else add this key always with default value as false.
              Add key travelClass with default value as ECONOMY, update this if user specify, value would be in uppercase all time.
              Add key endUserIp, with default value ${ip}.
              Add key includedAirlines, with default value as [], if in query specified any airline to include, add that airline code in this array.
              Add key excludedAirlines, with default value as [], If in query specified any airline to exclude, add that airline code in this array as string.
              Add key passengersCount which holds an object, contains keys adult with default value 1, children default value 0 and infants with default value 0, update this object as per user query.
              Add key tripType for round trip add value roundTrip, for one way add oneWay & for multi city flights, add type multiCity.
              Add key currencyCode with default value INR, update this, if user provide currency.
              Add key travelType & set its value as domestic if user fly within a country, and set international, if user searching for across the countries.
              Add key 'key' with default value as search.
              Add key source with default value ["GDS"].
              Add key 'maxPrice' with default value "", update this if user specify any budget, amount, figure or price added in query.
              Add key journeys with default value as [] & push an object containing originCode, destCode, departureDate, returnDate, departureTime default value "", in it, Make sure this object & its keys never remove.
              Make sure to Remove keys originCode, destCode, departureDate, returnDate, from, to, on keys outside of journeys only.
              Move journeys as first key of JSON.
              Put this whole json in new object's key 'FlightsJSON' an send result json.
              If there is no information about hotel booking, just return above JSON & don't read below.
              Add key 'HotelsJSON' with default value {}.
              Add key MaxRating with default value of 5, & update this if user specify.
              Add key MinRating with default value of 0, & update this if user specify.
              Add key NoOfRooms with default value 1, update if user specify any.
              Add key CheckInDate from query in format DD/MM/YYYY, add next day date, if user not specify.
              Add key CountryCode with default value 'IN', update this with hotel's location's country code, he is looking for.
              Add key GuestNationality with default value 'IN', update if user specify his country, add 2 digit country code.
              Add key PrefferedCurrency with default value as 'INR', update this if user specify currency (add curreny code).
              Add key EndUserIp with default value ${ip}.
              Add key NoOfNights with default value "1", and calculate this based on his checkin & checkout dates.
              Add key 'RoomGuests' with default JSON value "[{"NoOfAdults": 1, "NoOfChild": 0}]", update number of adults & children if provided, if number of children are provided, add a key ChildAge with default value ["1"].
              Add key CityId, with default value "", update this with hotel's city.`,
            },
          ],
          max_tokens: 400,
          temperature: 0.7,
        },
        {
          headers: {
            Authorization: `Bearer ${REACT_APP_OPEN_AI_TOKEN}`,
          },
        }
      );

      const apiResContent = get(
        response,
        "data.choices[0].message.content",
        {}
      );
      const apiResContentJSON = JSON.parse(apiResContent);
      setIsLoading(false);
      return apiResContentJSON;
    } catch (error) {
      setIsLoading(false);
      console.error("Error generating JSON from text:", error);
      return null;
    }
  }

  function updateJourneyCities(jsonData) {
    const updatedJourneys = jsonData.journeys.map((journey) => {
      const { originCode, destCode } = journey;
      return {
        ...journey,
        originCode: {
          iata: originCode,
          cityName: getCityNameByIata(originCode),
        },
        destCode: {
          iata: destCode,
          cityName: getCityNameByIata(destCode),
        },
      };
    });

    return {
      ...jsonData,
      journeys: updatedJourneys,
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      generateJSONFromText(prompt).then((jsonData) => {
        if (jsonData) setApiResponse(JSON.stringify(jsonData, null, 3));
        else console.log("Failed to generate JSON from text.");
      });
    } catch (e) {
      console.log(e);
      setApiResponse("Something is going wrong, Please try again.");
    }
  };

  const RenderJSON = () => {
    if (isLoading)
      return (
        <div className="w-full items-center flex justify-center h-56">
          <Loader size="w-12 h-10" />
          <span className="inline-block align-baseline">
            Loading... Please wait!
          </span>
        </div>
      );

    return apiResponse ? (
      <div className="text-wrap h-vh w-full bg-gray-50 p-4 text-sm">
        <div className="flex justify-between pb-2">
          <div className="font-semibold">Generated JSON for Search Query:</div>
          <div>
            <button
              onClick={copyToClipboard}
              className={classNames(
                "border rounded mx-3 py-2 px-4 bg-blue-gray-100",
                {
                  "bg-lime-600": isCopied == true,
                }
              )}
            >
              {isCopied ? "Copied!" : "Copy JSON"}
            </button>
            <button
              onClick={handleClick}
              className="border rounded py-2 px-4 bg-blue-gray-100"
            >
              Search Flights
            </button>
          </div>
        </div>
        <pre>
          <div className="bg-white text-black border-2 rounded-lg p-4 text-wrap">
            <code>{apiResponse}</code>
          </div>
        </pre>
      </div>
    ) : (
      <div className="w-full items-center flex justify-center h-56">
        <span>Try asking something for flight booking...</span>
      </div>
    );
  };

  return (
    <>
      <div className="bg-gray-200 text-xs">
        <form
          onSubmit={handleSubmit}
          className="flex justify-between px-4 xs:py-6 bg-blue-gray-100"
        >
          <textarea
            type="text"
            value={prompt}
            placeholder="Please ask TavaTrip-Intelligence for flight booking..."
            onChange={(e) => setPrompt(e.target.value)}
            className="w-full rounded text-base"
          ></textarea>
          <button
            disabled={prompt.length === 0}
            type="submit"
            className="text-base ml-3 cursor-pointer border rounded py-2 px-4 max-h-12 bg-white"
          >
            {isLoading ? "Generating..." : "Generate"}
          </button>
        </form>
      </div>
      <RenderJSON />
    </>
  );
};

export default Intelligence;
