import { isEmpty, get } from "lodash";
import { DEFAULT_VALUES } from "../constants";

const { EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES

export const generateHotelAddress = (hotelAddress) => {
    if (typeof hotelAddress === 'string')
        return hotelAddress;

    if (!isEmpty(hotelAddress)) {
        const addressLines = get(hotelAddress, 'addressLines', EMPTY_ARRAY);
        const firstAddressLine = !isEmpty(addressLines) ? addressLines[0] : EMPTY_STRING;
        const city = get(hotelAddress, 'cityName', EMPTY_STRING);
        const postalCode = get(hotelAddress, 'postalCode', EMPTY_STRING);
        const state = get(hotelAddress, 'stateOrProvince', EMPTY_STRING);
        const country = get(hotelAddress, 'countryName', EMPTY_STRING);

        return `${firstAddressLine} ${city} ${postalCode}, ${state} ${country}`;
    }
    else return EMPTY_STRING;
}
