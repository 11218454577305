import { uniqueId } from "lodash";
import { getCityNameByIata, getLayoverDuration } from "../helper";

const getStops = (segments) =>
  getLayoverDuration(segments).map((duration, index) => (
    <div key={uniqueId()} className="w-20 flex flex-wrap whitespace-normal">
      <div className="mr-1">{duration},</div>
      <div>{getCityNameByIata(segments[index].arrival.iataCode)}</div>
    </div>
  ));

export { getStops };
