import moment from "moment";
import { MIN_TRAVELER_AGES, MAX_TRAVELER_AGES, DEFAULT_VALUES, TRAVELER_TYPE } from "../constants";
const { ONE } = DEFAULT_VALUES;
const { HELD_INFANT } = TRAVELER_TYPE;

export const isValidMinDateOfBirth = (flightDepartureDate, dateOfBirth, travelerType, daysToSkip = ONE) => {
  let departureDate = new Date(flightDepartureDate);
  departureDate.setDate(departureDate.getDate() - daysToSkip);
  return Math.abs(
      moment(departureDate, "YYYY-MM-DD").diff(
        dateOfBirth,
        travelerType === HELD_INFANT ? "days": "years"
      )
    ) >= MIN_TRAVELER_AGES[travelerType];
};

export const isValidMaxDateOfBirth = (flightDepartureDate, dateOfBirth, travelerType, daysToSkip = ONE) => {
   let departureDate = new Date(flightDepartureDate);
   departureDate.setDate(departureDate.getDate() - daysToSkip);
  return Math.abs(
      moment(new Date(departureDate), "YYYY-MM-DD").diff(
        dateOfBirth,
        "years"
      )
    ) < MAX_TRAVELER_AGES[travelerType];
};
