import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Cross, RenderSVG, Check } from "../../../../assets/icons";
import { setSelectedModal } from "../../Modal";
import { ROUTES } from "../../../../constants";

const { CANCELLATION_AND_REFUND_POLICY } = ROUTES;

const SuccessModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(setSelectedModal());
  };

  return (
    <div className="bg-white">
      <div className="w-full px-4 justify-end items-center gap-4 inline-flex">
        <button
          type="button"
          className="p-[9px] rounded-[19px] justify-center items-center flex "
          onClick={handleClose}
        >
          <RenderSVG Svg={Cross} alt="cross" className="relative" />
        </button>
      </div>
      <div className="w-10 h-10 mx-auto rounded-full bg-green-50 p-1">
        <RenderSVG
          Svg={Check}
          width="30"
          height="30"
          className=" fill-green-400"
        />
      </div>
      <div className="p-4 flex-col justify-start items-start gap-4 inline-flex">
        <div className="grow shrink basis-0 justify-center mx-auto text-contrast-700 text-xl font-bold font-['Inter']">
          {t("userBookings.cancel.successModal.header")}
        </div>
        <div className="self-stretch text-contrast-500 text-sm font-normal font-['Inter'] leading-tight">
          <p className="pl-12 text-sm text-contrast-400 inline-block text-[#868A93]">
            {t("userBookings.cancel.successModal.confirmationMessage")}&nbsp;
            <Link
              to={CANCELLATION_AND_REFUND_POLICY}
              className="text-primary-600 underline hover:text-primary-500"
              target="_blank"
            >
              {t("userBookings.cancel.successModal.cancellationPolicy")}
            </Link>
            &nbsp;{t("userBookings.cancel.support")}&nbsp;
            <a
              href="mailto:support@tavatrip.com"
              className="text-primary-600 hover:opacity-75 text-sm inline"
            >
              support@tavatrip.com
            </a>
             <div>{t("userBookings.cancel.support1")}</div>
          </p>
        </div>
      </div>
      <div className="w-full p-3 border-t border-contrast-200 justify-start items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 h-[38px] justify-end items-start gap-4 flex">
          <button
            type="button"
            className="px-[17px] py-[9px] bg-white rounded-md shadow border border-contrast-300 justify-center items-center flex hover:bg-zinc-300"
            onClick={handleClose}
          >
            <div className="text-contrast-700 text-sm font-medium font-['Inter'] leading-tight">
              {t("userBookings.close")}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
