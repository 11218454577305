import { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getRequiredErrorMessage } from "../../../helper";
import { resetPassword, sendOtp } from "../auth.actions";
import { InputField } from "../../../components/molecules";
import { DEFAULT_VALUES, REGEX, USER_AUTH_FIELDS } from "../../../constants";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";

const { ONLY_DIGITS } = REGEX;
const { AUTH } = SPINNER_NAMES;
const { EMPTY_STRING, SIX, TWO, ZERO } = DEFAULT_VALUES;
const { EMAIL, PASSWORD, PIN, CONFIRMPASSWORD } = USER_AUTH_FIELDS;

const initialValues = {
  pin: EMPTY_STRING,
  email: EMPTY_STRING,
  password: EMPTY_STRING,
  confirmPass: EMPTY_STRING,
};

const Fields = {
  forgotPassword: [
    {
      id: EMAIL,
      type: "email",
      label: "loginAndSignup.formFields.email",
      placeholder: "loginAndSignup.placeholders.email",
    },
  ],
  resetPassword: [
    {
      id: PIN,
      type: "number",
      label: "loginAndSignup.formFields.pin",
      placeholder: "loginAndSignup.placeholders.pin",
    },
    {
      id: PASSWORD,
      type: "password",
      label: "loginAndSignup.formFields.password",
      placeholder: "loginAndSignup.placeholders.password",
    },
    {
      id: CONFIRMPASSWORD,
      type: "password",
      label: "loginAndSignup.formFields.confirmPassword",
      placeholder: "loginAndSignup.placeholders.confirmPassword",
    },
  ],
};

export const ForgotPassword = ({
  forgotPasswordStep,
  setForgotPasswordStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("validationSchemas.loginAndSignup.invalidEmail"))
      .required(getRequiredErrorMessage("loginAndSignup.formFields.email", t)),
  });

  const resetPasswordValidationSchema = Yup.object({
    pin: Yup.string()
      .matches(ONLY_DIGITS, t("validationSchemas.loginAndSignup.mustBeDigits"))
      .min(SIX, t("validationSchemas.loginAndSignup.mustBeSixDigits"))
      .max(SIX, t("validationSchemas.loginAndSignup.mustBeSixDigits"))
      .required(getRequiredErrorMessage("loginAndSignup.formFields.pin", t)),
    password: Yup.string()
      .min(8, t("validationSchemas.loginAndSignup.minPassword"))
      .max(20, t("validationSchemas.loginAndSignup.maxPassword"))
      .required(
        getRequiredErrorMessage("loginAndSignup.formFields.password", t)
      ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password")],
        t("validationSchemas.loginAndSignup.passwordMatch")
      )
      .oneOf([Yup.ref("password"), null], "Password does not match")
      .required(
        getRequiredErrorMessage("loginAndSignup.formFields.confirmPassword", t)
      ),
  });

  const showResetPasswordForm = forgotPasswordStep === TWO;

  const fieldsToBeRendered = showResetPasswordForm
    ? Fields.resetPassword
    : Fields.forgotPassword;

  const validationSchema = showResetPasswordForm
    ? resetPasswordValidationSchema
    : emailValidationSchema;

  const handleOnSubmit = (values, { setSubmitting, resetForm }) => {
    const { email, password, pin } = values;
    if (showResetPasswordForm)
      dispatch(
        resetPassword({
          code: String(pin),
          email,
          password,
        })
      ).then((res) => {
        if (res.payload) {
          resetForm();
          setForgotPasswordStep(ZERO);
          toast.success(t("forgotPassword.resetSuccess"));
        }
      });
    else
      dispatch(sendOtp({ provider: EMAIL, email: email })).then((res) => {
        if (res.payload) {
          setForgotPasswordStep(TWO);
          toast.success(t("forgotPassword.otpSent"));
        }
      });
    setSubmitting(true);
  };

  return (
    <div>
      <div className="text-sm text-contrast-600 mb-6 font-semibold">
        <span>{t("forgotPassword.or")}</span>
        <button
          type="button"
          className="text-primary-600"
          onClick={() => setForgotPasswordStep(ZERO)}
        >
          {t("forgotPassword.loginInstead")}
        </button>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          dirty,
          isValid,
        }) => (
          <Form>
            {fieldsToBeRendered.map(({ id, label, placeholder, type }) => (
              <InputField
                key={id}
                id={id}
                name={id}
                type={type}
                label={t(label)}
                value={values[id]}
                handleChange={handleChange}
                handleBlur={handleBlur}
                placeholder={t(placeholder)}
                errors={errors}
                touched={touched}
              />
            ))}
            <button
              type="submit"
              className="py-3 px-4 w-full rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium disabled:cursor-not-allowed disabled:opacity-70"
              disabled={isSubmitting || !dirty || !isValid}
            >
              <Spinner name={AUTH} setIsSpinnerActive={setIsSubmitting}>
                {t("loginAndSignup.buttonText.continue")}
              </Spinner>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
