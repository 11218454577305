import { useState } from "react";
import { get } from "lodash";
import { Field, useFormikContext } from "formik";
import ErrorMessage from "../../atoms/ErrorMessage";
import {
  ShowPasswordIcon,
  HidePasswordIcon,
  RenderSVG,
} from "../../../assets/icons";
import { DEFAULT_VALUES, USER_AUTH_FIELDS } from "../../../constants";

const { NUMBER, EMPTY_STRING} = DEFAULT_VALUES
const { PIN } = USER_AUTH_FIELDS
const replaceNonDigitsRegex = /[^\d]/g

const InputField = ({ id, name, label, type, value, placeholder }) => {
  const { errors, touched, handleBlur, handleChange } = useFormikContext();
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const renderFieldError = () =>
    get(errors, name, false) &&
    get(touched, name, false) && (
      <ErrorMessage errorMessage={get(errors, name)} />
    );

  return (
    <div className="form-group mb-6">
      <label className="block text-sm font-medium mb-1 text-contrast-900">
        {label}
      </label>
      <div className="flex items-center focus-within:border-primary-500 border-2 rounded-md">
        <Field
          id={id}
          name={name}
          type={shouldShowPassword ? "text" : type}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          className="form-control border-none rounded-md w-full !ring-0"
          placeholder={placeholder}
          autoComplete="off"
          onInput={(e) => {
            if (id === PIN)
            e.target.value = e.target.value.replace(replaceNonDigitsRegex, EMPTY_STRING);
          }}
          onKeyPress={(e) => {
            if (type === NUMBER && (e.key === "e" || e.key === "E")) {
              e.preventDefault();
            }
          }}
        />
        {type === "password" && (
          <RenderSVG
            className="w-8 h-5 cursor-pointer"
            Svg={shouldShowPassword ? ShowPasswordIcon : HidePasswordIcon}
            alt="Password"
            onClick={() => setShouldShowPassword(!shouldShowPassword)}
          />
        )}
      </div>
      <div>{renderFieldError()}</div>
    </div>
  );
};

export default InputField;
