import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { RenderSVG, Plus, Cross } from "../../assets/icons";
import { actions as hotelBookActions } from "../HotelBooking/hotelBooking.reducer";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import { selectOtherGuests } from "../HotelBooking";
import HotelGuestModal from "./HotelGuestModal";
import LeadGuestInfo from "./LeadGuestInfo";
import { DEFAULT_VALUES } from "../../constants";

const { ZERO, ONE } = DEFAULT_VALUES;
const { setOtherGuests } = hotelBookActions;

const GuestDetailsSection = ({
  leadGuestFormRef,
  guestDetailsRef,
  isPANRequired,
  isPassportRequired,
  isSamePANForAllAllowed,
  areAllGuestDetailsRequired,
}) => {
  const dispatch = useDispatch();
  const searchFilters = useSelector(selectHotelSearchFilters);
  const otherGuests = useSelector(selectOtherGuests);
  const [showGuestModal, setShowGuestModal] = useState(false);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState();

  const { roomGuests = [] } = searchFilters || {};
  const totalGuests = roomGuests.reduce(
    (total, guestObject) =>
      total + guestObject["noOfAdults"] + guestObject["noOfChild"],
    ZERO
  );

  const handleRemoveSelectedGuest = (guestId) => {
    const updatedGuestsArray = otherGuests.map((guest) => {
      if (guest.id === guestId) {
        return {
          ...guest,
          isSelected: false,
          roomIndex: null,
        };
      }
      return guest;
    });
    dispatch(setOtherGuests(updatedGuestsArray));
  };

  const handleClose = () => setShowGuestModal(false);

  const getRequiredNoOfGuests = (roomIndex) => {
    const adults =
      roomGuests[roomIndex].noOfAdults - (roomIndex === ZERO ? ONE : ZERO);
    const children = roomGuests[roomIndex].noOfChild;

    const adultSuffix = `${adults > ONE ? "Adults" : "Adult"}`;
    const childSuffix = `${children > ONE ? "Children" : "Child"}`;
    const adultsCountTag = adults ? `${adults} ${adultSuffix}` : "";
    const childrenCountTag = children ? `${children} ${childSuffix}` : "";
    const separator = adultsCountTag && childrenCountTag ? ", " : "";

    return `${adultsCountTag}${separator}${childrenCountTag}`;
  };

  return (
    <div
      className="bg-white rounded-xl border border-contrast-300 overflow-hidden"
      ref={guestDetailsRef}
    >
      <div className="px-3 py-2 flex items-center border-b border-contrast-300 bg-contrast-50 sm:py-4 md:px-6 ">
        <h4 className="font-bold text-contrast-900 flex-1 text-sm sm:text-base">
          Guest Details
        </h4>
      </div>
      <div className="px-3 pt-1 sm:pt-3 md:px-6">
        <div className="mb-1 sm:mb-2 font-semibold text-[12px] sm:text-[15px]">Primary Guest</div>
        <LeadGuestInfo
          leadGuestFormRef={leadGuestFormRef}
          isPANRequired={isPANRequired}
          isPassportRequired={isPassportRequired}
        />
        {totalGuests > ONE && areAllGuestDetailsRequired && (
          <div className="py-2">
            <h4 className="text-base font-bold text-contrast-900 flex-1 py-2 pt-4 border-t border-contrast-300">
              Other Guests
            </h4>
            {roomGuests.map((_, index) => {
              const shouldShowSection =
                index > ZERO ||
                roomGuests[index]?.noOfAdults > ONE ||
                roomGuests[index]?.noOfChild > ZERO;

              return (
                <div key={uuid()}>
                  {shouldShowSection && (
                    <div className="mb-6">
                      <div className="font-semibold mb-2">
                        Room {index + ONE}
                        <span className="font-light text-sm text-contrast-800 ml-1">
                          ({getRequiredNoOfGuests(index)})
                        </span>
                      </div>
                      <div className="flex-wrap flex gap-3">
                        <div className="flex-wrap flex gap-3">
                          {otherGuests
                            .filter(
                              ({ roomIndex, isSelected }) =>
                                isSelected && roomIndex === index
                            )
                            .map(
                              ({
                                roomIndex,
                                id,
                                profileDetails: {
                                  firstName,
                                  middleName,
                                  lastName,
                                } = {},
                              }) =>
                                roomIndex === index && (
                                  <div
                                    key={id}
                                    className="bg-contrast-100 flex items-center gap-2 text-sm px-3 py-1 rounded-2xl"
                                  >
                                    <span>{`${firstName} ${middleName} ${lastName}`}</span>
                                    <button
                                      className="opacity-80 hover:opacity-100"
                                      onClick={() =>
                                        handleRemoveSelectedGuest(id)
                                      }
                                    >
                                      <RenderSVG
                                        Svg={Cross}
                                        width="14"
                                        className="text-contrast-900"
                                      />
                                    </button>
                                  </div>
                                )
                            )}
                          <button
                            className="py-2 px-4 inline-flex items-center justify-center gap-2 rounded-md bg-primary-100 hover:bg-primary-200 active:bg-primary-100 border border-primary-100 shadow-sm text-sm text-primary-700 font-medium"
                            onClick={() => {
                              setSelectedRoomIndex(index);
                              setShowGuestModal(true);
                            }}
                          >
                            <RenderSVG
                              Svg={Plus}
                              width="14"
                              className="text-contrast-900"
                            />
                            Add Guest
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {showGuestModal && (
              <HotelGuestModal
                handleClose={handleClose}
                isPANRequired={isPANRequired}
                isPassportRequired={isPassportRequired}
                isSamePANForAllAllowed={isSamePANForAllAllowed}
                roomIndex={selectedRoomIndex}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GuestDetailsSection;
