import { useRef, useState } from "react";
import classNames from "classnames";
import useClickOutside from "../../../helper/useClickOutside";;
import { Check, ChevronDown, RenderSVG } from "../../../assets/icons";

const BookingFilter = ({ myTripsSearch, setActiveFilter, bookingFilters, myTripsFilters = [] }) => {  const [show, setShow] = useState(false);
  const dropDownRef = useRef();

  const [selectedBookingFilter, setSelectedBookingFilter] = useState(
    myTripsSearch ? "BookingId" : "Select Filter"
  );
  const filters = myTripsSearch ? myTripsFilters : bookingFilters;

  useClickOutside(dropDownRef, setShow);

  return (
    <div className="col-span-12 md:col-span-6 lg:col-span-2 text-black text-[16px]">
      <div className="relative">
        <button
          type="button"
          onClick={() => setShow(!show)}
          className="w-full bg-contrast-100 border-2 shadow-sm border-contrast-300 flex items-center gap-2 px-3 py-2 rounded-l-lg"
        >
          <span className="flex text-gray-900 whitespace-nowrap text-ellipsis text-sm gap-1">
            {selectedBookingFilter}
            <RenderSVG
              Svg={ChevronDown}
              width="20"
              style={{ transform: show ? "rotate(180deg)" : "rotate(0deg)" }}
            />
          </span>
        </button>
        {show && (
          <div ref={dropDownRef} className="dropdown-menu  absolute z-10 bg-contrast-100 py-4 rounded-lg border border-contrast-200 shadow-2xl my-4 w-60">
            <ul className="">
              {filters.map(({ id, label, value }) => (
                <li
                  key={id}
                  onClick={() => {
                    setSelectedBookingFilter(label);
                    setActiveFilter(value);
                    setShow(false);
                  }}
                >
                  <span className="flex gap-3 text-sm px-6 py-2 cursor-pointer hover:bg-primary-100 hover:text-primary-600 hover:font-semibold">
                    <span
                      className={classNames({
                        "font-semibold !flex": selectedBookingFilter === label,
                      })}
                    >
                      {label}
                    </span>
                    <RenderSVG
                      Svg={Check}
                      width="20"
                      alt="Checkmark"
                      className={classNames("ms-auto hidden", {
                        "!flex": selectedBookingFilter === label,
                      })}
                    />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingFilter;
