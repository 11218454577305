
const defaultColors = {
    primary: { 50: '238, 242, 255', 100: '224 231 255', 200: '199, 210, 254', 300: '165, 180, 252', 400: '129, 140, 248', 500: '99, 102, 241', 600: '79 70 229', 700: '67 56 202', 800: '55, 48, 163', 900: '49 46 129' },
    contrast: {
        50: '249, 250, 251',
        100: '243 244 246',
        200: '229, 231, 235',
        300: '209, 213, 219',
        400: '156, 163, 175',
        500: '107 114 128',
        600: '75 85 99',
        700: '55 65 81',
        800: '31, 41, 55',
        900: '17 24 39',
    },
    secondary: { 50: '238, 242, 255', 100: '224 231 255', 200: '199, 210, 254', 300: '165, 180, 252', 400: '129, 140, 248', 500: '99, 102, 241', 600: '79 70 229', 700: '67 56 202', 800: '55, 48, 163', 900: '49 46 129' }
}

const alphaValues = ['0.1', '0.2', '0.3', '0.4', '0.5', '0.6', '0.7', '0.8', '0.9', '1']

const getColorValues = (hexColor) => {
    const normalizedHex = hexColor.replace(/#/g, '')
    const color =
        normalizedHex.length === 3 ? normalizedHex.repeat(2) : normalizedHex
    const r = parseInt(color.substr(0, 2), 16)
    const g = parseInt(color.substr(2, 2), 16)
    const b = parseInt(color.substr(4, 2), 16)
    return `${r}, ${g}, ${b}`
}

export const generateColorVariables = (hex, type, isDefaultTheme = true) => {
    if (!isDefaultTheme) {
        const rgbColor = getColorValues(hex)
        const variants = Object.keys(defaultColors[type])

        return alphaValues.map(
            (alpha, index) =>
                `--color-${type}-${variants[index]}: ${rgbColor}, ${alpha};`
        )
    } else {
        const defaultColorValues = Object.entries(defaultColors[type])
        return defaultColorValues.map(
            ([key, colorValue]) => `--color-${type}-${key}: ${colorValue};`
        )
    }
}
