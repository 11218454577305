import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { get, isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import Confetti from "react-confetti";
import classnames from "classnames";
import { Helmet } from "react-helmet-async";
import { getAirportInfoByIata, getFromSessionStorage, setToSessionStorage } from "../../../helper";
import { selectBookCallBackResponse, selectPaymentMethod, selectCancellationStatus, fetchFlightPollingResponse, selectFlightPollingResponse } from "../FlightBookings";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import {
  FlightInfo,
  TravelersInfo,
  WebCheckinInfo,
  AirlineContactInfo,
  PaymentInfo,
  FailedBookingHeader,
  PartialBookingHeader,
  SuccessBookingHeader
} from "../../../components/organisms/FlightBookingInfoSections";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import config from "../../../config.json";
import { MODALS } from "../../../components/organisms/AppModals/modals.constants";
import { setSelectedModal } from "../../../components/organisms/Modal";
import mapCancellationRequest from "../../../RequestMappers/CancellationRequestMapping";
import {
  InvoiceIcon,
  RenderSVG,
  TicketOutline,
  TicketSlash,
} from "../../../assets/icons";
import {
  DEFAULT_VALUES,
  ROUTES,
  WINDOWS_EVENTS,
  CACHE_KEYS,
  TRIP_TYPES,
  BOOKING_STATUS_CODES,
  BOOKING_CATEGORIES,
  CANCELLATION_STATUS,
  CURRENCY_SYMBOLS,
  FLIGHT_PROVIDERS,
  SEARCH_SECTION,
} from "../../../constants";
import {
  getAmadeusRefundCharges,
  getTBORefundCharges,
  setSelectedBooking,
  getUserBookingInfo,
  setIsGenerateVoucherInitiated
} from "../../MyTrips/index";
import { selectUserInfo, selectCountryInfo } from "../../Profile";
import { toast } from "react-toastify";

const { ZERO, ONE, POLLING_INTERVAL, POLLING_DURATION, EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } =
  DEFAULT_VALUES;
const { BOOK_CALLBACK, FETCH_USER_BOOKING_INFO } = SPINNER_NAMES;
const { HOME } = ROUTES;
const { SUCCESSFUL } = CANCELLATION_STATUS;
const { POPSTATE } = WINDOWS_EVENTS;
const { USER_BOOKINGS, CONTACT_US } = ROUTES;
const { BOOKING_API_REQUEST } = CACHE_KEYS;
const { ROUND_TRIP } = TRIP_TYPES;
const { SUCCESS, FAILURE, PARTIAL } = BOOKING_STATUS_CODES;
const { CONFIRMED } = BOOKING_CATEGORIES;
const { INR } = CURRENCY_SYMBOLS;
const { logo, brandName } = config;
const { BOOKING_CANCELLATION_MODAL, LOGIN_MODAL } = MODALS;
const { TBO, AMADEUS } = FLIGHT_PROVIDERS;
const { FLIGHT } = SEARCH_SECTION;

const getJourneyData = (journey) => {
  const totalPrice = get(journey, "price.grandTotal", ZERO);
  const currency = get(journey, "price.currency", INR);
  const segments = get(journey, "itineraries.0.segments", EMPTY_ARRAY);
  const departIata = get(segments, "0.departure.iataCode", EMPTY_STRING);
  const arrivalIata = get(
    segments,
    `${segments.length - ONE}.arrival.iataCode`,
    EMPTY_STRING
  );
  const departLocation =
    getAirportInfoByIata(departIata).cityName || departIata;
  const arrivalLocation =
    getAirportInfoByIata(arrivalIata).cityName || arrivalIata;
  return {
    journeyData: `${departLocation}-${arrivalLocation}`,
    amount: totalPrice,
    currency: currency,
  };
};

const BookingStatusLoader = ({ t }) => (
  <SkeletonTheme baseColor="lightgray" highlightColor="#14b8a5">
    <div className="relative w-full z-0">
      <Skeleton width={"100%"} height={180} duration={2} borderRadius={0} />
      <div className="absolute top-16 left-12 z-10 text-xl text-contrast-900 font-bold">
        {t("bookingResult.loadingMsg")}
      </div>
    </div>
  </SkeletonTheme>
);

const BookingResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = useSelector(selectUserInfo);
  const [collectiveBookingStatus, setCollectiveBookingStatus] = useState();
  const [isSpinnerActive, setIsSpinnerActive] = useState(true);
  const [isPollingWorking, setIsPollingWorking] = useState(false);
  const { ip: endUserIp } = useSelector(selectCountryInfo) || EMPTY_OBJECT;
  const isUserAuthenticated = !!userInfo?.id;
  const paymentMethod = useSelector(selectPaymentMethod);
  const cancellationStatus = useSelector(selectCancellationStatus);
  const flightPollingResponse = useSelector(selectFlightPollingResponse);
  const bookCallbackResponse = useSelector(selectBookCallBackResponse);
  const count = useRef(ZERO)
  const startTime = useRef(ZERO)
  const bookingAPIRequest =
    getFromSessionStorage(BOOKING_API_REQUEST) || EMPTY_OBJECT;
  const journeyDetails = get(
    bookingAPIRequest,
    "bookingRequest.journeyDetails",
    EMPTY_ARRAY
  );
  const itineraries = journeyDetails.reduce(
    (itineraryAcc, journey) => [...itineraryAcc, ...journey.itineraries],
    []
  );
  const travelerData = get(journeyDetails, "0.travelerDetails", EMPTY_ARRAY);
  const tripType = get(journeyDetails, "0.tripType", ROUND_TRIP);

  const bookingResult = bookCallbackResponse?.output?.map((each) =>
    each?.externalOutput
      ? get(each, "externalOutput.0.internalOutput.input.output", EMPTY_OBJECT)
      : get(
        each,
        "output.ticketingResponse.input.internalOutput.input.response",
        EMPTY_OBJECT
      )
  );

  const flightBookingResponse = !isEmpty(bookingResult) ? bookingResult : flightPollingResponse;
  const bookingId = get(
    bookingAPIRequest,
    "bookingRequest.tavaBookingId",
    EMPTY_STRING
  );

  useEffect(() => {
    const pendingResponse = flightPollingResponse;
    if (!startTime.current) startTime.current = Date.now();
    if (startTime.current && startTime.current + POLLING_DURATION < Date.now()) {
      setIsPollingWorking(false);
      return;
    }

    let timeoutId;
    if (isEmpty(bookCallbackResponse) && (!count.current || isEmpty(pendingResponse))) {
      if (!isPollingWorking) {
        setIsPollingWorking(true);
      }
      timeoutId = setTimeout(() => {
        dispatch(fetchFlightPollingResponse({ id: bookingId }));
        count.current = count.current + ONE;
      }, POLLING_INTERVAL);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [flightPollingResponse, bookCallbackResponse]);

  useEffect(() => {
    if (!isEmpty(flightPollingResponse) || !isEmpty(bookCallbackResponse)) {
      setIsPollingWorking(false);
    }
  }, [flightPollingResponse, bookCallbackResponse]);

  const pnrList = flightBookingResponse?.map((each) => each.pnr || each.allPnr);
  const bookingStatus = bookingResult?.map(booking => booking?.ticketData?.[ZERO]?.TicketStatus) || [];
  const bookingPollingStatus = flightPollingResponse?.map(booking => booking?.status === CONFIRMED ? ONE : ZERO) || [];
  const allBookingStatus = !isEmpty(bookingStatus) ? bookingStatus : bookingPollingStatus;
  const failedJourneyIndex = pnrList?.findIndex((pnr) => !pnr);
  const {
    journeyData = EMPTY_STRING,
    amount = ZERO,
    currency = INR,
  } = getJourneyData(journeyDetails[failedJourneyIndex]) || EMPTY_OBJECT;

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    navigate(HOME);
  };

  useEffect(() => {
    if (paymentMethod) {
      const updatedJourneyDetails = journeyDetails.map((journey) => {
        const paymentDetails = get(journey, 'price.paymentsDetails')
        if (paymentDetails) {
          return {
            ...journey,
            price: {
              ...journey.price,
              paymentsDetails: {
                ...paymentDetails,
                paymentMethod
              }
            }
          };
        }
        return journey;
      });

      const updatedBookingRequest = {
        ...bookingAPIRequest,
        bookingRequest: {
          ...bookingAPIRequest.bookingRequest,
          journeyDetails: updatedJourneyDetails
        }
      };
      setToSessionStorage(BOOKING_API_REQUEST, updatedBookingRequest);
    }
  }, [paymentMethod]);

  useEffect(() => {
    let bookingStatus;
    const filteredPnrs = pnrList?.filter((pnr) => pnr);
    if (!filteredPnrs?.length) bookingStatus = FAILURE;
    else if (filteredPnrs?.length && !isEmpty(allBookingStatus) && allBookingStatus?.every(status => status !== 1)) bookingStatus = FAILURE
    else if ((filteredPnrs?.length === (bookCallbackResponse?.output?.length) || flightPollingResponse?.length) && !isEmpty(allBookingStatus) && allBookingStatus?.every(status => status === ONE))
      bookingStatus = SUCCESS;
    else bookingStatus = PARTIAL;
    setCollectiveBookingStatus(bookingStatus);
  }, [bookCallbackResponse, flightPollingResponse]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener(POPSTATE, onBackButtonEvent);

    return () => window.removeEventListener(POPSTATE, onBackButtonEvent);
  }, []);

  const handleBookingTBOResponse = () => {
    dispatch(setIsGenerateVoucherInitiated(false));
    try {
      const response = dispatch(
        getUserBookingInfo({
          queryParams: {
            division: FLIGHT,
            id: bookingId,
          },
        })
      ).then((res) => {
        return res;
      })
      return response;
    } catch (error) {
      console.error("Error occurred while fetching booking info:", error);
    }
  };

  let source = get(flightBookingResponse, "provider", EMPTY_STRING);
  const bookingActions = [
    {
      id: "cancelTicket",
      label: "bookingResult.actions.cancelTicket",
      icon: TicketSlash,
      handleClick: async () => {
        let bookingResponse;
        if (!isUserAuthenticated) {
          toast.error("Please log in to access Ticket Cancel functionality. Thank you!");
          dispatch(setSelectedModal(LOGIN_MODAL));
          return;
        }
        if (!source) {
          source = TBO;
          const tboBookingResponse = await handleBookingTBOResponse();
          bookingResponse = get(tboBookingResponse, "payload.result.0", EMPTY_STRING);
        } else if(source === AMADEUS) {
          bookingResponse = get(bookingResult, "bookingInfo.0", EMPTY_STRING);
        }

        let finalbookingResponse = { ...bookingResponse };
        finalbookingResponse.endUserIp = endUserIp;
        const cancellationRequestBody = mapCancellationRequest(
          source,
          finalbookingResponse
        );
        source === TBO
          ? dispatch(getTBORefundCharges({ body: cancellationRequestBody }))
          : dispatch(
            getAmadeusRefundCharges({ body: cancellationRequestBody })
          );
        dispatch(setSelectedBooking(finalbookingResponse));
        dispatch(setSelectedModal(BOOKING_CANCELLATION_MODAL));
      }
    },
  ];
  const cancelBookingActions = [
    {
      id: "cancelTicket",
      label: "Contact Us",
      icon: TicketSlash,
      handleClick: () => navigate(CONTACT_US),
    },
  ];

  return (
    <div className="relative z-0">
      <Helmet>
        <title>{brandName} | Flight Booking Confirmation</title>
      </Helmet>
      {isSpinnerActive && (
        <div className={classnames("bg-red-700 text-white text-sm w-full px-8 py-2 text-center sticky top-[75px] z-10 ",
          {
            "lg:top-[62px]": !isUserAuthenticated,
          }
        )}
        >
          {t("bookingResult.warningMsg")}
        </div>
      )}
      <Tooltip
        id="comingSoon-tooltip"
        className="!w-56 !sm:w-72 !z-50 !bg-primary-600 !rounded-lg text-center"
      />
      <Spinner
        name={BOOK_CALLBACK}
        loaderComponent={<BookingStatusLoader t={t} />}
        setIsSpinnerActive={setIsSpinnerActive}
        showSpinner={isPollingWorking}
      >
        {collectiveBookingStatus === SUCCESS ||
          (collectiveBookingStatus === PARTIAL && (
            <Confetti
              numberOfPieces={2000}
              width={window.innerWidth * 0.95}
              initialVelocityX={10}
              gravity={0.1}
              recycle={false}
              tweenDuration={4000}
            />
          ))}
        {collectiveBookingStatus === SUCCESS && (
          <SuccessBookingHeader
            bookingId={bookingId}
            bookingStatus={collectiveBookingStatus}
            t={t}
          />
        )}
        {collectiveBookingStatus === PARTIAL && (
          <PartialBookingHeader
            bookingId={bookingId}
            bookingStatus={collectiveBookingStatus}
            failedJourneyInfo={{
              journeyData,
              currency,
              amount
            }}
            t={t}
          />
        )}
        {collectiveBookingStatus === FAILURE && (
          <FailedBookingHeader
            bookingId={bookingId}
            bookingStatus={collectiveBookingStatus}
            t={t}

          />
        )}
      </Spinner>
      <div className="pb-16 relative">
        <div className="container px-8 mx-auto -mt-12">
          <div className="grid grid-cols-12 gap-4 sm:gap-8 ">
            <div className="col-span-12 xl:col-span-8 flex flex-col gap-4">
              <div className="flex flex-col gap-8">
                <div className="bg-white rounded-lg border border-dashed border-contrast-300 shadow-sm">
                  <div className="flex flex-wrap px-4 py-3 border-b border-dashed border-contrast-300 justify-between">
                    <h4 className="text-contrast-900 font-bold text-sm">
                      {t(`searchSection.tripTypes.${tripType}`)}{" "}
                      {t("bookingResult.flight")}
                    </h4>
                    {!isSpinnerActive && (
                      <p className="text-contrast-800 font-semibold text-[10px] xs:text-xs flex gap-2 items-center">
                        <span>{t("bookingResult.bookingId")}</span>
                        <span className="text-primary-700 bg-primary-100 px-2 py-0.5 rounded-2xl">
                          {bookingId || EMPTY_STRING}
                        </span>
                      </p>
                    )}
                  </div>
                  {itineraries.map((itinerary, index) => (
                    <FlightInfo
                      key={uuid()}
                      pnrList={pnrList}
                      itinerary={itinerary}
                      index={index}
                      bookingStatus={allBookingStatus}
                    />
                  ))}
                  <div className="px-4 py-2 border-t border-dashed border-contrast-300">
                    <p className="text-[10px] text-contrast-800">
                      for <strong>Baggage details</strong> &{" "}
                      <strong>Cancellation Policy</strong> for this trip{" "}
                      <button
                        className="text-primary-700 font-semibold disabled:cursor-not-allowed"
                        onClick={() => navigate(USER_BOOKINGS)}
                        disabled={isSpinnerActive}
                      >
                        {t("bookingResult.gotoMyTrips")}
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <PaymentInfo
                journeyDetails={journeyDetails}
              />
              <TravelersInfo travelerData={travelerData} />
              {collectiveBookingStatus !== FAILURE && (
                <WebCheckinInfo
                  itineraries={itineraries}
                  pnrList={pnrList}
                  tripType={tripType}
                />
              )}

              <div className="rounded-lg border border-contrast-300 shadow-sm overflow-hidden">
                <div className="px-6 py-4 border-b border-contrast-300 bg-contrast-50">
                  <h4 className="text-sm xs:text-base font-bold text-contrast-900 flex-1">
                    {t("bookingResult.importantInfo")}
                  </h4>
                </div>
                <div className="py-4 px-6 flex flex-col gap-6">
                  <div className="">
                    <h4 className="font-bold text-contrast-900 mb-2 text-sm">
                      {t("bookingResult.checkList")}
                    </h4>
                    <ul className="list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1">
                      <li>{t("bookingResult.checkListPoints.point1")}</li>
                      <li>{t("bookingResult.checkListPoints.point2")}</li>
                      <li>{t("bookingResult.checkListPoints.point3")}</li>
                    </ul>
                  </div>

                  <div className="">
                    <h4 className="font-bold text-contrast-900 mb-2 text-sm">
                      {t("bookingResult.checkinAndBoarding")}
                    </h4>
                    <ul className="list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1">
                      <li>{t("bookingResult.boardingPoints.point1")}</li>
                      <li>{t("bookingResult.boardingPoints.point2")}</li>
                    </ul>
                  </div>

                  <div className="">
                    <h4 className="font-bold text-contrast-900 mb-2 text-sm">
                      {t("bookingResult.kindAttention")}
                    </h4>
                    <ul className="list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1">
                      <li>{t("bookingResult.attentionPoints.point1")}</li>
                      <li>{t("bookingResult.attentionPoints.point2")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 xl:col-span-4">
              <div className="border border-contrast-300 rounded-lg mb-6 bg-white print:hidden">
                <ul className="flex flex-col text-sm divide-y">
                  <li className="flex items-center gap-2 px-4 py-3">
                    <h4 className="font-bold text-sm xs:text-base text-contrast-900">
                      {t("bookingResult.manageTrip")}
                    </h4>
                  </li>
                  {cancellationStatus !== SUCCESSFUL && (
                    <div className="py-2">
                      <Spinner
                        name={FETCH_USER_BOOKING_INFO}
                        setIsSpinnerActive={setIsSpinnerActive}
                      >
                        {(collectiveBookingStatus === FAILURE ? cancelBookingActions : bookingActions)
                          .map(({ id, icon, label, handleClick }, index) => (
                            <li
                              key={id}
                              className={classnames("flex items-center gap-2 px-4 py-1", { "border-0 border-t border-solid border-contrast-300": index })}
                            >
                              <button
                                className="flex gap-2 items-center text-sm font-medium text-primary-600"
                                onClick={handleClick}
                              >
                                <div className="icon">
                                  <RenderSVG Svg={icon} height="20" />
                                </div>
                                <span>{t(label)}</span>
                              </button>
                            </li>
                          ))}
                      </Spinner>
                    </div>
                  )}

                  <li className="flex items-center gap-2 px-4 py-3 bg-primary-100/50 border-0 border-t border-contrast-300 border-solid">
                    <button
                      className="py-3 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium w-full disabled:cursor-not-allowed"
                      disabled={isSpinnerActive}
                      onClick={() => navigate(USER_BOOKINGS)}
                    >
                      {t("bookingResult.gotoMyTrips")}
                    </button>
                  </li>
                </ul>
              </div>

              {collectiveBookingStatus !== FAILURE && (
                <div className="border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6 print:hidden">
                  <div className="p-6">
                    <h4 className="font-bold text-contrast-900 text-sm xs:text-base mb-5">
                      {t("bookingResult.tickets")}
                    </h4>
                    <ul className="flex flex-col gap-3">
                      <li className="">
                        <button
                          className="flex gap-2 items-center text-sm font-medium text-primary-600"
                          onClick={() => window.print()}
                          disabled={collectiveBookingStatus === FAILURE}
                        >
                          <div className="icon">
                            <RenderSVG Svg={TicketOutline} width="20" />
                          </div>
                          <span>{t("bookingResult.downloadTicket")}</span>
                        </button>
                      </li>
                      {/* <li className="">
                      <button
                        className="flex gap-2 items-center text-sm font-medium text-primary-600 cursor-pointer"
                        data-tooltip-id="comingSoon-tooltip"
                        data-tooltip-content={t("bookingResult.comingSoon")}
                        disabled
                      >
                        <div className="icon">
                          <RenderSVG
                            Svg={InvoiceIcon}
                            width="20"
                            className="text-white"
                          />
                        </div>
                        <span>{t("bookingResult.emailTicket")}</span>
                      </button>
                    </li> */}
                    </ul>
                  </div>
                </div>
              )}
              <AirlineContactInfo itineraries={itineraries} />
              <div className="border border-contrast-300 rounded-lg divide-y divide-contrast-300 mb-6">
                <div className="p-6">
                  <h4 className="font-bold text-contrast-900 text-sm xs:text-base mb-5">
                    {t("bookingResult.contactUs")}
                  </h4>
                  <div className="flex items-center gap-3 ">
                    <div className="logo">
                      <img
                        src={logo}
                        width="30"
                        className="rounded border border-contrast-300"
                        alt="tavatrip logo"
                      />
                    </div>
                    <div className="flex-1">
                      <p className="text-xs text-contrast-600 mb-1">
                        {t("bookingResult.customerSupport")}
                      </p>
                      <h5 className="text-xs xs:text-sm font-semibold text-primary-600 underline">
                        <a target="_blank" href="mailto:support@tavatrip.com">
                          support@tavatrip.com
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingResult;
