import MockAdapter from "axios-mock-adapter";
import flightPricingResponse from "./flightPricingResponse.json";
import flightBookingResponse from "./flightBookingResponse.json";
import seatMap from "../mockData/seatmapData.json";
import flightLocations from "./locations.json";
import apiLogResponse from "./apiLogResponse.json";
import Users from "./users.json";
import calendarDatePrices from "./calenderDatePrices.json";
import adminBookings from "./adminBookings.json";
import userBookings from "./userBookings.json";
import hotelResultsResponse from "./hotelResultsResponse.json";
import {
  stripeResponse,
  razorpayResponse,
  cardResponse,
} from "./flightPaymentResponse";
import hotelPriceResponse from "./hotelPriceResponse.json";
import hotelLocations from "./hotelLocations.json";
import fareCalendarResponse from "./fareCalendarResponse.json";
import amadeusSearchResponse from "./amadeusSearchResponse.json";
import tboSearchResponse from "./tboSearchResponse.json";
import allPromoCodesResponse from "./allPromoCodeResponse.json";
import bookCallbackResponse from "./bookCallbackResponse.json";
import specialServicesResponse from "./specialServicesResponse.json";
import pricePolicyResponse from "./pricePolicyResponse.json";
import {
  REGEX,
  SUCCESS,
  DEFAULT_VALUES,
  HTTPS_STATUS_CODES,
  MOCK_API_ENDPOINTS,
  PAYMENT_PROVIDERS,
} from "../constants";

const {
  FLIGHT_SEARCH,
  LOCATIONS,
  BOOKINGS,
  LOGS,
  HOTEL_SEARCH,
  FARE_CALENDAR,
} = REGEX;
const { EMPTY_STRING, ZERO, TWELVE, ONE } = DEFAULT_VALUES;
const { BAD_REQUEST } = HTTPS_STATUS_CODES;
const { STRIPE, RAZORPAY } = PAYMENT_PROVIDERS;
const {
  LOGIN,
  PRICE,
  PAYMENT_METHOD,
  SIGNUP,
  FLIGHT_DATES,
  FLIGHT_PAYMENT,
  SEATMAP,
  USER_BOOKINGS,
  BOOKING_INFO_ROUTE,
  HOTEL_PRICE,
  AMADEUS_SEARCH,
  TBO_SEARCH,
  PROMO_CODE,
  AMADEUS_BOOKING,
  TBO_BOOKING,
  BOOK_CALLBACK,
  SPECIAL_SERVICES,
  VERIFY_PRICE_POLICY,
} = MOCK_API_ENDPOINTS;
const { REACT_APP_HOTEL_SEARCH_URL } = process.env;

const SEARCH = "search";
const LIMIT = "locations?limit";
const PAGE = "page";
const BOOKING_LIMIT = "limit";
const STATUS = "status";
const KEY = "key";

const getFilteredLocations = (isHotelAPI, searchTerm) => {
  return (isHotelAPI ? hotelLocations : flightLocations).output.filter(
    (location) => {
      const combinedData = Object.values(location).join(" ");
      return combinedData.toLowerCase().includes(searchTerm.toLowerCase());
    }
  );
};

export const createMockAdapter = (ApiClient) => {
  const mock = new MockAdapter(ApiClient);

  mock.onPost(FARE_CALENDAR).reply(SUCCESS, fareCalendarResponse);
  mock.onPost(AMADEUS_SEARCH).reply(SUCCESS, amadeusSearchResponse);
  mock.onPost(TBO_SEARCH).reply(SUCCESS, tboSearchResponse);

  mock.onPost(FLIGHT_SEARCH).reply((config) => {
    if (config.url.includes(HOTELS)) return [SUCCESS, hotelResultsResponse];
  });

  mock.onPost(PRICE).reply(SUCCESS, flightPricingResponse);

  mock.onPost(SEATMAP).reply(SUCCESS, seatMap);
  mock.onGet(LOCATIONS).reply((config) => {
    const urlParams = new URLSearchParams(config.url.split("?")[ONE]);
    const searchTerm = config.url.includes(HOTELS)
      ? urlParams.get(KEY)
      : urlParams.get(SEARCH);
    const filteredLocations = getFilteredLocations(
      config.url.includes(HOTELS),
      searchTerm
    );
    if (config.url.includes(HOTELS))
      return [SUCCESS, { output: filteredLocations }];
    else {
      const limit = parseInt(urlParams.get(LIMIT)) || TWELVE;
      const limitedLocations = filteredLocations.slice(ZERO, limit);
      return [SUCCESS, { output: limitedLocations }];
    }
  });
  mock.onGet(LOGS).reply(SUCCESS, apiLogResponse);
  mock.onPost(SIGNUP).reply(() => {
    return [SUCCESS, { created: "User signed up successfully" }];
  });

  mock.onPost(LOGIN).reply((config) => {
    const mockErrorResponse = {
      errorMessage: {
        errCode: 400,
        errMessage: "Invalid credentials",
      },
    };
    const mockSuccessResponse = {
      output: {
        response: {
          tokens: {
            accessToken: "MOCK ACCESS TOKEN",
            refreshToken: "MOCK REFRESH TOKEN",
          },
          userInfo: {
            email: "test@gmail.com",
          },
        },
      },
    };
    const { email, password } = JSON.parse(config.data);
    const user = Users.find((item) => item.email === email);
    if (!user) return [BAD_REQUEST, mockErrorResponse];
    if (password !== user.password) return [BAD_REQUEST, mockErrorResponse];
    return [SUCCESS, mockSuccessResponse];
  });

  mock.onGet(FLIGHT_DATES).reply((config) => {
    let { url } = config;
    url = url.substring(url.indexOf("?") + ONE);
    const urlParams = new URLSearchParams(url);
    const origin = urlParams.get("origin") || EMPTY_STRING;
    const destination = urlParams.get("destination") || EMPTY_STRING;
    const filteredData = calendarDatePrices.output.filter(
      (flight) => flight.origin === origin && flight.destination === destination
    );

    return [SUCCESS, filteredData];
  });

  mock.onDelete(BOOKING_INFO_ROUTE).reply(() => [SUCCESS, {}]);

  mock.onGet(USER_BOOKINGS).reply((config) => {
    const urlParams = new URLSearchParams(config.url.split("?")[ONE]);
    const limit = +urlParams.get(BOOKING_LIMIT) || 6;
    const page = +urlParams.get(PAGE) || ZERO;
    const status = urlParams.get(STATUS)?.toUpperCase();
    const filteredResults = status
      ? userBookings.output.filter((item) => item.status === status)
      : userBookings.output;
    const count = filteredResults.length;
    const total = userBookings.output.length;
    const results = filteredResults.slice(limit * page, limit * (page + ONE));
    const response = {
      page,
      limit,
      count,
      total,
      bookings: results,
    };
    return [SUCCESS, { output: response }];
  });

  mock.onGet(BOOKINGS).reply((config) => {
    const urlParams = new URLSearchParams(config.url.split("?")[1]);
    const limit = +urlParams.get(BOOKING_LIMIT) || 6;
    const page = ZERO;
    const status = urlParams.get(STATUS)?.toUpperCase();
    const filteredResults = status
      ? adminBookings.result.filter((item) => item.status === status)
      : adminBookings.result;
    const totalResults = filteredResults.length;
    const result = filteredResults.slice(limit * page, limit * (page + ONE));
    const response = {
      pageSize: page,
      limit,
      total: totalResults,
      result,
    };
    return [SUCCESS, response];
  });

  mock.onPost(PAYMENT_METHOD).reply(() => {
    return [SUCCESS, flightBookingResponse];
  });

  mock.onPost(FLIGHT_PAYMENT).reply((config) => {
    const { selectedPaymentPortal } = JSON.parse(config.data);
    switch (selectedPaymentPortal) {
      case STRIPE.toUpperCase():
        return [SUCCESS, stripeResponse];
      case RAZORPAY.toUpperCase():
        return [SUCCESS, razorpayResponse];
      default:
        return [SUCCESS, cardResponse];
    }
  });

  mock.onPost(HOTEL_PRICE).reply(SUCCESS, hotelPriceResponse);
  mock.onPost(HOTEL_SEARCH).reply(SUCCESS, hotelResultsResponse);
  mock.onGet(PROMO_CODE).reply(SUCCESS, allPromoCodesResponse);
  mock.onPost(TBO_BOOKING).reply(SUCCESS, flightBookingResponse);
  mock.onPost(AMADEUS_BOOKING).reply(SUCCESS, flightBookingResponse);
  mock.onGet(BOOK_CALLBACK).reply(SUCCESS, bookCallbackResponse);
  mock.onPost(SPECIAL_SERVICES).reply(SUCCESS, specialServicesResponse);
  mock.onPost(VERIFY_PRICE_POLICY).reply(SUCCESS, pricePolicyResponse);
  mock.onPost(REACT_APP_HOTEL_SEARCH_URL).reply(SUCCESS, hotelLocations);
};
