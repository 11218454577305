import reducers from "./admin.reducer";

export const selectSlice = (state) => state[reducers.name];

export const selectBookings = (state) => selectSlice(state).bookings;

export const selectHotelBookings = (state) => selectSlice(state).hotelBookings;

export const selectlogsInformation = (state) =>
  selectSlice(state).logsInformation;

export const selectBookingInfo = (state) => selectSlice(state).bookingInfo;
