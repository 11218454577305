import { DEFAULT_VALUES } from "../../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;

const ErrorMessage = ({ errorMessage, className = EMPTY_STRING }) => (
  <div className={`text-xs mx-1 text-red-500 mt-1 ${className}`}>
    {errorMessage}
  </div>
);

export default ErrorMessage;
