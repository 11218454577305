import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal, { setSelectedModal } from "../Modal";

const LastNameAmendmentModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => dispatch(setSelectedModal(null));

  return (
    <Modal handleClose={handleClose}>
      <div className="modal flex justify-center flex-wrap flex-col bg-white text-contrast-900 rounded-lg p-6">
        <div className="text-2xl font-bold mt-2">
          <p>{t("travelerInfo.lastNamePopUp.msgHeading")}</p>
        </div>
        <div className="mt-6">
          <p>{t("travelerInfo.lastNamePopUp.confirmationMsg")}</p>
        </div>
        <div className="self-end mt-6 mb-2">
          <button
            type="button"
            aria-label={t("travelerInfo.lastNamePopUp.ok")}
            className="py-[10px] px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium "
            onClick={handleClose}
          >
            {t("travelerInfo.lastNamePopUp.ok")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LastNameAmendmentModal;
