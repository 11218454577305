import SearchSection from "./SearchSection";
import * as flightActions from "./search.actions";
import * as slice from "./search.reducer";
import * as selectors from "./search.selectors";

export const {
  fetchAmadeusFlights,
  fetchTBOFlights,
  getIATALocations,
  getCalendarDatePrices,
  getHotelLocations,
  getHotels,
  getHotelsStaticData,
  getPlacesFromGoogle,
} = flightActions;

export const {
  name,
  reducer,
  actions: {
    setSearchFilters,
    setFlights,
    setHotels,
    setHotelsStaticData,
    setSearchHotelFilters,
    setFilteredFlights,
    setActiveFilters,
    setFlightSearchOptions,
    resetFlights,
    setTotalFlightCount,
    setSortedFlights,
    setActiveSortOrder,
    setFlightsCount,
    setIsNonStopSelected,
    setSelectedReIssuanceFlight,
    setSearch,
    setSelectedPrediction,
    setCurrentSearchLevel,
    setSearchFilterURL,
    resetHotelSearchResult,
    setFilteredHotels,
    setHotelsActiveFilters,
    setHotelsSortingValues,
    setHotelsCount,
    setIsHotelsLoaded,
    setIsStaticDataLoaded,
    setHotelCurrency
  },
} = slice;

export const {
  selectFlights,
  selectFlightsCount,
  selectFilteredFlights,
  selectFlightTokens,
  selectSearchFilters,
  selectSelectedReIssuanceFlight,
  selectToCityCodes,
  selectFromCityCodes,
  selectCalendarDatePrices,
  selectActiveFilters,
  selectFlightSearchOptions,
  selectIsNonStopSelected,
  selectSortedFlights,
  selectActiveSortOrder,
  // TODO: Move below states to separate Hotels redux state
  selectHotels,
  selectHotelsStaticData,
  selectHotelSearchFilters,
  selectHotelLocations,
  selectPredictions,
  selectSelectedPrediction,
  selectCurrentSearchLevel,
  selectFilteredHotels,
  selectHotelsActiveFilters,
  selectHotelsSortingValues,
  selectHotelsCount,
  selectIsHotelsLoaded,
  selectIsStaticDataLoaded,
  selectHotelCurrency
} = selectors;

export default SearchSection;
