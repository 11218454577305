import { jwtDecode } from "jwt-decode";

//Time to refresh token before expiry
const TOKEN_REFRESH_TIME = 300000;

export const checkTokenExpiry = (token) => {
  if (!token) return;
  const decodedToken = jwtDecode(token);
  if (!decodedToken?.exp) return;
  const expiryTime = decodedToken.exp * 1000;
  const currentTime = Date.now();
  const isTokenToBeExpired = expiryTime - currentTime < TOKEN_REFRESH_TIME;
  return isTokenToBeExpired;
};
