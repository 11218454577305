import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { SKELETON_COLOR } from "../../../constants";

const { BASE_COLOR, HIGHTLIGHT__COLOR} = SKELETON_COLOR;

const HotelCancellationSkeleton = () => {
  return (
    <SkeletonTheme baseColor={BASE_COLOR} highlightColor={HIGHTLIGHT__COLOR}>
      <div className="relative w-full px-2 z-0">
        <Skeleton width={"100%"} height={120} borderRadius={0} />
      </div>
      <div className=" w-full pt-4 px-2">
        <Skeleton width={"100%"} height={70} />
      </div>
      <div className="py-4 px-5">
      <Skeleton width={"100%"}  height={360} />
      </div>
    </SkeletonTheme>
  );
};

export default HotelCancellationSkeleton;
