import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { Carousel } from "@material-tailwind/react";
import { v4 as uuid } from "uuid";
import { selectHotelInfo } from "../../../../screens/HotelInfo";
import Modal, { setSelectedModal } from "../../Modal";
import { DEFAULT_VALUES, MODAL_SIZE } from "../../../../constants";
const { LG } = MODAL_SIZE;

const { EMPTY_STRING, EMPTY_ARRAY, ZERO, ONE } = DEFAULT_VALUES;
const HotelFacilitiesModal = () => {
  const dispatch = useDispatch();
  const hotelInfo = useSelector(selectHotelInfo);

  const hotelDetails = get(
    hotelInfo,
    "HotelRoomInfo.HotelInfoResult.HotelDetails",
    EMPTY_ARRAY
  );
  const {
    HotelName = EMPTY_STRING,
    Images = EMPTY_ARRAY,
    HotelFacilities = EMPTY_ARRAY,
  } = hotelDetails;
  const handleClose = () => dispatch(setSelectedModal(null));
  return (
    <div className="overflow-auto">
      <Modal
        title={`Amenities and Images of ${HotelName}`}
        size={LG}
        shouldShowModalFromProps
        handleClose={handleClose}
      >
        <div className="px-10 pb-10 font-[inter] overflow-auto font-medium text-xs sm:text-sm text-contrast-900 flex flex-col md:flex-row">
          <div className="w-full md:w-2/3 pr-5">
            <div className="bg-contrast-200 relative rounded-lg h-60 sm:max-h-60 md:max-h-96">
              {!isEmpty(Images) && (
                <Carousel className="rounded-xl">
                  {Images.map((imageUrl, index) => (
                    <img
                      key={uuid()}
                      src={imageUrl}
                      alt={`Slide ${index + 1}`}
                      className="h-full w-full object-cover"
                    />
                  ))}
                </Carousel>
              )}
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="text-lg font-semibold">Hotel Amenities</div>
            <ul className="grid grid-cols-2 sm:grid-cols-2 gap-2 text-gray-500">
              {HotelFacilities.map((facility) => (
                <li key={uuid()} className="flex items-center">
                  <div className="w-1 h-1 rounded-full bg-gray-500 shrink-0 mr-3"></div>
                  <span className="flex text-xs font-medium">{facility}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HotelFacilitiesModal;
