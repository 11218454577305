import DatePicker from "react-multi-date-picker";
import get from "lodash/get";
import { Calendar, RenderSVG } from "../../assets/icons";
import { DATE_FORMAT, DEFAULT_VALUES } from "../../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;

const DateSearch = ({
  name,
  values,
  setFieldValue,
  setFieldTouched = () => {},
  minDate,
  maxDate,
  height = "38px",
  handleChange = () => {},
  position = "bottom-left",
  setDateErrorMessage = () => {},
}) => {
  const selectedDate = get(values, `${name}`);

  const handleOnChange = (value) => {
    setFieldTouched(name, true);
    handleChange();
    const selectedDate = value.format(DATE_FORMAT);
    setFieldValue(name, selectedDate);
    setDateErrorMessage(EMPTY_STRING);
  };

  return (
    <div className="relative flex items-center bg-white">
      <RenderSVG Svg={Calendar} className="absolute ml-1" alt="Calendar" />
      <DatePicker
        shadow={false}
        format={DATE_FORMAT}
        highlightToday={false}
        value={selectedDate}
        editable={false}
        onChange={handleOnChange}
        minDate={minDate}
        maxDate={maxDate}
        style={{
          width: "100%",
          boxSizing: "border-box",
          height,
          zIndex: "1",
        }}
        portal={true}
        zIndex={1000}
        inputClass="rounded-md border border-gray-300 !pl-8"
        containerClassName="calendar"
        calendarPosition={position}
      />
    </div>
  );
};
export default DateSearch;
