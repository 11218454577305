import { useCollapse } from "react-collapsed";
import get from "lodash/get";
import classNames from "classnames";
import FlightJourneyDetails from "../../../FlightResults/FlightJourneyDetails";
import { ChevronDown, RenderSVG } from "../../../../assets/icons";
import {
  getAirlineIconUrlByCarrierCode,
  getFormattedDuration,
  getFormattedLongDate,
  getLayoverDuration,
} from "../../../../helper";
import { DEFAULT_VALUES } from "../../../../constants";

const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;

const FlightDetailsCards = ({ itinerary }) => {
  const { getToggleProps, getCollapseProps, isExpanded } = useCollapse({
    duration: 500,
  });
  const { segments, duration } = itinerary;

  const startSegment = segments[ZERO];
  const lastSegment = segments.at(-ONE);
  const noOfStops = segments.length - ONE;

  return (
    <div>
      <p className="text-gray-800 font-semibold mb-3 text-sm">
        Departing
        <p className="text-indigo-600">
          {getFormattedLongDate(startSegment.departure.date, {
            day: "numeric",
            month: "long",
            year: "numeric",
            weekday: "long",
          })}
        </p>
      </p>
      <div className="border border-indigo-500 border-dashed bg-indigo-50/40 rounded-lg">
        <div className="p-4">
          <div className="grid grid-cols-12 gaFp-4 mb-4">
            <div className="col-span-12 lg:col-span-4 2xl:col-span-5">
              <div className="flex items-center gap-3">
                <div className="logo">
                  <img
                    src={getAirlineIconUrlByCarrierCode(
                      startSegment.carrierCode
                    )}
                    width="30"
                    className="rounded border border-gray-300"
                    alt={`${startSegment.carrierCode}_logo`}
                  />
                </div>
                <div className="flex-1">
                  <h5 className="text-sm font-semibold text-gray-900 mb-1">
                    {startSegment.departure.time} -&nbsp;
                    {lastSegment.arrival.time}
                  </h5>
                  <p className="text-xs text-gray-600">
                    {startSegment.carrierName}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-8 2xl:col-span-7 grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-5 lg:col-span-7 flex gap-4">
                <div className="flex-1">
                  <h5 className="text-sm text-gray-900 font-semibold mb-1">
                    {getFormattedDuration(duration)}
                  </h5>
                  <p className="text-xs text-gray-600">
                    {get(startSegment, "departure.iataCode", EMPTY_STRING)}-
                    {get(lastSegment, "arrival.iataCode", EMPTY_STRING)}
                  </p>
                </div>
                <div className="flex-1 text-end sm:text-start">
                  <h5 className="text-sm text-gray-900 font-semibold mb-1">
                    {noOfStops || "Non"} Stop
                  </h5>
                  <div className="text-xs text-gray-600">
                    {getLayoverDuration(segments).map((duration, index) => (
                      <div key={segments[index].arrival.iataCode}>
                        {duration} {segments[index].arrival.iataCode}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-7 lg:col-span-5 flex items-center justify-between sm:justify-end gap-1">
                <div {...getToggleProps()}>
                  <RenderSVG
                    Svg={ChevronDown}
                    width="18"
                    height="18"
                    alt="ChevronDown"
                    color="black"
                    className={classNames({
                      "rotate-180": isExpanded,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div {...getCollapseProps()}>
          <div className="py-4 px-4 border-t border-indigo-500 border-dashed">
            <FlightJourneyDetails segments={segments} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightDetailsCards;
