import get from "lodash/get";
import { DEFAULT_VALUES } from "../../constants";
import { getFormattedAmadeusReponse } from "./getFormattedAmadeusResponse";
import { getFormattedTBOResponse } from "./getFormattedTBOResponse";

const { EMPTY_ARRAY } = DEFAULT_VALUES;

const AMADEUS = "AMADEUS";
const TBO = "TBO";

export const getFormattedFarePricesPerDates = (payload) => {
  const output = get(payload, "output", EMPTY_ARRAY);
  let response = [];
  output.map((item) => {
    switch (item.source) {
      case AMADEUS:
        response.push(getFormattedAmadeusReponse(item));
        break;
      case TBO:
        response.push(getFormattedTBOResponse(item));
        break;
    }
  });
  return response.flat().reduce((acc, obj) => {
    const { date, price } = obj;
    acc[date] = Math.min(+price, +acc[date]) || price;
    return acc;
  }, {});
};
