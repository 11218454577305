import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { SKELETON_COLOR } from "../../../constants";

const { BASE_COLOR, HIGHTLIGHT__COLOR} = SKELETON_COLOR;

const DocumentRequiredSkeleton = () => (
  <SkeletonTheme baseColor={BASE_COLOR} highlightColor={HIGHTLIGHT__COLOR}>
    
      <div className="w-full">
        <Skeleton height={80} />
      </div>
    
  </SkeletonTheme>
);

export default DocumentRequiredSkeleton;
