import { getFormattedAmadeusResponse } from "./getFormattedAmadeusResponse";
import { getFormattedTBOResponse } from "./getFormattedTBOResponse";
import { DEFAULT_VALUES } from "../../constants";
import { getformattedResponseForBoth } from "./getFormattedResponseforBoth";

const AMADEUS = "AMADEUS";
const TBO = "TBO";
const DEFAULT = "Default";
const { ZERO, EMPTY_ARRAY, ONE, TWO } = DEFAULT_VALUES;

const getSource = (priceResponse) => {
  if (!priceResponse) return DEFAULT;
  if (priceResponse.tboResponse) return TBO;
  else return AMADEUS;
};

const areObjectsOfSameSource = (priceResponses) => {
  if (priceResponses.length >= TWO) {
    const source1 = getSource(priceResponses[ZERO]);
    const source2 = getSource(priceResponses[ONE]);
    return source1 === source2;
  }
  return false;
};
export const getFormattedFlightPriceResponse = (flightPriceResponse) => {

  if (areObjectsOfSameSource(flightPriceResponse)) {
    switch (getSource(flightPriceResponse?.[ZERO])) {
      case AMADEUS:
        return getFormattedAmadeusResponse(flightPriceResponse);

      case TBO:
        return getFormattedTBOResponse(flightPriceResponse);

      case DEFAULT:
        return EMPTY_ARRAY;
    }
  } else return getformattedResponseForBoth(flightPriceResponse);
};
