import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../screens/Profile";
import { ROUTES, AUTH_ROLES, DEFAULT_VALUES } from "../../constants";

const { HOME } = ROUTES;
const { USER } = AUTH_ROLES;
const { ONE } = DEFAULT_VALUES;

const AuthenticateRoute = ({ element, roles = [USER] }) => {
  const selectedUserInfo = useSelector(selectUserInfo);
  const loggedInUserRole = selectedUserInfo?.role;
  const isValidRole =
    roles.findIndex((role) => role === loggedInUserRole) !== -ONE;

  return isValidRole ? element : <Navigate to={HOME} />;
};

export default AuthenticateRoute;
