import { useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { Cross, RenderSVG } from "../../../../assets/icons";
import DateSearch from "../../../atoms/DateSearch";
import { deleteBookingById } from "../../../../screens/MyTrips";
import Modal from "../../Modal";
import Spinner, { SPINNER_NAMES } from "../../Spinner";

const RESCHEDULE = "Reschedule";
const CANCEL = "Cancel";
const PROFILE = "Profile";
const { DELETE_TRAVELER, UPDATE_TRAVELER } = SPINNER_NAMES;

const ConfirmationModal = ({
  id,
  data,
  setSuccessResponse,
  setShowSuccesModal,
  setIsModalOpen,
  departureTime,
  isModalOpen,
  removeTraveler,
  source,
  travelerId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { type, img, header, description, button } = data;
  const initialValues = {
    depatrureDate: new Date(departureTime),
  };

  const handleAction = (type) => {
    if (type === CANCEL) {
      if (source === PROFILE) removeTraveler(travelerId);
      else {
        dispatch(deleteBookingById(id)).then((res) => {
          if (!res.payload) return;
          setSuccessResponse({
            message: "userBookings.message",
          });
          setShowSuccesModal(true);
          setIsModalOpen(false);
        });
      }
    } else setIsModalOpen(false);
  };

  return (
    <Modal shouldShowModalFromProps={isModalOpen}>
      <div className="bg-white px-4 pb-2 pt-2 sm:p-6 sm:pb-4">
        <div className="w-full p-3 border-b border-contrast-200 justify-start items-center gap-4 inline-flex">
          <div className="w-10 h-10 p-2 bg-red-100 rounded-3xl justify-center items-center flex">
            <RenderSVG
              Svg={img}
              alt="image"
              className="w-6 h-6 relative flex-col justify-start items-start flex"
            />
          </div>
          <div className="grow shrink basis-0 text-contrast-900 text-2xl font-bold font-['Inter'] leading-loose">
            {t(header)}
          </div>
          <button
            type="button"
            className="p-[9px] rounded-[19px] justify-center items-center flex hover:shadow hover:bg-zinc-300"
            onClick={() => setIsModalOpen(false)}
          >
            <RenderSVG Svg={Cross} alt="cross" className="relative" />
          </button>
        </div>
        <div
          className={classNames(
            "p-4 flex-col justify-start items-start gap-4 inline-flex",
            {
              "h-[450px]": type === RESCHEDULE,
            }
          )}
        >
          <div className="self-stretch text-contrast-500 text-sm font-normal font-['Inter'] leading-tight">
            {t(description)}
          </div>
          {type === RESCHEDULE && (
            <Formik
              initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize
            >
              {({ values, handleChange, setFieldValue }) => (
                <Form>
                  <div className="flex items-start gap-4">
                    <DateSearch
                      name="depatrureDate"
                      values={values.depatrureDate}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      minDate={new Date(departureTime)}
                      required
                      showCalendarIcon={true}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
        <div className="w-full p-3 border-t border-contrast-200 justify-start items-center gap-2 inline-flex">
          <div className="grow shrink basis-0 h-[38px] justify-end items-start gap-4 flex">
            <button
              type="button"
              className="px-[17px] py-[9px] bg-white rounded-md shadow border border-contrast-300 justify-center items-center flex hover:bg-zinc-300"
              onClick={() => setIsModalOpen(false)}
            >
              <div className="text-contrast-700 text-sm font-medium font-['Inter'] leading-tight">
                {t("userBookings.close")}
              </div>
            </button>
            <button
              type="button"
              className={classNames(
                "px-[17px] py-[9px] rounded-md shadow justify-center items-center flex",
                {
                  "bg-primary-600 hover:bg-primary-700": type === RESCHEDULE,
                  "bg-red-600 hover:bg-red-700": type === CANCEL,
                }
              )}
              onClick={() => handleAction(type)}
              disabled={isSubmitting}
            >
              <Spinner
                name={[DELETE_TRAVELER, UPDATE_TRAVELER]}
                setIsSpinnerActive={setIsSubmitting}
              >
                <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
                  {t(button)}
                </div>
              </Spinner>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
