import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { get, isEmpty } from "lodash";
import {
  getAirlineIconUrlByCarrierCode,
  priceFormatter,
  getCityNameByIata,
  getFormattedPriceRequest,
} from "../../helper";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { selectCountryInfo } from "../Profile";
import {
  FLIGHTS_RESULT_TYPE,
  DEFAULT_VALUES,
  CURRENCY_SYMBOLS,
} from "../../constants";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { selectFlightTokens } from "../../components/organisms/Search";
import logEvent from "../../utils/GATracker";

const { OUTBOUND_RESULT, INBOUND_RESULT, PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const { ZERO, TWO, EMPTY_STRING } = DEFAULT_VALUES;
const { PRICE } = SPINNER_NAMES;
const { INR } = CURRENCY_SYMBOLS;

const DEPARTURE = "flightResults.roundTripCard.departure";
const RETURN = "flightResults.roundTripCard.return";

const getTotalPrice = (flightOptions, isPackagedFlightSelection) => {
  if (isPackagedFlightSelection)
    return get(flightOptions, `${PACKAGE_RESULT}.price.totalPrice`, ZERO);
  else {
    const inboundPrice = get(
      flightOptions,
      `${INBOUND_RESULT}.price.totalPrice`,
      null
    );
    const outboundPrice = get(
      flightOptions,
      `${OUTBOUND_RESULT}.price.totalPrice`,
      null
    );

    return inboundPrice !== null && outboundPrice !== null
      ? inboundPrice + outboundPrice
      : null;
  }
};

const shouldShowBookNowButton = (flightOptions, isPackagedFlightSelection) => {
  if (isPackagedFlightSelection) return true;
  else
    return (
      get(flightOptions, `${INBOUND_RESULT}.flightId`, EMPTY_STRING) &&
      get(flightOptions, `${OUTBOUND_RESULT}.flightId`, EMPTY_STRING)
    );
};

const JourneySummary = ({
  flightType,
  currency,
  flightOption,
  isPackagedFlightSelection,
}) => {
  const { t } = useTranslation();
  const price = get(flightOption, "price", {});

  const itinerariesInfo = flightOption?.itineraries.map((itinerary, index) => {
    const departure = get(itinerary, "segments.0.departure", {});
    const finalSegment = get(itinerary, "segments", []).at(-1);
    const finalArrival = get(finalSegment, "arrival", {});
    const carrierCode = get(itinerary, "segments.0.carrierCode", EMPTY_STRING);

    return { id: `itinerary_${index}`, departure, finalArrival, carrierCode };
  });

  return (
    <div className="flex justify-between px-2 sm:px-0">
      {itinerariesInfo?.map(({ id, departure, finalArrival, carrierCode }) => (
        <div className="flex justify-between w-full" key={id}>
          <div className="col-span-12 sm:col-span-6 md:col-span-4 flex items-center gap-2 justify-between">
            <div className="thumb flex-shrink-0">
              <img
                src={getAirlineIconUrlByCarrierCode(carrierCode)}
                className="rounded w-8 sm:w-18"
                alt="carrierCode"
              />
            </div>
            <div className="grid grid-cols-1 gap-5">
              <div className="grid grid-cols">
                <span className="text-[10px] sm:text-xs text-white flex mb-1">
                  {!isPackagedFlightSelection && (
                    <>
                      {t(flightType === OUTBOUND_RESULT ? DEPARTURE : RETURN)} •{" "}
                    </>
                  )}
                  {getCityNameByIata(departure.iataCode)}-
                  {getCityNameByIata(finalArrival.iataCode)}
                </span>
                <h6 className="font-semibold text-white text-[10px] sm:text-lg">
                  {departure.time} - {finalArrival.time}
                </h6>
              </div>
            </div>
          </div>
          {!isPackagedFlightSelection && (
            <div className="grid grid-cols items-center justify-center">
              <h6 className="font-semibold text-white text-sm sm:text-lg text-center whitespace-nowrap">
                {currency} {priceFormatter(price.totalPrice)}
              </h6>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const SelectedFlightsCard = ({ selectedFlightOptions }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const selectedCountryInfo = useSelector(selectCountryInfo);
  const flightTokens = useSelector(selectFlightTokens);

  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const isPackagedFlightSelection = Object.keys(selectedFlightOptions).includes(
    PACKAGE_RESULT
  );

  const flightResultTypeArray = isPackagedFlightSelection
    ? [PACKAGE_RESULT]
    : [OUTBOUND_RESULT, INBOUND_RESULT];

  const handleBookNow = () => {
    logEvent(`book_now_btn_clicked`)
    const flightData = isPackagedFlightSelection
      ? get(selectedFlightOptions, PACKAGE_RESULT, {})
      : [
        get(selectedFlightOptions, OUTBOUND_RESULT, {}),
        get(selectedFlightOptions, INBOUND_RESULT, {}),
      ];

    getFormattedPriceRequest(flightData, flightTokens, navigate, dispatch);
  };

  const currency = get(selectedCountryInfo, "currency.symbol", INR);

  return (
    <div className="sticky bottom-14 sm:bottom-2 z-10 w-[98%] max-w-7xl mx-auto bg-primary-900 rounded-2xl border-primary-900 border-2 overflow-hidden">
      <div className="grid grid-cols-12 gap-2 sm:gap-4 lg:gap-10 lg:divide-x divide-dashed xl:gap-16 mb-2 p-2 sm:p-6">
        <div className="col-span-12 lg:col-span-8 grid grid-cols-12 gap-6 lg:gap-10 xl:gap-16">
          {!isEmpty(selectedFlightOptions) &&
            flightResultTypeArray.map((flightType) => (
              <div
                key={flightType}
                className={classNames("col-span-12", {
                  "sm:col-span-6": !isPackagedFlightSelection,
                })}
              >
                {selectedFlightOptions[flightType] ? (
                  <JourneySummary
                    flightType={flightType}
                    currency={currency}
                    flightOption={selectedFlightOptions[flightType]}
                    isPackagedFlightSelection={isPackagedFlightSelection}
                  />
                ) : (
                  <div className="flex justify-center">
                    <h6 className="font-semibold text-white text-xs sm:text-lg">
                      {t("flightResults.pleaseSelect")}{" "}
                      {flightType === OUTBOUND_RESULT ? t("flightResults.roundTripCard.departure") : t("flightResults.roundTripCard.return")}{" "}
                      {t("flightResults.flight")}
                    </h6>
                  </div>
                )}
              </div>
            ))}
        </div>

        <div className="flex col-span-12 lg:col-span-4 items-center gap-6 sm:gap-3 justify-center lg:justify-end">
          <div className="text-sm sm:text-xl font-bold text-white xl:flex sm:items-center">
            {getTotalPrice(
              selectedFlightOptions,
              isPackagedFlightSelection
            ) && (
                <div className="sm:text-center">
                  {currency}
                  {priceFormatter(
                    parseFloat(
                      getTotalPrice(
                        selectedFlightOptions,
                        isPackagedFlightSelection
                      )
                    ).toFixed(TWO)
                  )}
                </div>
              )}
          </div>
          {shouldShowBookNowButton(
            selectedFlightOptions,
            isPackagedFlightSelection
          ) && (
              <div className="flex">
                <button
                  onClick={handleBookNow}
                  className="btn btn-primary w-full"
                  disabled={isSpinnerActive}
                >
                  <Spinner name={PRICE} setIsSpinnerActive={setIsSpinnerActive}>
                    {t("flightResults.bookNow")}
                  </Spinner>
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default SelectedFlightsCard;
