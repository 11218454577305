import { get, isEmpty } from "lodash";
import {
  DEFAULT_VALUES,
  FLIGHT_PROVIDERS,
  FORMATTED_API_ENDPOINTS,
  TBO_ERROR_CODE_TWO,
} from "../constants";

const { ONE, EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;
const { BOOKING, PRICE, SESSION, HOTEL_PRICE, VERIFY_PRICE_POLICY } =
  FORMATTED_API_ENDPOINTS;
const { AMADEUS } = FLIGHT_PROVIDERS;
const SUCCESS_RESPONSE = "SUCCESS_RESPONSE";

export const checkForSuccessResponse = (response, source, url) => {
  const formattedURL =
    url.includes("-") && !url.includes("hotels") ? url.split("-")[ONE] : url;

  switch (formattedURL) {
    case PRICE:
      return source === AMADEUS
        ? get(
            response,
            "output.informativePricing.input.soap:Envelope.soap:Body.Fare_InformativePricingWithoutPNRReply.mainGroup.pricingGroupLevelGroup",
            EMPTY_ARRAY
          )
        : get(response, "tboResponse.Response.Results", EMPTY_OBJECT);
    case BOOKING:
      return get(response, "output.session.id", EMPTY_STRING);
    case SESSION:
      return get(response, "output.session.id", EMPTY_STRING);
      case HOTEL_PRICE:
        return get(
          response,
          "priceResult.hotelRoomsResult.hotelRoomsDetails",
          EMPTY_OBJECT
        );
    case VERIFY_PRICE_POLICY:
      return get(
        response,
        "blockRoomResult",
        EMPTY_OBJECT
      );
    default:
      return SUCCESS_RESPONSE;
  }
};
