import { useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import classNames from "classnames";
import { MONTHS, CHART_FORMAT } from "./dashboard.utils";
import { DEFAULT_VALUES } from "../../../constants";
import { ChevronDown, RenderSVG } from "../../../assets/icons";
import useClickOutside from "../../../helper/useClickOutside";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltips: {
      position: "nearest",
      mode: "index",
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      caretSize: 4,
      backgroundColor: "rgba(72, 241, 12, 1)",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 4,
    },
  },
  maintainAspectRatio: false,
};

export const data = {
  labels: MONTHS,
  datasets: [
    {
      label: "Dataset",
      borderColor: "#1967d2",
      backgroundColor: "#1967d2",
      data: [196, 132, 215, 362, 210, 252],
      fill: false,
    },
  ],
};

const { ZERO } = DEFAULT_VALUES;

const AnalyticsChart = () => {
  const [selectedOption, setSelectedOption] = useState(CHART_FORMAT[ZERO]);
  const [selectorBtnClicked, setSelectorBtnClicked] = useState(false);
  const languageSelectorRef = useRef();

  useClickOutside(languageSelectorRef, setSelectorBtnClicked);

  return (
    <div className="mx-2 pb-30">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-medium">Earning Statistics</h2>
        <div className="flex items-center relative cursor-pointer">
          <div className=" flex items-center bg-white border border-contrast-200 rounded-full px-6 py-2  gap-1 text-xs leading-5">
            <span>{selectedOption}</span>
            <RenderSVG
              Svg={ChevronDown}
              width="15"
              height="15"
              alt="Down Arrow"
              className={classNames({
                "rotate-180": selectorBtnClicked,
              })}
            />
            {selectorBtnClicked && (
              <div
                className="dropdown-menu absolute z-30 top-full bg-white p-1 rounded-lg border border-contrast-200 shadow-2xl mt-2 -right-10"
                ref={languageSelectorRef}
              >
                <div className="flex gap-1 flex-col">
                  {CHART_FORMAT.map((option) => (
                    <div key={option}>
                      <button
                        className={classNames(
                          "block w-full text-xs py-2 px-4 text-left font-semibold hover:bg-contrast-100 focus:outline-none focus:bg-contrast-100",
                          {
                            "text-primary-500": selectedOption === option,
                          }
                        )}
                        onClick={() => setSelectedOption(option)}
                      >
                        {option}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="overflow-scroll overflow-x-auto">
        <div className="flex justify-center h-96">
          <Line options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsChart;
