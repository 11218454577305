import { createSlice } from "@reduxjs/toolkit";
import { getHotelInfo, verifyHotelPricePolicy } from "./hotelInfo.actions";
import { getFromSessionStorage, setToSessionStorage } from "../../helper";
import { CACHE_KEYS, DEFAULT_VALUES } from "../../constants";
const { HOTEL_DETAIL } = CACHE_KEYS;
const { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;

const initialState = {
  hotelInfo: {},
  hotelInfoReqBody: {},
  selectedRoomInfo: [],
  pricePolicyReqBody: {},
  pricePolicyInfo: {},
  selectedHotelViewMore: {},
};

const slice = createSlice({
  name: "hotelInfo",
  initialState,
  reducers: {
    setHotelInfo(state, action) {
      state.hotelInfo = action.payload;
    },
    setHotelInfoReqBody(state, action) {
      if (!action.payload) return;
      state.hotelInfoReqBody = action.payload;
    },
    setSelectedRoomInfo(state, action) {
      state.selectedRoomInfo = action.payload;
    },
    setPricePolicyReqBody(state, action) {
      state.pricePolicyReqBody = action.payload;
    },
    setPricePolicyInfo(state, action) {
      state.pricePolicyInfo = action.payload;
    },
    setSelectedHotelViewMore: (state, action) => {
      state.selectedHotelViewMore = action.payload
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getHotelInfo.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.hotelInfo = action.payload;
        const { hotelName = EMPTY_STRING, address = EMPTY_STRING, images = EMPTY_ARRAY } = action.payload.priceResult?.hotelDetails || EMPTY_OBJECT;
        setToSessionStorage(HOTEL_DETAIL, { hotelName, addressLine1: address, images })

      })
      .addCase(verifyHotelPricePolicy.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.pricePolicyInfo = action.payload;
        const { hotelName = EMPTY_STRING, addressLine1 = EMPTY_STRING, hotelPolicyDetail = EMPTY_STRING } = action.payload.blockRoomResult || EMPTY_OBJECT;
        const hotelDetail = getFromSessionStorage(HOTEL_DETAIL)
        setToSessionStorage(HOTEL_DETAIL, { ...hotelDetail, hotelName, addressLine1, hotelPolicyDetail })
      });
  },
});

export default slice;
export const { name, reducer, actions } = slice;
