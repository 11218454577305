import { TRIP_TYPES, ROUNDTRIP_RESULT_FORMATS } from "../constants";

const { ISOLATED, PACKAGES } = ROUNDTRIP_RESULT_FORMATS;
const { ROUND_TRIP, ONE_WAY } = TRIP_TYPES;
const getUpdatedFlightFilters = ({
  filters,
  type,
  boundType,
  roundTripResultFormat,
  key,
  updatedFilters,
  tripType,
}) => {
  if (roundTripResultFormat === PACKAGES && tripType === ROUND_TRIP) {
    if (type) return { ...filters.packages, [type]: updatedFilters };
    else
      return {
        ...filters,
        packages: { ...filters.packages, [key]: updatedFilters },
      };
  }

  if (roundTripResultFormat === ISOLATED && filters?.isolated) {
    if (type)
      return {
        ...filters,
        isolated: {
          ...filters?.isolated,
          [boundType]: {
            ...filters?.isolated[boundType],
            [type]: updatedFilters,
          },
        },
      };
    else
      return {
        ...filters,
        isolated: {
          ...filters.isolated,
          [boundType]: updatedFilters,
        },
      };
  }
  if (type || tripType === ONE_WAY || roundTripResultFormat === PACKAGES)
    return {
      ...filters,
      packages: { ...filters.packages, [type]: updatedFilters },
    };
  return updatedFilters;
};

export default getUpdatedFlightFilters;
