import React, { memo, useRef, useState, useEffect } from "react";
import { isEmpty, get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  getAirlineIconUrlByCarrierCode,
  getLayoverDuration,
  getCityNameByIata,
  calculateTotalDuration,
  getFormattedDuration,
} from "../../helper";
import { Cloud, RenderSVG, Baggage } from "../../assets/icons";
import {
  selectFlightInfo,
  setCurrentFlightType,
  setSelectedFlightInfo,
  setSelectedFlightIndexInfo,
  selectSelectedFlightOptions,
  setSelectedFlightOptions,
} from "./index";
import {
  selectActiveSortOrder,
  selectFilteredFlights,
  selectSearchFilters,
} from "../../components/organisms/Search";
import {
  selectSelectedFlightId,
  selectSelectedTripType,
} from "./flightResults.selectors";
import FlightDetailsTag from "../../components/atoms/FlightDetailsTag";
import { selectCountryInfo } from "../Profile";
import { setSelectedModal } from "../../components/organisms/Modal";
import { getStops } from "../../utils/flights.utils";
import { MODALS } from "../../components/organisms/AppModals";
import {
  DEFAULT_VALUES,
  INDEX,
  TRIP_TYPES,
  CURRENCY_SYMBOLS,
  FLIGHT_SERVICE_TYPE,
  FLIGHTS_RESULT_TYPE,
} from "../../constants";
import logEvent from "../../utils/GATracker";
import PriceAndPaxInfo from "./PriceAndPaxInfo";

const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { ROUND_TRIP } = TRIP_TYPES;
const { LAST } = INDEX;
const { AMADEUS } = FLIGHT_SERVICE_TYPE;
const { FLIGHT_OPTIONS_MODAL, VIEW_FLIGHT_DETAILS_MODAL } = MODALS;
const { PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const maxQuantityOfCabinCO2EmissionInPound = 500;

const OneWayCard = ({ flights, currentFlightType, isReissuanceFlight }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cardRef = useRef(null);
  const [flightIndex, setFlightIndex] = useState(ZERO);
  const [totalPassengersCount, setTotalPassengersCount] = useState(ZERO);

  const selectedFlightInfo = useSelector(selectFlightInfo);
  const tripType = useSelector(selectSelectedTripType);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const filteredFlights = useSelector(selectFilteredFlights);
  const activeSortOrder = useSelector(selectActiveSortOrder);
  const selectedFlightId = useSelector(selectSelectedFlightId);
  const selectedFlightOptions = useSelector(selectSelectedFlightOptions);
  const selectedSearchFilters = useSelector(selectSearchFilters);

  useEffect(() => {
    if (!isEmpty(selectedSearchFilters)) {
        const { passengersCount } = selectedSearchFilters;
        const totalPassengers = Object.values(passengersCount).reduce((total, count) => total + Number(count), ZERO);
        setTotalPassengersCount(totalPassengers);
    }
  }, [selectedSearchFilters])

  useEffect(() => {
    let selectedFlightIndex = ZERO;
    if (selectedFlightOptions[currentFlightType]) {
      const selectedFlightIdx = flights.findIndex(
        (flight) =>
          flight.flightId === selectedFlightOptions[currentFlightType]?.flightId
      );
      if (selectedFlightIdx !== -1) selectedFlightIndex = selectedFlightIdx;
    }

    setFlightIndex(selectedFlightIndex);
  }, [
    activeSortOrder,
    filteredFlights,
    selectedFlightId,
    selectedFlightOptions,
    currentFlightType,
  ]);

  const currencySymbol = get(
    selectedCountryInfo,
    "currency.symbol",
    CURRENCY_SYMBOLS.INR
  );

  const {
    itineraries = [],
    price = {},
    carbonEmissionByCabin,
    isRefundable,
    isPanRequiredAtTicket,
    source,
    flightId,
    miniFareRules,
  } = flights[flightIndex] || {};

  const { totalPrice = 0, publishedPrice = 0 } = price;
  const { segments, duration } = itineraries[ZERO];
  const {
    noOfAvailableSeats = 0,
    flightNumber = "",
    aircraftCode = "",
    carrierCode = "",
    departure = {},
    carrierName = "",
    baggageDetails,
  } = segments[ZERO];
  const finalDestination = segments.at(LAST);
  const { arrival = {} } = finalDestination;
  const { weight } = baggageDetails;
  const totalNoOfStops = segments.length - ONE;
  const layoverDuration = getLayoverDuration(segments);
  const areSimilarFlightsAvailable = flights.length > ONE;

  const carbonEmissionsArray = Array.isArray(carbonEmissionByCabin)
    ? carbonEmissionByCabin
    : [];
  const hasHighCO2Emission = carbonEmissionsArray.some(
    (emission) => emission.quantity > maxQuantityOfCabinCO2EmissionInPound
  );

  const selectedFlightOptionsIds = Object.values(selectedFlightOptions).map(
    (flight) => {
      return get(flight, "flightId", EMPTY_STRING);
    }
  );
  const selected = selectedFlightOptionsIds.includes(flightId);

  const handleFlightSelect = (flights) => {
    dispatch(setCurrentFlightType(currentFlightType));
    if (tripType === ROUND_TRIP) {
      dispatch(
        setSelectedFlightInfo({
          ...selectedFlightInfo,
          [currentFlightType]: {
            flights,
            selectedCountryInfo,
          },
        })
      );
    } else {
      dispatch(setSelectedFlightInfo({ packages: flights }));
    }

    if (flights.length > ONE) dispatch(setSelectedModal(FLIGHT_OPTIONS_MODAL));
    else {
      if (currentFlightType === PACKAGE_RESULT)
        dispatch(
          setSelectedFlightOptions({
            [currentFlightType]: flights[0],
          })
        );
      else
        dispatch(
          setSelectedFlightOptions({
            ...selectedFlightOptions,
            [currentFlightType]: flights[0],
          })
        );
    }
  };

  const handleViewFlightDetails = (flightIndexInfo) => {
    dispatch(setSelectedFlightIndexInfo(flightIndexInfo));
    dispatch(setSelectedModal(VIEW_FLIGHT_DETAILS_MODAL));
  };

  return (
    <div
      ref={cardRef}
      className={classNames(
        "border rounded-lg shadow-md transition duration-1000 ease-in-out",
        {
          "border-primary-600 bg-primary-100": selected,
          "border-contrast-200 bg-white hover:border-contrast-400": !selected,
          "mb-3": areSimilarFlightsAvailable,
        }
      )}
    >
      <div className="grid grid-cols-12 gap-2 p-4 pb-3">
        <div
          className={classNames("col-span-12 3xl:col-span-4 4xl:col-span-5", {
            "lg:col-span-4": tripType !== ROUND_TRIP,
          })}
        >
          <div className="flex justify-between">
            <div className="flex items-center gap-4">
              <div className="logo">
                <img
                  src={getAirlineIconUrlByCarrierCode(carrierCode)}
                  className="rounded border-contrast-300 w-8 h-8"
                  alt={`${carrierCode}_logo`}
                />
              </div>
              <div className="flex-1">
                <h5
                  className={classNames(
                    "text-base font-semibold text-contrast-900",
                    {
                      "text-sm": tripType === ROUND_TRIP,
                    }
                  )}
                >
                  {departure.time}
                  &nbsp;-&nbsp;
                  {arrival.time}
                </h5>
                <p className="text-[10px] sm:text-xs text-contrast-600">
                  {carrierName || carrierCode}, {flightNumber}{" "}
                  {aircraftCode ? `| ${aircraftCode}` : ""}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "col-span-12 3xl:col-span-8 4xl:col-span-7 grid grid-cols-12 gap-2 sm:gap-4",
            {
              "lg:col-span-8": tripType !== ROUND_TRIP,
              "md:gap-2": tripType === ROUND_TRIP,
            }
          )}
        >
          <div
            className={classNames(
              "col-span-12 sm:col-span-5 lg:col-span-7 flex gap-4",
              {
                "md:flex-col lg:flex-row md:col-span-6":
                  tripType === ROUND_TRIP,
              }
            )}
          >
            <div className="flex-1">
              <h5 className="text-sm xs:text-base text-contrast-900 font-semibold">
                {source === AMADEUS
                  ? getFormattedDuration(duration)
                  : calculateTotalDuration(layoverDuration, duration)}
              </h5>
              <p className="text-[10px] sm:text-xs text-contrast-600">
                {getCityNameByIata(departure.iataCode)} -&nbsp;
                {getCityNameByIata(arrival.iataCode)}
              </p>
            </div>
            <div
              className={classNames(
                "text-sm md:text-base flex-1 text-end sm:text-start",
                {
                  "md:text-sm": tripType === ROUND_TRIP,
                }
              )}
            >
              {!totalNoOfStops ? (
                <h5 className="text-contrast-900 font-semibold">
                  {t("flightResults.stopTypes.nonStop")}
                </h5>
              ) : (
                <div>
                  <h5 className="text-contrast-900 font-semibold">
                    {totalNoOfStops} {t("flightResults.stopTypes.stop")}
                  </h5>
                  <div className="text-xs text-contrast-600">
                    {getStops(segments)}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              "col-span-12 sm:col-span-7 lg:col-span-5 flex items-start justify-end flex-wrap xs:flex-nowrap",
              {
                "md:flex-col lg:flex-row md:col-span-6 md:items-center gap-5":
                  tripType === ROUND_TRIP,
                "gap-4": tripType !== ROUND_TRIP,
              }
            )}
          >
            <div className="sm:text-end mr-auto sm:mr-0">
              <PriceAndPaxInfo
                flightIndex={flightIndex}
                totalPrice={totalPrice}
                publishedPrice={publishedPrice}
                currencySymbol={currencySymbol}
                totalPassengersCount={totalPassengersCount}
                translationHandler={t}
              />
            </div>
            <div className="flex flex-col items-center gap-1">
              <div className="gap-1">
                <button
                  className={classNames(
                    "py-2 px-2 sm:px-4 flex items-center gap-2 rounded-md hover:bg-primary-300 active:bg-primary-200 border-none shadow-sm lg:text-sm text-primary-700 font-medium whitespace-nowrap text-xs",
                    {
                      "bg-primary-200": selected,
                      "bg-primary-100 ": !selected,
                    }
                  )}
                  onClick={() => {
                    logEvent(`flight_selected_from_one_way_flight_results`)
                    handleFlightSelect(flights)
                  }}
                >
                  {isReissuanceFlight
                    ? t("flightResults.reissueFlight")
                    : t("flightResults.selectFlight")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames("flex mb-4 sm:mb-2 pl-4 sm:px-4", {
          "mb-6 md:mb-4": areSimilarFlightsAvailable && tripType !== ROUND_TRIP,
          "mb-6": areSimilarFlightsAvailable && tripType === ROUND_TRIP,
        })}
      >
        <div className="flex flex-1 flex-wrap gap-2 ">
          {hasHighCO2Emission && (
            <div className="rounded-2xl  h-fit py-0.5 px-2 bg-green-100 text-green-900 text-[10px] font-medium flex items-center gap-[6px]">
              <RenderSVG Svg={Cloud} width="14" height="14" alt="cloud-icon" />
              <span>{t("flightResults.lowCo2")}</span>
            </div>
          )}
          {weight && (
            <div className="text-[10px] h-fit font-semibold whitespace-nowrap bg-gray-200 text-primary-900 rounded-2xl py-0.5 px-1 sm:px-2 flex items-center">
              <RenderSVG Svg={Baggage} width="14" height="14" alt="baggage" />
              <span className="ml-1 sm:ml-2">
              <span className="hidden sm:inline">
                {t("flightResults.baggageAllowed")}: 
              </span>
                {weight}
              </span>
            </div>
          )}
          {isPanRequiredAtTicket && (
            <div className="text-xs h-fit font-medium bg-gray-200 text-black rounded-2xl py-0.5 px-2 flex items-center">
              {t("flightResults.panRequired")}
            </div>
          )}
          <FlightDetailsTag
            refundableStatus={isRefundable}
            miniFareRules={miniFareRules}
            noOfAvailableSeats={noOfAvailableSeats}
          />
        </div>
        <div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              logEvent('view_details_oneway_flight_btn_clicked')
              handleViewFlightDetails(flights[flightIndex]);
            }}
            className="py-2 px-4 sm:px-[12px] flex items-center text-xs sm:text-sm text-primary-700 font-medium whitespace-nowrap"
          >
            {t("flightResults.viewDetails")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(OneWayCard);
