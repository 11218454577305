import reducers from "./flightBookings.reducer";

export const selectSlice = (state) => state[reducers.name];

export const selectSelectedTravelers = (state) =>
  selectSlice(state).selectedTravelers;

export const selectSpecialServices = (state) =>
  selectSlice(state).specialServices;

export const selectSelectedLCCSeats = (state) =>
  selectSlice(state).selectedLCCSeats;

export const selectFlightBookingInfo = (state) =>
  selectSlice(state).flightBookingInfo;

export const selectCancellationStatus = (state) => 
  selectSlice(state).cancellationStatus;

export const selectPromoCodes = (state) => selectSlice(state).promoCodes;

export const selectSelectedPromoCode = (state) =>
  selectSlice(state).selectedPromoCode;

export const selectBookCallBackResponse = (state) =>
  selectSlice(state).bookCallbackResponse;

export const selectSelectedLCCMeals = (state) =>
  selectSlice(state).selectedLCCMeals;

export const selectSelectedNonLCCMeals = (state) =>
  selectSlice(state).selectedNonLCCMeal;

export const selectSelectedLCCBaggages = (state) =>
  selectSlice(state).selectedLCCBaggages;

export const selectTotalSSRPrice = (state) => selectSlice(state).totalSSRPrice;

export const selectBookingTravelers = (state) =>
  selectSlice(state).bookingTravelers;

export const selectSSRDefaultValuesLCC = (state) =>
  selectSlice(state).SSRDefaultValuesLCC;

export const selectSelectedNonLCCSeats = (state) =>
  selectSlice(state).selectedNonLCCSeats;

export const selectGSTDetails = (state) => selectSlice(state).gstDetails;

export const selectContactDetails = (state) =>
  selectSlice(state).contactDetails;

export const selectAddressDetails = (state) =>
  selectSlice(state).addressDetails;

export const selectFlightBookingReqBody = (state) =>
  selectSlice(state).flightBookingReqBody;

export const selectPaymentMethod = (state) =>
  selectSlice(state).paymentMethod;

export const selectFlightPollingResponse = (state) => selectSlice(state).flightPollingResponse;
