import { useState, useEffect } from "react";
import { get, isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import { PhoneNumberInput } from "../../../components/atoms/PhoneNumberInput";
import Asterisk from "../../../components/atoms/Asterisk";
import { DEFAULT_VALUES, REGEX } from "../../../constants";
import { selectTravelerInfo } from "../../../screens/Profile";
import { actions as flightBookingActions } from "../../../screens/Booking/FlightBookings/flightBookings.reducer";
import * as Yup from "yup";
import { getRequiredErrorMessage } from "../../../helper";
import { selectGSTDetails } from "../../../screens/Booking/FlightBookings";
import logEvent from "../../../utils/GATracker";

const { EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const { PHONE_NUMBER, COMPANY_NAME, GST_REGEX } = REGEX;

const { setGstDetails } = flightBookingActions;

const renderFieldError = (name, errors, touched) =>
  get(errors, name, false) &&
  get(touched, name, false) && (
    <ErrorMessage errorMessage={get(errors, name)} />
  );

const initialFormValues = {
  includeGST: false,
  companyName: EMPTY_STRING,
  gstNo: EMPTY_STRING,
  companyAddress: EMPTY_STRING,
  companyContactNumber: EMPTY_STRING,
  companyEmail: EMPTY_STRING,
};

const GstDetailCard = ({ documentsRequired, gstFormRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTravelerInfo = useSelector(selectTravelerInfo);
  const selectedGSTDetails = useSelector(selectGSTDetails) || EMPTY_OBJECT;
  const { IsGSTMandatory } = documentsRequired;
  const travelerInfo = selectedTravelerInfo || EMPTY_ARRAY;
  const [initialValues, setInitialValues] = useState(initialFormValues);

  const handleCheckboxChange = (e, resetForm, setFieldValue) => {
    const isChecked = e.target.checked;
    if (isChecked) resetForm();
    setFieldValue("includeGST", isChecked);
  };

  const gstValidationSchema = Yup.object({
    companyEmail: Yup.string()
      .required(
        getRequiredErrorMessage("profilePage.gstDetails.companyEmail", t)
      )
      .email(t("profilePage.errors.emailFormat")),
    companyContactNumber: Yup.string()
      .matches(PHONE_NUMBER, t("profilePage.errors.phone"))
      .required(
        getRequiredErrorMessage("profilePage.gstDetails.companyContactNo", t)
      ),
    companyAddress: Yup.string().required(
      getRequiredErrorMessage("profilePage.gstDetails.companyAddress", t)
    ),
    companyName: Yup.string()
      .matches(COMPANY_NAME, t("profilePage.errors.nameFormat"))
      .required(
        getRequiredErrorMessage("profilePage.gstDetails.companyName", t)
      ),
    gstNo: Yup.string()
      .required(getRequiredErrorMessage("profilePage.gstDetails.GSTNumber", t)),
  });

  useEffect(() => {
    const primaryTraveler = travelerInfo.find(({ isPrimary }) => isPrimary);
    if (!isEmpty(selectedGSTDetails)) setInitialValues(selectedGSTDetails);
    else if (!isEmpty(primaryTraveler))
      setInitialValues((pre) => ({
        ...pre,
        companyEmail:
          primaryTraveler.profileDetails.companyEmail || EMPTY_STRING,
        companyName: primaryTraveler.profileDetails.companyName || EMPTY_STRING,
        companyContactNumber:
          primaryTraveler.profileDetails.companyContactNumber || EMPTY_STRING,
      }));
  }, [travelerInfo, selectedGSTDetails]);

  const handleFormSubmit = (values) => dispatch(setGstDetails(values));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={gstValidationSchema}
      validateOnMount={true}
      enableReinitialize
      onSubmit={handleFormSubmit}
      innerRef={gstFormRef}
    >
      {({
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        resetForm,
      }) => (
        <Form>
          <div className="p-2 xs:p-4 w-full">
            <div className="form-group flex items-center">
              {!IsGSTMandatory && (
                <>
                  <Field
                    id="includeGST"
                    name="includeGST"
                    type="checkbox"
                    className="mr-2 form-control cursor-pointer"
                    onChange={(e) => {
                      logEvent(`include_gst_details_checkbox_clicked`)
                      handleCheckboxChange(e, resetForm, setFieldValue)
                    }}
                  />
                  <label
                    htmlFor="includeGST"
                    className="text-sm font-medium text-contrast-900 inline-flex items-center cursor-pointer"
                  >
                    {t("profilePage.gstDetails.includeGst")}
                  </label>
                </>
              )}
            </div>
            {(values.includeGST || IsGSTMandatory) && (
              <div className="w-full mt-4">
                <div className="text-contrast-900 pl-4 text-md font-semibold bg-white justify-between">
                  <span>{t("profilePage.gstDetails.includeGst")}</span>
                </div>
                <div className="grid gap-x-6 gap-y-4 grid-cols-6 bg-white w-full rounded-b-lg p-4">
                  <div className="form-group col-span-6 md:col-span-2 lg:col-span-2">
                    <label className="block text-sm font-medium mb-1 text-contrast-900">
                      {t("profilePage.gstDetails.companyName")}
                      <Asterisk />
                    </label>
                    <Field
                      name="companyName"
                      type="text"
                      className="form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500"
                      placeholder={t("profilePage.placeholders.companyName")}
                    />
                    <div>
                      {renderFieldError("companyName", errors, touched)}
                    </div>
                  </div>
                  <div className="form-group col-span-6 md:col-span-2 lg:col-span-2">
                    <label className="block text-sm font-medium mb-1 text-contrast-900">
                      {t("profilePage.gstDetails.GSTNumber")}
                      <Asterisk />
                    </label>
                    <Field
                      name="gstNo"
                      type="text"
                      className="form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500"
                      placeholder={t("profilePage.placeholders.GSTNumber")}
                    />
                    <div>{renderFieldError("gstNo", errors, touched)}</div>
                  </div>
                  <div className="form-group col-span-6 md:col-span-2 lg:col-span-2">
                    <label className="block text-sm font-medium mb-1 text-contrast-900">
                      {t("profilePage.gstDetails.companyAddress")}
                      <Asterisk />
                    </label>
                    <Field
                      name="companyAddress"
                      type="text"
                      className="form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500"
                      placeholder={t("profilePage.placeholders.address")}
                    />
                    <div>
                      {renderFieldError("companyAddress", errors, touched)}
                    </div>
                  </div>
                  <div className="form-group col-span-6 md:col-span-2 lg:col-span-2">
                    <label className="block text-sm font-medium mb-1 text-contrast-900">
                      {t("profilePage.gstDetails.companyEmail")}
                      <Asterisk />
                    </label>
                    <Field
                      name="companyEmail"
                      type="text"
                      className="form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500"
                      placeholder={t("profilePage.placeholders.email")}
                    />
                    <div>
                      {renderFieldError("companyEmail", errors, touched)}
                    </div>
                  </div>
                  <div className="form-group col-span-6 md:col-span-2 lg:col-span-2">
                    <label className="block text-sm font-medium mb-1 text-contrast-900">
                      {t("profilePage.gstDetails.companyContactNo")}
                      <Asterisk />
                    </label>
                    <div className=" mb-2 w-full justify-between rounded-lg border border-contrast-300 h-9 bg-white flex space-x-1">
                      <PhoneNumberInput
                        name="companyContactNumber"
                        values={values}
                        placeholder={"loginAndSignup.placeholders.phone"}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        t={t}
                      />
                    </div>
                    <div>
                      {renderFieldError(
                        "companyContactNumber",
                        errors,
                        touched
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GstDetailCard;
