import { memo, useEffect, useState } from "react";
import { isEmpty, get } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SortingHeaders from "./SortingHeaders";
import NoFlightResult from "./NoFlightResult";
import OneWayCard from "./OneWayCard";
import { SwiperScript } from "../../utils";
import { DEFAULT_VALUES } from "../../constants";
import {
  selectSortedFlights,
  selectFilteredFlights,
} from "../../components/organisms/Search";
import {
  getCityNameByIata,
  getFormattedArrivalAndDepartureDate,
  getRearrangedFlights,
} from "../../helper";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { TBOAPICallSkeleton } from "../../components/organisms/AppSkeletons";
import { ArrowLeft, RenderSVG } from "../../assets/icons";
import config from "../../config.json";

const { ZERO, ONE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { FETCH_TBO_FLIGHTS } = SPINNER_NAMES;

const RoundTripFlights = ({ selectedFlightId }) => {
  const { t } = useTranslation();

  const sortedFlights = useSelector(selectSortedFlights);
  const filteredFlights = useSelector(selectFilteredFlights);
  const individualSortedFlights = get(sortedFlights, "isolated", EMPTY_OBJECT);
  const individualFilteredFlights = get(
    filteredFlights,
    "isolated",
    EMPTY_OBJECT
  );
  const [flights, setFlights] = useState(individualSortedFlights);

  useEffect(() => {
    SwiperScript();
  }, []);

  useEffect(() => {
    const applySortingLogic = () => {
      let updatedFlights;
      const selectedInbound = selectedFlightId?.inbound;
      const selectedOutbound = selectedFlightId?.outbound;
      if (
        !selectedInbound &&
        !selectedOutbound &&
        !isEmpty(individualSortedFlights)
      ) {
        updatedFlights = {
          ...individualSortedFlights,
        };
      } else if (!selectedInbound) {
        const rearrangedOutBoundResult = getRearrangedFlights(
          individualFilteredFlights?.outbound,
          selectedOutbound
        );
        updatedFlights = {
          outbound: rearrangedOutBoundResult,
          inbound: individualSortedFlights?.inbound,
        };
      } else if (!selectedOutbound) {
        const rearrangedInBoundResult = getRearrangedFlights(
          individualFilteredFlights?.inbound,
          selectedInbound
        );
        updatedFlights = {
          outbound: individualSortedFlights?.outbound,
          inbound: rearrangedInBoundResult,
        };
      } else if (selectedInbound && selectedOutbound) {
        const rearrangedOutBoundResult = getRearrangedFlights(
          individualFilteredFlights.outbound,
          selectedOutbound
        );
        const rearrangedInBoundResult = getRearrangedFlights(
          individualFilteredFlights.inbound,
          selectedInbound
        );
        updatedFlights = {
          outbound: rearrangedOutBoundResult,
          inbound: rearrangedInBoundResult,
        };
      }
      setFlights(updatedFlights);
    };
    applySortingLogic();
  }, [selectedFlightId, individualSortedFlights, individualFilteredFlights]);

  if (isEmpty(flights?.outbound) && isEmpty(flights?.inbound))
    return <NoFlightResult showPackages={false} />;

  return (
    <div>
      <div className="swiper flight-results-slider flex flex-row">
        <div className="swiper-wrapper flex justify-between gap-2">
          {Object.entries(flights).map(([flightType, flightsData]) => {
            const segments = get(flightsData, "0.0.itineraries.0.segments", []);
            const firstSegment = segments[0];
            const lastSegment = segments[segments.length - ONE];
            let departIataCode, departDate, arrivalIataCode;
            if (firstSegment) {
              ({
                departure: { iataCode: departIataCode, date: departDate } = {},
              } = firstSegment);
            }
            if (lastSegment) {
              ({ arrival: { iataCode: arrivalIataCode } = {} } = lastSegment);
            }

            return (
              <div
                className="swiper-slide md:flex-1 md:max-w-[50%]"
                key={flightType}
              >
                {!isEmpty(flightsData) ? (
                  <>
                    <div className="bg-primary-100 opacity-75 rounded-xl border border-contrast-200 font-bold text-sm md:text-base lg:text-xl mr-1 mb-2 py-4 flex flex-row justify-center">
                      <div className="flex flex-row items-center flex-wrap justify-center gap-2">
                        <div className="flex flex-row items-center">
                          {getCityNameByIata(departIataCode)}
                          <RenderSVG
                            Svg={ArrowLeft}
                            alt="Left Arrow"
                            className="mx-2 w-4"
                            stroke={config.contrast}
                          />
                          {getCityNameByIata(arrivalIataCode)}
                        </div>
                        <div className="flex flex-row items-center text-sm lg:text-base font-normal text-nowrap">
                          {getFormattedArrivalAndDepartureDate(departDate)}
                        </div>
                      </div>
                    </div>
                    <div className="bg-contrast-50 rounded-t-xl border border-contrast-200 mr-1">
                      <SortingHeaders type={flightType} />
                      <Spinner
                        name={FETCH_TBO_FLIGHTS}
                        showSkeleton={true}
                        loaderComponent={<TBOAPICallSkeleton />}
                      ></Spinner>
                      <div className="flex flex-col xs:gap-2 max-h-screen sm:max-h-full overflow-y-scroll no-scrollbar pb-2 px-3 ">
                        {flightsData.map((flights) => (
                          <div key={flights[ZERO].flightId}>
                            <OneWayCard
                              flights={flights}
                              currentFlightType={flightType}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="bg-contrast-50 rounded-xl border border-contrast-200 mr-1 flex justify-center p-5">
                    <div className="text-center p-5">
                      <div className="text-primary-600 text-sm mb-6 font-semibold uppercase leading-tight tracking-tight">
                        {t("flightResults.noFlights.noResults")}
                      </div>
                      <div className="text-contrast-900 lg:text-3xl font-semibold text-xl">
                        {t("flightResults.noFlights.noMatchFilters")}
                      </div>
                      <div className="text-contrast-500 text-base font-normal leading-normal mt-1">
                        {t("flightResults.noFlights.adjustFilters")}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(RoundTripFlights);
