import get from "lodash/get";
import { v4 as uuid } from "uuid";
import {
  getFromSessionStorage,
  getSSRPrice,
  getUpdatedItineraryDetails,
  mapTravelersInfo,
} from "./index";
import {
  DEFAULT_VALUES,
  DEFAULT_CURRENCY_CODE,
  TBO_FARE_TYPES,
  PG_PROCESSING_FEE_RATE,
  GST_RATE,
  CACHE_KEYS,
  DEFAULT_CURRENCY,
  TAVATRIP_FEE,
} from "../constants";
import { getPriceWithConversion } from "./priceFormatter";

const { ZERO, ONE, EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { REGULAR } = TBO_FARE_TYPES;
const { CURRENCY_INFO } = CACHE_KEYS;
const INSTANT = "INSTANT";
const ADULT_TRAVELER_TYPE = "Adult";
const AGENT_REFERENCE_NO = "innostax";
const AMADEUS_SOURCE = "AMADEUS";
const AMADEUS_PROVIDER = "AM";

const getFareRulesByJourneyId = (source, flightPriceInfo, journeyId) => {
  const fareRulesKey =
    source === AMADEUS_SOURCE ? "amadeusFareRules" : "fareRulesDetails";
  const fareRulesData = get(flightPriceInfo, `0.${fareRulesKey}`, EMPTY_ARRAY);
  const filteredFareRulesData = fareRulesData.filter(
    (fareRule) => fareRule.journeyId === journeyId
  );
  return filteredFareRulesData;
};
const mapAllowedBaggageDetails = (baggageData) => {
  const checkInBaggage =
    get(baggageData, "weight") ||
    get(baggageData, "numberOfBags", EMPTY_STRING);
  const cabinBaggage = get(baggageData, "cabinBaggage", EMPTY_STRING);
  return { checkInBaggage, cabinBaggage };
};

export const getSurchargesAmount = (grandTotal) => {
  const gatewayCharges = (grandTotal + TAVATRIP_FEE) * PG_PROCESSING_FEE_RATE;
  const gstAmount = gatewayCharges * GST_RATE;
  return gatewayCharges + gstAmount;
};

const getUpdatedPaymentDetails = (
  paymentsDetails,
  flightPriceInfo,
  specialServicesData
) => {
  const { exchangeRate } =
    getFromSessionStorage(CURRENCY_INFO) || DEFAULT_CURRENCY;

  const {
    price: { basePrice: baseFare, grandTotal, currency },
  } = flightPriceInfo[ZERO] || EMPTY_OBJECT;
  const { discountAmount, discountedPrice, promocode } = paymentsDetails;
  const {
    Baggage = EMPTY_OBJECT,
    MealDynamic = EMPTY_OBJECT,
    SeatDynamic = EMPTY_OBJECT,
  } = specialServicesData;
  const baggageAmt = getSSRPrice(Baggage);
  const mealAmt = getSSRPrice(MealDynamic);
  const seatAmt = getSSRPrice(SeatDynamic);
  const totalSSRPrice = parseFloat((baggageAmt + mealAmt + seatAmt).toFixed(2));
  const totalSum = grandTotal + totalSSRPrice;
  const surcharges = parseFloat(getSurchargesAmount(totalSum).toFixed(2));
  const platformFee = TAVATRIP_FEE / exchangeRate;
  const paidAmount = parseFloat(
    (totalSum + surcharges + platformFee - discountAmount).toFixed(2)
  );
  const currencyInfo = getFromSessionStorage(CURRENCY_INFO) || DEFAULT_CURRENCY;

  return {
    currency,
    offeredPrice: grandTotal,
    discountAmount,
    discountedPrice,
    otherServices: parseFloat(totalSSRPrice.toFixed(2)),
    surcharges,
    paidAmount,
    grossAmount: paidAmount,
    otherServicesInfo: {
      mealAmt,
      seatAmt,
      baggageAmt,
    },
    offeredPriceInfo: {
      baseFare,
      airlineTaxes: parseFloat((grandTotal - baseFare).toFixed(2)),
    },
    promocode,
    selectedPaymentPortal: "RAZORPAY",
    paymentMethod: EMPTY_STRING,
    currencyInfo,
  };
};

const mapBookingRequest = ({
  selectedFlightPriceReqBody,
  selectedSelectedTravelers,
  selectedFlightPriceInformation,
  specialServicesData,
  paymentsDetails,
  tavaBookingId,
  isAmadeusAndInternational,
  isGoingToNepal,
  accountEmail,
  tripType,
}) => {
  const { traceId, endUserIp, source, tokenId } =
    selectedFlightPriceReqBody[ZERO];
  const { currency } = get(
    selectedFlightPriceInformation[ZERO],
    "price",
    EMPTY_OBJECT
  );
  const fareType = get(selectedFlightPriceInformation, "[0].fareType", REGULAR);

  const amadeusFareRules = get(
    selectedFlightPriceInformation,
    "0.amadeusFareRules",
    EMPTY_ARRAY
  );
  const TBOFareRules = get(
    selectedFlightPriceInformation,
    "0.fareRulesDetails",
    EMPTY_ARRAY
  );

  return {
    TokenId: tokenId,
    TraceId: traceId,
    EndUserIp: endUserIp,
    orderType: INSTANT,
    tavaBookingId,
    AgentReferenceNo: AGENT_REFERENCE_NO,
    accountEmail,
    paymentsDetails: getUpdatedPaymentDetails(
      paymentsDetails,
      selectedFlightPriceInformation,
      specialServicesData
    ),
    provider: Array.isArray(source)
      ? source
      : source === AMADEUS_SOURCE
      ? AMADEUS_PROVIDER
      : source,
    journeyDetails: selectedFlightPriceReqBody.map(
      ({
        resultIndex,
        isLCC,
        price,
        source: flightsource,
        journeyId,
        baggage,
      }) => {
        const { flightId, itineraries, price: journeyPrice } = price;
        const allowedBaggage = mapAllowedBaggageDetails(baggage);
        return {
          id: flightId,
          provider:
            flightsource === AMADEUS_SOURCE ? AMADEUS_PROVIDER : flightsource,
          TokenId: tokenId,
          ResultIndex: resultIndex,
          isLCC,
          fareRules: getFareRulesByJourneyId(
            flightsource,
            selectedFlightPriceInformation,
            journeyId
          ),
          tripType,
          itineraries: getUpdatedItineraryDetails(itineraries),
          price: {
            basePrice: journeyPrice.basePrice,
            currency,
            grandTotal: journeyPrice.grandTotal,
            fareType,
            paymentsDetails: getUpdatedPaymentDetails(
              paymentsDetails,
              selectedFlightPriceInformation,
              specialServicesData
            ),
          },
          travelerDetails: mapTravelersInfo({
            isLCC,
            travelersInformation: selectedSelectedTravelers,
            specialServicesData,
            itineraries,
            isAmadeusAndInternational,
            selectedFlightPriceInformation,
            fareType,
            resultIndex,
            isGoingToNepal,
            source: flightsource,
            allowedBaggage,
          }),
        };
      }
    ),
    fareRules: {
      amadeusFareRules: amadeusFareRules,
      TBOFareRules: TBOFareRules,
    },
  };
};

const mapPaymentReq = ({
  selectedTravelersInfo,
  flightPriceInformation,
  specialServicesData,
  paymentsDetails,
  userCountryInfo,
}) => {
  const code = get(userCountryInfo, "currency.code", DEFAULT_CURRENCY_CODE);
  const { exchangeRate } =
    getFromSessionStorage(CURRENCY_INFO) || DEFAULT_CURRENCY;
  const { Baggage, MealDynamic, SeatDynamic } = specialServicesData;
  const { discountAmount } = paymentsDetails;
  const totalSSRPrice =
    getSSRPrice(Baggage) + getSSRPrice(MealDynamic) + getSSRPrice(SeatDynamic);
  const { grandTotal } = get(
    flightPriceInformation[ZERO],
    "price",
    EMPTY_OBJECT
  );
  const finalPaymentAmount =
    grandTotal +
    totalSSRPrice +
    getSurchargesAmount(totalSSRPrice + grandTotal) -
    discountAmount;
  const firstAdultTraveler = selectedTravelersInfo.find(
    (traveler) => traveler.travelerType === ADULT_TRAVELER_TYPE
  );
  const finalAmount = parseFloat(finalPaymentAmount.toFixed(2));
  const {
    email,
    phone: contactNo,
    firstName: name,
  } = get(firstAdultTraveler, "profileDetails", EMPTY_OBJECT);
  const baseURL = window.location.href.replace(
    window.location.pathname,
    EMPTY_STRING
  );

  return {
    successUrl: `${baseURL}/booking-confirmed`,
    cancelUrl: `${baseURL}/booking-failed`,
    lineItems: [
      {
        price_data: {
          currency: code,
          product_data: {
            name,
            images: EMPTY_ARRAY,
            description: EMPTY_STRING,
            metadata: {
              id: ONE.toString(),
            },
          },
          unit_amount: (finalAmount * 100).toString(),
        },
        quantity: ONE,
      },
    ],
    selectedPaymentPortal: "RAZORPAY",
    cardInfo: {
      billerDetails: {
        billerAddress: EMPTY_STRING,
        billerCity: EMPTY_STRING,
        billerCountry: EMPTY_STRING,
        billerPin: EMPTY_STRING,
        billerState: EMPTY_STRING,
      },
      cardDetails: {
        number: EMPTY_STRING,
        name: EMPTY_STRING,
        expiry_month: EMPTY_STRING,
        expiry_year: EMPTY_STRING,
        cvv: EMPTY_STRING,
      },
      fetchBinFailed: null,
      saveCard: null,
    },
    checkoutId: EMPTY_STRING,
    payMode: EMPTY_STRING,
    payOption: EMPTY_STRING,
    networkConsent: false,
    deviceFingerPrintID: EMPTY_STRING,
    mcid: EMPTY_STRING,
    enforceOtpOnPage: false,
    version: "1.5",
    msgId: EMPTY_STRING,
    operation: "PAYMENT_INIT",
    requestDate: new Date().toISOString().split(".")[ZERO],
    validateOnly: false,
    requestData: {
      clientId: "14000003",
      clientIdHash: EMPTY_STRING,
      transactionType: "PURCHASE",
      transactionAmount: {
        totalAmount: finalAmount,
        paymentAmount: finalAmount,
        serviceFeeAmount: ZERO,
        currency: code,
      },
      redirect: {
        returnUrl: EMPTY_STRING,
        cancelUrl: EMPTY_STRING,
        returnMethod: "GET",
      },
      clientRef: uuid(),
      comment: EMPTY_STRING,
      tokenize: true,
      cssLocation1: EMPTY_STRING,
      cssLocation2: EMPTY_STRING,
      useReliability: true,
      "extraData ": "{1,2,3}",
    },
    amount: (
      getPriceWithConversion(finalAmount, exchangeRate) + TAVATRIP_FEE
    ).toFixed(2),
    currency: code,
    email,
    contactNo,
  };
};

export const mapBookingAPIRequest = ({
  selectedFlightPriceReqBody,
  selectedSelectedTravelers,
  selectedFlightPriceInformation,
  specialServicesData,
  paymentsDetails,
  tavaBookingId,
  userCountryInfo,
  isAmadeusAndInternational,
  isReissuanceFlight,
  isGoingToNepal,
  accountEmail,
  tripType,
}) => ({
  paymentRequest: mapPaymentReq({
    selectedTravelersInfo: selectedSelectedTravelers,
    flightPriceInformation: selectedFlightPriceInformation,
    specialServicesData,
    paymentsDetails,
    userCountryInfo,
  }),
  ...(!isReissuanceFlight && {
    bookingRequest: mapBookingRequest({
      selectedFlightPriceReqBody,
      selectedSelectedTravelers,
      selectedFlightPriceInformation,
      specialServicesData,
      paymentsDetails,
      tavaBookingId,
      isAmadeusAndInternational,
      isGoingToNepal,
      accountEmail,
      tripType,
    }),
  }),
});
