import { ReactComponent as Aeroplane } from "./aeroplane.svg";
import { ReactComponent as Apple } from "./apple.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Information } from "./information.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as ChevronDownPrimary } from "./chevron-down-primary.svg";
import { ReactComponent as ChevronDown } from "./chevron-down.svg";
import { ReactComponent as Circle } from "./circle.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as CurrencyDollar } from "./currency-dollar.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as GlobeAlt } from "./globe-alt.svg";
import { ReactComponent as Globe } from "./globe.svg";
import { ReactComponent as Google } from "./google.svg";
import { ReactComponent as Home } from "./home.svg";
import { ReactComponent as LocationMarkerLight } from "./location-marker-light.svg";
import { ReactComponent as LocationMarker } from "./location-marker.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as TripTavaWithBrand } from "./tripTavaWithBrand.svg";
import { ReactComponent as Mail } from "./mail.svg";
import { ReactComponent as MailOutline } from "./mail-outline.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as Menu } from "./menu.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as SearchIcon } from "./searchIcon.svg";
import { ReactComponent as Ticket } from "./ticket.svg";
import { ReactComponent as Users } from "./users.svg";
import { ReactComponent as ToggleDirection } from "./toggle-direction.svg";
import { ReactComponent as UserCircle } from "./user-circle.svg";
import { ReactComponent as Cloud } from "./cloud.svg";
import { ReactComponent as Lightning } from "./lightning.svg";
import { ReactComponent as CurrencyDollarDark } from "./currency-dollar-dark.svg";
import { ReactComponent as XCircle } from "./x-circle.svg";
import { ReactComponent as CheckGreen } from "./check-green.svg";
import { ReactComponent as CheckDark } from "./check-dark.svg";
import { ReactComponent as TripTavaLogo } from "./tripTavaLogo.svg";
import { ReactComponent as ChevronDownDark } from "./chevronDownDark.svg";
import { ReactComponent as LeftArrow } from "./leftArrow.svg";
import { ReactComponent as RightArrow } from "./rightArrow.svg";
import { ReactComponent as LineSeparator } from "./lineSeparator.svg";
import { ReactComponent as DotPattern } from "./dot-pattern.svg";
import { ReactComponent as Checkbox } from "./checkbox.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as ExclamationTriangle } from "./exclamationTriangle.svg";
import { ReactComponent as FlightIcon } from "./flight.svg";
import { ReactComponent as FlightDarkIcon } from "./flightDark.svg";
import { ReactComponent as HotelsIcon } from "./hotels.svg";
import { ReactComponent as HotelsDarkIcon } from "./hotelsDark.svg";
import { ReactComponent as CopyToClipboard } from "./copy-to-clipboard.svg";
import { ReactComponent as VacationsIcon } from "./vacations.svg";
import { ReactComponent as VacationDarkIcon } from "./vacationsDark.svg";
import { ReactComponent as TripTavaLogoMin } from "./tripTavaLogoMin.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as PhoneIcon } from "./phone-icon.svg";
import { ReactComponent as SpinnerIcon } from "./spinner.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as MenuVerticalIcon } from "./menuVertical.svg";
import { ReactComponent as CloseDark } from "./close-dark.svg";
import { ReactComponent as ChevronUp } from "./chevron-up.svg";
import { ReactComponent as FilterResetIcon } from "./filterReset.svg";
import { ReactComponent as TriangleExclaimation } from "./triangle-exclaimation.svg";
import { ReactComponent as Gender } from "./gender.svg";
import { ReactComponent as LeftIcon } from "./chevron-left.svg";
import { ReactComponent as RightIcon } from "./chevron-right.svg";
import { ReactComponent as Stripe } from "./stripe.svg";
import { ReactComponent as Razorpay } from "./razorpay.svg";
import { ReactComponent as PlusWhite } from "./plusWhite.svg";
import { ReactComponent as CheckWhite } from "./checkWhite.svg";
import { ReactComponent as Barcode } from "./barcode.svg";
import { ReactComponent as RewardsPoint } from "./rewardsPoint.svg";
import { ReactComponent as ExclamationCircleIcon } from "./exclamationCircle.svg";
import { ReactComponent as QuestionIcon } from "./questionmark.svg";
import { ReactComponent as CheckCircleIcon } from "./check-circle.svg";
import { ReactComponent as ArrowRoundTrip } from "./arrow-roundtrip.svg";
import { ReactComponent as ShowPasswordIcon } from "./showPasswordIcon.svg";
import { ReactComponent as HidePasswordIcon } from "./hidePasswordIcon.svg";
import { ReactComponent as Bed } from "./bed.svg";
import { ReactComponent as WhiteChevronDown } from "./white-chevron-down.svg";
import { ReactComponent as WhiteChevronUp } from "./white-chevron-up.svg";
import { ReactComponent as StarLight } from "./star-light.svg";
import { ReactComponent as Star } from "./star.svg";
import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as SearchDarkIcon } from "./searchDarkIcon.svg";
import { ReactComponent as Booking } from "./booking.svg";
import { ReactComponent as Compass } from "./compass.svg";
import { ReactComponent as History } from "./history.svg";
import { ReactComponent as ApiLogs } from "./apiLogs.svg";
import { ReactComponent as PendingIcon } from "./pending.svg";
import { ReactComponent as ServiceIcon } from "./service.svg";
import { ReactComponent as EarningIcon } from "./earning.svg";
import { ReactComponent as DownloadIcon } from "./download.svg";
import { ReactComponent as LogoutIcon } from "./logoutIcon.svg";
import { ReactComponent as DiscountIcon } from "./discount.svg";
import { ReactComponent as InflationIcon } from "./inflationIcon.svg";
import { ReactComponent as Baggage } from "./baggage.svg";
import { ReactComponent as FlightCancel } from "./flightCancel.svg";
import { ReactComponent as Morning } from "./morning.svg";
import { ReactComponent as Noon } from "./noon.svg";
import { ReactComponent as NoRooms } from "./noRooms.svg";
import { ReactComponent as Evening } from "./evening.svg";
import { ReactComponent as BaggageIcon } from "./baggageIcon.svg";
import { ReactComponent as DeparturePlaneIcon } from "./departurePlane.svg";
import { ReactComponent as ArrivalPlaneIcon } from "./arrivalPlane.svg";
import { ReactComponent as IndianCoinIcon } from "./indianRupeeCoin.svg";
import { ReactComponent as TicketSlash } from "./ticketSlash.svg";
import { ReactComponent as ThreeDotsLoading } from "./threeDotsLoading.svg";
import indianFlag from "./indianFlag.svg";
import Wave from "./wave.svg";
import { ReactComponent as bookingIcon } from "./bookingIcon.svg";
import { ReactComponent as SortAscIncon } from "./sortArrowDown.svg";
import { ReactComponent as SortDescIcon } from "./sortArrowUp.svg";
import { ReactComponent as EmailIcon } from "./email.svg";
import { ReactComponent as KebabMenu } from "./kebabMenu.svg";
import { ReactComponent as InvoiceIcon } from "./invoice.svg";
import { ReactComponent as FreeLabel } from "./freeLabel.svg";
import { ReactComponent as CalendarOutline } from "./calendar-outline.svg";
import { ReactComponent as TicketOutline } from "./ticket-outline.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";
import { ReactComponent as HotelTicket } from "./ticket-hotel.svg";
import { ReactComponent as CrossRed } from "./cross-red.svg";
import { ReactComponent as StartLocationMarker } from "./start-location-marker.svg";
import { ReactComponent as InformationRules } from "./information-rules.svg";
import { ReactComponent as UserPlus } from "./user-plus.svg";
import { ReactComponent as SlashTicket } from "./ticket-slash.svg";
import { ReactComponent as noUserAdded } from "./no-user-added.svg";
import { ReactComponent as Meal } from "./meal.svg";
import { ReactComponent as Refund } from "./refund.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as Confetti } from "./confetti.svg";
import { ReactComponent as CircledExclaimation } from "./circled-exclaimation.svg";
import { ReactComponent as Seat } from "./seat.svg";
import {ReactComponent as Map} from "./map.svg";
import { ReactComponent as HotelImage } from "./hotelImage.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as SuitCase } from "./suit-case.svg";

import config from "../../config.json";

const RenderSVG = ({
  Svg,
  alt = "",
  color = "",
  width = "20",
  height = "20",
  className = "",
  ...restProps
}) => (
  <Svg
    fill="currentColor"
    color={color || config.primaryColor}
    aria-label={alt}
    width={width}
    height={height}
    className={className}
    {...restProps}
  />
);

export {
  Aeroplane,
  Apple,
  ArrowLeft,
  Calendar,
  Check,
  ChevronDownPrimary,
  ChevronDown,
  Circle,
  Close,
  Cross,
  CurrencyDollar,
  ChevronDownDark,
  Facebook,
  GlobeAlt,
  Globe,
  Google,
  Home,
  LocationMarkerLight,
  LocationMarker,
  Minus,
  Mail,
  MailOutline,
  Logout,
  Plus,
  SearchIcon,
  Ticket,
  Users,
  ToggleDirection,
  TripTavaLogo,
  UserCircle,
  Menu,
  TripTavaWithBrand,
  Cloud,
  Lightning,
  CurrencyDollarDark,
  XCircle,
  CheckGreen,
  CheckDark,
  LeftArrow,
  RightArrow,
  LineSeparator,
  DotPattern,
  Checkbox,
  Filter,
  ExclamationTriangle,
  FlightIcon,
  FlightDarkIcon,
  HotelsIcon,
  HotelsDarkIcon,
  CopyToClipboard,
  VacationsIcon,
  VacationDarkIcon,
  TripTavaLogoMin,
  Phone,
  PhoneIcon,
  Information,
  SpinnerIcon,
  Trash,
  MenuVerticalIcon,
  CloseDark,
  ChevronUp,
  FilterResetIcon,
  TriangleExclaimation,
  Gender,
  LeftIcon,
  RightIcon,
  Stripe,
  Razorpay,
  PlusWhite,
  CheckWhite,
  Barcode,
  RewardsPoint,
  ExclamationCircleIcon,
  QuestionIcon,
  CheckCircleIcon,
  ShowPasswordIcon,
  HidePasswordIcon,
  ArrowRoundTrip,
  Bed,
  WhiteChevronDown,
  WhiteChevronUp,
  StarLight,
  Star,
  EditIcon,
  DiscountIcon,
  Baggage,
  FlightCancel,
  RenderSVG,
  SearchDarkIcon,
  Booking,
  Compass,
  History,
  ApiLogs,
  PendingIcon,
  ServiceIcon,
  EarningIcon,
  DownloadIcon,
  LogoutIcon,
  InflationIcon,
  Morning,
  Noon,
  Evening,
  BaggageIcon,
  Wave,
  IndianCoinIcon,
  ArrivalPlaneIcon,
  DeparturePlaneIcon,
  indianFlag,
  TicketSlash,
  bookingIcon,
  SortAscIncon,
  SortDescIcon,
  EmailIcon,
  KebabMenu,
  FreeLabel,
  ThreeDotsLoading,
  InvoiceIcon,
  CalendarOutline,
  TicketOutline,
  Dashboard,
  HotelTicket,
  CrossRed,
  StartLocationMarker,
  InformationRules,
  UserPlus,
  SlashTicket,
  noUserAdded,
  Lock,
  Confetti,
  CircledExclaimation,
  NoRooms,
  Meal,
  Refund,
  Seat,
  Map,
  Location,
  HotelImage,
  SuitCase
};
