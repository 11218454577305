import { useState } from "react";
import { get, unescape } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { getLeadGuestInfo, mapPaymentReq, loadScript } from "../../../helper";
import { selectCountryInfo } from "../../Profile";
import { RAZORPAY_CHECKOUT_SCRIPT_URL, ROUTES, BOOKING_STATUS, DEFAULT_VALUES } from "../../../constants";
import {
  processPayment,
  selectUserBookingInfo,
  setSelectedBooking,
} from "../index";
import {
  BookingSummary,
  BookingPricing,
  BookingImportantInfo,
  BookingLinks,
} from "../../../components/organisms/HotelBookingInfoSections";
import { Lock, Confetti, RenderSVG } from "../../../assets/icons";
import { HotelBookingInfoSkeleton } from "../../../components/organisms/AppSkeletons";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";

const { HOTEL_NON_VOUCHERED_PAYMENT } = ROUTES;
const { FETCH_USER_BOOKING_INFO, GENERATE_VOUCHER } = SPINNER_NAMES;
const { CANCELED, CONFIRMED } = BOOKING_STATUS;
const { EMPTY_ARRAY,ZERO,COMMA } = DEFAULT_VALUES;
const calculateAdultsAndChildren = (hotelRooms) => {
  let totalPassengers = {
    adults: 0,
    children: 0,
  };

  hotelRooms.forEach((room) => {
    const roomPassengers = room.hotelPassenger.reduce(
      (total, passenger) => {
        if (passenger?.paxType === 1) total.adults = total.adults + 1;
        else total.children = total.children + 1;
        return total;
      },
      { adults: 0, children: 0 }
    );

    totalPassengers = {
      adults: totalPassengers.adults + roomPassengers.adults,
      children: totalPassengers.children + roomPassengers.children,
    };
  });

  return totalPassengers;
};

const SuccessfulBookingHeader = ({
  confirmationNo,
  tavaBookingId,
  bookingStatus,
  email,
  paymentId,
  hotelName,
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative bg-teal-200">
      <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-teal-100/50"></div>
      <div className="container px-8 pt-8 pb-16 mx-auto relative">
        {paymentId ? (
          <div className="flex">
            <h4 className="text-contrast-900 font-bold mr-2 text-base sm:text-xl">
              Your Trip booking has been successful
            </h4>
          </div>
        ) : (
          <div>
            <div className="flex">
              <h4 className="text-xl text-contrast-900 font-bold mr-2">
                Your Trip booking has been successful
              </h4>
              <RenderSVG
                Svg={Confetti}
                className="w-[25px] h-[25px]"
                alt="confetti-icon"
              />
            </div>
            <p className="text-sm font-medium mb-5 text-contrast-600">
              Ensure confirming your booking by making payment before due date.
              Hope you have a great stay!
            </p>
          </div>
        )}
        <div className="flex items-center gap-2 mb-2">
          <p className="text-contrast-600 text-xs xs:text-sm">
            {t("bookingResult.confirmationNumber")}:{" "}
            <span className="font-semibold text-contrast-900 sm:mx-1">
              {tavaBookingId}
            </span>
          </p>
          <span className="font-semibold text-xs px-3 py-1 rounded-3xl text-teal-800 bg-teal-100">
            {bookingStatus}
          </span>
        </div>
      </div>
    </div>
  );
};

const UnsuccessfulBookingHeader = ({ tavaBookingId }) => {
  const { t } = useTranslation();

  return (
    <div className="relative bg-red-200">
      <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-red-900/50"></div>
      <div className="container px-8 pt-8 pb-16 mx-auto relative">
        <h4 className="text-lg xs:text-xl text-contrast-900 mb-1 font-bold">
          Booking Unsuccessful
        </h4>
        <div className="flex items-center gap-2 mb-2">
          <p className="text-contrast-600 font-semibold text-xs xs:text-sm">
            Tava Booking ID:{" "}
            <span className="font-semibold text-contrast-900 sm:mx-1">
              {tavaBookingId}
            </span>
          </p>
        </div>
        <div className="w-full text-sm xs:text-md bg-red-100 p-4 rounded-md text-red-600 my-1 font-medium">
          {t("bookingResult.failureMsg")}
        </div>
      </div>
    </div>
  );
};

const CanceledBookingHeader = ({ tavaBookingId }) => {
  const { t } = useTranslation();

  return (
    <div className="relative bg-[#fef3c7]">
      <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-red-900/50"></div>
      <div className="container px-8 pt-8 pb-16 mx-auto relative">
        <h4 className="text-xl mb-1 font-bold text-[#b45309]">
          Booking Canceled
        </h4>
        <div className="flex items-center gap-2 mb-2">
          <p className="text-contrast-600 font-semibold text-xs xs:text-sm">
            Tava Booking ID:{" "}
            <span className="mx-1 font-semibold text-[#b45309]"> 
              {tavaBookingId}
            </span>
          </p>
        </div>
        <div className="w-full text-md bg-custom-yellow p-4 rounded-md text-[#b45309]">
          {t("bookingResult.cancelationMsg")}
        </div>
      </div>
    </div>
  );
};



const HotelBookingInfo = ({
  booking,
  isSpinnerActive,
  selectedIsGenerateVoucherInitiated,
  selectedBooking,
  setIsSpinnerActive,
}) => {
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const userBookingInformation = useSelector(selectUserBookingInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPayNowSpinnerActive, setIsPayNowSpinnerActive] = useState(false);

  const {
    paymentId,
    bookingStatus,
    bookingId,
    confirmationNo,
    invoiceNo,
    bookingReqJson,
    blockRoomResJson,
    tavaBookingId,
    createdAt,
  } = booking;
  const type = get(userBookingInformation, "type", "");

  const hotelRoomsInfo = get(
    blockRoomResJson,
    "blockRoomResult.hotelRoomsDetails",
    []
  );
  const inclusions = hotelRoomsInfo.flatMap((room) => room.inclusion);
  const splittedInclusions = inclusions.flatMap((inc) => inc.split(","));
  const starRating = get(blockRoomResJson, "blockRoomResult.starRating", 0);
  const lastCancellationDate = get(
    blockRoomResJson,
    "blockRoomResult.hotelRoomsDetails[0].cancellationPolicies[0].fromDate",
    ""
  );
  const hotelName = get(bookingReqJson, "hotelName", "");
  const hotelCode = get(bookingReqJson, "hotelCode", "");
  const cancellationPolicies = get(hotelRoomsInfo, "0.cancellationPolicy", "");
  const cancellationMsgArr = cancellationPolicies
    ?.split("#^#")[1]
    ?.replaceAll("#!#", "")
    ?.split("|")
    ?.filter((item) => item && item !== "undefined")
    ?.map((msg) => (
      <li className="list-item" key={uuid()}>
        {msg}
      </li>
    ));

  const hotelPolicyInfo = get(
    blockRoomResJson,
    "blockRoomResult.hotelPolicyDetail",
    ""
  );
  const DayRates = get(
    blockRoomResJson,
    "blockRoomResult.hotelRoomsDetails[0].dayRates",
    []
  );
  const { profileDetails: leadGuest } = getLeadGuestInfo(
    get(bookingReqJson, "hotelRoomsDetails.0.hotelPassenger", [])
  );

  const addressLine1 = get(
    blockRoomResJson,
    "blockRoomResult.addressLine1",
    ""
  );
  const addressLine2 = get(
    blockRoomResJson,
    "blockRoomResult.addressLine2",
    ""
  );
  const getHotelImages = () => {
    const hotelImage = get(
      blockRoomResJson,
      "blockRoomResult.hotelImage",
      EMPTY_ARRAY
    );
    return Array.isArray(hotelImage)
      ? hotelImage
      : typeof hotelImage === "string"
      ? [hotelImage]
      : EMPTY_ARRAY;
  };
  const hotelImages = getHotelImages();
  
  const { title, firstName, lastName, email } = leadGuest;
  const paymentInfo = mapPaymentReq({
    userCountryInfo: selectedCountryInfo,
    blockRoomRes: blockRoomResJson.blockRoomResult,
    leadGuest,
  });
  const { amount, currency } = paymentInfo || {};

  const { adults, children } = calculateAdultsAndChildren(
    get(bookingReqJson, "hotelRoomsDetails", [])
  );
  const noOfRooms = hotelRoomsInfo.length;
  const isBookingSuccessful = bookingStatus.toUpperCase() === CONFIRMED;
  const decodedHotelInfo = unescape(hotelPolicyInfo);
  const initiatePayment = async () => {
    dispatch(setSelectedBooking(booking));
    const loadedScript = await loadScript(RAZORPAY_CHECKOUT_SCRIPT_URL);
    if (!loadedScript) return;

    setIsPayNowSpinnerActive(true);

    dispatch(processPayment({ body: paymentInfo })).then(() => {
      setIsPayNowSpinnerActive(false);
      navigate(HOTEL_NON_VOUCHERED_PAYMENT.replace(":hotelId", hotelCode));
    });
  };

  const hotelInfo = {
    hotelBookingStatus: bookingStatus,
    addressLine1,
    addressLine2,
    hotelImages,
    hotelName,
    hotelCode,
    starRating,
    cancellationMsgArr,
    decodedHotelInfo,
  };
  const bookingInfo = {
    lastCancellationDate,
    tavaBookingId,
    bookingId,
    paymentId,
    invoiceNo,
    noOfRooms,
    amount,
    currency,
    splittedInclusions,
    dayRates: DayRates,
    createdAt
  };
  const customerInfo = {
    firstName,
    lastName,
    title,
    email,
    adults,
    children,
  };

  return (
    <Spinner
      name={[FETCH_USER_BOOKING_INFO, GENERATE_VOUCHER]}
      loaderComponent={
        <HotelBookingInfoSkeleton
          isGenerateVoucherInitiated={selectedIsGenerateVoucherInitiated}
          selectedBooking={selectedBooking}
        />
      }
      setIsSpinnerActive={setIsSpinnerActive}
    >
      <div className="relative z-0">
        {isBookingSuccessful ? (
          <SuccessfulBookingHeader
            confirmationNo={confirmationNo}
            tavaBookingId={tavaBookingId}
            bookingStatus={bookingStatus}
            email={email}
            paymentId={paymentId}
            hotelName={hotelName}
          />
        ) : (
            bookingStatus === CANCELED ? <CanceledBookingHeader tavaBookingId={tavaBookingId} /> : <UnsuccessfulBookingHeader tavaBookingId={tavaBookingId} />
        )}
        <div className="pb-16 relative">
          <div className="container px-8 mx-auto -mt-8">
            <div className="grid grid-cols-12 gap-4 sm:gap-8">
              <div className="col-span-12 xl:col-span-8 flex flex-col gap-4">
                <div className="flex flex-col gap-8">
                  <BookingSummary
                    hotelInfo={hotelInfo}
                    bookingInfo={bookingInfo}
                    customerInfo={customerInfo}
                    isVoucherBooking={paymentId}
                    isSpinnerActive={isSpinnerActive}
                    isPayNowSpinnerActive={isPayNowSpinnerActive}
                    initiatePayment={initiatePayment}
                    showPayNowOption={!paymentId}
                    bookingStatus={isBookingSuccessful}
                    type={type}
                  />
                </div>
                <BookingPricing
                  bookingInfo={bookingInfo}
                  hotelRoomsInfo={hotelRoomsInfo}
                  customerInfo={customerInfo}
                />
                <BookingImportantInfo decodedHotelInfo={decodedHotelInfo} />
              </div>
              <BookingLinks
                hotelBookingStatus={bookingStatus}
                isSpinnerActive={isSpinnerActive}
                tavaBookingId={tavaBookingId}
                isVoucherBooking={paymentId}
              />
            </div>
          </div>
        </div>
      </div>
    </Spinner>
  );
};

export default HotelBookingInfo;
