import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelectedModal } from "../../../components/organisms/Modal";
import { TripTavaLogo, DotPattern, RenderSVG } from "../../../assets/icons";

const WelcomeModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleModalClose = () => dispatch(setSelectedModal(null));

  return (
    <div className="modal fixed bg-contrast-900/80 w-full h-full start-0 top-0 z-50 p-3 sm:p-6 overflow-auto">
      <div className="modal-dialog mx-auto bg-white rounded-lg shadow-md max-w-2xl overflow-hidden">
        <div className="relative">
          <div className="z-9 absolute -right-[100px] -top-[140px]">
            <RenderSVG Svg={DotPattern} width="241" height="402" />
          </div>
          <div className="py-10 z-10 relative">
            <div className="modal-header flex gap-4 items-center justify-center">
              <div className="logo flex-shrink-0">
                <RenderSVG
                  Svg={TripTavaLogo}
                  className="w-[60px] h-[60px] mb-20"
                  alt="login-bg"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center px-6 w-full h-full">
              <div className="flex flex-col justify-start items-center gap-6 mt-70">
                <div className="text-3xl font-bold sm:text-4xl">
                  {t("loginAndSignup.welcome.title")}
                </div>
                <p className="text-md text-contrast-600 sm:text-xl">
                  {t("loginAndSignup.welcome.subtitle")}
                </p>
              </div>
              <div className="justify-center w-full items-center top-0 mt-20">
                <div>
                  <button
                    type="submit"
                    className="bg-primary-600 rounded-md text-white w-full text-center mb-2 h-10"
                    onClick={handleModalClose}
                  >
                    {t("loginAndSignup.welcome.getStarted")}
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    className="border-2 rounded-md text-grey-400 w-full text-center mt-2 h-10 bg-white"
                    onClick={handleModalClose}
                  >
                    {t("loginAndSignup.welcome.setUpLater")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="z-9 absolute -bottom-20 -left-[100px]">
            <RenderSVG Svg={DotPattern} width="241" height="402" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
