export const invoiceDownload = (base64Data, tripDetails) => {
    const bytes = Uint8Array.from(atob(base64Data), char => char.charCodeAt(0));

    const blob = new Blob([bytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${tripDetails}.pdf`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };
