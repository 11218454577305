import { RenderSVG, Star, StarLight } from "../assets/icons";

const MAX_STAR_RATING = 5;

export const renderStars = (rating) => {
  const filledStars = Math.round(rating);
  return (
    <div className="flex items-end sm:gap-1">
      {Array.from({ length: MAX_STAR_RATING }, (_, index) => {
        const isFilled = index < filledStars;
        return (
          <RenderSVG
            key={index}
            Svg={isFilled ? Star : StarLight}
            alt="Star icon"
            className="w-2 sm:w-3 inline"
          />
        );
      })}
    </div>
  );
};
