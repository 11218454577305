import { get } from "lodash";
import { v4 as uuid } from "uuid";
import { generateTavaBookingId } from "./generateBookingId";
import { mapGuestDetails } from "./mapGuestDetails";
import {
  DEFAULT_VALUES,
  DEFAULT_CURRENCY_CODE,
  BOOKING_TYPES,
  DEFAULT_CURRENCY,
  CACHE_KEYS,
  TAVATRIP_FEE,
} from "../constants";
import { getPriceWithConversion } from "./priceFormatter";
import { getFromSessionStorage, getSurchargesAmount } from "./index";

const { ZERO, ONE, EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { NON_VOUCHER_BOOKING } = BOOKING_TYPES;
const { CURRENCY_INFO } = CACHE_KEYS;

export const mapPaymentReq = ({ hotelCurrency, blockRoomRes, leadGuest }) => {
  const code = get(hotelCurrency, "code", DEFAULT_CURRENCY_CODE);
  const { exchangeRate } = getFromSessionStorage(CURRENCY_INFO) || DEFAULT_CURRENCY;

  const baseURL = window.location.href.replace(
    window.location.pathname,
    EMPTY_STRING
  );
  const roomDetails = get(
    blockRoomRes,
    "hotelRoomsDetails",
    EMPTY_ARRAY
  );
  const totalPublishedPrice = roomDetails.reduce(
    (total, room) => 
      total + 
      (room.price.roomPrice || ZERO) + 
      (room.price.tax || ZERO) + 
      (room.price?.otherCharges || ZERO) + 
      (room.price.tavaMarkup || ZERO),
    ZERO
  );
  const totalAmount =
  totalPublishedPrice + getSurchargesAmount(totalPublishedPrice) + TAVATRIP_FEE; 
  const {
    email,
    phoneNumber: contactNo,
    firstName: name,
  } = get(leadGuest, "profileDetails", EMPTY_OBJECT);

  return {
    division: "HOTEL",
    successUrl: `${baseURL}/booking-confirmed`,
    cancelUrl: `${baseURL}/booking-failed`,
    lineItems: [
      {
        price_data: {
          currency: code,
          product_data: {
            name,
            images: EMPTY_ARRAY,
            description: EMPTY_STRING,
            metadata: {
              id: ONE.toString(),
            },
          },
          unit_amount: (totalAmount * 100).toString(),
        },
        quantity: ONE,
      },
    ],
    selectedPaymentPortal: "RAZORPAY",
    cardInfo: {
      billerDetails: {
        billerAddress: EMPTY_STRING,
        billerCity: EMPTY_STRING,
        billerCountry: EMPTY_STRING,
        billerPin: EMPTY_STRING,
        billerState: EMPTY_STRING,
      },
      cardDetails: {
        number: EMPTY_STRING,
        name: EMPTY_STRING,
        expiry_month: EMPTY_STRING,
        expiry_year: EMPTY_STRING,
        cvv: EMPTY_STRING,
      },
      fetchBinFailed: null,
      saveCard: null,
    },
    checkoutId: EMPTY_STRING,
    payMode: EMPTY_STRING,
    payOption: EMPTY_STRING,
    networkConsent: false,
    deviceFingerPrintID: EMPTY_STRING,
    mcid: EMPTY_STRING,
    enforceOtpOnPage: false,
    version: "1.5",
    msgId: EMPTY_STRING,
    operation: "PAYMENT_INIT",
    requestDate: new Date().toISOString().split(".")[ZERO],
    validateOnly: false,
    requestData: {
      clientId: "14000004",
      clientIdHash: EMPTY_STRING,
      transactionType: "PURCHASE",
      transactionAmount: {
        totalAmount: totalAmount,
        paymentAmount: totalAmount,
        serviceFeeAmount: ZERO,
        currency: code,
      },
      redirect: {
        returnUrl: EMPTY_STRING,
        cancelUrl: EMPTY_STRING,
        returnMethod: "GET",
      },
      clientRef: uuid(),
      comment: EMPTY_STRING,
      tokenize: true,
      cssLocation1: EMPTY_STRING,
      cssLocation2: EMPTY_STRING,
      useReliability: true,
      "extraData ": "{1,2,3}",
    },
    amount: totalAmount?.toFixed(2),
    currency: code,
    email,
    contactNo,
  };
};

const mapBookingRequest = ({
  pricePolicyReqBody,
  leadGuest,
  otherGuests,
  isSamePanForAllAllowed,
  isPANRequired,
  roomGuests,
  loggedInUserEmail,
  hotelCurrency,
  totalPublishedPrice,
}) => {
  const tavaBookingId = generateTavaBookingId();
  const currency = get(hotelCurrency, "code", DEFAULT_CURRENCY_CODE);
  const endUserIp = get(pricePolicyReqBody, "endUserIp", EMPTY_STRING);
  const traceId = get(pricePolicyReqBody, "traceId", EMPTY_STRING);
  const resultIndex = get(pricePolicyReqBody, "resultIndex", ZERO);
  const hotelCode = get(pricePolicyReqBody, "hotelCode", ZERO);
  const categoryId = get(pricePolicyReqBody, "categoryId", EMPTY_STRING);
  const hotelName = get(pricePolicyReqBody, "hotelName", ZERO);
  const guestNationality = get(pricePolicyReqBody, "guestNationality", ZERO);
  const noOfRooms = get(pricePolicyReqBody, "noOfRooms", ZERO);
  const isVoucherBooking = get(pricePolicyReqBody, "isVoucherBooking", ZERO);
  let hotelRoomsDetails = get(pricePolicyReqBody, "hotelRoomsDetails", EMPTY_ARRAY);
  hotelRoomsDetails = hotelRoomsDetails.map((room, index) => ({
    ...room,
    hotelPassenger: mapGuestDetails(
      leadGuest,
      otherGuests,
      isSamePanForAllAllowed,
      isPANRequired,
      roomGuests,
      index
    ),
  }));

  const accountEmail =
    loggedInUserEmail || get(leadGuest, "profileDetails.email", EMPTY_STRING);
  const country = get(
    leadGuest,
    "profileDetails.nationality.name",
    EMPTY_STRING
  );
  const countryCode = get(
    leadGuest,
    "profileDetails.nationality.isoCode",
    EMPTY_STRING
  );
  const state = get(leadGuest, "profileDetails.state", EMPTY_STRING);

  const paymentDetails = {
    totalPrice: parseFloat(totalPublishedPrice.toFixed(2)),
    country,
    countryCode,
    state,
    currency,
  };

  return {
    tavaBookingId: tavaBookingId,
    accountEmail,
    resultIndex,
    hotelCode,
    hotelName,
    guestNationality,
    noOfRooms,
    isVoucherBooking,
    categoryId,
    endUserIp,
    traceId,
    hotelRoomsDetails,
    paymentDetails,
  };
};

export const mapHotelBookingAPIReq = ({
  pricePolicyReqBody,
  pricePolicyInfo,
  hotelCurrency,
  voucherBookingType,
  leadGuest,
  otherGuests,
  isSamePanForAllAllowed,
  isPANRequired,
  roomGuests,
  loggedInUserEmail,
  hotelImage,
}) => {
  if (voucherBookingType === NON_VOUCHER_BOOKING) {
    pricePolicyReqBody = { ...pricePolicyReqBody, isVoucherBooking: "false" };
  }
  const roomDetails = get(pricePolicyInfo, "blockRoomResult.hotelRoomsDetails", EMPTY_ARRAY);
  const totalPublishedPrice = roomDetails.reduce(
    (total, room) => 
      total + 
      (room.price.roomPrice || ZERO) + 
      (room.price.tax || ZERO) + 
      (room.price?.otherCharges || ZERO) + 
      (room.price.tavaMarkup || ZERO),
    ZERO
  );

  const blockRoomResult = pricePolicyInfo.blockRoomResult;
  const updateBlockRoomResult = { ...blockRoomResult, hotelImage };

  return {
    paymentRequest: mapPaymentReq({
      hotelCurrency,
      blockRoomRes: updateBlockRoomResult,
      leadGuest,
    }),
    bookingRequest: mapBookingRequest({
      pricePolicyReqBody,
      leadGuest,
      otherGuests,
      isSamePanForAllAllowed,
      isPANRequired,
      roomGuests,
      loggedInUserEmail,
      hotelCurrency,
      totalPublishedPrice,
    }),
    blockRoomReq: pricePolicyReqBody,
    blockRoomRes: { blockRoomResult: updateBlockRoomResult },
  };
};

export const mapGenericBookingRequest = ({ bookingType, ...rest }) => ({
  hotelBookingRequest: mapHotelBookingAPIReq({ ...rest }),
  bookingType,
});
