import Drawer from "./Drawer";
import * as slice from "./drawers.reducers";
import * as selectors from "./drawers.selectors";

export const {
  name,
  reducer,
  actions: { setSelectedDrawer, setDrawerData },
} = slice;

export const { selectSelectedDrawer } = selectors;

export default Drawer;
