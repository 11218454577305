import { createSlice } from "@reduxjs/toolkit";

import { DEFAULT_VALUES } from "../../../../src/constants";
import { SPINNER_NAMES } from "./spinner.constants";
const { ONE } = DEFAULT_VALUES;
const { PENDING } = SPINNER_NAMES;
const initialState = { spinners: [PENDING] };

const slice = createSlice({
  name: "spinner",
  initialState,
  reducers: {
    addSpinner(state, action) {
      if (action.payload) state.spinners = [...state.spinners, action.payload];
    },
    removeSpinner(state, action) {
      if (action.payload) {
        const spinnerIndex = state.spinners.indexOf(action.payload);
        if (spinnerIndex !== -ONE) state.spinners.splice(spinnerIndex, ONE);
      }
    },
    setSpinner(state, action) {
      if (action.payload) return action.payload;
    },
  },
});

export default slice;

export const { name, actions, reducer } = slice;
