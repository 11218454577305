import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "../../Modal";
import { selectSearchFilters } from "../../Search";
import { getQueryParams } from "../../../../helper";
import { RenderSVG, BaggageIcon } from "../../../../assets/icons";
import { ROUTES } from "../../../../constants";

const { FLIGHT_RESULTS } = ROUTES;

const DetailsChangeModal = ({ handleClose = () => {}, message = "" }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const activeFilters = useSelector(selectSearchFilters);

  return (
    <div className="overflow-auto">
      <Modal shouldShowModalFromProps handleClose={handleClose}>
        <div className="flex justify-center items-center py-4 mt-4">
          <RenderSVG
            Svg={BaggageIcon}
            alt="Inflation Icon"
            height="100"
            width="100"
          />
        </div>
        <div className="flex justify-center items-center py-8 p-10">
          <div className="text-center text-xl min-w-[65px]">{message}</div>
        </div>
        <div className="flex justify-center items-center px-10 gap-4 mb-4">
          <div className="py-4 text-center">
            <button
              className="btn bg-transparent border border-contrast-400 hover:opacity-90"
              onClick={() =>
                navigate(`${FLIGHT_RESULTS}?${getQueryParams(activeFilters)}`)
              }
            >
              {t("baggageModal.searchAgain")}
            </button>
          </div>
          <div className="py-4 text-center">
            <button className="btn btn-primary" onClick={() => handleClose()}>
              {t("travelerInfo.continueButton.continue")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const FlightDetailChangeInfoModal = ({
  handleClose,
  changeInBaggage,
  baggageUnit,
  hasTimeChanged,
  currentTime,
}) => {
  const { t } = useTranslation();
  const baggageMessage = t("detailsChangeModal.baggageChangeMessage", {
    changeInBaggageWeight: Math.abs(changeInBaggage),
    changeInBaggageUnit: baggageUnit,
  });
  const timeMessage = t("detailsChangeModal.timeChangeMessage", {
    currentTime,
  });
  return (
    <>
      {changeInBaggage && (
        <DetailsChangeModal
          handleClose={handleClose}
          message={baggageMessage}
        />
      )}
      {hasTimeChanged && (
        <DetailsChangeModal handleClose={handleClose} message={timeMessage} />
      )}
    </>
  );
};

export default FlightDetailChangeInfoModal;
