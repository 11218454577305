import { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get, isBoolean, isString } from "lodash";
import { priceFormatter } from "../../helper";
import { selectCountryInfo } from "../../screens/Profile";
import { COLORS } from "../../../src/colors";
import {
  Refund,
  CalendarOutline,
  SlashTicket,
  RenderSVG,
  Seat,
} from "../../assets/icons";
import {
  REFUNDABILITY_CODES,
  CURRENCY_SYMBOLS,
  DEFAULT_VALUES,
} from "../../constants";

const { NON_REFUNDABLE, PENALTY_REFUNDABLE, PERCENT_PENALTY_REFUNDABLE } =
  REFUNDABILITY_CODES;
const { ZERO, ONE, TWO } = DEFAULT_VALUES;
const { BlueGreen, SmokeyTopaz, PapayaWhip, AeroBlue } = COLORS;
const CANCELLATION = "Cancellation";
const REISSUE = "Reissue";
const priceRegex = /([A-Z]{3})\s+(\d+)/;

const getNoOfSeats = (noOfAvailableSeats) => {
  if (noOfAvailableSeats)
    return `${noOfAvailableSeats} seat${
      noOfAvailableSeats > ONE ? "s" : ""
    } left`;
};

const SeatsLeftTag = ({ noOfAvailableSeats }) => {
  return (
    !!noOfAvailableSeats && (
      <div className="text-[10px] h-fit font-semibold whitespace-nowrap bg-white text-red-500 rounded-2xl py-[1px] px-2 flex items-center border border-red-500">
        <RenderSVG Svg={Seat} width="12" height="12" alt="seat" />
        <span className="ml-1">{getNoOfSeats(noOfAvailableSeats)}</span>
      </div>
    )
  );
};

const FlightDetailsTag = ({
  refundableStatus,
  miniFareRules,
  noOfAvailableSeats = ZERO,
}) => {
  const { t } = useTranslation();
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencySymbol = get(
    selectedCountryInfo,
    "currency.symbol",
    CURRENCY_SYMBOLS.INR
  );
  const tooltipContent =
    refundableStatus === PENALTY_REFUNDABLE
      ? t("flightResults.penaltyRefundable")
      : t("flightResults.percentPenaltyRefundable");

  const findLowestCharge = (rule, lowestCharge) => {
    const match = rule?.Details.match(priceRegex);
    if (match && !isNaN(parseFloat(match[TWO]))) {
      const chargeAmount = parseFloat(match[TWO]);
      lowestCharge = Math.min(chargeAmount, lowestCharge);
    }
    return lowestCharge;
  };

  if (isBoolean(refundableStatus)) {
    let lowestCancellationCharge = Infinity;
    let lowestReissueCharge = Infinity;

    if (miniFareRules && miniFareRules.length > 0) {
      miniFareRules.flat().forEach((rule) => {
        if (rule?.Type === CANCELLATION) {
          lowestCancellationCharge = findLowestCharge(
            rule,
            lowestCancellationCharge
          );
        } else if (rule?.Type === REISSUE) {
          lowestReissueCharge = findLowestCharge(rule, lowestReissueCharge);
        }
      });
    }

    return (
      <>
        {!refundableStatus && (
          <div
            className={`h-fit gap-1 text-[10px] text-[${SmokeyTopaz}] bg-[${PapayaWhip}] font-medium rounded-2xl py-0.5 px-2 flex items-center w-fit`}
          >
            <RenderSVG
              Svg={Refund}
              width="14"
              height="14"
              className={`text-[${SmokeyTopaz}]`}
              alt="refund"
            />
            {t("flightResults.nonRefundable")}
          </div>
        )}
        {lowestCancellationCharge < Infinity && (
          <div
            className={`h-fit gap-1 text-[10px] font-medium rounded-2xl text-[${BlueGreen}] bg-[${AeroBlue}] py-0.5 px-2 flex items-center w-fit`}
          >
            <RenderSVG
              Svg={SlashTicket}
              width="14"
              height="14"
              fill="none"
              className={`text-[${BlueGreen}]`}
              alt="cancel"
            />
            <span className="hidden sm:inline">
              {t("flightResults.cancellationCharges")}&nbsp;
            </span>
            {currencySymbol}&nbsp;
            {priceFormatter(parseFloat(lowestCancellationCharge))}
          </div>
        )}
        {lowestReissueCharge < Infinity && (
          <div className="text-[10px] h-fit font-semibold whitespace-nowrap bg-blue-100 text-blue-900 rounded-2xl py-0.5 px-2 flex items-center">
            <RenderSVG
              Svg={CalendarOutline}
              width="14"
              height="14"
              className="text-blue-900"
              alt="reissue"
            />
            <span className="ml-1">
              {" "}
              <span className="hidden sm:inline">
                {t("flightResults.dateChangeCharges")}&nbsp;
              </span>
              {currencySymbol}&nbsp;
              {priceFormatter(parseFloat(lowestReissueCharge))}
            </span>
          </div>
        )}
        <SeatsLeftTag noOfAvailableSeats={noOfAvailableSeats} />
      </>
    );
  } else if (isString(refundableStatus)) {
    if (refundableStatus === NON_REFUNDABLE)
      return (
        <>
          <div
            className={`h-fit text-[10px] gap-1 font-medium text-[#7C2D12] rounded-2xl py-0.5 px-2 flex items-center w-fit bg-[${PapayaWhip}]`}
          >
            <RenderSVG
              Svg={Refund}
              width="14"
              height="14"
              alt="refund"
              className={`text-[${SmokeyTopaz}]`}
            />
            {t("flightResults.nonRefundable")}
          </div>
          <SeatsLeftTag noOfAvailableSeats={noOfAvailableSeats} />
        </>
      );
    else if (
      refundableStatus === PENALTY_REFUNDABLE ||
      refundableStatus === PERCENT_PENALTY_REFUNDABLE
    )
      return (
        <>
          <div
            className="text-[10px] gap-1 h-fit font-medium bg-[#D1FAE5] text-[#064E3B] rounded-2xl py-0.5 px-2 flex items-center w-fit"
            data-tooltip-id="refundability-tag"
            data-tooltip-content={tooltipContent}
          >
            <RenderSVG
              Svg={Refund}
              width="14"
              height="14"
              alt="refund"
              fill={BlueGreen}
              stroke={BlueGreen}
              className={`text-[${BlueGreen}]`}
            />
            <span className={`hidden md:block text-[${BlueGreen}]`}>
              {t("flightResults.refundable")}*
            </span>
            <span className="block md:hidden">{tooltipContent}</span>
          </div>
          <SeatsLeftTag noOfAvailableSeats={noOfAvailableSeats} />
        </>
      );
  }
};

export default memo(FlightDetailsTag);
