import { useSelector } from "react-redux";
import get from "lodash/get";
import { getFormattedKey, priceFormatter } from "../../../helper";
import {
  CURRENCY_SYMBOLS,
  DEFAULT_VALUES,
} from "../../../constants";
import { selectCountryInfo } from "../../../screens/Profile";

const { ZERO } = DEFAULT_VALUES;

const PricingSummaryCard = ({ pricingInfo }) => {
  const basePrice = get(pricingInfo, "basePrice", ZERO);
  const grandTotal = get(pricingInfo, "totalPrice", ZERO);
  const taxesAndCharges = get(pricingInfo, "taxPrice", ZERO);
  const details = { grandTotal, basePrice, taxesAndCharges };
  const selectedCountryInfo = useSelector(selectCountryInfo);
  
  return (
    <div className="bg-white rounded-lg pb-2">
      <div className="font-semibold border-b-[1px] p-2">Fare Details</div>
      {Object.entries(details).map(([key, value]) => (
        <div className="flex px-4 justify-between items-center py-2" key={key}>
          <div className="font-semibold text-base md:text-sm whitespace-nowrap">
            {getFormattedKey(key)}
          </div>
          <div className="font-semibold text-base md:text-sm">
            {get(selectedCountryInfo, "currency.symbol", CURRENCY_SYMBOLS.INR)}
            {priceFormatter(parseFloat(value))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingSummaryCard;
