import atmIcon from "./atm.svg";
import cabServiceIcon from "./cabService.svg";
import checkInIcon from "./checkIn.svg";
import conciergeDeskIcon from "./conciergeDesk.svg";
import giftShopIcon from "./giftShop.svg";
import laundryIcon from "./laundry.svg";
import meetingRoomIcon from "./meetingRoom.svg";
import parkingIcon from "./parking.svg";
import pickUpIcon from "./pickup.svg";
import terraceIcon from "./terrace.svg";
import tvIcon from "./tv.svg";
import wifiIcon from "./wifi.svg";
import notFoundIcon from "./notFound.svg";
import roomServiceIcon from "./roomService.svg";
import elevatorIcon from "./elevator.svg";
import noSmokingIcon from "./noSmoking.svg";
import safeDepositIcon from "./safeDeposit.svg";
import breakfastIcon from "./breakfast.svg";
import twentyFourHoursIcon from "./24Hours.svg";
import porterIcon from "./porter.svg";
import travlerIcon from "./traveler.svg";
import baggageStorageIcon from "./baggageStorage.svg";
import airportTransferIcon from "./airportTransfer.svg";
import shuttleIcon from "./shuttle.svg";
import cutomerAssistanceIcon from "./customerAssistance.svg";
import newspaperIcon from "./newspaper.svg";
import wheelchairIcon from "./wheelchair.svg";
import visa from "./visa.svg";
import hairDryer from "./hairDryer.svg";
import fan from "./fan.svg"
import masterCard from "./masterCard.svg"
import americanExpress from "./americanExpress.svg"
import bathroom from "./bathroom.svg"
import toiletries from "./toiletries.svg"
import iron from "./iron.svg"
import shower from "./shower.svg"
import garden from "./garden.svg"
import smokingArea from "./smokingArea.svg"
import noPets from "./noPets.svg"
import miniBar from "./miniBar.svg"
import airCondition from "./airCondition.svg"
import firePlace from "./firePlace.svg"
import bed from "./bed.svg"
import writingDesk from "./writingDesk.svg"
import golf from "./golf.svg"
import firstAidKit from "./firstAidKit.svg"
import bulletPoint from "./bullet_point.svg";
import coffeeTea from "./coffeeTea.svg";
import refrigerator from "./refrigerator.svg";
import library from "./library.svg";
import water_dispenser from "./water-dispenser.svg";
import barber_shop from "./barber_shop.svg";

const amenitiesMapping = {
  "in-room accessibility": roomServiceIcon,
  wifi: wifiIcon,
  "car service": cabServiceIcon,
  television: tvIcon,
  tv: tvIcon,
  "meeting room": meetingRoomIcon,
  conference: meetingRoomIcon,
  business: meetingRoomIcon,
  "check-in": checkInIcon,
  parking: parkingIcon,
  laundry: laundryIcon,
  shops: giftShopIcon,
  services: conciergeDeskIcon,
  atm: atmIcon,
  terrace: terraceIcon,
  pickup: pickUpIcon,
  elevator: elevatorIcon,
  "smoke-free": noSmokingIcon,
  "safe-deposit": safeDepositIcon,
  breakfast: breakfastIcon,
  "24-hour": twentyFourHoursIcon,
  porter: porterIcon,
  bellhop: porterIcon,
  "check-out": travlerIcon,
  luggage: baggageStorageIcon,
  airport: airportTransferIcon,
  shuttle: shuttleIcon,
  assistance: cutomerAssistanceIcon,
  newspaper: newspaperIcon,
  wheelchair: wheelchairIcon,
  visa: visa,
  "hair dryer": hairDryer,
  fan: fan,
  mastercard: masterCard,
  "american express": americanExpress,
  bathroom: bathroom,
  toiletries: toiletries,
  iron: iron,
  internet: wifiIcon,
  shower: shower,
  garden: garden,
  "smoking area": smokingArea,
  "pets not": noPets,
  minibar: miniBar,
  "air condition":airCondition,
  fireplace: firePlace,
  "concierge desk": conciergeDeskIcon,
  bed: bed,
  "front desk": conciergeDeskIcon,
  "writing desk": writingDesk,
  "tour desk": conciergeDeskIcon,
  golf: golf,
  lift: elevatorIcon,
  "room service": roomServiceIcon,
  "dry cleaning": laundryIcon,
  "first aid kit": firstAidKit,
  "coffee/tea in common areas":coffeeTea,
  "shared refrigerator": refrigerator,
  library:library,
  "water dispenser":water_dispenser,
  "hair salon":barber_shop
};

export const getAmenityIcon = (label) => {
  let mappedIcon;
  Object.entries(amenitiesMapping).forEach(([key, icon]) => {
    if (label.toLowerCase().includes(key)) mappedIcon = icon;
  });
  return mappedIcon || bulletPoint;
};
