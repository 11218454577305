import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal, { setSelectedModal } from "../../Modal";
import { RenderSVG, TriangleExclaimation } from "../../../../assets/icons";
import { ROUTES } from "../../../../constants";

const { HOME } = ROUTES;

const APIFailureModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSearchAgainAction = () => {
    navigate(HOME);
    dispatch(setSelectedModal());
  };

  return (
    <Modal>
      <div className="modal fixed bg-contrast-900/80 w-full h-full start-0 top-0 z-50 p-3 sm:p-6 overflow-auto">
        <div className="modal-dialog mx-auto bg-white rounded-lg shadow-md max-w-xl overflow-hidden mt-40">
          <div className="relative">
            <div className="py-10 z-10 relative">
              <div className="modal-header flex gap-4 items-center justify-center">
                <div className="logo flex-shrink-0">
                  <RenderSVG
                    Svg={TriangleExclaimation}
                    alt="Exclamation-Icon"
                    className="h-20 w-20"
                    color="#FF0000"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center items-center px-6 w-full h-full">
                <div className="flex flex-col justify-start items-center text-center mt-10">
                  <div className="text-2xl font-bold">
                    {t("apiFailureResponse.mainText")}
                  </div>
                  <p className="text-sm text-contrast-600">
                    {t("apiFailureResponse.subText")}
                  </p>
                </div>
                <div className="justify-center w-full items-center top-0 mt-10">
                  <div>
                    <button
                      type="submit"
                      className="bg-primary-600 rounded-md text-white w-full text-center mb-2 h-10"
                      onClick={handleSearchAgainAction}
                    >
                      {t("apiFailureResponse.actionButtonText")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default APIFailureModal;
