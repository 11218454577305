import { TRAVELER_TYPE, DEFAULT_VALUES } from "../constants";

const { ADULT, CHILD, HELD_INFANT } = TRAVELER_TYPE;
const { ZERO, TWO, TWELVE } = DEFAULT_VALUES;
const ELEVEN = 11;
const THIRTY_ONE = 31;

export const getTravelerType = (dateOfBirth) => {
  const dob = new Date(dateOfBirth);
  const dobYear = dob.getYear();
  const dobMonth = dob.getMonth();
  const dobDate = dob.getDate();
  const now = new Date();
  const currentYear = now.getYear();
  const currentMonth = now.getMonth();
  const currentDate = now.getDate();
  let yearAge = currentYear - dobYear;
  let monthAge = ZERO;
  let dateAge = ZERO;

  if (currentMonth >= dobMonth) monthAge = currentMonth - dobMonth;
  else {
    yearAge--;
    monthAge = TWELVE + currentMonth - dobMonth;
  }
  if (currentDate >= dobDate) dateAge = currentDate - dobDate;
  else {
    monthAge--;
    dateAge = THIRTY_ONE + currentDate - dobDate;

    if (monthAge < ZERO) {
      monthAge = ELEVEN;
      yearAge--;
    }
  }
  if (
    yearAge > TWELVE ||
    (yearAge === TWELVE && monthAge > ZERO) ||
    (yearAge === TWELVE && monthAge === ZERO && dateAge > ZERO)
  )
    return ADULT;
  else if (yearAge >= TWO) return CHILD;
  else return HELD_INFANT;
};
