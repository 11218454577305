import get from "lodash/get";
import { DEFAULT_VALUES } from "../constants";
import { getFormattedDate } from "./getDateTime";

const { EMPTY_STRING, EMPTY_ARRAY, ZERO } = DEFAULT_VALUES;

const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;

export const getTimeTravel = (segments) => {
  const timeTravel = segments.reduce((duration, segment) => {
    const arrival = get(segment, "arrival", EMPTY_STRING);
    const departure = get(segment, "departure", EMPTY_STRING);
    const arrivalTimestamp = new Date(
      `${getFormattedDate(arrival.date)}T${arrival.time}`
    );
    const departureTimestamp = new Date(
      `${getFormattedDate(departure.date)}T${departure.time}`
    );
    const diff = arrivalTimestamp - departureTimestamp;
    const days = Math.floor(diff / (24 * MILLISECONDS_IN_HOUR));
    const hours = Math.floor(diff / MILLISECONDS_IN_HOUR);
    const minutes = Math.floor(
      (diff % MILLISECONDS_IN_HOUR) / (MILLISECONDS_IN_HOUR / 60)
    );

    const travelTime =
      (days > ZERO ? `${days}D ` : EMPTY_STRING) +
      (hours > ZERO ? `${hours}H ` : EMPTY_STRING) +
      (minutes > ZERO ? `${minutes}M` : EMPTY_STRING);
    return [...duration, ...(travelTime && [travelTime])];
  }, EMPTY_ARRAY);
  return timeTravel;
};
