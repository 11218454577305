import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { FARE_TYPES, FARE_DETAILS } from "../../../constants";
import { Tooltip } from "react-tooltip";
import logEvent from "../../../utils/GATracker";

const REGULAR = "REGULAR";

const FareTypeCard = ({
  name,
  label,
  isChecked = false,
  handleFareTypeChange,
  isHomePage,
  translationHandler
}) => {
  const tooltipContent = translationHandler(FARE_DETAILS[name]);

  return (
    <div>
      <Tooltip
        id="fareRule-tooltip"
        className="!w-56 !sm:w-72 !bg-primary-600 !rounded-lg !z-10"
        render={({ content }) => (
          <span className="text-xs text-justify">{content}</span>
        )}
      />
      <div
        className="flex gap-1 items-center px-1 py-0"
        data-tooltip-id="fareRule-tooltip"
        data-tooltip-content={tooltipContent}
        data-tooltip-place="bottom"
        data-tooltip-hidden={name === REGULAR}
        data-tooltip-class-name="!border !border-contrast-300"
      >
        <input
          type="radio"
          id={name}
          className={classNames("shadow-none text-contrast-600 cursor-pointer", {
            "!border-white": isHomePage,
          })}
          checked={isChecked}
          onChange={handleFareTypeChange}
        />
        <label htmlFor={name} className="ml-1 cursor-pointer whitespace-nowrap text-xs sm:text-base">
          {translationHandler(`searchSection.${label}`)}
        </label>
      </div>
    </div>
  )
};

const FareTypeSelector = ({ fareType, setFareType, isHomePage }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col sm:flex-row items-center mt-6 gap-2 text-base">
      <div className="font-bold whitespace-nowrap w-fit text-xs sm:text-base">
        {t(`searchSection.fareType`)}
      </div>
      <div className="grid grid-cols-2 gap-1 sm:flex sm:flex-wrap rounded-1">
        {Object.entries(FARE_TYPES).map(([key, { value, label }]) => (
          <FareTypeCard
            key={key}
            name={key}
            label={t(label)}
            isChecked={fareType === value}
            handleFareTypeChange={() => {
              logEvent(`${value}_fare_type_selected`)
              setFareType(value)
            }}
            isHomePage={isHomePage}
            translationHandler={t}
          />
        ))}
      </div>
    </div>
  );
};

export default FareTypeSelector;
