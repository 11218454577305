import { memo, useState } from "react";
import { isEmpty, isObject, isString } from "lodash";
import { useCollapse } from "react-collapsed";
import classNames from "classnames";
import XMLViewer from "react-xml-viewer";
import { JsonView } from "react-json-view-lite";
import CopyToClipboardButton from "./../../../components/molecules/CopyToClipboard";
import { RenderSVG, ChevronDown, DownloadIcon } from "../../../assets/icons";
import { DEFAULT_VALUES, LOG_RESULT_TABLE_COLUMNS } from "../../../constants";
import { downloadTxtFile } from "../../../helper";
import { useTranslation } from "react-i18next";

const { ZERO, FOUR } = DEFAULT_VALUES;

const formatCopyData = (data) =>
  isObject(data) ? JSON.stringify(data, null, FOUR) : data;

const RenderFormattedAPILogs = memo(({ data }) => {
  const { t } = useTranslation()
  const [shouldRender, setShouldRender] = useState(false);

  const shouldExpandNode = (level) => shouldRender || level === ZERO;

  if (isObject(data))
    return (
      <div className="bg-gray-200 flex flex-col">
        <button
          onClick={() => setShouldRender((prev) => !prev)}
          className="bg-primary-600 text-contrast-100 w-fit self-start my-3 text-sm px-2 rounded-md"
        >
          {!shouldRender ? t("adminSection.expandAll") : t("adminSection.closeAll")}
        </button>
        <JsonView data={data} shouldExpandNode={shouldExpandNode} />
      </div>
    );
  else if (isString(data) && data.startsWith("<?xml"))
    return <XMLViewer xml={data} collapsible />;
  else return <>{data.toString()}</>;
});

const SingleLogTableRow = memo(({ log }) => {
  const {
    id,
    corelationId,
    url,
    date,
    serviceType,
    logType,
    log: logData,
    bookingId,
  } = log;

  const { isExpanded, getCollapseProps, getToggleProps } = useCollapse();

  const rowClassName =
    "py-4 px-4 text-sm whitespace-nowrap text-center border-r border-l";

  return (
    <>
      <tr key={id} className="border-b">
        <td className={rowClassName}>
          <div className="flex">
            {corelationId}
            {corelationId && (
              <CopyToClipboardButton
                text={corelationId}
                className="ml-4 cursor-pointer"
              />
            )}
          </div>
        </td>
        <td className={rowClassName}>{url}</td>
        <td className={rowClassName}>{date}</td>
        <td className={rowClassName}>{serviceType}</td>
        <td className={rowClassName}>{logType}</td>
        <td className={rowClassName}>{bookingId}</td>
        <td className="py-4 px-4 text-sm whitespace-nowrap text-center flex items-center justify-center gap-5">
          <RenderSVG
            Svg={ChevronDown}
            alt="ChevronDown"
            width="24"
            height="24"
            {...getToggleProps()}
            className={classNames("cursor-pointer transition duration-200", {
              "rotate-180": isExpanded,
            })}
          />
          <button onClick={() => downloadTxtFile(logData)}>
            <RenderSVG Svg={DownloadIcon} className="cursor-pointer h-6" />
          </button>
          <div className="self-end">
            <CopyToClipboardButton
              text={formatCopyData(log)}
              className="text-sm cursor-pointer"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan={7} className="max-w-screen-lg bg-gray-100 break-words">
          <div {...getCollapseProps()}>
            <div className="rounded flex flex-col">
              <div>
                <RenderFormattedAPILogs data={log} />
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
});

const LogResultTable = memo(({ data }) => (
  <div className="overflow-scroll overflow-x-auto h-[500px]">
    {isEmpty(data) ? (
      <div className="py-6 px-8 flex justify-center w-full h-full">
        No Data to display
      </div>
    ) : (
      <table className="w-full table-auto">
        <thead className="bg-contrast-200">
          <tr className="w-min">
            {LOG_RESULT_TABLE_COLUMNS.map(({ dataField, text }) => (
              <th
                key={dataField}
                className="py-2 px-4 text-sm font-semibold w-fit"
              >
                {text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((data) => (
            <SingleLogTableRow key={data.id} log={data} />
          ))}
        </tbody>
      </table>
    )}
  </div>
));

export default LogResultTable;
