import { useState } from "react";
import classNames from "classnames";
import { formatPriceWithCommas } from "../../../helper";
import { CURRENCY_SYMBOLS } from "../../../constants";

const cancellationReasons = [
  "My plan is cancelled",
  "Found a better property",
  "I want to change dates",
  "I want to change my primary guest name",
  "I want to add/remove guest",
  "Other",
];
const OTHER = "Other";

const RenderCancellationSteps = ({
  currentStep,
  selectedReason,
  setSelectedReason,
  totalRooms,
  amountDetails = {},
  setOtherReasonError,
}) => {
  const { totalPrice, currency, cancellationCharges, discount } = amountDetails;
  const [isOtherReasonSelected, setIsOtherReasonSelected] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSelectedReason({
      ...selectedReason,
      listedReason: value,
    });
    if (value === OTHER) setIsOtherReasonSelected(true);
    else setIsOtherReasonSelected(false);
    setOtherReasonError(false);
  };

  const handleTextAreaChange = (e) => {
    const { value } = e.target;
    setSelectedReason({
      ...selectedReason,
      otherReason: value,
    });
    value === "" ? setOtherReasonError(true) : setOtherReasonError(false);
  };

  return (
    <div className="mb-4">
      {currentStep === 1 && (
        <div>
          <div className="mb-4">
            <h2 className="text-base font-bold text-gray-900 mb-1">
              Please let us know the reason for cancelling this booking:
            </h2>
            <p className="text-xs text-gray-500">
              This information would help us in providing a better experience
            </p>
          </div>
          <div className="flex flex-col gap-2">
            {cancellationReasons.map((reason) => (
              <label key={reason} className="cursor-pointer">
                <input
                  type="radio"
                  className="hidden"
                  value={reason}
                  name="cancellation-reason"
                  onChange={(e) => handleInputChange(e)}
                />
                <div
                  className={classNames(
                    "flex items-center gap-2 border-2 py-2 px-4 rounded-lg border-gray-200 text-gray-900",
                    {
                      "bg-primary-100 border-primary-600 text-primary-900":
                        selectedReason.listedReason === reason,
                      "border-gray-200 text-gray-900":
                        selectedReason.listedReason !== reason,
                    }
                  )}
                >
                  <div
                    className={classNames(
                      "w-4 h-4 shrink-0 rounded-full grid place-content-center border border-gray-200",
                      {
                        "border-[5px] border-primary-600":
                          selectedReason.listedReason === reason,
                        "border-gray-200":
                          selectedReason.listedReason !== reason,
                      }
                    )}
                  ></div>
                  <div>
                    <span className="text-sm font-medium flex">{reason}</span>
                  </div>
                </div>
              </label>
            ))}
            {isOtherReasonSelected && (
              <textarea
                type="text"
                className="border border-gray-300 rounded-lg min-h-40 px-3 py-2"
                placeholder="Enter other reason"
                value={selectedReason.otherReason}
                onChange={(e) => handleTextAreaChange(e)}
              />
            )}
          </div>
        </div>
      )}
      {currentStep === 2 && (
        <div className="step-pane step-2">
          <div className="mb-4">
            <h2 className="text-base font-bold text-gray-900 mb-1">
              Refund for cancelling {totalRooms} Room(s)
            </h2>
          </div>
          <div className="table-responsive">
            <table className="border-collapse w-full border border-slate-400 dark:border-slate-500 bg-white dark:bg-slate-800 shadow-sm">
              <tbody>
                <tr>
                  <td
                    valign="top"
                    className="border border-slate-300 dark:border-slate-700 p-4 "
                  >
                    <div className="font-semibold text-gray-900 mb-2">
                      Total Paid
                    </div>
                    <p className="text-xs text-gray-800 ">Hotel Price</p>
                    <p className="text-xs text-gray-800">Discount Coupon</p>
                  </td>
                  <td
                    valign="top"
                    className="border border-slate-300 dark:border-slate-700 p-4 text-end"
                  >
                    <div className="font-bold mb-2">
                      {CURRENCY_SYMBOLS[currency]}
                      {formatPriceWithCommas(totalPrice)}
                    </div>
                    <p className="text-xs text-gray-800 font-medium">
                      {CURRENCY_SYMBOLS[currency]}
                      {formatPriceWithCommas(totalPrice + discount)}
                    </p>
                    <p className="text-xs text-gray-800 font-medium">
                      {CURRENCY_SYMBOLS[currency]}
                      {formatPriceWithCommas(discount)}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    valign="top"
                    className="border border-slate-300 dark:border-slate-700 p-4 "
                  >
                    <div className="font-semibold text-gray-900 mb-2">
                      Deduction
                    </div>
                  </td>
                  <td
                    valign="top"
                    className="border border-slate-300 dark:border-slate-700 p-4 text-end"
                  >
                    <div className="font-bold mb-2">
                      {CURRENCY_SYMBOLS[currency]}
                      {formatPriceWithCommas(cancellationCharges)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    valign="top"
                    className="border border-slate-300 dark:border-slate-700 p-4 "
                  >
                    <div className="font-semibold text-green-700 mb-2">
                      Refund Amount
                    </div>
                  </td>
                  <td
                    valign="top"
                    className="border border-slate-300 dark:border-slate-700 p-4 text-end"
                  >
                    <div className="font-bold mb-2 text-green-700">
                      {CURRENCY_SYMBOLS[currency]}
                      {formatPriceWithCommas(totalPrice - cancellationCharges)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-sm text-gray-700">
            *Amount will be refunded within 6-8 working days.
          </div>
        </div>
      )}
    </div>
  );
};

export default RenderCancellationSteps;
