export const timeFormatter = (timeString) => {
  const iscaretSymbol = timeString.includes(":");
  const hours = iscaretSymbol
    ? timeString.split(":").at(0)
    : timeString.slice(0, 2);
  const minutes = iscaretSymbol
    ? timeString.split(":").at(1)
    : timeString.slice(2);
  const period = +hours >= 12 ? "PM" : "AM";
  const formattedHours = +hours % 12 || 12;
  const formattedMinutes = minutes.padStart(2, "0");
  const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
  return formattedTime;
};
