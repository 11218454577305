import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveSpinners } from "./spinner.selectors";
import { SpinnerIcon, RenderSVG } from "../../../assets/icons";
import classNames from "classnames";
import { addSpinner, removeSpinner } from "./index";
import { SPINNER_NAMES } from "./spinner.constants";
const REFRESHED = "refreshed";
const { PENDING } = SPINNER_NAMES;
export const Loader = ({ size = "w-8 h-6" }) => (
  <RenderSVG
    Svg={SpinnerIcon}
    className={`inline ${size} animate-spin`}
    alt="spinner-icon"
  />
);
const Spinner = ({
  name,
  size = "w-8 h-6",
  setIsSpinnerActive = () => {},
  children,
  showSpinner,
  loaderComponent = <Loader size={size} />,
  showSkeleton = false,
  message,
  persistSize = false,
  customSpinnerMethod,
  spinnerClassName = "",
}) => {
  const dispatch = useDispatch();
  const activeSpinners = useSelector(selectActiveSpinners);
  const [shouldShowSpinner, setShouldShowSpinner] = useState(
    !activeSpinners.length
  );
  useEffect(() => {
    if (customSpinnerMethod)
      setShouldShowSpinner(customSpinnerMethod(name, activeSpinners));
    else
      setShouldShowSpinner(
        Array.isArray(name)
          ? name.some((spinnerName) =>
              activeSpinners.some((spinner) => spinnerName === spinner)
            )
          : activeSpinners.some((spinner) => spinner === name)
      );
  }, [name, activeSpinners]);
  useEffect(() => {
    if (
      !activeSpinners.length ||
      activeSpinners.every((spinner) => spinner === PENDING)
    ) {
      dispatch(addSpinner(REFRESHED));
    }
  }, [dispatch, activeSpinners]);
  useEffect(() => {
    setIsSpinnerActive(shouldShowSpinner);
  }, [shouldShowSpinner, setIsSpinnerActive]);
  return (
    <>
      {(shouldShowSpinner || showSpinner) && (
        <div
          className={classNames({
            "flex flex-col justify-center items-center h-full flex-1 m-auto":
              !showSkeleton,
          })}
        >
          {loaderComponent}
          {message && (
            <div className="text-2xl text-contrast-500 font-bold flex justify-center text-center mt-5 px-5">
              {message}
            </div>
          )}
        </div>
      )}
      {(!(shouldShowSpinner || showSpinner) || persistSize) && (
        <span
          className={classNames(spinnerClassName, {
            invisible: shouldShowSpinner || showSpinner,
          })}
        >
          {children}
        </span>
      )}
    </>
  );
};
export default Spinner;
