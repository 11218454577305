import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  cloneDeep,
  differenceWith,
  get,
  isEmpty,
  isEqual,
  size,
  toPairs,
} from "lodash";
import HotelsMapModal from "../../components/organisms/AppModals/HotelsMapModal/HotelsMapModal";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import { ChevronDown, Filter, RenderSVG } from "../../assets/icons";
import {
  getObjectFromQueryParams,
  setToSessionStorage,
  renderStars,
  generateHotelAddress,
  getHotelImageUrl,
  useClickOutside,
} from "../../helper";
import {
  setHotelInfo,
  setHotelInfoReqBody,
  setSelectedHotelViewMore,
} from "../HotelInfo";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  selectFilteredHotels,
  selectHotelCurrency,
  selectHotelSearchFilters,
  selectHotels,
  selectHotelsActiveFilters,
  selectHotelsCount,
  selectHotelsSortingValues,
  setFilteredHotels,
  setHotelsActiveFilters,
  setHotelsCount,
  setHotelsSortingValues,
} from "../../components/organisms/Search";
import { selectCountryInfo } from "../Profile";
import NoHotelResults from "./NoHotelResults";
import FilterHotelsDropDown from "./HotelFilters/FilterHotelsDropDown";
import SearchHotelSection from "../../components/organisms/Search/SearchHotelSection";
import {
  ROUTES,
  CACHE_KEYS,
  DEFAULT_VALUES,
  INITIAL_HOTEL_VALUES,
  INITIAL_HOTEL_FILTERS,
  HOTEL_RESULT_SORT_OPTIONS
} from "../../constants";
import config from "../../config.json";
import { formatPriceWithCommas } from "../../helper/priceFormatter";
import { MODALS } from "../../components/organisms/AppModals";
import { setSelectedModal } from "../../components/organisms/Modal";
import { isEmptyObject } from "../../utils/validateObject";
import { HotelLoader } from "../../components/organisms/AppLoaders";

const { brandName } = config;
const { HOTEL_INFO_REQUEST_BODY } = CACHE_KEYS;
const { HOTEL_INFO } = ROUTES;
const { FETCH_HOTEL_RESULTS, FETCH_HOTEL_STATIC_DATA } = SPINNER_NAMES;
const { ZERO, ONE, THREE, EMPTY_STRING, EMPTY_ARRAY } =
  DEFAULT_VALUES;

const Header = ({ resetFilters, setResetFilters, cityName, handleOptionClick, priceSorting, starSorting}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropDownRef = useRef();

  const selectedHotelResults = useSelector(selectHotels);
  const filteredHotels = useSelector(selectFilteredHotels);
  const hotelFilters = useSelector(selectHotelsActiveFilters);
  const actualFilters = useSelector(selectHotelsSortingValues);
  const filtersCount = useSelector(selectHotelsCount);
  const [showHotelMap, setShowHotelMap] = useState(false);
  const [isFilterResetting, setIsFilterResetting] = useState(false);
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  
  useClickOutside(dropDownRef, setIsSortDropdownOpen);

  const hotelResults = get(selectedHotelResults, "hotelResults", EMPTY_ARRAY);

  const handleDropdownToggle = () => {
    setIsSortDropdownOpen(!isSortDropdownOpen);
  };

  const getFiltersLength = (filters) => {

    if (isEmpty(filters)) return ZERO;
    const differencesCount = size(
      differenceWith(toPairs(actualFilters.price), toPairs(filters.price), isEqual)
    );

    let count = 0;
    if(differencesCount>=1){
      count = 1;
    }
    const diff = size(differenceWith(toPairs(filters), toPairs(INITIAL_HOTEL_FILTERS), isEqual))
    count += diff-1;
    return count;
  };

  useEffect(() => {
    const filterCount = getFiltersLength(hotelFilters);
    dispatch(setHotelsCount(filterCount));
    if (filterCount == 0) setIsFilterResetting(false);
  }, [hotelFilters]);

  const handleResetFilters = () => {
    setIsFilterResetting(true);
    dispatch(setFilteredHotels([]));
    dispatch(
      setHotelsActiveFilters({
        ...actualFilters,
        starRatings: [],
        locality: [],
        categories: [],
        price: {
          minPrice: actualFilters.minPrice,
          maxPrice: actualFilters.maxPrice,
        },
      })
    );
    // dispatch(setHotelsActiveFilters(actualFilters));
    setResetFilters(true);
    setIsFilterResetting(false);
    dispatch(setHotelsCount(0));
  };

  const hotelsWithLocation = filteredHotels.filter(
    (hotel) => !isEmpty(hotel.latitude) && !isEmpty(hotel.longitude)
  );

  const renderSection = (title, options) => (
    <div>
      <div className="px-4 py-2 text-sm font-medium text-gray-700">
        {title}
      </div>
      {options.map((option) => (
        <button
          key={option.value}
          onClick={() => {
            handleOptionClick(option.value)
            setIsSortDropdownOpen(false)
          }}
          className={classNames(
            "flex items-center w-full px-4 py-2 hover:bg-gray-100 text-xs sm:text-sm",
            { "bg-gray-100": option.active }
          )}
        >
          <span className="mr-2 text-yellow-700">{option.icon}</span>
          {option.label}
          {option.active && <span className="ml-24 text-green-700">✔</span>}
        </button>
      ))}
    </div>
  );

  const handleMapModalClose = () => setShowHotelMap(false);

  return (
    <div
      id="header"
      className="self-stretch justify-start items-center inline-flex border rounded-md shadow-sm px-2 py-1 gap-2 flex flex-col sm:flex-row sm:px-6 sm:py-3 sm:gap-6"
    >
      <div className="grow shrink flex-col justify-center items-start inline-flex">
        <div className="self-stretch font-semibold text-[10px] sm:text-base sm:leading-7">
          <div>
            {cityName} {" : "}
            {filteredHotels.length}{" "}
            {filteredHotels.length === ONE
              ? "Property found"
              : "Properties found"}
          </div>
        </div>
      </div>
      <div className="justify-start items-center flex sm:pr-4 gap-1 sm:gap-4">
        {!isEmpty(hotelsWithLocation) && (
          <div
            onClick={() => setShowHotelMap(true)}
            className="hidden text-xs sm:block sm:text-base lg:hidden text-primary-600 hover:text-primary-700 font-semibold hover:cursor-pointer justify-center items-center gap-2 flex relative "
          >
            Show On Map
          </div>
        )}
        {!isEmpty(filteredHotels) && <div
          ref={dropDownRef}
          className="relative inline-block text-nowrap text-left sm:z-20"
        >
          <button
            type="button"
            className="inline-flex items-center font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500 p-1 py-1.5 text-xs sm:py-2 sm:text-sm sm:px-4"
            onClick={handleDropdownToggle}
          >
            Sort by
            <RenderSVG
                    Svg={ChevronDown}
                    alt="Toggle dropdown"
                    width="14"
                    height="14"
                    className="ml-2"
                  />
          </button>

          {isSortDropdownOpen && (
            <div className="origin-top-center absolute  mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none sm:origin-top-right z-20 sm:right-0 sm:w-64">
              <div className="py-1">
                {renderSection("STAR", [
                  {
                    label: "Low to High",
                    value: HOTEL_RESULT_SORT_OPTIONS.STAR_ASCENDING,
                    icon: "★",
                    active: starSorting?.includes(HOTEL_RESULT_SORT_OPTIONS.STAR_ASCENDING),
                  },
                  {
                    label: "High to Low",
                    value: HOTEL_RESULT_SORT_OPTIONS.STAR_DESCENDING,
                    icon: "★",
                    active: starSorting?.includes(HOTEL_RESULT_SORT_OPTIONS.STAR_DESCENDING),
                  }
                ])}

                {renderSection("PRICE", [
                  {
                    label: "Low to High",
                    value: HOTEL_RESULT_SORT_OPTIONS.PRICE_ASCENDING,
                    icon: "💲",
                    active: priceSorting?.includes(HOTEL_RESULT_SORT_OPTIONS.PRICE_ASCENDING),
                  },
                  {
                    label: "High to Low",
                    value: HOTEL_RESULT_SORT_OPTIONS.PRICE_DESCENDING,
                    icon: "💲",
                    active: priceSorting?.includes(HOTEL_RESULT_SORT_OPTIONS.PRICE_DESCENDING),
                  }
                ])}
              </div>
            </div>
          )}
        </div>}
        {filtersCount > 0 && (
          <Spinner
            showSpinner={isFilterResetting}
            spinnerClassName={classNames(
              "w-full border border-contrast-300 rounded-md  bg-white hover:bg-contrast-50 active:bg-white shadow-sm sm:px-4 sm:py-2",
              { "flex px-12 py-[6px]": isFilterResetting }
            )}
          >
            <button
              className="flex items-center gap-2 text-contrast-600 font-medium justify-end text-xs p-1 sm:p-0 sm:text-sm"
              onClick={handleResetFilters}
              type="button"
            >
              <RenderSVG Svg={Filter} alt="Filter Icon" />
              <span className="text-nowrap">{t("flightResults.resetFilter")}</span>
            </button>
          </Spinner>
        )}
        {!isEmpty(hotelResults) && (
          <div className="lg:hidden bg-white rounded-md shadow border border-contrast-300 justify-center items-center gap-2 z-10 flex relative p-1 sm:pl-[15px] sm:pr-[17px] sm:py-[9px]">
            <FilterHotelsDropDown
              resetFilters={resetFilters}
              setResetFilters={setResetFilters}
            />
          </div>
        )}
      </div>
      {showHotelMap && (
        <HotelsMapModal
          hotels={hotelsWithLocation}
          handleClose={handleMapModalClose}
        />
      )}
    </div>
  );
};

const HotelResultCard = ({ hotelInformation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedHotelResults = useSelector(selectHotels);
  const sourceData = get(selectedHotelResults, "sourceData", EMPTY_ARRAY);
  const filteredHotels = useSelector(selectFilteredHotels);

  const [imageError, setImageError] = useState(false);
  const [showHotelMapModal, setShowHotelMapModal] = useState(false);
  const searchFilters = useSelector(selectHotelSearchFilters);
  const { HOTEL_VIEW_MORE_MODAL } = MODALS;

  const selectedCountryInfo = useSelector(selectCountryInfo);
  const { symbol: hotelCurrencySymbol = "" } = useSelector(selectHotelCurrency) || {}
  const hotelSearchFilters = useSelector(selectHotelSearchFilters);

  const {
    resultIndex,
    hotelCode,
    hotelName,
    hotelAddress = {},
    starRating,
    price: { otherCharges, tax, roomPrice, tavaMarkup },
    hotelPicture,
    supplierHotelCodes,
    hotelPolicy,
    hotelImages,
    hotelAmenities = [],
    userRatings,
    hotelReviewCount,
    freeBreakfast,
    freeCancellation,
    payAtHotel,
    source,
    latitude,
    longitude,
  } = hotelInformation;

  const { checkInDate, checkOutDate } = searchFilters;

  const findTraceId = (sourceData, source) => {
    const data = sourceData.find((item) => item.source === source);
    return data ? data.traceId : null;
  };

  const traceId = findTraceId(sourceData, source);

  const displayedAmenities = hotelAmenities?.slice(0, 3);
  const [isExpanded, setExpanded] = useState(false);
  const totaltax = otherCharges + tax;
  const categoryId = get(supplierHotelCodes, "[0].categoryId", EMPTY_STRING);

  const handleSelectHotel = () => {
    const { ip: endUserIp } = selectedCountryInfo;
    const hotelInfoAPIReq = {
      resultIndex,
      hotelCode,
      categoryId,
      endUserIp,
      traceId,
      checkInDate: checkInDate.replaceAll("/", "-"),
      checkOutDate: checkOutDate.replaceAll("/", "-"),
      source: "TBO", //TODO Need to eliminate use of specific provider name
    };
    dispatch(setHotelInfoReqBody(hotelInfoAPIReq));
    setToSessionStorage(HOTEL_INFO_REQUEST_BODY, hotelInfoAPIReq);
    dispatch(setHotelInfo({}));
    navigate(HOTEL_INFO.replace(":hotelId", hotelCode));
  };

  const addressLines = generateHotelAddress(hotelAddress);
  const hotelImage = getHotelImageUrl(hotelImages);

  const hotelsWithLocation = filteredHotels.filter(
    (hotel) => !isEmpty(hotel.latitude) && !isEmpty(hotel.longitude)
  );

  const handleMapModalClose = () => setShowHotelMapModal(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setExpanded(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isExpanded]);
  }

  const showHotelAmenitiesButton = () => {
    dispatch(setSelectedModal(HOTEL_VIEW_MORE_MODAL));
    dispatch(
      setSelectedHotelViewMore({
        hotelAmenities,
        hotelImages,
        hotelPolicy,
        addressLines,
        hotelName,
      })
    );
  };

  const viewMoreHandler = () => {
    return hotelAmenities?.length > ZERO
      ? hotelAmenities?.length
      : !isEmptyObject(hotelImages)
      ? Object.keys(hotelImages)?.length
      : !isEmptyObject(hotelPolicy)
      ? Object.keys(hotelPolicy)?.length
      : ZERO;
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="w-full mx-auto relative" ref={wrapperRef}>
      {freeBreakfast && (
        <div className="z-20 absolute top-[36px] -left-3 ">
          <div className="absolute w-[18px] h-[12px] skew-x-[45deg] rotate-45 -bottom-1 -left-0.5  bg-green-800" />
          <div className="relative bg-green-800 text-white text-xs text-center rounded-md rounded-bl-none p-1">
            Breakfast included
          </div>
        </div>
      )}
      <div className="border rounded-lg bg-white mb-3 hover:shadow-lg transition duration-400 ease-in-out w-full overflow-hidden">
        <div className="flex justify-between">
          <div className="relative px-4 flex flex-col items-center justify-between text-end">
            {userRatings && (
              <div className="absolute top-2 right-2">
                <div className="w-8 py-2 text-white text-center bg-primary-600 rounded-md font-medium text-xs rounded-bl-none">
                  {userRatings}
                </div>
                <div>
                  {hotelReviewCount && (
                    <span className="text-nowrap text-[10px]">
                      ({formatPriceWithCommas(hotelReviewCount)} reviews)
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="p-2 w-full sm:pr-5 sm:p-4 sm:h-full sm:grid sm:grid-cols-3 sm:gap-4 sm:min-h-60">
          {imageError ? (
            <div className="flex justify-center items-center rounded-lg h-40 w-full sm:h-[232px] sm:w-[252px] bg-black bg-opacity-30 px-4 py-2">
              <span className="text-white font-bold">{hotelName}</span>
            </div>
          ) : (
            <img
              src={hotelImage || hotelPicture}
              alt={hotelName}
              onError={() => setImageError(true)}
              className={"rounded-md object-cover  h-40 w-full sm:h-[232px] sm:w-[252px] sm:aspect-square"}
            />
          )}

          <div className="col-span-2 flex flex-col sm:flex-row justify-between">
            <div className="flex flex-col justify-between overflow-wrap-anywhere">
              <div>
                <div className="flex items-center justify-between mt-2 sm:mt-0 ">
                  <div className="flex gap-1 sm:flex-col sm:items-start sm:gap-0">
                    <h2 className="text-start font-bold text-primary-900 text-base sm:text-lg ">
                      {hotelName[ZERO]?.toUpperCase() + hotelName.slice(ONE)}{" "}
                    </h2>
                    {starRating > 0 && (
                      <div className="text-sm text-start">
                        {renderStars(starRating)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {addressLines && (
                    <p className="mt-1 text-start text-[12px] sm:text-[14px] ">
                      {addressLines}
                    </p>
                  )}
                  {latitude && longitude && (
                    <div
                      className="hidden text-[9px] sm:block sm:text-[12px]text-primary-700 text-start cursor-pointer hover:text-primary-900 w-fit"
                      onClick={() => {
                        setShowHotelMapModal(true);
                      }}
                    >
                      Show on Map
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row justify-between ">
                <div className="mb-2 flex flex-row items-end sm:items-start sm:flex-col sm:mb-0">
                  <div className="pb-2 text-start">
                    {freeBreakfast && (
                      <div className="mt-1 text-green-800 font-semibold text-[10px]">
                        Breakfast included
                      </div>
                    )}
                    {freeCancellation && (
                      <div className="flex gap-2">
                        <RenderSVG
                          Svg={Check}
                          width="12"
                          height="12"
                          className="text-green-700"
                        />
                        <p className=" text-green-800 font-semibold text-[10px]">
                          Free Cancellation
                        </p>
                      </div>
                    )}
                    {payAtHotel && (
                      <div className="flex gap-2">
                        <RenderSVG
                          Svg={Check}
                          width="12"
                          height="12"
                          className="text-green-700"
                        />
                        <p className=" text-green-800 font-semibold text-[10px]">
                          No prepayment needed - pay at the property
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col border-l-2 pt-1 text-start">
                    {displayedAmenities?.map((amenity) => (
                      <div key={amenity} className="text-[10px] px-1 sm:text-[12px] sm:px-2">
                        {amenity}
                      </div>
                    ))}
                  </div>

                  <div>
                    {(!isEmpty(hotelPolicy) ||
                      !isEmpty(hotelAmenities) ||
                      !isEmpty(hotelImages)) && (
                      <button
                        onClick={showHotelAmenitiesButton}
                        className="flex text-primary-700 font-medium whitespace-nowrap text-[10px] sm:text-xs sm:pt-2"
                      >
                        {viewMoreHandler() > THREE && (
                          <span>
                            {isExpanded
                              ? "View Less"
                              : `View More (+${viewMoreHandler()})`}
                          </span>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-end items-end">
              <div className="flex items-start justify-between w-full sm:mt-2 md:flex-col md:items-end">
                <p className="font-semibold text-base sm:text-lg">
                  {hotelCurrencySymbol}&nbsp;
                  {formatPriceWithCommas(roomPrice + tavaMarkup)}
                </p>
                <p className="text-[9px] text-gray-800 text-end">
                  + {hotelCurrencySymbol} {formatPriceWithCommas(totaltax)} taxes and
                  charges
                </p>
              </div>
              <button
                className="bg-primary-600 w-full font-medium hover:bg-primary-800 text-white p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600 text-nowrap disabled:cursor-not-allowed disabled:opacity-70 mt-1 sm:mt-4 text-xs sm:text-sm md:w-fit "
                onClick={handleSelectHotel}
                // disabled={source != "TBO"}
              >
                See availability
              </button>
            </div>
          </div>
        </div>
      </div>

      {showHotelMapModal && (
        <HotelsMapModal
          hotels={hotelsWithLocation}
          handleClose={handleMapModalClose}
          defaultSelectedHotel={hotelInformation}
        />
      )}
    </div>
  );
};

const HotelResults = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedHotelResults = useSelector(selectHotels);
  const hotelSearchFilters = useSelector(selectHotelSearchFilters);
  const { divRef } = useOutletContext();
  const hotelResults = get(selectedHotelResults, "hotelResults", EMPTY_ARRAY);
  const sourceData = get(selectedHotelResults, "sourceData", EMPTY_ARRAY);

  const { cityName } = getObjectFromQueryParams(search);
  const defaultExpandedValue = !useMediaQuery({ maxWidth: 1000 });

  const filteredHotels = useSelector(selectFilteredHotels);
  const hotelsActiveFilters = useSelector(selectHotelsActiveFilters);

  const [resetFilters, setResetFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const previousFiltersRef = useRef(hotelsActiveFilters);
  const containerRef = useRef(null);
  const [visibleHotels, setVisibleHotels] = useState([]);
  const [nextIndex, setNextIndex] = useState(0);
  const [showHotelMapModal, setShowHotelMapModal] = useState(false);
  const [starSorting, setStarSorting] = useState(
    HOTEL_RESULT_SORT_OPTIONS.STAR_DESCENDING
  );
  const [priceSorting, setPriceSorting] = useState(null);
  const hotelsPerPage = 10;

  const loadMoreHotels = () => {
    if (loading) return;

    setLoading(true);
    const newHotels = filteredHotels.slice(
      nextIndex,
      nextIndex + hotelsPerPage
    );
    if (isEmpty(newHotels)) {
      setLoading(false);
      return;
    }
    setVisibleHotels((prev) => {
      const combinedHotels = [...prev, ...newHotels];
      const uniqueHotels = Array.from(
        new Set(combinedHotels.map((hotel) => hotel.resultIndex))
      ).map((resultIndex) =>
        combinedHotels.find((hotel) => hotel.resultIndex === resultIndex)
      );
      return uniqueHotels;
    });

    setNextIndex((prev) => prev + hotelsPerPage);
    setLoading(false);
  };

  const handleScroll = () => {
    if (!divRef.current) return;
    const scrollTop = divRef.current.scrollTop;
    const containerHeight = divRef.current.clientHeight;
    const scrollHeight = divRef.current.scrollHeight;
    if (scrollTop + containerHeight >= scrollHeight - 100) {
      setLoading(true);
      loadMoreHotels();
    }
  };

  useEffect(() => {
    if (isEmpty(filteredHotels)) {
      setVisibleHotels([]);
      return;
    }
    setVisibleHotels(filteredHotels.slice(0, hotelsPerPage));
    setNextIndex(0);
    loadMoreHotels();
  }, [filteredHotels]);

  useEffect(() => {
    const container = divRef?.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [nextIndex, filteredHotels]);

  const getHotelPrice = (hotel) => {
    const {
      price: { roomPrice, tax, tavaMarkup, otherCharges },
    } = hotel;
    const totalPrice = roomPrice + tavaMarkup;
    return totalPrice;
  };

  useEffect(() => {
    if (!hotelResults.length) return;
    dispatch(setHotelsActiveFilters(INITIAL_HOTEL_FILTERS));
    const updatedSortingValues = hotelResults.reduce((values, hotel) => {
      const price = getHotelPrice(hotel);
      const location = hotel.hotelLocation;
      const stars = hotel.starRating;
      const category = hotel.hotelCategory;

      values.price.minPrice = values.price.minPrice
        ? Math.min(values.price.minPrice, Math.floor(price))
        : Math.floor(price);
      values.price.maxPrice = values.price.maxPrice
        ? Math.max(values.price.maxPrice, Math.ceil(price))
        : Math.ceil(price);

        if (location) {
          values.locality[location] = (values.locality[location] || ZERO) + ONE;
        }
      stars &&
        (values.starRatings[stars] = (values.starRatings[stars] || ZERO) + ONE);
      category &&
        (values.categories[category] =
          (values.categories[category] || ZERO) + ONE);

      return values;
    }, cloneDeep(INITIAL_HOTEL_VALUES));

    updatedSortingValues.locality = Object.entries(updatedSortingValues.locality).map(
      ([name, count]) => ({ name, count })
    );
    updatedSortingValues.categories = Object.entries(updatedSortingValues.categories).map(
      ([name, count]) => ({ name, count })
    );

    updatedSortingValues.price.currency = get(
      hotelResults[0],
      "price.currencyCode",
      EMPTY_STRING
    );

    dispatch(setHotelsSortingValues(updatedSortingValues));
    dispatch(
      setHotelsActiveFilters({
        ...updatedSortingValues,
        starRatings: [],
        locality: [],
        categories: [],
        price: {
          minPrice: updatedSortingValues.price.minPrice,
          maxPrice: updatedSortingValues.price.maxPrice,
          currency: updatedSortingValues.price.currency,
        },
      })
    );
  }, [hotelResults, hotelSearchFilters]);

  useEffect(() => {
    // if (!isEqual(previousFiltersRef.current, hotelsActiveFilters)) {
    //   previousFiltersRef.current = hotelsActiveFilters;
      const filteredHotelResults = hotelResults.filter((hotel) => {
        const {
          price: { roomPrice },
        } = hotel;
        const price = getHotelPrice(hotel);
        const location = hotel.hotelLocation;
        const stars = hotel.starRating;
        const category = hotel.hotelCategory;
        
        const locationCheck =
          !isEmpty(hotelsActiveFilters.locality) &&
          !hotelsActiveFilters.locality.includes(location);
        const starsCheck =
          !isEmpty(hotelsActiveFilters.starRatings) &&
          !hotelsActiveFilters.starRatings.includes(String(stars));
        const categoryCheck =
          !isEmpty(hotelsActiveFilters.categories) &&
          !hotelsActiveFilters.categories.includes(category);
        const maxPriceCheck =
          hotelsActiveFilters.price.maxPrice &&
          price >= hotelsActiveFilters.price.maxPrice;
        const minPriceCheck =
          hotelsActiveFilters.price.minPrice &&
          price <= hotelsActiveFilters.price.minPrice;

        return !(
          locationCheck ||
          starsCheck ||
          categoryCheck ||
          maxPriceCheck ||
          minPriceCheck
        );
      });
      const sortingResult = getSortedHotels(filteredHotelResults);
      dispatch(setFilteredHotels(sortingResult));
    // }
  }, [hotelsActiveFilters, hotelResults]);


  const getHotelPriceForSorting = (hotel) => {
    const {
      price: { roomPrice, tavaMarkup },
    } = hotel;
    const totalPrice = roomPrice + tavaMarkup;
    return totalPrice;
  };
  const handleOptionClick = (option) => {
    if (option.includes("price")) {
      setStarSorting(null);
      priceSorting !== option ? setPriceSorting(option) : setPriceSorting(null);
    } else {
      setPriceSorting(null);
      starSorting !== option ? setStarSorting(option) : setStarSorting(null);
    }
  };

  const getSortedHotels = (filteredHotels) => {
    const sortedHotels = [...filteredHotels];

    const sortFunctions = {
      [HOTEL_RESULT_SORT_OPTIONS.STAR_ASCENDING]: (a, b) =>
        a.starRating - b.starRating,
      [HOTEL_RESULT_SORT_OPTIONS.STAR_DESCENDING]: (a, b) =>
        b.starRating - a.starRating,
      [HOTEL_RESULT_SORT_OPTIONS.PRICE_ASCENDING]: (a, b) =>
        getHotelPriceForSorting(a) - getHotelPriceForSorting(b),
      [HOTEL_RESULT_SORT_OPTIONS.PRICE_DESCENDING]: (a, b) =>
        getHotelPriceForSorting(b) - getHotelPriceForSorting(a),
    };

    const compareFunctions = [];
    if (!starSorting && !priceSorting) {
      compareFunctions.push(
        sortFunctions[HOTEL_RESULT_SORT_OPTIONS.STAR_DESCENDING]
      );
    }
    if (starSorting) {
      if (compareFunctions.length === ONE) compareFunctions.pop();
      compareFunctions.push(sortFunctions[starSorting]);
    }
    if (priceSorting) {
      if (compareFunctions.length === ONE) compareFunctions.pop();
      compareFunctions.push(sortFunctions[priceSorting]);
    }

    return sortedHotels.sort((a, b) => {
      for (const compareFunction of compareFunctions) {
        const result = compareFunction(a, b);
        if (result !== 0) return result;
      }
      return 0;
    });
  };
  useEffect(() => {
    if (!isEmpty(filteredHotels)) {
      setVisibleHotels(filteredHotels.slice(0, hotelsPerPage));
      setNextIndex(hotelsPerPage);
    }
  }, [filteredHotels]);

  useEffect(() => {
    const sortingResult = getSortedHotels(filteredHotels);
    setVisibleHotels(sortingResult);
  }, [starSorting, priceSorting]);

  const renderEmptyLayout = () => {
    return (
      <div className="m-auto w-full overflow-hidden">
        {search ? (
          <NoHotelResults totalHotels={hotelResults.length} />
        ) : (
          <EmptyLayout />
        )}
      </div>
    );
  };
  return (
    <div className="flex-grow h-full mx-auto bg-white">
      <Helmet>
        <title>{brandName} | Hotels</title>
      </Helmet>

      <main className="relative pt-4 bg-white">
        <div className="sm:sticky sm:top-0 px-8 p-3 -mt-4 border-b z-30 bg-white border-contrast-200">
          <SearchHotelSection
            showEditSearch={true}
            defaultExpandedValue={defaultExpandedValue}
          />
        </div>
        <div className="container-hotel px-4 2xl:px-72 mx-auto">
          <div className="pt-3 h-full flex flex-col align-middle relative m-auto text-center">
            <Spinner
              name={[FETCH_HOTEL_RESULTS, FETCH_HOTEL_STATIC_DATA]}
              loaderComponent={<HotelLoader />}
              showSkeleton={true}
            >
              <div className="flex flex-row gap-2 w-full items-start">
                {!isEmpty(hotelResults) && (
                 <div className={classNames('hidden lg:block mr-2 sticky top-36',
                  {"z-[100]": showHotelMapModal}
                )}>
                    <FilterHotelsDropDown
                      resetFilters={resetFilters}
                      setResetFilters={setResetFilters}
                      showSideFilters={true}
                      setShowHotelMapModal={setShowHotelMapModal}
                      showHotelMapModal={showHotelMapModal}
                    />
                  </div>
                )}
                <div className="flex flex-col gap-2 w-full">
                  <div className="bg-white border-b-0 border-contrast-200 rounded-lg text-start flex-col justify-start items-start gap-6 inline-flex ml-2">
                    {!isEmpty(selectedHotelResults) && (
                      <Header
                        resetFilters={resetFilters}
                        setResetFilters={setResetFilters}
                        cityName={cityName}
                        handleOptionClick={handleOptionClick}
                        priceSorting={priceSorting}
                        starSorting={starSorting}
                      />
                    )}
                  </div>

                  <div className="h-full w-full">
                    {!isEmpty(filteredHotels) ? (
                      <div
                        className="h-full pl-2 self-stretch flex-col justify-start items-start sm:gap-2 flex"
                        ref={containerRef}
                      >
                        {visibleHotels.map((hotelInformation) => (
                          <HotelResultCard
                            key={hotelInformation.resultIndex}
                            hotelInformation={hotelInformation}
                            sourceData={sourceData}
                          />
                        ))}
                      </div>
                    ) : (
                      renderEmptyLayout()
                    )}
                  </div>
                </div>
              </div>
            </Spinner>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HotelResults;
