import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { isEmpty, get, size, differenceWith, toPairs, isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import {
  getSortedFlights,
  calculateNumberOfNonStops,
  getObjectFromQueryParams,
} from "../../helper";
import { LeftIcon, RightIcon, RenderSVG } from "../../assets/icons";
import FlightHeader from "./FlightHeader";
import SearchSection, {
  selectFlights,
  selectFlightsCount,
  selectSearchFilters,
  selectActiveSortOrder,
  selectFilteredFlights,
  selectActiveFilters,
} from "../../components/organisms/Search";
import NoFlightResult from "./NoFlightResult";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  selectCurrentFlightType,
  selectSelectedTripType,
  selectIsPrePackagedFlights,
  selectSelectedFlightOptions,
  selectSelectedFlightId,
  selectFiltersCount,
} from "./index";
import SelectedFlightsCard from "./SelectedFlightsCard";
import FlightFilters from "./FlightFilters";
import { selectSelectedDrawer } from "../../components/organisms/Drawer";
import FlightsChart from "./FlightsChart";
import FlightLoader from "./FlightLoader";
import RoundTripFlights from "./RoundTripFlights";
import PackagedFlights from "./PackagedFlights";
import { actions as searchActions } from "../../components/organisms/Search/search.reducer";
import { actions as flightResultsActions } from "./flightResults.reducer";
import {
  TRIP_TYPES,
  FLIGHTS_RESULT_TYPE,
  DEFAULT_VALUES,
  INITIAL_SORT_FILTERS,
  ROUNDTRIP_RESULT_FORMATS,
} from "../../constants";
import config from "../../config.json";

const { brandName } = config;
const { FETCH_TBO_FLIGHTS, FETCH_AMEDUES_FLIGHTS, PENDING, FETCH_USER } =
  SPINNER_NAMES;
const { ROUND_TRIP, ONE_WAY, MULTI_CITY } = TRIP_TYPES;
const { ZERO, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const { OUTBOUND_RESULT, INBOUND_RESULT, PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const { PACKAGES, ISOLATED } = ROUNDTRIP_RESULT_FORMATS;
const CAROUSEL_HANDLERS = {
  LEFT: "left",
  RIGHT: "right",
};
const { setSortedFlights } = searchActions;
const { setCurrentFlightType, setRoundTripResultFormat, setFiltersCount } =
  flightResultsActions;

const FlightResults = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const selectedFlightId = useSelector(selectSelectedFlightId);
  const flights = useSelector(selectFlights);
  const searchFilters = useSelector(selectSearchFilters);
  const currentFlightType = useSelector(selectCurrentFlightType);
  const tripType = useSelector(selectSelectedTripType);
  const flightFilters = useSelector(selectActiveFilters);
  const selectedDrawer = useSelector(selectSelectedDrawer);
  const filteredFlights = useSelector(selectFilteredFlights) || EMPTY_OBJECT;
  const flightsCount = useSelector(selectFlightsCount);
  const isPrePackaged = useSelector(selectIsPrePackagedFlights);
  const selectedActiveSortOrder = useSelector(selectActiveSortOrder);
  const selectedFlightOptions = useSelector(selectSelectedFlightOptions);
  const filtersCount = useSelector(selectFiltersCount);

  const [showPackages, setShowPackages] = useState(isPrePackaged);
  const [showSpinner, setShowSpinner] = useState(true);
  const [totalNonStops, setTotalNonStops] = useState(ZERO);

  const shouldShowSelectedFlightsCard = !isEmpty(selectedFlightOptions);

  const areFlightsAvailable =
    get(flightsCount, "packages", ZERO) ||
    get(flightsCount, "inbound", ZERO) ||
    get(flightsCount, "outbound", ZERO);

  const areFilteredFlightsAvailable =
    get(filteredFlights, "packages", [])?.length ||
    get(filteredFlights, "isolated.inbound", [])?.length ||
    get(filteredFlights, "isolated.outbound", [])?.length;

  const isReissuanceFlight =
    search && getObjectFromQueryParams(search).searchType === "1";

  const isOneWay = tripType === ONE_WAY;
  const isMultiCity = tripType === MULTI_CITY;
  const { packages = [], isolated = {} } = filteredFlights;
  const { inbound = [], outbound = [] } = isolated;
  const showMultiCityFilters =
    get(packages, "0.itineraries", EMPTY_ARRAY).length > 3;
  const shouldShowSideFilter =
    (isOneWay || showMultiCityFilters) &&
    !selectedDrawer &&
    !!areFlightsAvailable;

  const shouldShowFlightsChart = useMemo(() => {
    if (isMultiCity) return false;
    else return !isEmpty(packages) || !isEmpty(inbound) || !isEmpty(outbound);
  }, [tripType, packages, inbound, outbound]);

  const updatePackageFlights = (updatedFilteredFlights = {}) => {
    if (updatedFilteredFlights.packages && selectedActiveSortOrder.packages)
      return {
        ...updatedFilteredFlights,
        packages: getSortedFlights(
          updatedFilteredFlights.packages,
          selectedActiveSortOrder.packages
        ),
      };
    return updatedFilteredFlights;
  };

  const updateInboundFlights = (updatedFilteredFlights = {}) => {
    if (
      updatedFilteredFlights.isolated &&
      updatedFilteredFlights.isolated.inbound &&
      selectedActiveSortOrder.inbound
    )
      return {
        ...updatedFilteredFlights,
        isolated: {
          ...updatedFilteredFlights.isolated,
          inbound: getSortedFlights(
            updatedFilteredFlights.isolated.inbound,
            selectedActiveSortOrder.inbound
          ),
        },
      };
    return updatedFilteredFlights;
  };

  const updateOutboundFlights = (updatedFilteredFlights = {}) => {
    if (
      updatedFilteredFlights.isolated &&
      updatedFilteredFlights.isolated.outbound &&
      selectedActiveSortOrder.outbound
    )
      return {
        ...updatedFilteredFlights,
        isolated: {
          ...updatedFilteredFlights.isolated,
          outbound: getSortedFlights(
            updatedFilteredFlights.isolated.outbound,
            selectedActiveSortOrder.outbound
          ),
        },
      };
    return updatedFilteredFlights;
  };

  useEffect(() => {
    dispatch(setRoundTripResultFormat(showPackages ? PACKAGES : ISOLATED));
  }, [showPackages, tripType]);

  useEffect(() => {
    let updatedFilteredFlights = filteredFlights;
    updatedFilteredFlights = updatePackageFlights(updatedFilteredFlights);
    updatedFilteredFlights = updateInboundFlights(updatedFilteredFlights);
    updatedFilteredFlights = updateOutboundFlights(updatedFilteredFlights);
    dispatch(setSortedFlights(updatedFilteredFlights));
  }, [filteredFlights]);

  useEffect(() => {
    if (isEmpty(flights)) return;
    const { isolated, packages } = flights;
    !isEmpty(packages) && isEmpty(isolated) && setShowPackages(true);
    isEmpty(packages) && !isEmpty(isolated) && setShowPackages(false);
  }, [tripType, flights]);

  const handleFlightResultsSwipe = useCallback(
    (handle) =>
      dispatch(
        setCurrentFlightType(
          handle === CAROUSEL_HANDLERS.LEFT ? OUTBOUND_RESULT : INBOUND_RESULT
        )
      ),
    [dispatch]
  );

  useEffect(() => {
    const numberOfNonStops = calculateNumberOfNonStops(
      filteredFlights,
      PACKAGE_RESULT
    );
    setTotalNonStops(numberOfNonStops);
  }, [showPackages, filteredFlights]);

  const renderFlightsCard = useMemo(() => {
    let FlightsType = RoundTripFlights;
    if (isEmpty(filteredFlights)) return null;
    let props = {
      selectedFlightId,
    };
    if (showPackages) {
      FlightsType = PackagedFlights;
      props = {
        numberOfNonStops: totalNonStops,
        selectedFlightId: selectedFlightId.packages,
        showOneWayCard: tripType === ONE_WAY,
        isReissuanceFlight,
      };
    }
    return <FlightsType {...props} />;
  }, [
    showPackages,
    tripType,
    filteredFlights,
    selectedFlightId,
    totalNonStops,
  ]);

  const handleSpinner = useCallback(
    (name, spinners) => {
      const showSpinner =
        !areFlightsAvailable &&
        name.some((spinnerName) =>
          spinners.some(
            (spinner) => spinnerName === spinner || spinner === PENDING
          )
        );
      setShowSpinner(showSpinner);
      return showSpinner; // refactor
    },
    [flightsCount, areFlightsAvailable]
  );
  const getFiltersLength = (filters) => {
    if (!isEmpty(filters)) {
      const updatedFiltersCount = (INITIAL_SORT_FILTERS, filters) => {
        const differencesCount = size(
          differenceWith(
            toPairs(INITIAL_SORT_FILTERS),
            toPairs(filters),
            isEqual
          )
        );
        return differencesCount;
      };
      return updatedFiltersCount(INITIAL_SORT_FILTERS, filters);
    }
  };

  const calculateFiltersLength = useCallback(() => {
    if (!isEmpty(flightFilters)) {
      const { packages } = flightFilters;
      if (tripType === ONE_WAY) {
        return getFiltersLength(packages);
      } else if (tripType === ROUND_TRIP && !showPackages) {
        const { inbound, outbound } = flightFilters.isolated;
        const inboundFilters = getFiltersLength(inbound);
        const outboundFilters = getFiltersLength(outbound);
        return inboundFilters + outboundFilters;
      } else return getFiltersLength(packages);
    }
  }, [tripType, flightFilters]);

  useEffect(() => {
    const filterCount = calculateFiltersLength();
    setFiltersCount(filterCount);
    dispatch(setFiltersCount(filterCount));
  }, [calculateFiltersLength]);

  return (
    <>
      <Helmet>
        <title>{brandName} | Flights Results</title>
      </Helmet>
      <div className="sm:sticky sm:top-0 w-full z-20 bg-white">
        <div className="flex-center px-4 sm:px-6 py-4 border-b border-gray-200">
          <SearchSection showEditSearch={true} />
        </div>
        {!showSpinner && !!areFlightsAvailable && (
          <div
            className={classNames(
              "flex-center py-4 px-6 border-gray-200 border-b w-full border-contrast-200 flex flex-col md:flex-row md:items-center gap-0",
              {
                "flex justify-end w-full xl:hidden":
                  tripType === ONE_WAY && !filtersCount,
              }
            )}
          >
            {isMultiCity && (
              <span className="font-semibold">{packages.length} Flights</span>
            )}
            <FlightHeader
              showPackages={showPackages}
              setShowPackages={setShowPackages}
              currentFlightType={currentFlightType}
              searchFilters={searchFilters}
              searchResult={flights}
              calculateFiltersLength={calculateFiltersLength}
            />
          </div>
        )}
      </div>
      <section className="min-h-[60vh] flex flex-col align-middle relative">
        <Spinner
          name={[FETCH_AMEDUES_FLIGHTS, FETCH_TBO_FLIGHTS, FETCH_USER]}
          loaderComponent={<FlightLoader />}
          message={t("flightResults.searching")}
          customSpinnerMethod={handleSpinner}
          showSpinner={showSpinner}
        >
          {!isEmpty(flights) ? (
            <div
              className={classNames(
                "p-4 pt-2 md:p-2 flex flex-col justify-between ",
                {
                  "xl:ml-80": shouldShowSideFilter,
                  "h-[70vh]":
                    areFlightsAvailable && !areFilteredFlightsAvailable,
                }
              )}
            >
              <div className="flex-col m-2 max-w-auto justify-center items-center">
                {shouldShowFlightsChart && (
                  <div
                    className={classNames({ "m-2": tripType === ROUND_TRIP })}
                  >
                    <FlightsChart
                      showPackages={showPackages}
                      tripType={tripType}
                    />
                  </div>
                )}
                <Tooltip
                  className="!bg-primary-900 !z-30 !rounded-lg !hidden md:!block"
                  id="refundability-tag"
                />
                <div>
                  <div className="flex flex-col gap-6">{renderFlightsCard}</div>
                </div>
                {tripType === ROUND_TRIP &&
                  !isEmpty(isolated) &&
                  !showPackages && (
                    <>
                      {/* TODO: Update logic for swiper, TBD */}
                      <div className="block md:hidden">
                        <button
                          className="search-result-button-prev w-12 h-12 rounded-full bg-primary-400  hover:bg-primary-500 grid place-content-center fixed left-2 top-1/2 z-10 mt-10 mb-10 cursor-pointer disabled:bg-contrast-400 disabled:opacity-50 disabled:cursor-not-allowed"
                          disabled={currentFlightType === OUTBOUND_RESULT}
                          onClick={() =>
                            handleFlightResultsSwipe(CAROUSEL_HANDLERS.LEFT)
                          }
                        >
                          <RenderSVG
                            Svg={LeftIcon}
                            width="30"
                            height="30"
                            alt="left-icon"
                          />
                        </button>
                        <button
                          className="search-result-button-next w-12 h-12 rounded-full bg-primary-400  hover:bg-primary-500 grid place-content-center fixed right-2 top-1/2 z-10 mt-10 mb-10 cursor-pointer disabled:bg-contrast-400 disabled:opacity-50 disabled:cursor-not-allowed"
                          disabled={currentFlightType === INBOUND_RESULT}
                          onClick={() =>
                            handleFlightResultsSwipe(CAROUSEL_HANDLERS.RIGHT)
                          }
                        >
                          <RenderSVG
                            Svg={RightIcon}
                            width="30"
                            height="30"
                            alt="right-icon"
                          />
                        </button>
                      </div>{" "}
                    </>
                  )}
              </div>
              {shouldShowSelectedFlightsCard && (
                <SelectedFlightsCard
                  selectedFlightOptions={selectedFlightOptions}
                  currentFlightType={currentFlightType}
                />
              )}
              {shouldShowSideFilter && (
                <FlightFilters
                  currentFlightType={currentFlightType}
                  searchFilters={searchFilters}
                  showPackages={showPackages}
                />
              )}
            </div>
          ) : (
            <NoFlightResult showPackages={showPackages} />
          )}
        </Spinner>
      </section>
    </>
  );
};

export default FlightResults;
