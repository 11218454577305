import { DEFAULT_VALUES } from "../../constants";

const { ZERO } = DEFAULT_VALUES;

export const getFormattedAmadeusReponse = (amadeusData) => {
  if (!amadeusData || !amadeusData.data || amadeusData.data.length === ZERO)
    return [];

  return amadeusData.data.map((data) => ({
    price: data.price.total,
    date: data.departureDate,
  }));
};
