import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import { SKELETON_COLOR } from "../../../constants";

const { BASE_COLOR, HIGHLIGHT_COLOR } = SKELETON_COLOR;

const HotelsMapSkeleton = () => {
    return (
    <SkeletonTheme baseColor={BASE_COLOR} highlightColor={HIGHLIGHT_COLOR}>
        <div className='w-full flex flex-col gap-3 p-2'>
            <Skeleton height={160} width={"100%"} />
            <Skeleton height={160} width={"100%"} />
            <Skeleton height={160} width={"100%"} />
            <Skeleton height={160} width={"100%"} />
        </div>
    </SkeletonTheme>
    );
};

export default HotelsMapSkeleton;
