import getTBOCancellationReq from "./getTBOCancellationReq";
import getAmadeusCancellationReq from "./getAmadeusCancellationReq";
import { FLIGHT_PROVIDERS } from "../../constants";

const { TBO } = FLIGHT_PROVIDERS;

const mapCancellationRequest = (source, bookingInfo) => {
  return source === TBO
    ? getTBOCancellationReq(bookingInfo)
    : getAmadeusCancellationReq(bookingInfo);
};

export default mapCancellationRequest;
