
import {
  HotelImage,
  Location,
  RenderSVG,
  SuitCase,
} from "../../../../assets/icons";

const HotelLoader = () => {
  return (
    <div className='w-full h-[500px] flex justify-center items-center'>
      <div className='relative w-3/4 sm:w-1/2 h-32 flex items-center justify-center'>
        <div className='absolute w-full h-3 bg-contrast-300 rounded-full'>
          <div className='absolute h-3 w-full bg-primary-600 rounded-full animate-hotel-search-progress'></div>
          <div className='absolute animate-hotel-icon'>
            <RenderSVG Svg={SuitCase} width='80' height='80' />
          </div>
        </div>
        <div className='absolute right-0 top-[38px] animate-fade-search-fade'>
          <RenderSVG Svg={Location} />
        </div>
        <div className='absolute right-[-20px] bottom-[70px] animate-fade-hotel-fade '>
          <RenderSVG Svg={HotelImage} width='80' height='80' />
        </div>
        <div className='absolute text-lg sm:text-2xl top-24  leading-8 text-contrast-900 font-bold font-[inter]'>
          Finding Hotels...
        </div>
      </div>
    </div>
  );
};

export default HotelLoader;
