import get from "lodash/get";
import airportInfo from "../assets/json/airportInfo.json";
import { DEFAULT_VALUES } from "../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;

export const getUniqueCountriesByIataCodes = (uniqueIataCodes) => {
  const countryNames = uniqueIataCodes.map((iataCode) =>
    get(airportInfo, `${iataCode}.country`, EMPTY_STRING)
  );

  return [...new Set(countryNames)];
};

export const checkIsInternationalFlight = (itineraries) => {
  const allIataCodes = itineraries.flatMap((flight) =>
    flight?.segments?.flatMap((segment) => [
      segment.departure.iataCode,
      segment.arrival.iataCode,
    ])
  );
  const uniqueIataCodes = [...new Set(allIataCodes)];
  const countries = getUniqueCountriesByIataCodes(uniqueIataCodes);
  return countries.length > 1;
};

