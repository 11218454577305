import { DEFAULT_VALUES } from "../../../../constants";
import TravelerCard from "./TravelerCard";

const { EMPTY_ARRAY } = DEFAULT_VALUES;

const getFormattedTravelers = (travelers) =>
  travelers.reduce((result, traveler) => {
    const { travelerType } = traveler;
    if (travelerType) {
      result[travelerType] = result[travelerType] || [];
      result[travelerType].push(traveler);
    }
    return result;
  }, {});

const TravelerDetailsSection = ({ travlerDetails = EMPTY_ARRAY }) => {
  const totalTravelers = travlerDetails.length;
  const formattedTravelers = getFormattedTravelers(travlerDetails);

  return (
    <div className="bg-white rounded-xl border border-gray-300 overflow-hidden">
      <div className="px-6 py-4 flex items-center border-b border-gray-300 bg-gray-50">
        <h4 className="text-lg font-bold text-gray-900 flex-1">Travelers</h4>
        <h4 className="text-base font-medium text-gray-900">
          {totalTravelers}
        </h4>
      </div>
      <div>
        {Object.entries(formattedTravelers).map(
          ([travelerType, travelersData]) => (
            <div key={travelerType}>
              <div className="relative px-6 py-10">
                <div className="flex items-center mb-2">
                  <span className="text-xs font-semibold text-indigo-800 bg-indigo-50 px-3 py-1 rounded-3xl">
                    {travelerType} - {travelersData.length}
                  </span>
                </div>
                {travelersData.map((travelerData) => (
                  <TravelerCard
                    key={travelerData.id}
                    travelerData={travelerData}
                  />
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default TravelerDetailsSection;
