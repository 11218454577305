const pendingRequests = {};
const apiAbortMessage = "Request canceled due to new request";

const getFormatedUrl = (url) => url.split("?")[0];

export const addAwaitedRequest = (url, cancelTokenSource) => {
  const formattedUrl = getFormatedUrl(url);

  /*--- verify the already awaited request ---*/
  if (pendingRequests[formattedUrl])
    pendingRequests[formattedUrl].cancel(apiAbortMessage);

  pendingRequests[formattedUrl] = cancelTokenSource;
};

export const removeAwaitedRequests = (url) => {
  const formattedUrl = getFormatedUrl(url);
  delete pendingRequests[formattedUrl];
};
