import { useState, useEffect } from "react";
import { cloneDeep, get, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import { PhoneNumberInput } from "../../../components/atoms/PhoneNumberInput";
import Asterisk from "../../../components/atoms/Asterisk";
import { getRequiredErrorMessage } from "../../../helper";
import { DEFAULT_VALUES, REGEX } from "../../../constants";
import { selectTravelerInfo } from "../../Profile/profile.selector";
import { actions } from "./flightBookings.reducer";
import { selectContactDetails } from "./index";

const { EMPTY_STRING, EMPTY_ARRAY, ZERO } = DEFAULT_VALUES;
const { EMAIL, PHONE_NUMBER } = REGEX;

const { setContactDetails } = actions;

const renderFieldError = (name, errors, touched) =>
  get(errors, name, false) &&
  get(touched, name, false) && (
    <ErrorMessage errorMessage={get(errors, name)} />
  );

const ContactInformation = ({ defaultValues, contactFormRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTravelerInfo = useSelector(selectTravelerInfo);
  const selectedContactDetails = useSelector(selectContactDetails);

  const travelerInfo = !isEmpty(selectedTravelerInfo)
    ? selectedTravelerInfo
    : EMPTY_ARRAY;

  const [initialValues, setInitialValues] = useState(defaultValues);
  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(EMAIL, t("profilePage.errors.emailFormat"))
      .required(getRequiredErrorMessage("profilePage.email", t)),
    phoneNumber: Yup.string()
      .matches(PHONE_NUMBER, t("profilePage.errors.phone"))
      .required(getRequiredErrorMessage("profilePage.phone", t)),
  });

  useEffect(() => {
    const primaryTraveler = travelerInfo.find(({ isPrimary }) => isPrimary);
    if (selectedContactDetails) setInitialValues(selectedContactDetails);
    else if (primaryTraveler) {
      const phone = get(
        primaryTraveler,
        "profileDetails.phoneNumber",
        EMPTY_STRING
      );
      const updatedValues = {
        email: get(primaryTraveler, "profileDetails.email", EMPTY_STRING),
        phoneCode: phone.substring(ZERO, phone.indexOf('-')),
        phoneNumber: phone.replace('+', '').replace('-', ''),
      };
      setInitialValues(updatedValues);
    }
  }, [selectedContactDetails, travelerInfo]);

  const handleFormSubmit = (values) => {
    const updatedValues = cloneDeep(values)
    updatedValues.email = values.email.toLowerCase();
    dispatch(setContactDetails(updatedValues))
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      enableReinitialize
      innerRef={contactFormRef}
      onSubmit={handleFormSubmit}
    >
      {({ values, errors, touched, setFieldTouched, setFieldValue }) => (
        <Form>
          <div className="w-full mt-4">
            <div className="text-contrast-900 pl-4 text-md font-semibold bg-white justify-between">
              <span>{t("travelerInfo.contactInfo.title")}</span>
            </div>
            <div className="text-contrast-900 pl-4 text-xs bg-white justify-between rounded-t-lg">
              <span>{t("travelerInfo.contactInfo.subTitle")}</span>
            </div>
            <div className="grid gap-x-6 gap-y-4 grid-cols-6 bg-white w-full rounded-b-lg p-4">
              <div className="form-group col-span-6 md:col-span-2 lg:col-span-2">
                <label className="block text-sm font-medium mb-1 text-contrast-900">
                  {t("profilePage.email")}
                  <Asterisk />
                </label>
                <Field
                  id="email"
                  name="email"
                  type="text"
                  className="form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500"
                  placeholder={t("profilePage.placeholders.email")}
                  onBlur={() => {
                    setFieldTouched("email", true)
                    dispatch(
                      setContactDetails({
                        ...values,
                        email: values.email.toLowerCase(),
                      })
                    );
                  }}
                />
                <div>{renderFieldError("email", errors, touched)}</div>
              </div>
              <div className="form-group col-span-6 md:col-span-2 lg:col-span-2">
                <label className="block text-sm font-medium mb-1 text-contrast-900">
                  {t("profilePage.phone")}
                  <Asterisk />
                </label>
                <div className=" mb-2 w-full justify-between rounded-md border border-contrast-300 h-9 bg-white flex space-x-1">
                  <PhoneNumberInput
                    name="phoneNumber"
                    values={values}
                    placeholder={"loginAndSignup.placeholders.phone"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    t={t}
                    handleOnBlur={() => {
                      dispatch(
                        setContactDetails({
                          ...values,
                          phoneNumber: values.phoneNumber,
                          phoneCode: values.phoneCode,
                        })
                      );
                    }}
                  />
                </div>
                <div>{renderFieldError("phoneNumber", errors, touched)}</div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactInformation;
