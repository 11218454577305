import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { checkingPaxValidation, checkingContactAndAddress, getPaxErrorMessage } from "../../../helper";
import { selectFlightPriceInfo } from "../../FlightResults";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import Spinner, {
  SPINNER_NAMES,
  selectActiveSpinners,
} from "../../../components/organisms/Spinner";
import { SSR_BUTTONS, DEFAULT_VALUES } from "../../../constants";
import { actions } from "./flightBookings.reducer";
import { isEmpty } from "lodash";
import logEvent from "../../../utils/GATracker";

const { removeInvalidPax } = actions;
const { PRICE, PAYMENT_METHOD, SPECIAL_SERVICES } = SPINNER_NAMES;
const { BAGGAGES, PAYMENT } = SSR_BUTTONS;
const { TIMEOUT_DURATION } = DEFAULT_VALUES;

const ContinueButton = ({
  name,
  message,
  customMessage,
  shouldShowButton,
  isContinueDisabled,
  handleContinue,
  customOnClick = () => {},
  paxVerificationData = {},
  setTravelerSectionHighlighted = () => {},
  setScrollOnError = () => {}
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const flightPriceInfo = useSelector(selectFlightPriceInfo);
  const activeSpinners = useSelector(selectActiveSpinners);
  const spinnersList = [PRICE, PAYMENT_METHOD, SPECIAL_SERVICES];
  const isPaymentButtonDisabled = activeSpinners.some((spinnerName) =>
    spinnersList.includes(spinnerName)
  );
  const { travelerValidationSchema, selectedTravelers, selectedAddressAndContactDetails } = paxVerificationData;

  const showErrorMessage = (errorInfo, isValidationError) => {
    setShowError(isValidationError);
    setTravelerSectionHighlighted(true);
    setScrollOnError(errorInfo);
    setTimeout(() => {
      setShowError(false);
      setTravelerSectionHighlighted(false);
      setScrollOnError({});
    }, TIMEOUT_DURATION);
  };

  const handleOnClick = () => {
    if (name === BAGGAGES || name === PAYMENT) logEvent(`proceed_to_payment_btn_clicked`)
    else logEvent(`continue_to_next_step_btn_clicked`)
    customOnClick();
    const inValidPaxData = checkingPaxValidation(
      travelerValidationSchema,
      selectedTravelers
    );
    const inValidPaxIds = Object.keys(inValidPaxData);
    dispatch(removeInvalidPax(inValidPaxIds));
    const isPaxDetailsValidated = isEmpty(inValidPaxData);
    const isValidationError = isContinueDisabled || !isPaxDetailsValidated;
    const contactAndAddressErrorDetails = !isValidationError ? checkingContactAndAddress(selectedAddressAndContactDetails) : {};
    const errorMessage = getPaxErrorMessage(
      message,
      customMessage,
      name,
      flightPriceInfo,
      t,
      isPaxDetailsValidated
    );
    setErrorMessage(errorMessage);
    isValidationError || !isEmpty(contactAndAddressErrorDetails)
      ? showErrorMessage(contactAndAddressErrorDetails, isValidationError)
      : handleContinue();
  };

  return (
    <div className="my-4">
      {shouldShowButton && (
        <div className="my-4 w-full flex gap-2 align-text-bottom">
          <button
            type="button"
            className="h-10 py-2 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 border border-contrast-300 shadow-sm text-sm text-white font-medium disabled:cursor-not-allowed disabled:opacity-70"
            onClick={handleOnClick}
            disabled={isPaymentButtonDisabled}
          >
            <Spinner name={PAYMENT_METHOD} persistSize>
              {name === BAGGAGES || name === PAYMENT
                ? <div className="flex"><Spinner name={[PRICE, SPECIAL_SERVICES]}></Spinner>{t("travelerInfo.continueButton.continuePayment")}</div>
                : t("travelerInfo.continueButton.continue")}
            </Spinner>
          </button>
          {showError && (
            <ErrorMessage errorMessage={errorMessage} className="font-bold" />
          )}
        </div>
      )}
    </div>
  );
};

export default ContinueButton;
