import { DEFAULT_VALUES } from "../../constants";

const { ZERO, ONE, TWO, THREE } = DEFAULT_VALUES;

function convertToYYYYMMDD(dateString) {
  const parts = dateString.split("-");
  if (parts.length === THREE)
    return `${parts[TWO]}-${parts[ONE]}-${parts[ZERO]}`;
}

export const getFormattedTBOResponse = (tboData) => {
  if (!tboData || !tboData.data || tboData.data.length === ZERO) return [];

  return tboData.data.map((data) => ({
    price: (
      data.BaseFare +
      data.Tax +
      data.OtherCharges +
      data.FuelSurcharge
    ).toString(),
    date: convertToYYYYMMDD(data.DepartureDate.split(" ")[ZERO]),
  }));
};
