import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import {
  TRIP_TYPES,
  TRIP_TYPES_ARRAY,
  DEFAULT_VALUES,
  JOURNEY_DEFAULT_VALUES,
  ROUTES,
} from "../../../constants";
import logEvent from "../../../utils/GATracker";

const { MULTI_CITY, ROUND_TRIP, ONE_WAY } = TRIP_TYPES;
const { EMPTY_STRING, ZERO, EMPTY_OBJECT, TWO } = DEFAULT_VALUES;
const JOURNEY = "journey";
const { HOME } = ROUTES;

const TripTypeSelector = ({ textColor }) => {
  const { values, setFieldValue, setTouched, handleChange } =
    useFormikContext();
  const { t } = useTranslation();
  const location = useLocation();

  const isHomePage = location.pathname === HOME;

  const { tripType } = values;

  const handleTripTypeChange = (e) => {

    const getReturnDate = (departureDate) => {
      const returnDate = new Date(departureDate);
      return returnDate.setDate(returnDate.getDate() + TWO);
    };

    handleChange(e);
    const type = e.target.value;
    logEvent(`${type}_selected`);
    switch (type) {
      case MULTI_CITY:
        setTouched(EMPTY_OBJECT);
        const { departureDate, destCode } = values.journey[ZERO];
        const updatedJourneyValues = [
          { ...values.journey[ZERO], returnDate: EMPTY_STRING },
          {
            ...JOURNEY_DEFAULT_VALUES,
            originCode: destCode,
            destCode: EMPTY_STRING,
            departureDate,
          },
        ];
        setFieldValue(JOURNEY, updatedJourneyValues);
        break;
      case ROUND_TRIP:
        const returnDate = new Date(getReturnDate(values.journey[ZERO].departureDate)).toISOString().split("T")[0];
        setFieldValue(JOURNEY, [{ ...values.journey[ZERO], returnDate }]);
        break;
      case ONE_WAY:
        setFieldValue(JOURNEY, [
          { ...values.journey[ZERO], returnDate: EMPTY_STRING },
        ]);
    }
  };

  return (
    <div className=" flex flex-wrap items-center gap-2 sm:gap-4 me-auto">
      {TRIP_TYPES_ARRAY.map(({ id, value, label }) => (
        <div key={id} className="checkbox">
          <label className="flex items-center gap-2 cursor-pointer">
            <input
              className={classNames("no-shadow text-contrast-600 cursor-pointer", {
                "!border-white": isHomePage,
              })}
              type="radio"
              name="tripType"
              value={value}
              checked={tripType === value}
              onChange={handleTripTypeChange}
            />
            <span className={`text-xs sm:text-sm lg:text-md cursor-pointer ${textColor}`}>
              {t(label)}
            </span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default TripTypeSelector;
