import { useEffect, useMemo, useRef, useState } from "react";
import { get, set, isEmpty, isString } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SeatSelection from "./SeatSelection";
import MealSelection from "./Meals/MealSelection";
import {
  mapBookingAPIRequest,
  setToSessionStorage,
  getAirportInfoByIata,
  loadScript,
  generateTavaBookingId,
  getFormattedTime,
  travelersSortbyTravelerType,
  generateTravelerValidationSchema,
  generateContactValidationSchema,
  generateAddressValidationSchema,
  getPrimaryTravelerIndex,
  getPriceDifference,
} from "../../../helper";
import BookingTravelers from "./BookingTravelers";
import {
  DEFAULT_VALUES,
  ROUTES,
  CACHE_KEYS,
  FLIGHT_SERVICE_TYPE,
  ENDPOINTS,
  TRAVELER_TYPE,
  SSR_BUTTONS,
  INDEX,
  REGEX,
  INITIAL_ADDRESS_DETAILS,
  INITIAL_CONTACT_DETAILS,
  INITIAL_TRAVELER_VALIDATION_SCHEMA,
  TRAVELLER_INFORMATION_SECTIONS,
} from "../../../constants";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import {
  selectFlightPriceInfo,
  selectFlightPriceReq,
  selectIsInternationalFlight,
} from "../../FlightResults";
import TripOverviewCard from "../../../components/molecules/TripOverviewCard";
import {
  selectSelectedTravelers,
  initiateBooking,
  selectSpecialServices,
  selectSelectedPromoCode,
  selectSelectedLCCBaggages,
  selectSelectedLCCMeals,
  selectSelectedLCCSeats,
  selectSelectedNonLCCMeals,
  selectSelectedNonLCCSeats,
  selectSSRDefaultValuesLCC,
  setSelectedTravelers,
  setBookingTravelers,
  setGstDetails,
  selectBookingTravelers,
  setFlightBookingReqBody,
  selectAddressDetails,
  selectContactDetails,
} from "./index";
import PromoCodeCard from "../../../components/molecules/PromoCodeCard";
import {
  selectSearchFilters,
  selectSelectedReIssuanceFlight,
} from "../../../components/organisms/Search";
import PriceChangedModal from "../../../components/organisms/AppModals/PriceChangedModal";
import FlightDetailChangeInfoModal from "../../../components/organisms/AppModals/FlightDetailChangeInfoModal";
import BaggageSelection from "./Baggages";
import { selectCountryInfo, selectUserInfo } from "../../Profile";
import {
  setSelectedModal,
  selectSelectedModal,
} from "../../../components/organisms/Modal";
import { MODALS } from "../../../components/organisms/AppModals";
import { SpecialServicesSkeleton } from "../../../components/organisms/AppSkeletons";
import ContinueButton from "./ContinueButton";
import { setSessionFlag } from "../../session";

const { LAST_NAME_AMENDMENT_MODAL, REFUND_MODAL } = MODALS;
const { FLIGHT_PAYMENT } = ROUTES;
const { EMPTY_ARRAY, EMPTY_OBJECT, ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { SPECIAL_SERVICES, PRICE } = SPINNER_NAMES;
const { BOOKING_INFORMATION, BOOKING_API_REQUEST } = CACHE_KEYS;
const { TBO_BOOKING_URL, AMADEUS_BOOKING_URL, TBO_REISSUANCE_URL } = ENDPOINTS;
const { HELD_INFANT, ADULT, CHILD } = TRAVELER_TYPE;
const { TRAVELERS, SEATS, MEALS, BAGGAGES, PAYMENT } = SSR_BUTTONS;
const { FIRST, SECOND, THIRD } = INDEX;
const carriersToCheck = ["spicejet"];
const { BAGGAGE_COUNT_REGEX } = REGEX;
const NEPAL = "Nepal";
const BHUTAN_AIRLINES = "Bhutan Airlines";
const DEFAULT_BAGGAGE_UNIT = "kg";

const RAZORPAY_CHECKOUT_SCRIPT_URL =
  "https://checkout.razorpay.com/v1/checkout.js";
const { TBO, AMADEUS } = FLIGHT_SERVICE_TYPE;
const { CONTACT_INFO, ADDRESS_INFO } = TRAVELLER_INFORMATION_SECTIONS;

const getInitialSelectedDetails = (selectedReissuanceFlight) => {
  const travelerDetails = get(
    selectedReissuanceFlight,
    "bookingJSON.bookingRequest.travelerDetails",
    []
  );
  const contactDetails = travelerDetails.find(
    ({ email, phoneNumber }) => email && phoneNumber
  );
  const initialSelectedContact = contactDetails
    ? {
        email: contactDetails.email,
        phoneNumber: contactDetails.phoneNumber,
        phoneCode: contactDetails.phoneCode,
      }
    : INITIAL_CONTACT_DETAILS;
  const initialSelectedAddress = contactDetails
    ? {
        address: contactDetails.address,
        city: contactDetails.city,
        nationality: { isoCode: contactDetails.nationality },
      }
    : INITIAL_ADDRESS_DETAILS;
  return { initialSelectedContact, initialSelectedAddress };
};

const getFormattedBaggageDifference = (currentBaggage, previousBaggage) => {
  const currentBaggageValue = currentBaggage.split(" ");
  const previousBaggageValue = previousBaggage.split(" ");
  const changeInBaggage =
    currentBaggageValue[ZERO] - previousBaggageValue[ZERO];
  const baggageUnit = previousBaggageValue[ONE];
  return { changeInBaggage, baggageUnit };
};

const getBaggageDifference = (flightPriceReq, flightPriceInfo) => {
  const previousBaggage = flightPriceReq.reduce(
    (acc, requestBody) => {
      const itineraries = get(requestBody, "price.itineraries", EMPTY_ARRAY);
      const itineraryBaggage = itineraries.reduce(
        (itineraryAcc, itinerary) => {
          const baggage = get(itinerary, "segments[0].baggage", "0 kg");
          const match = baggage?.match(BAGGAGE_COUNT_REGEX) || EMPTY_ARRAY;
          const quantity =
            match.length > ZERO ? parseInt(match[SECOND], 10) || ZERO : ZERO;
          const unit =
            match.length > ZERO ? match[THIRD] : DEFAULT_BAGGAGE_UNIT;

          return {
            quantity: itineraryAcc.quantity + quantity,
            unit: unit,
          };
        },
        { quantity: ZERO, unit: DEFAULT_BAGGAGE_UNIT }
      );

      return {
        quantity: acc.quantity + itineraryBaggage.quantity,
        unit: itineraryBaggage.unit,
      };
    },
    { quantity: ZERO, unit: DEFAULT_BAGGAGE_UNIT }
  );
  const combinedPreviousBaggage = `${previousBaggage.quantity} ${previousBaggage.unit}`;

  const segments = get(flightPriceInfo, "[0].segments", EMPTY_ARRAY);
  const currentBaggage = segments.reduce(
    (segmentAcc, segment) => {
      const baggage = get(segment, "[0].Baggage", "0 kg");
      const match = baggage?.match(BAGGAGE_COUNT_REGEX) || EMPTY_ARRAY;
      const quantity =
        match.length > ZERO ? parseInt(match[SECOND], 10) || ZERO : ZERO;
      const unit = match.length > ZERO ? match[THIRD] : DEFAULT_BAGGAGE_UNIT;
      return {
        quantity: segmentAcc.quantity + quantity,
        unit: unit,
      };
    },
    { quantity: ZERO, unit: DEFAULT_BAGGAGE_UNIT }
  );
  const combinedCurrentBaggage = `${currentBaggage.quantity} ${currentBaggage.unit}`;

  return getFormattedBaggageDifference(
    combinedCurrentBaggage,
    combinedPreviousBaggage
  );
};

const TravellerInformation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const specialServices = useSelector(selectSpecialServices);
  const selectedTravelers = useSelector(selectSelectedTravelers);
  const selectedContactDetails = useSelector(selectContactDetails);
  const selectedAddressDetails = useSelector(selectAddressDetails);
  const flightPriceReq = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;
  // LCC
  const selectedBaggages = useSelector(selectSelectedLCCBaggages);
  const selectedMeals = useSelector(selectSelectedLCCMeals);
  const selectedSeats = useSelector(selectSelectedLCCSeats);
  // Non LCC
  const selectedMealCategory = useSelector(selectSelectedNonLCCMeals);
  const selectedSeatPreferencesData = useSelector(selectSelectedNonLCCSeats);
  const selectedSSRDefaultValuesLCC = useSelector(selectSSRDefaultValuesLCC);
  const appliedPromoCode = useSelector(selectSelectedPromoCode);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const flightPriceInfo = useSelector(selectFlightPriceInfo);
  const isInternational = useSelector(selectIsInternationalFlight);
  const selectedBookingTravelers = useSelector(selectBookingTravelers);
  const selectedReissuanceFlight = useSelector(selectSelectedReIssuanceFlight);
  const selectedAccountEmail = useSelector(selectUserInfo);
  const selectedModal = useSelector(selectSelectedModal);
  const searchFilters = useSelector(selectSearchFilters);

  const isReissuanceFlight = flightPriceReq.some(
    ({ isReissuanceFlight }) => isReissuanceFlight
  );
  const [selectionProgress, setSelectionProgress] = useState({
    travelerSelection: false,
    seatSelection: false,
    mealSelection: false,
    baggageSelection: false,
  });
  const [presentSpecialServices, setPresentSpecialServices] = useState({
    seatDynamic: false,
    mealDynamic: false,
    baggages: false,
  });
  const [shouldShowModal, setShouldShowModal] = useState(true);
  const [isPaymentButtonClicked, setIsPaymentButtonClicked] = useState(false);
  const [paxSortByTravelerType, setPaxSortByTravelerType] = useState([]);
  const [isPaymentInProcess, setIsPaymentInProcess] = useState(false);
  const [travelerSectionHighlighted, setTravelerSectionHighlighted] =
    useState(false);
  const [scrollOnError, setScrollOnError] = useState({});

  const noSSRPresent =
    !presentSpecialServices.baggages &&
    !presentSpecialServices.seatDynamic &&
    !presentSpecialServices.mealDynamic;

  const [showFlightInfoChangeModal, setShowFlightInfoChangeModal] =
    useState(false);
  const [areDistinctTraveler, setAreDistinctTraveler] = useState(false);

  const gstFormRef = useRef();
  const contactFormRef = useRef();
  const addressFormRef = useRef();

  const { initialSelectedAddress, initialSelectedContact } =
    getInitialSelectedDetails(selectedReissuanceFlight);

  const addressDefaultValues = isReissuanceFlight
    ? initialSelectedAddress
    : INITIAL_ADDRESS_DETAILS;

  const contactDefaultValues = isReissuanceFlight
    ? initialSelectedContact
    : INITIAL_CONTACT_DETAILS;

  useEffect(() => {
    setAreDistinctTraveler(hasDistinctNamesAndCarriers(selectedTravelers));
  }, [selectedTravelers]);

  const {
    travelerPricings = EMPTY_ARRAY,
    price = EMPTY_OBJECT,
    documentsRequired: bookingRequirements = {},
    isPriceChanged = false,
  } = flightPriceInfo[FIRST] || EMPTY_OBJECT;
  const { grandTotal = ZERO } = price;

  const numberOfTravelers = travelerPricings.reduce(
    (totalCount, { travelersCount }) => totalCount + +travelersCount,
    ZERO
  );
  const selectableSSRServicesCount = travelerPricings.reduce(
    (totalCount, { travelersCount, travelerType }) =>
      totalCount + travelerType !== HELD_INFANT ? +travelersCount : ZERO,
    ZERO
  );

  const changeInPrice =
    isPriceChanged && getPriceDifference(grandTotal, flightPriceReq);

  const showPriceModal = Boolean(
    isPriceChanged && changeInPrice && shouldShowModal
  );

  const { IsGSTMandatory, GSTAllowed } = bookingRequirements;

  useEffect(() => {
    const seatSelection = !specialServices.some(
      ({ data: { seatDynamic, seatPreference } }) =>
        !isEmpty(seatDynamic) || !isEmpty(seatPreference)
    );
    const mealSelection = !specialServices.some(
      ({ data: { mealDynamic, meal } }) =>
        !isEmpty(mealDynamic) || !isEmpty(meal)
    );
    const baggageSelection = !specialServices.some(
      ({ data: { baggage } }) => !isEmpty(baggage)
    );

    setSelectionProgress((preState) => ({
      ...preState,
      seatSelection,
      mealSelection,
      baggageSelection,
    }));
    setPresentSpecialServices({
      seatDynamic: !seatSelection,
      mealDynamic: !mealSelection,
      baggages: !baggageSelection,
    });
  }, [specialServices]);

  const isLccFlight = flightPriceReq[ZERO]?.isLCC || false;
  const isGoingToNepal = get(
    flightPriceReq,
    "0.price.itineraries",
    EMPTY_ARRAY
  ).some(({ segments }) =>
    segments.some((segment) => {
      const { country } = getAirportInfoByIata(segment.arrival.iataCode);
      const carrierName = segment.carrierName;
      return (
        country === NEPAL && carrierName !== BHUTAN_AIRLINES && isLccFlight
      );
    })
  );

  const handleClose = () => setShouldShowModal(false);

  const flightChangedDetailResponse = get(
    flightPriceInfo,
    "0.flightDetailChangeInfo",
    EMPTY_STRING
  );

  const isInflightDetailsChanged = (valuesInflightChangedDetail) => {
    if (!isString(valuesInflightChangedDetail)) {
      return false;
    }

    const trimmedflightChangedDetail = valuesInflightChangedDetail.trim();

    return !!trimmedflightChangedDetail;
  };

  const hasFlightDetalilsChanged = isInflightDetailsChanged(
    flightChangedDetailResponse
  );

  const { changeInBaggage = ZERO, baggageUnit = DEFAULT_BAGGAGE_UNIT } =
    hasFlightDetalilsChanged &&
    getBaggageDifference(flightPriceReq, flightPriceInfo);
  const hasBaggageChanged = changeInBaggage !== ZERO;

  const previousTime = get(
    flightPriceReq,
    "[0].price.itineraries[0].segments[0].departure.time",
    EMPTY_STRING
  );
  const currentTime = get(
    flightPriceInfo,
    "[0].segments[0][0].Origin.DepTime",
    EMPTY_STRING
  );
  const formattedCurrentTime = getFormattedTime(currentTime);
  const hasTimeChanged = formattedCurrentTime !== previousTime;

  useEffect(() => {
    const hasBaggageOrTimeChanged =
      hasFlightDetalilsChanged && (hasBaggageChanged || hasTimeChanged);

    setShowFlightInfoChangeModal(hasBaggageOrTimeChanged);
  }, [hasFlightDetalilsChanged, hasBaggageChanged, hasTimeChanged]);

  useEffect(() => {
    const travelerDetails = get(
      selectedReissuanceFlight,
      "bookingJSON.bookingRequest.travelerDetails",
      []
    );
    const gstDetails = travelerDetails.find(({ gstDetails }) => gstDetails);
    gstDetails && dispatch(setGstDetails(gstDetails));
  }, [dispatch, selectedReissuanceFlight]);

  useEffect(() => {
    if (isPaymentButtonClicked && noSSRPresent && selectedModal === null) {
      setIsPaymentButtonClicked(false);
      handlePayment();
    }
  }, [selectedTravelers, selectedModal]);

  const paymentsDetails = {
    discountedPrice: parseFloat(
      get(appliedPromoCode, "offeredPrice", ZERO).toFixed(2)
    ),
    promocode: appliedPromoCode,
    discountAmount: parseFloat(
      get(appliedPromoCode, "discountAmount", ZERO).toFixed(2)
    ),
  };

  const source = get(flightPriceReq, "0.source", TBO);
  let url;
  if (source === TBO)
    url = isReissuanceFlight ? TBO_REISSUANCE_URL : TBO_BOOKING_URL;
  else url = AMADEUS_BOOKING_URL;

  const isAmadeusAndInternational = isInternational && source === AMADEUS;

  const handlePayment = async () => {
    if (isPaymentInProcess) return;
    setIsPaymentInProcess(true);
    const bookingId = selectedReissuanceFlight?.tavaBookingId;
    const loadedScript = await loadScript(RAZORPAY_CHECKOUT_SCRIPT_URL);
    if (!loadedScript) return;
    const { tripType } = searchFilters;

    setSelectionProgress({ ...selectionProgress, baggageSelection: true });
    dispatch(setSessionFlag(`session_updated_on_${Date()}`));
    const tavaBookingId =
      isReissuanceFlight && bookingId ? bookingId : generateTavaBookingId();
    const specialServicesData = {
      Baggage: selectedBaggages,
      MealDynamic: selectedMeals,
      SeatDynamic: selectedSeats,
      SeatPreferences: selectedSeatPreferencesData,
      Meal: selectedMealCategory,
      defaultValues: selectedSSRDefaultValuesLCC,
    };
    const bookingAPIRequest = mapBookingAPIRequest({
      selectedFlightPriceReqBody: flightPriceReq,
      selectedSelectedTravelers: selectedTravelers,
      selectedFlightPriceInformation: flightPriceInfo,
      specialServicesData,
      paymentsDetails,
      tavaBookingId,
      userCountryInfo: selectedCountryInfo,
      isAmadeusAndInternational,
      isReissuanceFlight,
      isGoingToNepal,
      accountEmail: selectedAccountEmail?.email
        ? selectedAccountEmail.email
        : EMPTY_STRING,
      tripType,
    });

    dispatch(setFlightBookingReqBody(bookingAPIRequest));

    const { userEmail, pnr, providerBookingId, id } =
      selectedReissuanceFlight || {};
    const travelerDetails = get(
      selectedReissuanceFlight,
      "bookingJSON.bookingRequest.travelerDetails",
      []
    );

    const travelerName = travelerDetails.find(
      ({ email }) => email === userEmail
    )?.givenName;
    const totalPrice = get(flightPriceInfo, "0.price.grandTotal", ZERO);
    const reissuanceDetails = {
      userEmail,
      pnr,
      travelerName,
      refundAmount: (totalPrice < ZERO
        ? Math.abs(totalPrice)
        : ZERO
      ).toString(),
    };

    const reissuanceRequest = {
      pnr,
      providerBookingId,
      bookingId: id,
      userEmail,
      tavaBookingId,
    };
    const initiateBookingRequest = {
      requestBody: {
        ...bookingAPIRequest,
        ...(isReissuanceFlight && reissuanceRequest),
      },
      url,
    };
    setToSessionStorage(
      BOOKING_API_REQUEST,
      initiateBookingRequest.requestBody
    );

    dispatch(initiateBooking(initiateBookingRequest)).then((res) => {
      setIsPaymentInProcess(false);
      if (!res.payload) return;
      const {
        payload: { output, created },
      } = res;

      setToSessionStorage(
        BOOKING_INFORMATION,
        isReissuanceFlight
          ? {
              ...output,
              tavaBookingId,
              isReissuanceFlight,
              reissuanceDetails: {
                ...reissuanceDetails,
                recordId: created.id,
              },
            }
          : {
              ...output,
              tavaBookingId,
            }
      );
      totalPrice > ZERO
        ? navigate(FLIGHT_PAYMENT)
        : dispatch(setSelectedModal(REFUND_MODAL));
    });
  };

  const hasSpecificCarriers = flightPriceReq?.some((flight) =>
    flight?.price?.itineraries?.some((itinerary) =>
      itinerary.segments?.some((segment) =>
        carriersToCheck.includes(segment.carrierName?.toLowerCase())
      )
    )
  );

  const hasDistinctNamesAndCarriers = (updatedTravelers) => {
    if (!hasSpecificCarriers) return;
    const formattedTravelerNames = updatedTravelers.map(
      (traveler) =>
        `${traveler.profileDetails.firstName.toLowerCase()} 
         ${traveler.profileDetails.lastName.toLowerCase()}`
    );
    const areTravelersNamesDistinct =
      new Set(formattedTravelerNames).size === updatedTravelers.length;
    return !areTravelersNamesDistinct && hasSpecificCarriers;
  };

  const handleTravelerContinue = () => {
    const primaryTravelerIndex = getPrimaryTravelerIndex(selectedTravelers);

    let updatedSelectedTravelers = selectedTravelers.map((traveler, index) => ({
      ...traveler,
      profileDetails: {
        ...traveler.profileDetails,
        ...contactFormRef.current?.values,
        ...addressFormRef.current?.values,
      },
      IsLeadPax: primaryTravelerIndex === index,
    }));

    if (GSTAllowed) {
      const isFormValid =
        !gstFormRef.current.values?.includeGST || gstFormRef.current.isValid;

      const updateFormValues = gstFormRef?.current?.values;
      const isGSTValid = IsGSTMandatory
        ? gstFormRef.current.isValid
        : isFormValid;
        if (!isGSTValid) return false;

      set(
        updatedSelectedTravelers,
        `${primaryTravelerIndex}.gstDetails`,
        updateFormValues
      );
    }

    const isInformationValid =
      contactFormRef.current?.isValid && addressFormRef.current?.isValid;
    if (!isInformationValid) return false; 
    const hasNoLastName = selectedTravelers.some(
      (item) => !item.profileDetails.lastName
    );
    if (hasNoLastName) {
      updatedSelectedTravelers = updatedSelectedTravelers.map((traveler) => ({
        ...traveler,
        profileDetails: {
          ...traveler.profileDetails,
          lastName:
            traveler.profileDetails?.lastName ||
            traveler.profileDetails?.firstName,
        },
      }));

      const updatedBookingTraveler = selectedBookingTravelers.map(
        (traveler) => {
          const isTravelerSelected = selectedTravelers.some(
            ({ travelerId }) => travelerId === traveler.travelerId
          );

          return isTravelerSelected
            ? {
                ...traveler,
                profileDetails: {
                  ...traveler.profileDetails,
                  lastName:
                    traveler.profileDetails?.lastName ||
                    traveler.profileDetails?.firstName,
                },
              }
            : traveler;
        }
      );
      dispatch(setBookingTravelers(updatedBookingTraveler));
      dispatch(setSelectedModal(LAST_NAME_AMENDMENT_MODAL));
    }
    dispatch(setSelectedTravelers(updatedSelectedTravelers));

    !hasDistinctNamesAndCarriers(updatedSelectedTravelers) &&
      !noSSRPresent &&
      setSelectionProgress({
        ...selectionProgress,
        travelerSelection: true,
      });
    dispatch(setSessionFlag(`session_updated_on_${Date()}`));
    return true;
  };

  useEffect(() => {
    const requiredFormattedTravelers = travelersSortbyTravelerType(
      dispatch,
      isReissuanceFlight,
      selectedReissuanceFlight,
      selectedBookingTravelers,
      setSelectedTravelers
    );
    setPaxSortByTravelerType(requiredFormattedTravelers);
  }, [
    dispatch,
    isReissuanceFlight,
    selectedReissuanceFlight,
    selectedBookingTravelers,
  ]);

  const travelerValidationSchema = useMemo(() => {
    const paxType = Object.keys(paxSortByTravelerType);
    return paxType.reduce((acc, type) => {
      acc[type] = generateTravelerValidationSchema(
        type,
        isAmadeusAndInternational,
        isGoingToNepal,
        flightPriceReq,
        flightPriceInfo,
        t
      );
      return acc;
    }, INITIAL_TRAVELER_VALIDATION_SCHEMA);
  }, [paxSortByTravelerType, flightPriceInfo]);

  const contactDetailsValidationSchema = useMemo(() => {
    return generateContactValidationSchema(t);
  }, []);

  const addressDetailsValidationSchema = useMemo(() => {
    return generateAddressValidationSchema(travelerPricings);
  }, []);

  const continueButtonProps = {
    [TRAVELERS]: {
      name: TRAVELERS,
      shouldShowButton: !noSSRPresent && !selectionProgress.travelerSelection,
      customOnClick: () => {
        gstFormRef.current?.handleSubmit();
        contactFormRef.current?.handleSubmit();
        addressFormRef.current?.handleSubmit();
      },
      isContinueDisabled:
        areDistinctTraveler || numberOfTravelers !== selectedTravelers.length,
      ...(areDistinctTraveler && {
        message: t("travelerInfo.error.distinctTraveler"),
      }),
      paxVerificationData: {
        travelerValidationSchema,
        selectedTravelers,
        selectedAddressAndContactDetails: [
          {
            data: selectedContactDetails,
            validationSchema: contactDetailsValidationSchema,
            section: CONTACT_INFO,
          },
          {
            data: selectedAddressDetails,
            validationSchema: addressDetailsValidationSchema,
            section: ADDRESS_INFO,
          },
        ],
      },
      setTravelerSectionHighlighted,
      setScrollOnError,
      handleContinue: handleTravelerContinue,
    },

    [SEATS]: {
      name: SEATS,
      shouldShowButton:
        selectionProgress.travelerSelection && !selectionProgress.seatSelection,
      isContinueDisabled: specialServices.some(
        ({ data: { seatDynamic } }, index) =>
          flightPriceInfo[index]?.requiredFields?.IsSeatRequired &&
          !isEmpty(seatDynamic) &&
          get(selectedSeats, seatDynamic[ZERO].FlightNumber, EMPTY_ARRAY)
            .length !== selectableSSRServicesCount
      ),
      paxVerificationData: {
        travelerValidationSchema,
        selectedTravelers,
      },
      handleContinue: () => {
        setSelectionProgress({
          ...selectionProgress,
          seatSelection: true,
        });
        dispatch(setSessionFlag(`session_updated_on_${Date()}`));
      },
    },

    [MEALS]: {
      name: MEALS,
      shouldShowButton:
        selectionProgress.travelerSelection &&
        selectionProgress.seatSelection &&
        !selectionProgress.mealSelection,
      isContinueDisabled: specialServices.some(
        ({ data: { mealDynamic } }, index) =>
          flightPriceInfo[index]?.requiredFields?.IsMealRequired &&
          !isEmpty(mealDynamic) &&
          get(selectedMeals, mealDynamic[ZERO].FlightNumber, EMPTY_ARRAY)
            .length !== selectableSSRServicesCount
      ),
      paxVerificationData: {
        travelerValidationSchema,
        selectedTravelers,
      },
      handleContinue: () => {
        setSelectionProgress({
          ...selectionProgress,
          mealSelection: true,
        });
        dispatch(setSessionFlag(`session_updated_on_${Date()}`));
      },
    },

    [BAGGAGES]: {
      name: BAGGAGES,
      shouldShowButton:
        selectionProgress.travelerSelection &&
        selectionProgress.seatSelection &&
        selectionProgress.mealSelection,
      isContinueDisabled: specialServices.some(
        ({ data: { baggage } }, index) =>
          flightPriceInfo[index]?.requiredFields?.IsBaggageRequired &&
          !isEmpty(baggage) &&
          get(selectedBaggages, baggage[ZERO].FlightNumber, EMPTY_ARRAY)
            .length !== selectableSSRServicesCount
      ),
      paxVerificationData: {
        travelerValidationSchema,
        selectedTravelers,
      },
      handleContinue: handlePayment,
    },

    [PAYMENT]: {
      name: PAYMENT,
      shouldShowButton: noSSRPresent,
      customOnClick: () => {
        gstFormRef.current?.handleSubmit();
        contactFormRef.current?.handleSubmit();
        addressFormRef.current?.handleSubmit();
      },
      isContinueDisabled:
        areDistinctTraveler || numberOfTravelers !== selectedTravelers.length,
      ...(areDistinctTraveler && {
        message: t("travelerInfo.error.distinctTraveler"),
      }),
      paxVerificationData: {
        travelerValidationSchema,
        selectedTravelers,
      },
      setTravelerSectionHighlighted,
      handleContinue: () => {
        handleTravelerContinue() && setIsPaymentButtonClicked(true);
      },
    },
  };

  return (
    <div className="flex flex-col align-middle min-h-[70vh]">
      <div className="mx-6 my-5">
        <div className="grid grid-cols-12 items-start gap-6 md:gap-10">
          <div className="col-span-12 lg:col-span-8">
            <div className="block lg:hidden col-span-12 lg:col-span-4 bg-white rounded-2xl">
              <TripOverviewCard />
            </div>
            <BookingTravelers
              selectionProgress={selectionProgress}
              setSelectionProgress={setSelectionProgress}
              gstFormRef={gstFormRef}
              contactFormRef={contactFormRef}
              addressFormRef={addressFormRef}
              contactDefaultValues={contactDefaultValues}
              addressDefaultValues={addressDefaultValues}
              isReissuanceFlight={isReissuanceFlight}
              isGoingToNepal={isGoingToNepal}
              travelersSortTravelerType={paxSortByTravelerType}
              travelerValidationSchema={travelerValidationSchema}
              highlightErrorBorder={travelerSectionHighlighted}
              bookingTravellerErrorSections={scrollOnError}
            />
            <ContinueButton {...continueButtonProps[TRAVELERS]} />
            <Spinner
              name={[SPECIAL_SERVICES, PRICE]}
              showSkeleton={true}
              loaderComponent={<SpecialServicesSkeleton />}
            >
              {specialServices.some(
                ({ data: { seatDynamic, seatPreference } }) =>
                  !isEmpty(seatDynamic) || !isEmpty(seatPreference)
              ) && (
                <div>
                  <SeatSelection
                    selectionProgress={selectionProgress}
                    setSelectionProgress={setSelectionProgress}
                  />
                  <ContinueButton {...continueButtonProps[SEATS]} />
                </div>
              )}
              {specialServices.some(
                ({ data: { mealDynamic, meal } }) =>
                  !isEmpty(mealDynamic) || !isEmpty(meal)
              ) && (
                <div>
                  <MealSelection
                    selectionProgress={selectionProgress}
                    setSelectionProgress={setSelectionProgress}
                  />
                  <ContinueButton {...continueButtonProps[MEALS]} />
                </div>
              )}
              {specialServices.some(
                ({ data: { baggage } }) => !isEmpty(baggage)
              ) && (
                <BaggageSelection
                  selectionProgress={selectionProgress}
                  setSelectionProgress={setSelectionProgress}
                />
              )}
            </Spinner>
            <div className="block lg:hidden col-span-12 lg:col-span-4 bg-white rounded-2xl">
              <PromoCodeCard />
            </div>
            {noSSRPresent ? (
              <ContinueButton {...continueButtonProps[PAYMENT]} />
            ) : (
              <ContinueButton {...continueButtonProps[BAGGAGES]} />
            )}
          </div>
          <div className="hidden lg:block col-span-12 lg:col-span-4 bg-white rounded-2xl">
            <TripOverviewCard />
            <PromoCodeCard />
          </div>
        </div>
        {showPriceModal && (
          <PriceChangedModal
            handleClose={handleClose}
            changeInPrice={changeInPrice}
          />
        )}
        {showFlightInfoChangeModal && (
          <FlightDetailChangeInfoModal
            handleClose={() => setShowFlightInfoChangeModal(false)}
            changeInBaggage={changeInBaggage}
            baggageUnit={baggageUnit}
            hasTimeChanged={hasTimeChanged}
            currentTime={formattedCurrentTime}
          />
        )}
      </div>
    </div>
  );
};

export default TravellerInformation;
