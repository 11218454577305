import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { SSO_PROVIDER, ROUTES } from "../constants";

const { FACEBOOK } = SSO_PROVIDER;
const { HOME } = ROUTES;

export const signout = (activeUser, timeout = 0) => {
  const { provider } = activeUser || {};
  if (provider === FACEBOOK) FacebookLoginClient.logout();
  localStorage.clear();
  sessionStorage.clear();

  setTimeout(() => {
    window.location.assign(HOME);
  }, timeout);
};
