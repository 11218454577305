import { useState } from "react";
import Modal, { setSelectedModal } from "../../Modal";
import Spinner, { SPINNER_NAMES } from "../../Spinner";
import { InformationRules, RenderSVG } from "../../../../assets/icons";
import { MODAL_SIZE } from "../../../../constants";
import { useDispatch } from "react-redux";
const { HOTEL_CANCELLATION } = SPINNER_NAMES;
const { LG } = MODAL_SIZE;

const HotelCancellationModal = ({ handleHotelCancellation }) => {
  const dispatch = useDispatch();
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const handleClose = () =>
    !isSpinnerActive && dispatch(setSelectedModal(null));
  return (
    <div className="overflow-auto">
      <Modal title="Confirm Cancellation" size={LG} handleClose={handleClose}>
        <div className="text-center">
          <div className="pt-6 pb-12">
            <div className="icon mb-5">
              <RenderSVG
                Svg={InformationRules}
                className="stroke-primary-800 mx-auto"
                fill="none"
                width={60}
                height={60}
              />
            </div>
            <h4 className="font-bold text-xl mb-2 text-red-600">
              This is the final step of cancellation
            </h4>
            <p className="text-blue-gray-500 text-sm">
              Once Cancelled, your booking can not be reinstated
            </p>
          </div>

          <div className="flex gap-3 pb-4 justify-center">
            <button
              type="button"
              onClick={handleClose}
              className="btn py-3 px-4 text-indigo-700 font-semibold"
            >
              Back
            </button>
            <button
              type="button"
              onClick={handleHotelCancellation}
              className="flex justify-center min-w-44 gap-2 py-3 px-4 rounded-md bg-red-600 disabled:bg-red-300 disabled:cursor-not-allowed hover:bg-red-800 active:bg-red-600 shadow-sm text-sm text-white font-medium"
              disabled={isSpinnerActive}
            >
              <Spinner
                name={HOTEL_CANCELLATION}
                setIsSpinnerActive={setIsSpinnerActive}
                size="w-5 h-5"
              />
             {!isSpinnerActive && <span> Confirm cancellation</span>} 
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HotelCancellationModal;
