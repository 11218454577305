import { DEFAULT_VALUES } from "../../../constants";

const { ONE } = DEFAULT_VALUES;

const RatingAndReview = ({ overAllRating }) => {
  const getRemark = () => {
    switch (Math.floor(overAllRating)) {
      case 1:
        return "Bad";
      case 2:
        return "Acceptable";
      case 3:
        return "Good";
      case 4:
        return "Very Good";
      case 5:
        return "Excellent";
      default:
        return "Average";
    }
  };

  const reviews = [
    {
      id: 1,
      title: "Featured Reviews by Couples",
      data: [
        {
          id: 1,
          review: "Good Stay",
          rated: "4.0 ",
          name: "Mitesh Patel",
          date: " Apr 23, 2023",
          description:
            "park in by radisson goa,we stayed for 3 night in april 23. about amenities, swiming pool time is arounnd 7 am to 10 pm so you can enjoy the pool in night time also..and bar was close to pool also so can have drink too . it has beautiful garden area..but quite small. Restaurants remain open 24 hours..and very important and good thing is .. morning breakfast time is 7:30to 11..so its good.. about room service was good.. room was clean..they provide room service at our convenient time...so uts nice every day they provide fresh water bottle 3-4 times although they have mentioned only 2 bottle per day..they dint charg us for morthan 2 bottle per day.its good.. cons:we had booked premium garden room , our room was 4103 ,had not any garden view,so we very very disappointed, it was back side view ... and gallery you cant use it because it has outdoor unit of AC it thorows hot air in gallery!!! this is big issue all garden view room..!! so do notice before booking of garden view premium room .. And About Reception area. There is no FAN /AC NOTHING AT ALL,...NO FAN ALSO..IN HOT SUMMER AND WE RECAHED HOTEL AT 11 AM..AND CHEKIJ WAS AROUND 2 PM..SO . HORRIBLE TO SIT THERE.. WITHOUT FAN PLEASE MAY REQUEST TO HOTEL MANAGEMENT ATLEAS INSTAL FAN AT RECEPTION.. reception lady and male person had ther own table fan ..but for guest no facilities, shocked.. only this was big negativ point.and ya room also had not fan too..so it somt time you will feel hot when ac will cutout...room should have also fan. apart from it..we had wonderful stay at hotel .about room cleaning,staff behaviour,service was wonderful, property is close to condolim beach and main market ,you can get taxi ,rent car ,bike infront of hotel,so was good thing . and yes breakfast was delicious.had large menu..north indian,american, south indian.20 to 30 item in brekafast 😋",
        },
        {
          id: 2,
          review: "Excellent Stay",
          rated: "5.0 ",
          name: "Gitesh Lonushte.",
          date: " Apr 23, 2023",
          description:
            "I recently had the pleasure of staying at the park inn by radisson in goa, and i must say it was an absolutely delightful experience from start to finish. From the moment i arrived, i was greeted by a warm and friendly staff who made me feel right at home. The location of the hotel is fantastic, situated in the heart of goa with easy access to the beach and other popular attractions. I enjoyed the convenience of being able to explore the vibrant city and then return to the tranquility of the hotel. The rooms at park inn by radisson were modern, spacious, and beautifully designed. I was impressed with the attention to detail and the thoughtful amenities provided. The bed was incredibly comfortable, ensuring a restful night's sleep after a day of exploration. The room was also well-equipped with all the necessary amenities, including a flat-screen tv, a minibar, and a coffee maker. One of the highlights of my stay was the exceptional service provided by the hotel staff. They were always available and ready to assist with any request or inquiry. From the front desk to the housekeeping team, everyone was attentive, professional, and went above and beyond to ensure my stay was enjoyable. The staff's genuine hospitality truly made a difference and added a personal touch to my experience.",
        },
      ],
    },
    {
      id: 2,
      title: "Other Reviews",
      data: [
        {
          id: 1,
          review: "Improvement needed",
          rated: "4.0 ",
          name: "Mitesh Patel",
          date: " Apr 23, 2023",
          description:
            "Washroom, toilet was very neat & clean & there is no single word to complaint. Even room was also neat & clean. Only one complaint on first day room ac was not working. Even after complaining, it was not rectified & we couldn't sleep through out night. Next day same was narrated to reception & they have exchanged my room after confirmation that ac was not working. Changed room was nice & we could enjoy the facility for next two days. So in all out of 3 days we could enjoy 2 days out of 3 days stay",
        },
        {
          id: 2,
          review: "Wonderful seamless service",
          rated: "5.0 ",
          name: "Gitesh Lonushte.",
          date: " Apr 23, 2023",
          description:
            "The complete experience was seamless right from the time we checked in. Ordering food was very uncomplicated. Food came on time and orders were executed perfe tly. Liked the chicken biryani a lot. Quite a few paid tv channels were available which is not the case in even some 3 star hotels. Breakfast was good. The staff was very cooperative.",
        },
      ],
    },
  ];

  const ratings = [
    { id: 1, category: "Loation", rating: "4.2" },
    { id: 2, category: "Room", rating: "4" },
    { id: 3, category: "Food", rating: "3.9" },
    { id: 4, category: "Amenities", rating: "3.8" },
    { id: 5, category: "Hospitality", rating: "4.1" },
  ];

  return (
    <div className="my-10">
      <div className="font-bold text-xl text-contrast-800 pb-3">
        User Ratings & Reviews
      </div>
      <p className="text-sm font-normal text-zinc-600">
        Important information that you need to know before you book!
      </p>
      <div className="border-2 border-x-0 border-contrast-300 mt-4 py-4">
        <div className="flex gap-x-4 justify-between items-center">
          <div className="flex items-center w-1/3">
            <div className="flex h-11 w-20 items-center rounded justify-center mr-2.5 bg-primary-600">
              <span className="text-white font-black text-lg">
                {overAllRating?.toFixed(ONE)}
              </span>
            </div>
            <div className="flex flex-col">
              <p className="text-md font-black text-primary-600">
                {getRemark()}
              </p>
              <p className="font-normal text-sm">
                <span>
                  <span className="font-bold">3689 </span>User Reviews &
                </span>
                <span>
                  <span className="font-bold"> 5878 </span> Ratings
                </span>
              </p>
            </div>
          </div>
          <div className="flex flex-wrap items-center w-2/3">
            {ratings.map(({ id, category, rating }) => (
              <div key={id} className="my-1 mr-5 flex-auto">
                <p className="flex justify-between font-semibold mb-2.5 text-xs">
                  {category} {rating}
                </p>
                <div className="bg-zinc-300 h-1 rounded-sm overflow-hidden">
                  <div className="h-full bg-primary-600 w-5/6"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {reviews.map(({ id, title, data }) => (
        <div key={id}>
          <h2 className="text-lg font-bold text-contrast-800 mt-4">{title}</h2>
          {data.map(({ id, review, rated, name, date, description }) => (
            <div
              key={id}
              className="mt-4 p-5 text-sm border rounded shadow-md border-contrast-300"
            >
              <p className="text-lg font-black">{review}</p>
              <p className="text-primary-600 font-semibold">
                Rated:
                <span className="bg-primary-600 text-white rounded px-2">
                  {rated}
                </span>
              </p>
              <p className="font-normal">By: {name}</p>
              <p>Date: {date}</p>
              <p className="mt-2 font-light">{description}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RatingAndReview;
