import { memo } from "react";
import { get } from "lodash";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { priceFormatter } from "../../../helper";
import { ChevronDown, ChevronUp, RenderSVG } from "../../../assets/icons";
import {
  CURRENCY_SYMBOLS,
  DEFAULT_VALUES,
  TAVATRIP_FEE_DETAIL,
} from "../../../constants";
import { useSelector } from "react-redux";
import { selectCountryInfo } from "../../../screens/Profile";

const { ZERO, EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const { INR } = CURRENCY_SYMBOLS;

const PaymentInfo = ({
  journeyDetails = EMPTY_ARRAY,
}) => {
  const { t } = useTranslation();
  const { isExpanded, getCollapseProps, getToggleProps } = useCollapse({
    duration: 200,
  });
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);
  const paymentDetails = get(journeyDetails, "0.price.paymentsDetails", EMPTY_OBJECT);

  const {
    paidAmount: userPaidAmount,
    offeredPriceInfo,
    selectedPaymentPortal = EMPTY_STRING,
    paymentMethod,
    surcharges,
    otherServices,
    discountAmount,
  } = paymentDetails;

  let basePrice = ZERO;
  let grandTotal = ZERO;

  journeyDetails.forEach((journey) => {
    basePrice += get(journey, "price.basePrice", ZERO);
    grandTotal += get(journey, "price.grandTotal", ZERO);
  });

  return (
    <div className="bg-white rounded-lg border border-contrast-300 shadow-sm ">
      <div className="p-4 flex items-center gap-4">
        <h4 className="text-sm xs:text-base font-bold text-contrast-900 flex-1">
          {t("bookingResult.totalPrice")}
        </h4>
        <div className="flex items-center gap-3">
          <span className="text-sm sm:text-lg font-bold text-primary-700">
            {currencySymbol}
            {priceFormatter(userPaidAmount)}
          </span>
          <button
            className="flex p-2 cursor-pointer shrink-0"
            {...getToggleProps()}
          >
            <RenderSVG
              Svg={isExpanded ? ChevronUp : ChevronDown}
              className="text-contrast-800"
              width="20"
            />
          </button>
        </div>
      </div>
      <ul className="flex flex-col text-sm divide-y" {...getCollapseProps()}>
        <li className="flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid">
          <span className=" mr-auto text-contrast-900">
            {t("bookingResult.baseFare")}
          </span>
          <span className="font-medium">
            {currencySymbol}
            {priceFormatter(offeredPriceInfo?.baseFare)}
          </span>
        </li>
        <li className="flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid">
          <span className="mr-auto text-contrast-900">
            {t("bookingResult.taxAndFees")}
          </span>
          <span className="font-medium">
            {currencySymbol}
            {priceFormatter(offeredPriceInfo?.airlineTaxes)}
          </span>
        </li>
        {!!otherServices && (
          <li className="flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid">
            <span className="mr-auto text-contrast-900">
              {t("bookingResult.ssrCharges")}
            </span>
            <span className="font-medium">
              {currencySymbol}
              {priceFormatter(otherServices)}
            </span>
          </li>
        )}
        {!!discountAmount && (
          <li className="flex items-center gap-2 py-3 px-4 border-0 border-b border-contrast-300 border-solid">
            <span className="mr-auto text-contrast-900">
              {t("bookingResult.promoCode")}
            </span>
            <span className="font-medium">
              - {currencySymbol}
              {priceFormatter(discountAmount)}
            </span>
          </li>
        )}
        {!!surcharges && (
          <li className="flex items-center gap-2 py-3 px-4 border-0 border-t border-contrast-300 border-solid">
            <span className="mr-auto text-contrast-900">
              {t("travelerInfo.fareCard.surcharges")}
            </span>
            <span className="font-medium">
              {currencySymbol}
              {priceFormatter(surcharges)}
            </span>
          </li>
        )}
        <li className="flex items-center gap-2 py-3 px-4 border-0 border-t border-contrast-300 border-solid">
          <span className="mr-auto text-contrast-900">
            {t("travelerInfo.fareCard.tavaTripFee")}
          </span>
          <span className="font-medium text-end">
            {currencySymbol}
            {TAVATRIP_FEE_DETAIL}
          </span>
        </li>
        {!!paymentMethod && (
          <li className="flex items-center gap-2 py-3 px-4 border-0 border-t border-contrast-300 border-solid">
            <span className="mr-auto text-contrast-900">
              {t("bookingResult.paymentMethod")}
            </span>
            <span className="font-medium">{ paymentMethod.toUpperCase() }</span>
          </li>
        )}
        <li className="flex items-center gap-2 py-3 px-4 bg-primary-100/50 border-0 border-b border-contrast-300 border-solid">
          <span className="text-base font-bold mr-auto text-primary-600">
            {t("bookingResult.totalPaid")}
          </span>
          <span className="text-base font-bold text-primary-600">
            {currencySymbol}
            {priceFormatter(userPaidAmount)}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default memo(PaymentInfo);
