import { createAsyncThunk } from "@reduxjs/toolkit";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { REQUEST_METHODS } from "../../constants";
import asyncAction from "../../infrastructure/asyncAction";

const { SUBMIT_QUERY } = SPINNER_NAMES;
const { POST } = REQUEST_METHODS;

export const submitQuery = createAsyncThunk(
  "submit-query",
  async (requestBody, thunkArgs) => {
    return await asyncAction({
      url: "submit-query",
      methodType: POST,
      spinner: SUBMIT_QUERY,
      body: JSON.stringify(requestBody),
      abortOnPageChange: false,
      errorMessage: "Failed to submit query.",
      ...thunkArgs,
    });
  }
);
