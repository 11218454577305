import { memo, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import SortingHeaders from "./SortingHeaders";
import NoFlightResult from "./NoFlightResult";
import OneWayCard from "./OneWayCard";
import PackageCard from "./PackageCard";
import { selectIsPrePackagedFlights } from "./flightResults.selectors";
import {
  selectSortedFlights,
  selectFilteredFlights,
} from "../../components/organisms/Search";
import { getRearrangedFlights } from "../../helper";
import { DEFAULT_VALUES, FLIGHTS_RESULT_TYPE } from "../../constants";
import { TBOAPICallSkeleton } from "../../components/organisms/AppSkeletons";
import Spinner, { SPINNER_NAMES }  from "../../components/organisms/Spinner";

const { ZERO, EMPTY_ARRAY } = DEFAULT_VALUES;
const { PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const { FETCH_TBO_FLIGHTS } = SPINNER_NAMES;

const PackagedFlights = ({
  numberOfNonStops,
  selectedFlightId,
  showOneWayCard,
  isReissuanceFlight,
}) => {
  const isPrePackaged = useSelector(selectIsPrePackagedFlights);
  const sortedFlights = useSelector(selectSortedFlights);
  const filteredFlights = useSelector(selectFilteredFlights);
  const [flights, setFlights] = useState(
    Array.isArray(sortedFlights.packages) ? sortedFlights.packages : EMPTY_ARRAY
  );

  useEffect(() => {
    const applySorting = () => {
      let updatedFlights;

      if (!selectedFlightId) updatedFlights = sortedFlights.packages;
      else
        updatedFlights = getRearrangedFlights(
          filteredFlights.packages,
          selectedFlightId
        );

      setFlights(updatedFlights);
    };

    applySorting();
  }, [selectedFlightId, sortedFlights, filteredFlights]);

  if (isEmpty(flights)) return <NoFlightResult showPackages={isPrePackaged} />;

  return (
    <div className="bg-contrast-50 rounded-2xl border border-contrast-200">
      <SortingHeaders numberOfNonStops={numberOfNonStops} />
      <Spinner
        name={FETCH_TBO_FLIGHTS}
        showSkeleton={true}
        loaderComponent={<TBOAPICallSkeleton />}
      ></Spinner>
      <div className="flex flex-col gap-2 overflow-y-scroll no-scrollbar px-2">
        {flights.map((flightsArray) =>
          showOneWayCard ? (
            <OneWayCard
              key={flightsArray[ZERO].flightId}
              flights={flightsArray}
              currentFlightType={PACKAGE_RESULT}
              isReissuanceFlight={isReissuanceFlight}
            />
          ) : (
            <PackageCard
              key={flightsArray[ZERO].flightId}
              flights={flightsArray}
              currentFlightType={PACKAGE_RESULT}
            />
          )
        )}
      </div>
    </div>
  );
};

export default memo(PackagedFlights);
