import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useCollapse } from "react-collapsed";
import { ChevronDown, Checkbox, RenderSVG } from "../../../assets/icons";
import { getStopLabel } from "../../../helper";

const NoOfStopsFilters = ({ stopsFilter, stopsData, handleFilterChange }) => {
  const { t } = useTranslation();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  return (
    <>
      <div className="flex items-center gap-2" {...getToggleProps()}>
        <h4 className="flex-1 text-md sm:text-lg font-semibold text-contrast-800">
          {t("flightResults.filters.stops.header")}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            alt="Checkbox Icon"
            className={classNames({ "rotate-180": isExpanded })}
          />
        </span>
      </div>
      <div {...getCollapseProps()}>
        <div className="pt-6">
          <div className="grid grid-cols-2 gap-6">
            {stopsData &&
              Object.entries(stopsData).map(([stop]) => (
                <div className="col-span-1" key={stop}>
                  <div className="checbox-tab">
                    <label className="cursor-pointer">
                      <input
                        type="checkbox"
                        className="peer hidden"
                        id="stops"
                        value={stop}
                        onChange={handleFilterChange}
                        checked={stopsFilter?.includes(stop)}
                      />
                      <div
                        className={classNames(
                          "flex items-center gap-2 border-2 p-4 rounded-lg",
                          {
                            "bg-primary-100 border-primary-600 text-primary-900":
                              stopsFilter?.includes(stop),
                            "border-contrast-200 text-contrast-900":
                              !stopsFilter?.includes(stop),
                          }
                        )}
                      >
                        <div
                          className={classNames(
                            "w-4 h-4 rounded grid place-content-center border",
                            {
                              "bg-primary-600 border-primary-600":
                                stopsFilter?.includes(stop),
                              "border-gray-300": !stopsFilter?.includes(stop),
                            }
                          )}
                        >
                          <RenderSVG
                            Svg={Checkbox}
                            width="15"
                            height="9"
                            alt="Checkbox Icon"
                          />
                        </div>
                        <span className="text-xs font-medium whitespace-nowrap">
                          {getStopLabel(stop, t)}
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NoOfStopsFilters;
