import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { JourneyDetails } from "../../../molecules/TripOverviewCard";
import { DEFAULT_VALUES } from "../../../../constants";
import { Cross, RenderSVG } from "../../../../assets/icons";
import { selectSelectedFlightIndexInfo } from "../../../../screens/FlightResults";
import { setSelectedModal } from "../../Modal";

const { ZERO } = DEFAULT_VALUES;

const ViewFlightDetailsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedFlightIndexInfo = useSelector(selectSelectedFlightIndexInfo);

  const { itineraries } = selectedFlightIndexInfo || {};

  const handleClose = () => dispatch(setSelectedModal(null));

  return (
    <div className="fixed bg-gray-900/80 w-full h-full start-0 top-0 z-50 p-3 sm:p-6 overflow-auto no-scrollbar">
      <div className="modal-dialog mx-auto bg-white rounded-lg shadow-md max-w-[1000px] overflow-hidden">
        <div className="modal-header flex gap-4 items-center p-6 border-b border-gray-200">
          <h4 className="flex-1 text-sm font-semibold  text-gray-900">
            <span className="text-base font-bold text-primary-700">
              {t("flightResults.journeyDetails.flightDetails")}
            </span>
          </h4>
          <button
            className="text-blue-gray-600 ms-auto hover:opacity-80"
            onClick={handleClose}
          >
            <RenderSVG Svg={Cross} className="text-contrast-black" />
          </button>
        </div>
        <div className="p-4 bg-gray-50 max-h-[500px] overflow-y-auto">
          <JourneyDetails
            itineraries={itineraries}
            segments={itineraries[ZERO].segments}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ViewFlightDetailsModal);
