import { useState } from "react";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ChevronDown, Checkbox, RenderSVG } from "../../../assets/icons";
import { internationalFlightsDetails } from "../../../constants";

const InternationalFlightFilters = () => {
  const { t } = useTranslation();

  const [checkboxes, setCheckboxes] = useState(internationalFlightsDetails);

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  const handleCheckboxChange = (id) => {
    setCheckboxes((prevCheckboxes) => {
      return prevCheckboxes.map((checkbox) =>
        checkbox.id === id
          ? { ...checkbox, isChecked: !checkbox.isChecked }
          : checkbox
      );
    });
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <h4 className="flex-1 text-md sm:text-lg font-semibold text-contrast-800">
          {t("flightResults.filters.internationalFlights.header")}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            alt="Chevron Down Icon"
            className={classNames({ "rotate-180": isExpanded })}
          />
        </span>
      </div>
      <div {...getCollapseProps()}>
        <div className="pt-6">
          <div className="grid grid-cols-2 gap-4">
            {checkboxes.map(({ id, label, isChecked }) => (
              <div className="col-span-2" key={id}>
                <div className="checbox-tab">
                  <label className="cursor-pointer">
                    <input
                      type="checkbox"
                      className="peer hidden"
                      id={id}
                      value={label}
                      onChange={() => handleCheckboxChange(id)}
                      checked={isChecked}
                    />
                    <div
                      className={classNames(
                        "flex items-center gap-2  border-2  p-4 rounded-lg",
                        {
                          "bg-primary-100 border-primary-600 text-primary-900":
                            isChecked,
                          "border-contrast-200 text-contrast-900": !isChecked,
                        }
                      )}
                    >
                      <div
                        className={classNames(
                          "w-4 h-4 rounded grid place-content-center border",
                          {
                            "bg-primary-600 border-primary-600": isChecked,
                            "border-contrast-300": !isChecked,
                          }
                        )}
                      >
                        <RenderSVG
                          Svg={Checkbox}
                          width="9"
                          height="9"
                          alt="Checkbox Icon"
                        />
                      </div>
                      <div>
                        <span className="text-sm font-medium flex">
                          {t(
                            `flightResults.filters.internationalFlights.${id}`
                          )}
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default InternationalFlightFilters;
