import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { History, ApiLogs, RenderSVG } from "../../../assets/icons";
import { ROUTES } from "../../../constants";
import { Aeroplane, HotelsDarkIcon, ChevronDown } from "../../../assets/icons";
const { ADMIN_BOOKINGS, ADMIN_API_LOGS, ADMIN_HOTEL_BOOKINGS } = ROUTES;

const ADMIN_BOOKINGS_CONTENT = [
  {
    id: 1,
    name: "Flight Bookings",
    routePath: ADMIN_BOOKINGS,
    icon: History,
  },
  {
    id: 2,
    name: "Hotel Bookings",
    routePath: ADMIN_HOTEL_BOOKINGS,
    icon: History,
  },
];

const ADMIN_SIDE_BAR_CONTENT = [
  {
    id: 1,
    name: "Bookings",
    subItems: ADMIN_BOOKINGS_CONTENT,
    icon: History
  },
  {
    id: 2,
    name: "API logs",
    routePath: ADMIN_API_LOGS,
    icon: ApiLogs,
  },
];

const AdminLeftSidebar = () => {
  const [isBookingsOpen, setBookingsOpen] = useState(false);

  const toggleBookings = () => {
    setBookingsOpen(!isBookingsOpen);
  };

  return (
    <div className="bg-white min-w-[220px] p-5 h-full fixed">
      <ul className="flex flex-col gap-2">
        {ADMIN_SIDE_BAR_CONTENT.map(({ id, name, routePath, icon, subItems }) => (
          <div key={id}>
            {subItems ? (
              <>
                <div
                  className="flex items-center justify-between w-full text-base font-medium px-2 py-2 cursor-pointer hover:bg-gray-100 transition duration-200 ease-in-out rounded-md"
                  onClick={toggleBookings}
                >
                  <div>{name}</div>
                  <RenderSVG width={20} height={20} Svg={ChevronDown} alt="image" />
                </div>
                {isBookingsOpen && (
                  <ul className="flex flex-col pl-5">
                    {subItems.map(({ id, name, routePath, icon }) => (
                      <NavLink key={id} to={routePath}>
                        {({ isActive }) => (
                          <div
                            className={classNames(
                              "flex gap-2 w-full text-sm font-medium px-2",
                              "flex items-center rounded-md overflow-hidden sm:h-11",
                              {
                                "bg-primary-100 text-primary-600": isActive,
                              }
                            )}
                          >
                            {name == "Flight Bookings" ? <RenderSVG width={20} height={20} Svg={Aeroplane} alt="image" /> : <RenderSVG width={20} height={20} Svg={HotelsDarkIcon} alt="image" />}
                            <div>{name}</div>
                          </div>
                        )}
                      </NavLink>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              <NavLink to={routePath}>
                {({ isActive }) => (
                  <div
                    className={classNames(
                      "flex gap-2 w-full text-base font-medium px-2",
                      "flex items-center rounded-md overflow-hidden sm:h-11",
                      {
                        "bg-primary-100 text-primary-600": isActive,
                      }
                    )}
                  >
                    <RenderSVG width={20} height={20} Svg={icon} alt="image" />
                    <div>{name}</div>
                  </div>
                )}
              </NavLink>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default AdminLeftSidebar;
