import { useState, useEffect } from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Country } from "country-state-city";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import Asterisk from "../../../components/atoms/Asterisk";
import { getRequiredErrorMessage } from "../../../helper";
import DropdownSelectField from "../../../components/atoms/DropdownSelectField";
import { actions } from "./flightBookings.reducer";
import { selectAddressDetails } from "./index";
import { useSelector, useDispatch } from "react-redux";
import { selectCountryInfo } from "../../Profile";
import { DEFAULT_VALUES } from "../../../constants";

const { setAddressDetails } = actions;
const { EMPTY_STRING } = DEFAULT_VALUES;

const renderFieldError = (name, errors, touched) =>
  get(errors, name, false) &&
  get(touched, name, false) && (
    <ErrorMessage errorMessage={get(errors, name)} />
  );

const AddressInformation = ({ defaultValues, addressFormRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedAddressDetails = useSelector(selectAddressDetails);
  const countryList = Country.getAllCountries();
  const [initialValues, setInitialValues] = useState(defaultValues);
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const validationSchema = Yup.object({
    address: Yup.string().required(
      getRequiredErrorMessage("profilePage.address", t)
    ),
    city: Yup.string().required(getRequiredErrorMessage("profilePage.city", t)),
    nationality: Yup.object().shape({
      isoCode: Yup.string().required(
        getRequiredErrorMessage("profilePage.nationality", t)
      ),
    }),
  });
  const defaultNationality = countryList.find(
    (country) => country?.isoCode === selectedCountryInfo.code
  );

  useEffect(() => {
    if (selectedAddressDetails) setInitialValues(selectedAddressDetails);
  }, [selectedAddressDetails]);

  useEffect(() => {
    if (!selectedAddressDetails) {
      setInitialValues({
        ...selectedAddressDetails,
        nationality: {
          isoCode: defaultNationality.isoCode,
          name: defaultNationality.name,
        },
      });
    }
  }, [defaultNationality, selectedAddressDetails]);

  const handleFormSubmit = (values) => dispatch(setAddressDetails(values));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      enableReinitialize
      onSubmit={handleFormSubmit}
      innerRef={addressFormRef}
    >
      {({
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        validateForm,
      }) => (
        <Form>
          <div className="w-full">
            <div className="mt-4 text-contrast-900 pl-4 text-md font-semibold bg-white justify-between">
              <span>{t("travelerInfo.addressInfo.title")}</span>
            </div>
            <div className="text-contrast-900 pl-4 text-xs bg-white justify-between rounded-t-lg">
              <span>{t("travelerInfo.addressInfo.subTitle")}</span>
            </div>
            <div className="grid gap-x-6 gap-y-4 grid-cols-6 bg-white w-full rounded-b-lg p-4">
              <div className="form-group col-span-6 xs:col-span-12 md:col-span-2 lg:col-span-2">
                <label className="block text-sm font-medium mb-1 text-contrast-900">
                  {t("profilePage.address")}
                  <Asterisk />
                </label>
                <Field
                  value={get(values, "address", EMPTY_STRING)}
                  id="address"
                  name="address"
                  type="text"
                  className="form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500"
                  placeholder={t("profilePage.placeholders.address")}
                  onBlur={() => {
                    setFieldTouched("address", true)
                    dispatch(
                      setAddressDetails({
                        ...values,
                        address: values.address,
                      })
                    );
                  }}
                />
                <div>{renderFieldError("address", errors, touched)}</div>
              </div>
              <div className="form-group col-span-6 xs:col-span-12 md:col-span-2 lg:col-span-2">
                <label className="block text-sm font-medium mb-1 text-contrast-900">
                  {t("profilePage.city")}
                  <Asterisk />
                </label>
                <Field
                  value={get(values, "city", EMPTY_STRING)}
                  id="city"
                  name="city"
                  type="text"
                  className="form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500"
                  placeholder={t("profilePage.placeholders.city")}
                  onBlur={() => {
                    setFieldTouched("city", true)
                    dispatch(
                      setAddressDetails({
                        ...values,
                        city: values.city,
                      })
                    );
                  }}
                />
                <div>{renderFieldError("city", errors, touched)}</div>
              </div>
              <div className="form-group col-span-6 xs:col-span-12 md:col-span-2 lg:col-span-2">
                <label className="block text-sm font-medium mb-1 text-contrast-900">
                  {t("profilePage.country")}
                  <Asterisk />
                </label>
                <DropdownSelectField
                  name="nationality"
                  value={values.nationality || defaultNationality.nationality}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  selectableValues={countryList}
                  valueToShow={values.nationality.name ? "name" : "isoCode"}
                  validateForm={validateForm}
                  placeholder={t("profilePage.placeholders.country")}
                  handleOnBlur={() => {
                    dispatch(
                      setAddressDetails({
                        ...values,
                        nationality: values.nationality,
                      })
                    );
                  }}
                />
                <div>
                  {renderFieldError("nationality.isoCode", errors, touched)}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddressInformation;
