import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import classNames from "classnames";
import Footer from "./components/organisms/Footer";
import NavBar from "./components/organisms/NavBar";
import SidePanel from "./components/organisms/SidePanel";
import { selectUserInfo } from "./screens/Profile";
import config from "./config.json";
import { ROUTES } from "./constants";

const { logo } = config;
const { FLIGHT_RESULTS, HOTEL_RESULTS } = ROUTES;
const ADMIN = "admin";

const RouteLayout = () => {
  const divRef = useRef();
  const location = useLocation();
  const userInfo = useSelector(selectUserInfo);
  const isUserAuthenticated = !!userInfo?.id;

  useEffect(() => {
    logo &&
      document.querySelector('link[rel="icon"]')?.setAttribute("href", logo);
  }, []);

  const shouldShowFooter = !location.pathname.includes(ADMIN);
  const isFlightResultsPage = location.pathname.includes(FLIGHT_RESULTS);
  const isHotelResultsPage = location.pathname.includes(HOTEL_RESULTS);

  return (
    <div ref={divRef} className="App flex flex-col max-h-screen overflow-auto">
      <NavBar />
      <div
        className={classNames("flex-1", {
          "mt-16": !isFlightResultsPage && !isHotelResultsPage,
          "lg:mt-12": !isFlightResultsPage && !isHotelResultsPage && !isUserAuthenticated,
        })}
      >
        <Outlet context={{ divRef }} />
        <SidePanel />
        <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      </div>
      {shouldShowFooter && <Footer />}
    </div>
  );
};

export default RouteLayout;
