const ANCHOR_ELEMENT = "a";

const getDataType = (data) => {
  try {
    if (typeof data === "object") {
      const file = new Blob([JSON.stringify(data)], {
        type: "application/json",
      });
      const fileExtension = "logs" + Date.now() + ".json";
      return { file, fileExtension };
    }
  } catch (error) {
    const isXml = typeof data === "string" && data.startsWith("<?xml");
    const file = new Blob([data], {
      type: isXml ? "application/xml" : "text/plain",
    });
    const fileExtension = "logs" + Date.now() + isXml ? ".xml" : ".text";
    return { file, fileExtension };
  }
};

export const downloadTxtFile = (text) => {
  const dataType = getDataType(text);
  const { file, fileExtension } = dataType;
  const element = document.createElement(ANCHOR_ELEMENT);
  element.href = URL.createObjectURL(file);
  element.download = fileExtension;
  document.body.appendChild(element);
  element.click();
};
