import reducers from "./search.reducer";

export const selectSlice = (state) => state[reducers.name];

export const selectFlights = (state) => selectSlice(state).flights;

export const selectHotels = (state) => selectSlice(state).hotels;

export const selectHotelsStaticData = (state) => selectSlice(state).hotelsStaticData;

export const selectSearchFilters = (state) => selectSlice(state).searchFilters;

export const selectFromCityCodes = (state) =>
  selectSlice(state).fromIataLocations;

export const selectToCityCodes = (state) => selectSlice(state).toIataLocations;

export const selectCalendarDatePrices = (state) =>
  selectSlice(state).farePricesPerDates;

export const selectHotelSearchFilters = (state) =>
  selectSlice(state).searchHotelFilters;

export const selectHotelLocations = (state) =>
  selectSlice(state).hotelLocations;

export const selectFilteredFlights = (state) =>
  selectSlice(state).filteredFlights;

export const selectFlightSearchOptions = (state) =>
  selectSlice(state).flightSearchOptions;

export const selectActiveFilters = (state) => selectSlice(state).activeFilters;

export const selectFlightsCount = (state) => selectSlice(state).flightsCount;

export const selectFlightTokens = (state) => selectSlice(state).flightTokens;

export const selectSortedFlights = (state) => selectSlice(state).sortedFlights;

export const selectActiveSortOrder = (state) =>
  selectSlice(state).activeSortOrder;
export const selectIsNonStopSelected = (state) =>
  selectSlice(state).isNonStopSelected;

export const selectSelectedReIssuanceFlight = (state) =>
  selectSlice(state).selectedReIssuanceFlight;

export const selectPredictions = (state) => selectSlice(state).predictions;

export const selectSelectedPrediction = (state) =>
  selectSlice(state).selectedPrediction;

export const selectCurrentSearchLevel = (state) => selectSlice(state).currentSearchLevel;

export const selectFilteredHotels = (state) =>
  selectSlice(state).filteredHotels;

export const selectHotelsSortingValues = (state) =>
  selectSlice(state).hotelsSortingValues;

export const selectHotelsActiveFilters = (state) =>
  selectSlice(state).hotelsActiveFilters;

export const selectHotelsCount = (state) =>
  selectSlice(state).hotelsCount;

export const selectIsStaticDataLoaded = (state) => selectSlice(state).isStaticDataLoaded;

export const selectIsHotelsLoaded = (state) => selectSlice(state).isHotelsLoaded;

export const selectHotelCurrency = (state) => selectSlice(state).hotelCurrency;
