import { useSelector } from "react-redux";
import classNames from "classnames";
import { isEmpty, get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  getAirlineIconUrlByCarrierCode,
  getFormattedArrivalAndDepartureDate,
  getLayoverDuration,
  getTotalTimeDifference,
  getCityNameByIata,
} from "../../../helper";
import { selectIsPrePackagedFlights } from "../../../screens/FlightResults";
import {
  RenderSVG,
  FlightDarkIcon,
  Baggage,
  CalendarOutline,
  Seat,
} from "../../../assets/icons";
import { DEFAULT_VALUES } from "../../../constants";
import Tabs from "../../organisms/Tabs";

const { ONE, ZERO, EMPTY_STRING } = DEFAULT_VALUES;

const getOriginDestinationIataCodes = (itinerary = []) => {
  const originIata = get(
    itinerary,
    "segments.0.departure.iataCode",
    EMPTY_STRING
  );
  const lastSegment = get(itinerary, "segments", []).at(-1);
  const destinationIata = get(lastSegment, "arrival.iataCode", EMPTY_STRING);

  return { originIata, destinationIata };
};

const RenderSegments = ({ segments, layoverDuration }) => {
  const { t } = useTranslation();
  const showPackages = useSelector(selectIsPrePackagedFlights);

  return segments.map(
    (
      {
        carrierCode,
        carrierName,
        flightNumber,
        departure,
        arrival,
        cabinClass,
        aircraftCode,
        baggageDetails,
      },
      index
    ) => (
      <div
        className="w-full p-2 sm:p-4 pb-6 bg-white rounded-lg"
        key={departure.iataCode}
      >
        <div className="grid sm:grid-cols-12 gap-x-1">
          <div className="mb-3 flex flex-row gap-2 sm:gap-0 sm:col-span-2 sm:flex-col">
            <img
              className="rounded border-contrast-300 w-10 h-10 sm:w-12 sm:h-12"
              src={getAirlineIconUrlByCarrierCode(carrierCode)}
              alt={`${carrierCode}_logo`}
            />
            <div className="flex flex-col text-sm text-contrast-900 font-semibold sm:justify-between">
              <div>{carrierName}</div>
              {carrierCode} {flightNumber} {aircraftCode}
            </div>
          </div>
          <div className="grid grid-cols-12 sm:col-span-10">
            <div className="col-span-3">
              <div className="font-semibold text-xs sm:text-base">
                {departure.time}
                &nbsp;
              </div>
              <div className="text-contrast-800 text-[10px] sm:text-xs">
                {getFormattedArrivalAndDepartureDate(departure.date)}
              </div>
              <div className="font-semibold text-xs sm:text-base">
                {getCityNameByIata(departure.iataCode)}
              </div>
              {departure.terminal && (
                <div className="text-contrast-800 text-xs">
                  {t("flightResults.journeyDetails.terminal")}&nbsp;
                  {departure.terminal}
                </div>
              )}
              {departure.airportName && (
                <div className="text-contrast-800 text-xs">
                  {departure.airportName}
                </div>
              )}
            </div>
            <div
              className={classNames("col-span-6 text-center", {
                "md:col-span-6": !showPackages,
              })}
            >
              <div className="text-contrast-500 px-4 md:px-0">
                {getTotalTimeDifference(
                  departure.iataCode,
                  departure.date,
                  departure.time,
                  arrival.iataCode,
                  arrival.date,
                  arrival.time
                )}
              </div>
              <div className="border-t-2 border-orange-500 my-2 w-4/5 h-6 mx-auto flex justify-center relative">
                <RenderSVG
                  Svg={FlightDarkIcon}
                  className="rotate-90 absolute bottom-3.5"
                  alt="Flight-Icon"
                />
              </div>
            </div>
            <div className="col-span-3">
              <div className="font-semibold text-xs sm:text-base">
                {arrival.time}
              </div>
              <div className="text-contrast-800 text-[10px] sm:text-xs">
                {getFormattedArrivalAndDepartureDate(arrival.date)}
              </div>
              <div className="font-semibold text-xs sm:text-base">
                {getCityNameByIata(arrival.iataCode)}
              </div>
              {arrival.terminal && (
                <div className="text-contrast-800 text-[10px] sm:text-xs">
                  {t("flightResults.journeyDetails.terminal")}&nbsp;
                  {arrival.terminal}
                </div>
              )}
              {arrival.airportName && (
                <div className="text-contrast-800 text-[10px] sm:text-xs">
                  {arrival.airportName}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap sm:grid sm:grid-cols-12 gap-1 sm:gap-2 mt-5">
          {baggageDetails.weight && (
            <div className="sm:col-span-2 text-[10px] h-fit font-semibold whitespace-nowrap bg-blue-100 text-blue-900 rounded-2xl py-0.5 px-2 flex items-center">
              <RenderSVG
                Svg={Baggage}
                width="14"
                height="14"
                className="text-blue-900"
                alt="reissue"
              />
              <span className="ml-1">
                <span className="hidden sm:inline">
                  {t("flightResults.journeyDetails.baggage")}:{" "}
                </span>
                {baggageDetails.weight}
              </span>
            </div>
          )}
          {baggageDetails.cabinBaggage && (
            <div className="sm:col-span-2 text-[10px] h-fit font-semibold whitespace-nowrap bg-blue-100 text-blue-900 rounded-2xl py-0.5 px-2 flex items-center">
              <RenderSVG
                Svg={CalendarOutline}
                width="14"
                height="14"
                className="text-blue-900"
                alt="reissue"
              />
              <span className="ml-1">
                <span className="hidden sm:inline">
                  {t("flightResults.journeyDetails.checkIn")}:{" "}
                </span>
                {baggageDetails.cabinBaggage}
              </span>
            </div>
          )}
          {cabinClass && (
            <div className="sm:col-span-2 text-[10px] h-fit font-semibold whitespace-nowrap bg-blue-100 text-blue-900 rounded-2xl py-0.5 px-2 flex items-center">
              <RenderSVG
                Svg={Seat}
                width="14"
                height="14"
                className="text-blue-900"
                alt="reissue"
              />
              <span className="ml-1">
                <span className="hidden sm:inline">
                  {t("flightResults.journeyDetails.cabin")}:
                </span>
                {cabinClass}
              </span>
            </div>
          )}
        </div>
        {!isEmpty(layoverDuration) && segments[index + ONE] && (
          <div className="flex items-center mt-4">
            <div className="flex-grow border-t-2 border-gray-300"></div>
            <div className="rounded p-2 mx-2">
              <div className="flex items-center">
                <div className="font-semibold text-primary-900 text-sm">
                  {t("flightResults.journeyDetails.changeOfPlanes")}
                </div>
                <div className="flex items-center">
                  <div className="flex-grow">
                    <div className="border-t-2 border-gray-300"></div>
                  </div>
                  <div className="ml-2 text-sm">
                    {t("flightResults.journeyDetails.layoverDuration")}:
                  </div>
                  <div className="ml-2 font-semibold text-sm">
                    {layoverDuration[index]}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow border-t-2 border-gray-300"></div>
          </div>
        )}
      </div>
    )
  );
};

const JourneyDetails = ({ itineraries = [], segments }) => {
  const layoverDuration =
    itineraries.length > ONE
      ? itineraries.map((itinerary) => getLayoverDuration(itinerary.segments))
      : [getLayoverDuration(segments)];

  const tabs = itineraries.map((itinerary, index) => {
    const { originIata, destinationIata } =
      getOriginDestinationIataCodes(itinerary);

    return {
      id: index,
      default: index === ZERO,
      title: `${originIata}-${destinationIata}`,
      element: (
        <RenderSegments
          segments={itinerary.segments}
          layoverDuration={layoverDuration[index]}
          duration={itinerary.duration}
        />
      ),
    };
  });

  return (
    <div className="bg-gray-50">
      <div
        className={classNames("p-2 font-semibold border-b-[1px] bg-white", {
          "rounded py-3 font-normal text-black": itineraries.length > ONE,
        })}
      >
        {itineraries.length > ZERO && (
          <>
            {itineraries.length > ONE ? (
              <Tabs tabs={tabs} className="h-10 pt-0" />
            ) : (
              <RenderSegments
                segments={itineraries[ZERO]?.segments}
                layoverDuration={layoverDuration[ZERO]}
                duration={itineraries[ZERO]?.duration}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default JourneyDetails;
