import { get, isEmpty } from "lodash";
import { DEFAULT_VALUES } from "../../constants";

const SSR_FIELD = {
  seatDynamic: { stateKey: "seatDynamic", defaultCode: "NoSeat" },
  mealDynamic: { stateKey: "mealDynamic", defaultCode: "NoMeal" },
  baggage: { stateKey: "baggage", defaultCode: "NoBaggage" },
};

const { ZERO, EMPTY_OBJECT } = DEFAULT_VALUES;
const SEAT_DYNAMIC = "seatDynamic";
const MEAL_DYNAMIC = "mealDynamic";
const BAGGAGE = "baggage";

const getFormattedData = (rawData, type, state) => {
  const { stateKey, defaultCode } = SSR_FIELD[type];
  return rawData.reduce((acc, segment) => {
    const initialState = { filteredData: {}, defaultValue: {} };
    const { filteredData, defaultValue } = segment.reduce((acc, each) => {
      const { FlightNumber, Destination, Origin } = each;
      const filteredDataKey = `${Origin}-${Destination}`;
      if (each.Code === defaultCode) acc.defaultValue[FlightNumber] = each;
      else {
        acc.filteredData[filteredDataKey] = [
          ...(acc.filteredData[filteredDataKey]
            ? acc.filteredData[filteredDataKey]
            : []),
          each,
        ];
      }

      return acc;
    }, initialState);

    if (!isEmpty(defaultValue)) {
      state.SSRDefaultValuesLCC = {
        ...state.SSRDefaultValuesLCC,
        [stateKey]: {
          ...state.SSRDefaultValuesLCC[stateKey],
          ...defaultValue,
        },
      };
    }
    const requiredFilteredData = Object.values(filteredData);
    if (requiredFilteredData.length) acc = [...acc, ...requiredFilteredData];
    return acc;
  }, []);
};

const getFormattedSeatData = (flights, type, state) => {
  const { stateKey, defaultCode } = SSR_FIELD[type];
  const initialState = { filteredSeats: [] };
  const { defaultValue, ...restFilteredData } = flights.reduce((acc, each) => {
    acc.FlightNumber = each.Seats[ZERO].FlightNumber;
    if (each.Seats[ZERO].Code === defaultCode)
      acc.defaultValue = each.Seats[ZERO];
    else acc.filteredSeats = [...acc.filteredSeats, each];

    return acc;
  }, initialState);

  if (defaultValue) {
    const flightNo = defaultValue.FlightNumber;
    state.SSRDefaultValuesLCC = {
      ...state.SSRDefaultValuesLCC,
      [stateKey]: {
        ...state.SSRDefaultValuesLCC[stateKey],
        [flightNo]: defaultValue,
      },
    };
  }
  return restFilteredData;
};

const getDecks = (flights, state) => {
  let tabs = [];
  flights.forEach((flight) => {
    const segmentTabs = flight.SegmentSeat.map((seatsMatrix) => {
      const { filteredSeats, FlightNumber } = getFormattedSeatData(
        seatsMatrix.RowSeats,
        SEAT_DYNAMIC,
        state
      );

      return {
        FlightNumber,
        rowSeats: filteredSeats.reduce((acc, { Seats }) => {
          const { RowNo } = get(Seats, "0");
          const deckConfiguration = {
            width: filteredSeats.length,
            startSeatRow: RowNo,
          };
          return [
            ...acc,
            {
              row: RowNo,
              deckConfiguration,
              seats: Seats,
            },
          ];
        }, []),
      };
    });
    tabs = [...tabs, ...segmentTabs];
  });
  return tabs;
};

export const getFormattedSpecialServicesData = (
  specialServicesApiRes,
  state
) => {
  const getFlightData = (flights = []) =>
    flights.map((flightData) => {
      const { isLCC, resultIndex } = flightData?.output || EMPTY_OBJECT;
      const {
        SeatDynamic = [],
        MealDynamic = [],
        Baggage = [],
        SeatPreference = [],
        Meal = [],
      } = get(flightData, "output.Response", EMPTY_OBJECT);

      return isLCC
        ? {
            isLCC,
            resultIndex,
            data: {
              seatDynamic: getDecks(SeatDynamic, state),
              mealDynamic: getFormattedData(MealDynamic, MEAL_DYNAMIC, state),
              baggage: getFormattedData(Baggage, BAGGAGE, state),
            },
          }
        : {
            isLCC,
            resultIndex,
            data: {
              seatPreference: SeatPreference.map((each) => ({
                ...each,
                resultIndex,
              })),
              meal: Meal.map((each) => ({
                ...each,
                resultIndex,
              })),
            },
          };
    });

  return getFlightData(specialServicesApiRes);
};
