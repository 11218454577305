import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import { DEFAULT_VALUES, ROUTES } from "../../constants";
import {
  COOKIE_POLICY_CONTENT,
  PAYMENT_SECURITY_CONTENT,
  PRIVACY_POLICY_CONTENT,
  CANCELLATION_AND_REFUND_POLICY_CONTENT,
  TERMS_AND_CONDITION_CONTENT,
  CONTACT_US_CONTENT,
  SHIPPING_AND_DELIVERY_CONTENT,
} from "./PoliciesContent";
import config from "../../config.json";

const { brandName } = config;
const { EMPTY_ARRAY } = DEFAULT_VALUES;
const {
  PRIVACY_POLICY,
  PAYMENT_SECURITY,
  COOKIE_POLICY,
  CANCELLATION_AND_REFUND_POLICY,
  TERMS_AND_CONDITION,
  CONTACT_US,
  SHIPPING_AND_DELIVERY,
} = ROUTES;

const getSiteTitle = (policyType) => {
  switch (policyType) {
    case PRIVACY_POLICY:
      return "Privacy Policy";
    case PAYMENT_SECURITY:
      return "Payment Security";
    case COOKIE_POLICY:
      return "Cookie Policy";
    case CANCELLATION_AND_REFUND_POLICY:
      return "Cancellation and Refund Policy";
    case TERMS_AND_CONDITION:
      return "Terms and Conditions";
    case CONTACT_US:
      return "Contact Us";
    case SHIPPING_AND_DELIVERY:
      return "Shipping and Delivery";
    default:
      return "";
  }
};

const FooterLinkContent = () => {
  const [pageContent, setPageContent] = useState(EMPTY_ARRAY);
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();

  const getContent = (policyType) => {
    switch (policyType) {
      case PRIVACY_POLICY:
        return PRIVACY_POLICY_CONTENT;
      case PAYMENT_SECURITY:
        return PAYMENT_SECURITY_CONTENT;
      case COOKIE_POLICY:
        return COOKIE_POLICY_CONTENT;
      case CANCELLATION_AND_REFUND_POLICY:
        return CANCELLATION_AND_REFUND_POLICY_CONTENT;
      case TERMS_AND_CONDITION:
        return TERMS_AND_CONDITION_CONTENT;
      case CONTACT_US:
        return CONTACT_US_CONTENT;
      case SHIPPING_AND_DELIVERY:
        return SHIPPING_AND_DELIVERY_CONTENT;
      default:
        return EMPTY_ARRAY;
    }
  };

  useEffect(() => {
    const { pathname } = location;
    setPageContent(getContent(pathname));
  }, [location]);

  return (
    <div className="bg-contrast-100 text-secondary-600 pb-3 md:py-10">
      <Helmet>
        <title>
          {brandName} | {getSiteTitle(pathname)}
        </title>
      </Helmet>
      <div className="flex-col items-center bg-contrast-100">
        <div className="flex justify-center">
          <h1 className="text-xl font-bold text-secondary-600 mt-2 p-2 sm:text-3xl lg:text-4xl">
            {t(pageContent.header)}
          </h1>
        </div>
      </div>
      <div className="flex flex-col items-center bg-contrast-100">
        <div className="p-4 sm:p-8 md:p-10 lg:p-12 xl:p-20 bg-white rounded-lg shadow-lg mx-4 sm:mx-8 md:mx-16 lg:mx-32 sm:my-4 md:my-6 lg:my-8 ">
          {pageContent?.body?.map(({ title, content, subHeading }) => (
            <div
              key={title}
              className={classNames("mb-4 md:mb-6 lg:mb-8", {
                "-mt-3": t(subHeading),
              })}
            >
              <h2
                className={classNames(
                  "text-sm md:text-lg lg:text-xl font-semibold mb-2 text-left text-black",
                  {
                    "font-semibold ml-6 md:mb-2": t(subHeading),
                    "font-bold md:mb-4": !t(subHeading),
                  }
                )}
              >
                {t(title)}
              </h2>
              <p
                className={classNames(
                  "text-contrast-800 sm:text-xm text-xs md:text-lg",
                  {
                    "ml-6": t(subHeading),
                    "ml-16": location.pathname.includes("conditions"),
                  }
                )}
                dangerouslySetInnerHTML={{
                  __html: t(content)
                    .replace(/<ul>/g, '<ul style="list-style: disc;">')
                    .replace(/<li>/g, '<li style="display: list-item;">')
                    .replace(/<a/g, '<a class="text-blue-500"'),
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterLinkContent;
