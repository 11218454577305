import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { get, isEmpty } from "lodash";
import classNames from "classnames";
import {
  cancelHotelBooking,
  getUserBookingInfo,
  selectUserBookingInfo,
} from "..";
import { selectCountryInfo } from "../../Profile";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import RenderCancellationSteps from "./RenderCancellationSteps";
import {
  selectSelectedModal,
  setSelectedModal,
} from "../../../components/organisms/Modal";
import HotelCancellationModal from "../../../components/organisms/AppModals/HotelCancellationModal";
import { MODALS } from "../../../components/organisms/AppModals";
import HotelCancellationSkeleton from "../../../components/organisms/AppSkeletons/HotelCancellationSkeleton";
import HotelDetailSection from "./HotelDetailSection";
import { LeftIcon, RenderSVG, SlashTicket } from "../../../assets/icons";
import {
  DEFAULT_VALUES,
  ROUTES,
  SEARCH_SECTION,
  REQUEST_STATUS_MAPPING,
} from "../../../constants";

const { FETCH_USER_BOOKING_INFO } = SPINNER_NAMES;
const { USER_BOOKINGS } = ROUTES;
const { HOTEL } = SEARCH_SECTION;
const { HOTEL_CANCELLATION_MODAL } = MODALS;
const { ZERO, ONE, TWO, THREE, FOUR } = DEFAULT_VALUES;
const REQUEST_TYPE = FOUR;
const cancellationSteps = [
  {
    step: ONE,
    label: "Select Reason for Cancellation",
    btnText: "View Refunds",
  },
  { step: TWO, label: "View Refunds", btnText: "Cancel Booking" },
];
const OTHER = "Other";

const getBookingAndRefundAmount = (hotelDetails) => {
  const todayDate = new Date();
  const currentTime = todayDate.getTime();

  const finalCharges = hotelDetails.reduce(
    (
      finalCharges,
      { Price, DayRates, LastCancellationDate, CancellationPolicies }
    ) => {
      const { Discount, PublishedPrice, CurrencyCode } = Price;
      finalCharges.discount += Discount;
      const totalPrice = PublishedPrice;
      finalCharges.currency = CurrencyCode;
      finalCharges.totalPrice += totalPrice;
      const lastCancellationTime = new Date(LastCancellationDate).getTime();

      if (lastCancellationTime > currentTime) {
        finalCharges.cancellationCharges =
          (finalCharges.cancellationCharges || ZERO) + ZERO;
        return finalCharges;
      }

      CancellationPolicies.forEach(
        ({ Charge, ToDate, FromDate, ChargeType }) => {
          const fromTime = new Date(FromDate).getTime();
          const toTime = new Date(ToDate).getTime();
          const isCurrentChargeApplicable =
            fromTime < currentTime && currentTime < toTime;
          if (isCurrentChargeApplicable) {
            let charges;
            switch (ChargeType) {
              case ONE:
                charges = Charge;
                break;
              case TWO:
                charges = totalPrice * (Charge / 100);
                break;
              case THREE:
                charges = (totalPrice / DayRates.length) * Charge;
                break;
              default:
                break;
            }
            finalCharges.cancellationCharges =
              (finalCharges.cancellationCharges || ZERO) + charges;
          }
        }
      );

      return finalCharges;
    },
    {
      totalPrice: ZERO,
      currency: "",
      discount: ZERO,
      cancellationCharges: null,
    }
  );
  return finalCharges;
};

const getRemarks = (selectedReason) => {
  const { listedReason, otherReason } = selectedReason;
  if (listedReason !== OTHER) return listedReason;
  return isEmpty(otherReason) ? listedReason : otherReason;
};

const HotelCancellation = () => {
  const [currentStep, setCurrentStep] = useState(ONE);
  const [selectedReason, setSelectedReason] = useState({
    listedReason: "",
    otherReason: "",
  });
  const [otherReasonError, setOtherReasonError] = useState(false);
  const errorRef = useRef(null);
  const { bookingId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookingResponse = useSelector(selectUserBookingInfo);
  const selectedModal = useSelector(selectSelectedModal);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const { ip } = selectedCountryInfo || {};
  useEffect(() => {
    if (otherReasonError && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [otherReasonError]);

  const bookingInfo = get(bookingResponse, "result.0", []);

  const totalRooms = get(
    bookingInfo,
    "bookingReqJson.HotelRoomsDetails",
    []
  ).length;
  const amountDetails =
    bookingInfo &&
    getBookingAndRefundAmount(
      get(bookingInfo, "blockRoomResJson.BlockRoomResult.HotelRoomsDetails", [])
    );

  useEffect(() => {
    dispatch(
      getUserBookingInfo({ queryParams: { division: HOTEL, id: bookingId } })
    );
  }, [dispatch]);

  const handleHotelCancellation = () => {
    const requestBody = {
      EndUserIp: ip,
      BookingId: bookingInfo.bookingId,
      RequestType: REQUEST_TYPE,
      Remarks: getRemarks(selectedReason),
    };
    dispatch(cancelHotelBooking(requestBody)).then((res) => {
      dispatch(setSelectedModal(null));
      const status = get(
        res,
        "payload.HotelCancelation.HotelChangeRequestStatusResult.ChangeRequestStatus",
        ZERO
      );
      if (!status || status === FOUR) {
        toast.error(REQUEST_STATUS_MAPPING[status]);
        return;
      } else if (status === THREE)
        toast.success(REQUEST_STATUS_MAPPING[status]);
      else toast.warn(REQUEST_STATUS_MAPPING[status]);
      navigate(USER_BOOKINGS);
    });
  };

  const handleNextStep = () => {
    if (
      selectedReason.listedReason === OTHER &&
      isEmpty(selectedReason.otherReason)
    )
      setOtherReasonError(true);
    else
      currentStep === cancellationSteps.length
        ? dispatch(setSelectedModal(HOTEL_CANCELLATION_MODAL))
        : setCurrentStep(currentStep + ONE);
  };

  const handleStepsAndCancellation = (step = ZERO) => {
    if (
      selectedReason.listedReason === OTHER &&
      isEmpty(selectedReason.otherReason)
    )
      setOtherReasonError(true);
    else {
      step !== ZERO
        ? (currentStep !== ONE || selectedReason.listedReason) &&
          setCurrentStep(step)
        : dispatch(setSelectedModal(HOTEL_CANCELLATION_MODAL));
    }
  };

  return (
    <div>
      <header className="relative bg-teal-200 ">
        <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-gray-900/0 to-teal-900/50" />
        <div className="container px-4 pt-12 pb-20 mx-auto relative">
          <div className="grid grid-cols-12 gap-4  max-w-5xl mx-auto">
            <div className="col-span-12 md:col-span-4 lg:col-span-7">
              <h4 className="text-xl text-teal-950 mb-1 font-bold">
                Hotel Cancellation
              </h4>
            </div>
          </div>
        </div>
      </header>
      <main className="pb-16 relative">
        <div className="container px-4 mx-auto -mt-12">
          <div className="w-full">
            <div className="bg-white rounded-lg shadow-lg max-w-5xl flex flex-col justify-center mx-auto min-h-[50vh]">
              <div className="flex gap-2 px-4 py-2 border-b border-dashed border-gray-300 items-center">
                <div className="shrink-0">
                  <RenderSVG
                    Svg={SlashTicket}
                    fill="none"
                    height={20}
                    width={20}
                  />
                </div>
                <div className="flex-1">
                  <h4 className="text-gray-900 font-bold text-base">
                    Cancel your stay
                  </h4>
                </div>
              </div>
              <Spinner
                name={FETCH_USER_BOOKING_INFO}
                showSkeleton={true}
                loaderComponent={<HotelCancellationSkeleton />}
                spinnerClassName="w-full"
              >
                {!isEmpty(bookingInfo) ? (
                  <div className="flex flex-col gap-8 w-full">
                    <div className="rounded-lg border border-dashed border-gray-300 shadow-sm">
                      <HotelDetailSection bookingInfo={bookingInfo} />
                      <div className="p-4">
                        <div className="flex gap-2 items-center mb-6">
                          {cancellationSteps.map(({ step, label }) => (
                            <button
                              key={step}
                              onClick={() => handleStepsAndCancellation(step)}
                              className={classNames(
                                "flex gap-2 items-center rounded-3xl p-2 cursor-pointer",
                                {
                                  "bg-primary-100 text-primary-700 ring-1 ring-primary-700":
                                    step === currentStep,
                                  "bg-gray-100 text-gray-900":
                                    step !== currentStep,
                                  "!cursor-not-allowed":
                                    currentStep === ONE &&
                                    isEmpty(selectedReason.listedReason) &&
                                    step !== ONE,
                                }
                              )}
                            >
                              <span
                                className={classNames(
                                  "text-xs rounded-full h-5 w-5 grid place-content-center font-bold",
                                  {
                                    "bg-primary-700 text-white":
                                      step === currentStep,
                                    "bg-black/10 text-gray-900":
                                      step !== currentStep,
                                  }
                                )}
                              >
                                {step}
                              </span>
                              <span className="text-xs font-semibold">
                                {label}
                              </span>
                            </button>
                          ))}
                        </div>
                        <RenderCancellationSteps
                          currentStep={currentStep}
                          selectedReason={selectedReason}
                          setSelectedReason={setSelectedReason}
                          totalRooms={totalRooms}
                          amountDetails={amountDetails}
                          otherReasonError={otherReasonError}
                          setOtherReasonError={setOtherReasonError}
                        />

                        <div className="flex justify-between">
                          {currentStep > ONE && (
                            <button
                              onClick={() => setCurrentStep(currentStep - ONE)}
                              className="py-3 px-4 rounded-md bg-white hover:bg-primary-100 active:bg-white shadow-sm text-sm text-primary-900 font-semibold flex items-center gap-2"
                            >
                              <RenderSVG
                                Svg={LeftIcon}
                                width="20"
                                height="20"
                              />
                              Back
                            </button>
                          )}
                          <div>
                            {otherReasonError && (
                              <span
                                ref={errorRef}
                                className="text-red-500 text-[10px] md:text-sm"
                              >
                                Please enter a reason for cancellation.
                              </span>
                            )}
                          </div>
                          {isEmpty(selectedReason.listedReason) && (
                            <Tooltip
                              id="cancellation-reason-tooltip"
                              className="!w-56 !sm:w-80 !bg-primary-600 !rounded-lg !z-50 !opacity-75"
                            />
                          )}
                          <button
                            onClick={handleNextStep}
                            className="py-3 px-4 rounded-md bg-primary-600 disabled:bg-primary-400 disabled:cursor-not-allowed hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium"
                            disabled={isEmpty(selectedReason.listedReason)}
                            data-tooltip-id="cancellation-reason-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-content="Please select the reason for cancellation first."
                          >
                            {cancellationSteps[currentStep - ONE]?.btnText}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full min-h-[50vh] flex justify-center items-center">
                    <span className="text-xl font-normal">
                      Failed to fetch the hotel booking details
                    </span>
                  </div>
                )}
              </Spinner>
            </div>
          </div>
        </div>
      </main>
      {selectedModal === HOTEL_CANCELLATION_MODAL && (
        <HotelCancellationModal
          handleHotelCancellation={handleHotelCancellation}
        />
      )}
    </div>
  );
};

export default HotelCancellation;
