import { DEFAULT_VALUES } from "../constants";

const { ZERO } = DEFAULT_VALUES;
const PERCENTAGE = "PERCENTAGE";

export const getPriceAfterDiscount = (promoCodeDetails, totalGrand) => {
  const { tava_value, tava_maxamount, tava_type } = promoCodeDetails;
  let discountAmount = ZERO;
  if (tava_type?.toUpperCase() === PERCENTAGE) {
    const discountedPrice = (tava_value * totalGrand) / 100;
    discountAmount = Math.min(discountedPrice, +tava_maxamount);
  }
  return {
    updatedPrice: totalGrand - discountAmount,
    discountAmount,
  };
};
