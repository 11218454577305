import { useCollapse } from "react-collapsed";
import classNames from "classnames";
import countriesData from "../../../../mockData/countriesData.json";
import { ChevronDown, RenderSVG } from "../../../../assets/icons";
import {
  DEFAULT_VALUES,
  TRAVELER_TYPE,
  DEFAULT_BOOKING_DETAILS
} from "../../../../constants";

const { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { ADULT } = TRAVELER_TYPE;
const { DEFAULT_DOCUMENT_TYPE } = DEFAULT_BOOKING_DETAILS;

const getDocumentsFromType = (
  documents = EMPTY_ARRAY,
  documentType = EMPTY_STRING
) => documents.find((document) => document.documentType === documentType);

const getCountryFromCountryCode = (code) =>
  countriesData.find((country) => country.code === code)?.name;

const TravelerCard = ({ travelerData }) => {
  const { getToggleProps, getCollapseProps, isExpanded } = useCollapse({
    duration: 500,
  });

  const {
    givenName,
    familyName,
    dateOfBirth,
    gender,
    email,
    phoneNumber,
    documents,
    travelerType,
  } = travelerData;

  const passportDetails = getDocumentsFromType(
    documents,
    DEFAULT_DOCUMENT_TYPE
  );
  const {
    number,
    issuanceCountry,
    nationality,
    issuanceDate,
    expiryDate
  } = passportDetails || EMPTY_OBJECT;

  return (
    <div className="flex flex-col gap-3 mb-2">
      <div className="border rounded-lg border-gray-300 text-gray-900 relative">
        <div className="flex justify-between items-center gap-2 px-3 py-5">
          <div>
            <span className="text-sm font-semibold flex">
              {givenName}&nbsp;{familyName}
            </span>
          </div>
          <div {...getToggleProps()}>
            <div className="flex items-center gap-2">
              <RenderSVG
                Svg={ChevronDown}
                width="18"
                height="18"
                alt="ChevronDown"
                color="black"
                className={classNames({
                  "rotate-180": isExpanded,
                })}
              />
            </div>
          </div>
        </div>
        <div {...getCollapseProps()}>
          <div className="p-4 border-t border-gray-200">
            <div
              className={classNames("flex flex-col gap-4 pb-6 mb-4", {
                "border-b border-gray-200": travelerType === ADULT,
              })}
            >
              <h5 className="text-base text-gray-900 font-bold">
                Demographics
              </h5>
              <div className="flex gap-2 items-center">
                <h6 className="text-sm text-gray-900 flex-1">Date of Birth</h6>
                <span className="text-sm text-gray-900 font-medium">
                  {dateOfBirth}
                </span>
              </div>
              <div className="flex gap-2 items-center">
                <h6 className="text-sm text-gray-900 flex-1">Gender</h6>
                <span className="text-sm text-gray-900 font-medium capitalize">
                  {gender}
                </span>
              </div>
            </div>

            {travelerType === ADULT && (
              <div
                className={classNames("flex flex-col gap-4 pb-6 mb-4", {
                  " border-b border-gray-200": passportDetails,
                })}
              >
                <h5 className="text-base text-gray-900 font-bold">
                  Contact Information
                </h5>
                <div className="flex gap-2 items-center">
                  <h6 className="text-sm text-gray-900 flex-1">
                    Email Address
                  </h6>
                  <span className="text-sm text-gray-900 font-medium">
                    {email}
                  </span>
                </div>
                <div className="flex gap-2 items-center">
                  <h6 className="text-sm text-gray-900 flex-1">Phone Number</h6>
                  <span className="text-sm text-gray-900 font-medium">
                    {phoneNumber}
                  </span>
                </div>
              </div>
            )}

            {passportDetails && (
              <div className="flex flex-col gap-4 pb-6 mb-4">
                <h5 className="text-base text-gray-900 font-bold">
                  Passport Information
                </h5>
                <div className="flex gap-2 items-center">
                  <h6 className="text-sm text-gray-900 flex-1">
                    Passport Number
                  </h6>
                  <span className="text-sm text-gray-900 font-medium">
                    {number}
                  </span>
                </div>
                <div className="flex gap-2 items-center">
                  <h6 className="text-sm text-gray-900 flex-1">
                    Issuing Country
                  </h6>
                  <span className="text-sm text-gray-900 font-medium">
                    {getCountryFromCountryCode(issuanceCountry)}
                  </span>
                </div>
                <div className="flex gap-2 items-center">
                  <h6 className="text-sm text-gray-900 flex-1">Nationality</h6>
                  <span className="text-sm text-gray-900 font-medium">
                    {getCountryFromCountryCode(nationality)}
                  </span>
                </div>
                <div className="flex gap-2 items-center">
                  <h6 className="text-sm text-gray-900 flex-1">Date Issued</h6>
                  <span className="text-sm text-gray-900 font-medium">
                    {issuanceDate}
                  </span>
                </div>
                <div className="flex gap-2 items-center">
                  <h6 className="text-sm text-gray-900 flex-1">Date Expires</h6>
                  <span className="text-sm text-gray-900 font-medium">
                    {expiryDate}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelerCard;
