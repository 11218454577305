import { useEffect } from "react";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { DEFAULT_VALUES } from "../../../constants";
import { ChevronDown, Checkbox, RenderSVG } from "../../../assets/icons";
import { selectHotelsActiveFilters, setHotelsActiveFilters } from "../../../components/organisms/Search";
import { useDispatch, useSelector } from "react-redux";

const { EMPTY_ARRAY } = DEFAULT_VALUES;

const LOCALITY = "locality";
const CATEGORIES = "categories";

const CategoryFilters = ({
  header,
  categories,
  resetFilters,
  setResetFilters,
  isLocalityFilter = false,
}) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectHotelsActiveFilters);
  const CATEGORY_TYPE = isLocalityFilter ? LOCALITY : CATEGORIES;
  const { t } = useTranslation();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: false,
  });

  // useEffect(() => {
  //   if (resetFilters) {
  //     setFilters({ ...filters, [CATEGORY_TYPE]: EMPTY_ARRAY });
  //     setResetFilters(false);
  //   }
  // }, [resetFilters]);

  const isChecked = (category) => filters[CATEGORY_TYPE]?.includes(category);

  const handleCheckboxChange = (category) => {
    let updatedCategories;
    if (isChecked(category)) {
      updatedCategories = filters[CATEGORY_TYPE].filter(
        (itemCategory) => itemCategory != category
      );
    } else {
      updatedCategories = [...filters[CATEGORY_TYPE], category];
    }
    dispatch(setHotelsActiveFilters({ ...filters, [CATEGORY_TYPE]: updatedCategories }));
  };

  return (
    <>
      <div className='flex items-center gap-2'>
        <h4 className='flex-1 text-sm font-semibold text-contrast-800'>
          {t(header)}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            alt='Expand Icon'
            className={classNames({ "rotate-180": isExpanded })}
          />
        </span>
      </div>
      <div {...getCollapseProps()}>
        <div className='pt-6'>
          <div className='grid grid-cols-2 gap-4'>
          {categories.map(({ name: category, count }) => {
              const checked = isChecked(category);
              return (
                <div className='col-span-2' key={category}>
                  <div className='checbox-tab'>
                    <label className='cursor-pointer'>
                      <input
                        type='checkbox'
                        className='peer hidden'
                        id={category}
                        value={category}
                        onChange={() => handleCheckboxChange(category)}
                        checked={checked}
                      />
                      <div
                        className={classNames(
                          "flex items-center gap-2 border-2 p-4 rounded-lg",
                          {
                            "bg-primary-100 border-primary-600 text-primary-900":
                              checked,
                            "border-contrast-200 text-contrast-900": !checked,
                          }
                        )}
                      >
                        <div
                          className={classNames(
                            "w-4 h-4 rounded grid place-content-center border",
                            {
                              "bg-primary-600 border-primary-600": checked,
                              "border-contrast-300": !checked,
                            }
                          )}
                        >
                          <RenderSVG
                            Svg={Checkbox}
                            width='9'
                            height='9'
                            alt='Checkbox Icon'
                          />
                        </div>
                        <div className='w-full flex justify-between text-sm font-medium'>
                          <span className='flex flex-center'>
                            {category || "Default"}
                          </span>
                          <span className='text-contrast-500'>{count}</span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryFilters;
