export const getHotelImageUrl = (images) => {
  if (!images || typeof images !== 'object') {
    return null;
  }

  const categories = ['Exterial', 'LobbyView', 'Restaurant'];
  for (let category of categories) {
    if (images[category] && images[category].fullImage && images[category].fullImage.length > 0) {
      return images[category].fullImage[0];
    }
  }

  if (Array.isArray(images) && images[0] && images[0].fullImage) {
    return images[0].fullImage;
  }

  return null;
};
