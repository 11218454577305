import { isArray, get } from "lodash";
import { DEFAULT_VALUES } from "../../constants";

const { ZERO, EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY } = DEFAULT_VALUES;

const getTravelerType = (type) => {
  const travelerTypeMapping = { 1: "Adult", 2: "Child", 3: "Infant" };
  return travelerTypeMapping[type];
};

const combineTaxes = (taxes, prevTaxes) => {
  return (
    taxes?.map(({ key, value }) => {
      const matchingItem = prevTaxes.find((prevTax) => key === prevTax.key);
      return matchingItem
        ? { key: key, value: value + matchingItem.value }
        : { key: key, value: value };
    }) || []
  ).concat(
    prevTaxes.filter(
      (prevTax) => !taxes?.some((tax) => tax.key === prevTax.key)
    )
  );
};

const formatTravelerDetails = (passengerDetails, previousPassengerDetails) => {
  return passengerDetails.map((passenger, index) => {
    const prevPricing = previousPassengerDetails?.travelerPricings?.[index]
      ?.price || {
      basePrice: ZERO,
      grandTotal: ZERO,
      taxes: [],
    };

    let reissuancePriceDetails = {};
    let reissuancePrice = ZERO;
    if (passenger?.SupplierReissueCharges) {
      reissuancePriceDetails = {
        reissuancePrice: passenger.SupplierReissueCharges,
        reissueDifference: passenger.ReissueDifferenceAmount,
      };

      reissuancePrice =
        reissuancePriceDetails.reissuancePrice +
        (reissuancePriceDetails.reissueDifference < ZERO
          ? reissuancePriceDetails.reissueDifference
          : ZERO);
    }

    return {
      id: EMPTY_STRING,
      fareOption: EMPTY_STRING,
      travelerType: getTravelerType(passenger.PassengerType),
      travelersCount: passenger.PassengerCount,
      price: {
        currency: passenger.Currency,
        basePrice: passenger.BaseFare + prevPricing.basePrice,
        ...reissuancePriceDetails,
        grandTotal:
          passenger.BaseFare +
          passenger.Tax +
          prevPricing.grandTotal +
          reissuancePrice,
        commission: EMPTY_STRING,
        taxes: combineTaxes(passenger.TaxBreakUp, prevPricing.taxes),
      },
      fareDetailsBySegment: EMPTY_STRING,
    };
  });
};

export const getFormattedTBOResponse = (data) => {
  let flightPriceInfo = {};
  const fareBreakdown = {};
  const response = Array.isArray(data) ? data : [data];
  let fareRules = response.map((priceData) => {
    const fareRuleResponse = get(
      priceData,
      "tboResponse.fareRuleResponse.Response",
      {}
    );
    const journeyId = get(priceData, "tboResponse.journeyId");
    return { ...fareRuleResponse, journeyId };
  });
  response.forEach((priceData) => {
    const tboRes = get(priceData, "tboResponse.Response", EMPTY_OBJECT);
    const results = get(tboRes, "Results", EMPTY_OBJECT);
    const { FareBreakdown = {}, ResultIndex } = results;
    const isFlightInfoAvailable = isArray(flightPriceInfo?.resultIndex);

    if (ResultIndex) fareBreakdown[ResultIndex] = FareBreakdown;

    const fare =
      isFlightInfoAvailable && flightPriceInfo?.price
        ? flightPriceInfo.price
        : {};
    const taxes =
      isFlightInfoAvailable && flightPriceInfo?.taxes
        ? flightPriceInfo.taxes
        : {};

    if (
      !flightPriceInfo?.resultIndex?.some(
        (each) => each === results.ResultIndex
      )
    ) {
      let reissuancePriceDetails = {};
      let reissuancePrice = ZERO;
      if (results?.Fare?.SupplierReissueCharges) {
        reissuancePriceDetails = {
          reissuancePrice: results.Fare.SupplierReissueCharges,
          reissueDifference: results.Fare.ReissueDifferenceAmount,
        };
        reissuancePrice =
          reissuancePriceDetails.reissuancePrice +
          (reissuancePriceDetails.reissueDifference < ZERO
            ? reissuancePriceDetails.reissueDifference
            : ZERO);
      }

      flightPriceInfo = {
        ...flightPriceInfo,
        resultIndex: [
          ...(flightPriceInfo?.resultIndex || []),
          results.ResultIndex,
        ],
        flightDetailChangeInfo: `${
          flightPriceInfo.flightDetailChangeInfo || EMPTY_STRING
        } ${tboRes.FlightDetailChangeInfo || EMPTY_STRING}`,
        fareRulesDetails: fareRules,
        isPriceChanged:
          flightPriceInfo?.isPriceChanged || tboRes.IsPriceChanged,
        requiredFields: results.RequiredFieldValidators,
        source: "TBO",
        flightId: "",
        price: {
          currency: get(results, "Fare.Currency", EMPTY_STRING),
          basePrice:
            get(results, "Fare.BaseFare", ZERO) + (fare.basePrice || ZERO),
          ...reissuancePriceDetails,
          grandTotal:
            get(results, "Fare.OfferedFare", ZERO) +
            (fare.grandTotal || ZERO) +
            reissuancePrice,
          commission: EMPTY_STRING,
          IsLCC: results.IsLCC,
        },
        pricingOptions: {
          fareType: ["PUBLISHED"],
          includedCheckedBagsOnly: false,
        },
        segments: [
          ...(flightPriceInfo.segments || EMPTY_ARRAY),
          ...get(results, "Segments", EMPTY_ARRAY),
        ],
        taxes: {
          taxAmount: get(results, "Fare.Tax", 0) + (taxes.taxAmount || 0),
          taxCurrency: get(results, "Fare.currency", EMPTY_STRING),
          otherCharges:
            get(results, "Fare.OtherCharges", 0) + (taxes.otherCharges || 0),
        },
        refund: {
          refundableTax: EMPTY_STRING,
          refundApplicable: results.IsRefundable,
          refundAmount: EMPTY_STRING,
          refundConditions: [],
        },
        travelerPricings: formatTravelerDetails(
          get(results, "FareBreakdown", EMPTY_ARRAY),
          isFlightInfoAvailable ? flightPriceInfo : null
        ),
        documentsRequired: {
          IsPanRequiredAtBook: results.IsPanRequiredAtBook,
          IsPanRequiredAtTicket: results.IsPanRequiredAtTicket,
          IsPassportRequiredAtBook: results.IsPassportRequiredAtBook,
          IsPassportRequiredAtTicket: results.IsPassportRequiredAtTicket,
          IsPassportFullDetailRequiredAtBook:
            results.IsPassportFullDetailRequiredAtBook,
          IsGSTMandatory: results.IsGSTMandatory,
          GSTAllowed: results.GSTAllowed,
        },
        fareType: results.ResultFareType,
        fareBreakdown,
      };
    }
  });
  return [flightPriceInfo];
};
