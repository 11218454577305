import { createSlice } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import {
  fetchAmadeusFlights,
  fetchTBOFlights,
  getCalendarDatePrices,
  getIATALocations,
  getHotelLocations,
  getHotels,
} from "./index";
import { handleGroupedFlights } from "../../../helper";
import { getFormattedFlightSearchResponse } from "../../../ResponseMapper";
import { getFormattedFarePricesPerDates } from "../../../ResponseMapper/FareCalendarMapping/getFormattedFareCalendarResponse";
import {
  DEFAULT_VALUES,
  LOCATION_TYPE,
  INITIAL_FLIGHT_COUNT,
  SORTING_HEADERS,
  FLIGHTS_RESULT_TYPE,
  INITIAL_FILTERS,
  DEFAULT_FLIGHT_SEARCH_OPTIONS,
  INITIAL_HOTEL_VALUES,
  INITIAL_HOTEL_FILTERS,
} from "../../../constants";
import { getHotelsStaticData, getPlacesFromGoogle } from "./search.actions";

const { EMPTY_ARRAY, EMPTY_OBJECT, GDS, ONE, ZERO} = DEFAULT_VALUES;
const { FROM, TO } = LOCATION_TYPE;
const { cheapest } = SORTING_HEADERS;
const { INBOUND_RESULT, OUTBOUND_RESULT } = FLIGHTS_RESULT_TYPE;

const initialState = {
  flights: EMPTY_OBJECT,
  flightsCount: INITIAL_FLIGHT_COUNT,
  filteredFlights: EMPTY_OBJECT,
  activeFilters: INITIAL_FILTERS,
  flightSearchOptions: DEFAULT_FLIGHT_SEARCH_OPTIONS,
  flightTokens: EMPTY_OBJECT,
  searchFilters: EMPTY_OBJECT,
  searchFilterURL: null,
  selectedReIssuanceFlight: null,
  toIataLocations: EMPTY_ARRAY,
  fromIataLocations: EMPTY_ARRAY,
  farePricesPerDates: EMPTY_OBJECT,
  isNonStopSelected: false,
  sortedFlights: EMPTY_OBJECT,
  activeSortOrder: {
    inbound: cheapest.name,
    outbound: cheapest.name,
    packages: cheapest.name,
  },
  // TODO: Need to move to separate hotel redux state
  predictions: EMPTY_ARRAY,
  selectedPrediction: {},
  currentSearchLevel: ONE,
  hotels: EMPTY_OBJECT,
  hotelsStaticData: EMPTY_ARRAY,
  isHotelsLoaded: false,
  isStaticDataLoaded: false,
  searchHotelFilters: null,
  hotelLocations: EMPTY_ARRAY,
  filteredHotels: EMPTY_ARRAY,
  hotelsSortingValues: INITIAL_HOTEL_VALUES,
  hotelsActiveFilters: INITIAL_HOTEL_FILTERS,
  resetHotelsActiveFilters: INITIAL_HOTEL_FILTERS,
  hotelsCount: ZERO,
  hotelCurrency: EMPTY_OBJECT
};

const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchFilters(state, action) {
      state.searchFilters = action.payload || initialState.searchFilters;
    },
    setFlights(state, action) {
      state.flights = action.payload || initialState.flights;
    },
    setHotels(state, action) {
      state.hotels = action.payload || initialState.hotels;
    },
    setHotelsStaticData(state, action) {
      state.hotelsStaticData = action.payload || initialState.hotelsStaticData;
    },
    setSearchHotelFilters(state, action) {
      if (action.payload) state.searchHotelFilters = action.payload;
    },
    setActiveFilters(state, action) {
      state.activeFilters = action.payload || initialState.activeFilters;
    },
    setFlightSearchOptions(state, action) {
      state.flightSearchOptions =
        action.payload || initialState.flightSearchOptions;
    },
    setFilteredFlights(state, action) {
      state.filteredFlights = action.payload || initialState.filteredFlights;
    },
    setSearch(state, action) {
      if (action.payload) return action.payload;
    },
    resetFlights(state) {
      state.flights = initialState.flights;
      state.flightsCount = initialState.flightsCount;
      state.flightTokens = initialState.flightTokens;
      state.filteredFlights = initialState.filteredFlights;
      state.activeSortOrder = initialState.activeSortOrder;
    },
    resetHotelSearchResult(state) {
      state.hotels = initialState.hotels;
      state.hotelsStaticData = initialState.hotelsStaticData;
      state.searchHotelFilters = initialState.searchHotelFilters;
      state.hotelsCount = initialState.hotelsCount;
      state.filteredHotels = initialState.filteredHotels;
    },
    setFlightsCount(state, action) {
      state.flightsCount = action.payload || initialState.flightsCount;
    },
    setIsNonStopSelected(state, action) {
      state.isNonStopSelected =
        action.payload || initialState.isNonStopSelected;
    },
    setSortedFlights(state, action) {
      state.sortedFlights = action.payload;
    },
    setActiveSortOrder(state, action) {
      const { activeSort, type } = action.payload;
      switch (type) {
        case INBOUND_RESULT:
          state.activeSortOrder.inbound = activeSort;
          break;
        case OUTBOUND_RESULT:
          state.activeSortOrder.outbound = activeSort;
          break;
        default:
          state.activeSortOrder.packages = activeSort;
          break;
      }
    },
    setSelectedReIssuanceFlight(state, action) {
      state.selectedReIssuanceFlight = action.payload;
    },
    setSelectedPrediction(state, action) {
      state.selectedPrediction = action.payload;
    },
    setCurrentSearchLevel(state, action) {
      state.currentSearchLevel = action.payload;
    },
    setSearchFilterURL(state, action) {
      state.searchFilterURL = action.payload;
    },
    setHotelsActiveFilters(state, action){
      state.hotelsActiveFilters = action.payload || initialState.hotelsActiveFilters;
    },
    setFilteredHotels(state, action){
      state.filteredHotels = action.payload || initialState.filteredHotels;
    },
    setHotelsSortingValues(state, action){
      state.hotelsSortingValues = action.payload || initialState.hotelsSortingValues;
    },
    setHotelsActiveFilters(state, action){
      state.hotelsActiveFilters = action.payload || initialState.hotelsActiveFilters;
    },
    setHotelsCount(state, action) {
      state.hotelsCount = action.payload || initialState.hotelsCount;
    },
    setIsHotelsLoaded(state, action) {
      state.isHotelsLoaded = action.payload || initialState.isHotelsLoaded;
    },
    setIsStaticDataLoaded(state, action) {
      state.isStaticDataLoaded = action.payload || initialState.isStaticDataLoaded;
    },
    resetHotelsActiveFilters(state) {
      state.resetHotelsActiveFilters = initialState.resetHotelsActiveFilters;
    },
    setHotelCurrency(state, action){
      state.hotelCurrency = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAmadeusFlights.fulfilled, (state, action) => {
        const travelType = get(action, "meta.arg.body.travelType", "domestic");
        if (!action.payload) return;
        const formattedAmadeusFlightsResult = getFormattedFlightSearchResponse(
          state.flights,
          state.flightsCount,
          action.payload.output,
          state.searchFilters.tripType,
          travelType,
          state.searchFilters.fareType
        );
        const { flights, count } = formattedAmadeusFlightsResult;
        const groupedFlights = handleGroupedFlights(flights);
        state.flights = groupedFlights;
        state.filteredFlights = groupedFlights;
        state.flightsCount = { ...state.flightsCount, ...count };
      })
      .addCase(fetchTBOFlights.fulfilled, (state, action) => {
        const travelType = get(action, "meta.arg.body.travelType", "domestic");

        if (!action.payload) return;
        const formattedTBOFlightsResult = getFormattedFlightSearchResponse(
          state.flights,
          state.flightsCount,
          action.payload.output,
          state.searchFilters.tripType,
          travelType,
          state.searchFilters.fareType
        );
        const { flights, count, tokenId, traceId } = formattedTBOFlightsResult;
        const groupedFlights = handleGroupedFlights(flights);
        const source = get(action, "meta.arg.body.sources",[])
        if( groupedFlights && !(!isEmpty(state.flights) && source?.includes(GDS))){
          state.flights = groupedFlights;
          state.filteredFlights = groupedFlights;
          state.flightTokens = { traceId, tokenId };
          state.flightsCount = { ...state.flightsCount, ...count };
        }
      })
      .addCase(getHotels.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.hotels = action.payload;
        state.isHotelsLoaded = true;

        if (state.isStaticDataLoaded) {
          if (
            state.hotels &&
            state.hotels.hotelResults
          ) {
            const staticDataMap = {};
            state.hotelsStaticData?.forEach((staticHotel) => {
              if (staticHotel?.hotelCode) {
                staticDataMap[staticHotel.hotelCode] = staticHotel;
              }
            });

            const updatedHotelResults =
              state.hotels?.hotelResults.map((hotel) => {
                const staticData = staticDataMap[hotel.hotelCode];
                if (staticData) return {...hotel, ...staticData}
                else return hotel;
              }).filter((hotel) => hotel?.hotelName && !isEmpty(hotel?.hotelAddress));
            state.hotels.hotelResults = updatedHotelResults;
          }
        }
      })
      .addCase(getHotelsStaticData.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.hotelsStaticData = action.payload;

        state.isStaticDataLoaded = true;

        if (state.isHotelsLoaded) {
          if (
            state.hotels &&
            state.hotels.hotelResults
          ) {
            const staticDataMap = {};
            state.hotelsStaticData?.forEach((staticHotel) => {
              if (staticHotel?.hotelCode) {
                staticDataMap[staticHotel.hotelCode] = staticHotel;
              }
            });

            const updatedHotelResults =
              state.hotels?.hotelResults.map((hotel) => {
                const staticData = staticDataMap[hotel.hotelCode];

                if (staticData) return {...hotel, ...staticData};
                else return hotel;
              }).filter((hotel) => hotel?.hotelName && !isEmpty(hotel?.hotelAddress));;
            state.hotels.hotelResults = updatedHotelResults;
          }
        }
      })
      .addCase(getIATALocations.fulfilled, (state, action) => {
        if (!action.payload) return;
        if (action.payload.type === FROM)
          state.fromIataLocations = action.payload;
        else if (action.payload.type === TO)
          state.toIataLocations = action.payload;
        else {
          state.fromIataLocations = action.payload;
          state.toIataLocations = action.payload;
        }
      })
      .addCase(getCalendarDatePrices.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.farePricesPerDates = getFormattedFarePricesPerDates(
          action.payload
        );
      })
      .addCase(getHotelLocations.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.hotelLocations = get(action, "payload.hits", []);
      })
      .addCase(getPlacesFromGoogle.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.predictions = get(action, "payload.predictions", []);
      });
  },
});
export default slice;

export const { name, reducer, actions } = slice;
