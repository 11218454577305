import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import { getAmenityIcon } from "../../../assets/icons/Amenities";
import { selectHotelInfo } from "../../../screens/HotelInfo";
import { DEFAULT_VALUES } from "../../../constants";
import { MODALS } from "../../organisms/AppModals";
import { setSelectedModal } from "../../organisms/Modal";

const CARD_AMENITIES_LIMIT = 5;
const { EMPTY_OBJECT, ZERO } = DEFAULT_VALUES;
const { AMENITIES_MODAL } = MODALS;

const AmenitiesCard = () => {
  const dispatch = useDispatch();
  const hotelInfo = useSelector(selectHotelInfo);
  const hotelDetails = get(
    hotelInfo,
    "HotelRoomInfo.HotelInfoResult.HotelDetails",
    EMPTY_OBJECT
  );
  const hotelFacilities = hotelDetails.HotelFacilities;
  const totalAmenities = hotelFacilities?.length || ZERO;

  const minifiedAmenities =
    totalAmenities > CARD_AMENITIES_LIMIT
      ? hotelFacilities.slice(ZERO, CARD_AMENITIES_LIMIT)
      : hotelFacilities;

  return (
    <div>
      {minifiedAmenities && totalAmenities && (
        <div className="mb-2">
          <div className="text-lg mb-2 font-bold">Amenities</div>
          <div className="flex flex-col justify-between sm:flex-row rounded border border-primary-900 px-3 py-2 font-thin text-xs gap-2 text-center">
            {minifiedAmenities.map((amenity) => (
              <div
                key={amenity}
                className="flex-1 flex flex-row  sm:flex-col gap-3 sm:gap-1 items-center sm:text-center"
              >
                <img src={getAmenityIcon(amenity)} width={30} />
                <div> {amenity} </div>
              </div>
            ))}
            {CARD_AMENITIES_LIMIT < totalAmenities && (
              <div
                className="flex flex-1 sm:flex-col sm:justify-center items-center w-auto sm:w-min gap-3 sm:gap-1 cursor-pointer group"
                onClick={() => dispatch(setSelectedModal(AMENITIES_MODAL))}
              >
                <span className="text-base">
                  + {totalAmenities - CARD_AMENITIES_LIMIT}
                </span>
                <span className="text-primary-600 group-hover:text-primary-700">
                  More Ameneties
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AmenitiesCard;
