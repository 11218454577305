import { getFormattedAmadeusResponse } from "./getFormattedAmadeusResponse";
import { getFormattedTBOResponse } from "./getFormattedTBOResponse";

const formattedTravelerResult = (travelData) => {
  const totalPriceByTravelerType = [];
  const findIndex = (travelerType) =>
    totalPriceByTravelerType.findIndex(
      (item) => item.travelerType === travelerType
    );
  travelData.forEach((trip) => {
    trip.travelerPricings.forEach((pricing) => {
      const { travelerType, price, travelersCount } = pricing;
      const { basePrice, grandTotal } = price;
      const index = findIndex(travelerType);

      if (index === -1) {
        totalPriceByTravelerType.push({
          id: pricing.id,
          fareOption: pricing.fareOption,
          travelerType,
          travelersCount,
          price: {
            currency: price.currency,
            basePrice,
            grandTotal,
            commission: price.commission,
            taxes: price.taxes,
          },
        });
      } else {
        totalPriceByTravelerType[index].price.basePrice += basePrice;
        totalPriceByTravelerType[index].price.grandTotal += grandTotal;
      }
    });
  });

  return totalPriceByTravelerType;
};

const handlePrice = (result, value) => {
  const key = "price";
  if (result.hasOwnProperty(key)) {
    result[key].basePrice += value.basePrice || 0;
    result[key].grandTotal += value.grandTotal || 0;
  } else {
    result[key] = { ...value };
  }
};

const handleTaxes = (result, value) => {
  const key = "taxes";
  if (result.hasOwnProperty(key)) {
    result[key].taxAmount += value.taxAmount || 0;
  } else {
    result[key] = { ...value };
  }
};

const handleDefault = (result, key, value) => {
  if (result.hasOwnProperty(key)) {
    result[key] = Array.isArray(result[key])
      ? [...result[key], value]
      : [result[key], value];
  } else {
    result[key] = value;
  }
};

const mergeObjectsArray = (objArray) => {
  return objArray.reduce((result, obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      switch (key) {
        case "price":
          handlePrice(result, value);
          break;
        case "travelerPricings":
          result[key] = formattedTravelerResult(objArray);
          break;
        case "taxes":
          handleTaxes(result, value);
          break;
        default:
          handleDefault(result, key, value);
      }
    });
    return result;
  }, {});
};

export const getformattedResponseForBoth = (priceResponse) => {
  const formattedResponsefromEachSource = priceResponse.map((response) => {
    if (response.tboResponse) {
      return getFormattedTBOResponse(response);
    } else {
      return getFormattedAmadeusResponse(response);
    }
  });
  const flattenedData = formattedResponsefromEachSource.flatMap(
    (innerArray) => innerArray
  );
  const mergedResult = mergeObjectsArray(flattenedData);

  return [mergedResult];
};
