import ReactGA from "react-ga4";

// log events to google analytics
const logEvent = (
    name,
    { category = "button", action = "click", label = "" } = {}
) =>
    ReactGA.event(name, {
        category,
        action,
        label: label || name,
    });

export default logEvent;
