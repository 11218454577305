import { DEFAULT_VALUES } from "../constants";

const { ONE, TWO, THREE } = DEFAULT_VALUES;

export const dateFormatter = (inputString) => {
  const day = inputString.slice(0, 2);
  const monthAbbreviation = inputString.slice(2, 5);
  const year = inputString.slice(5);

  const formattedMonth = monthAbbreviation.toUpperCase();

  const updatedString = `${day}-${formattedMonth}-${year}`;

  return updatedString;
};

export const removeLeadingZerosFromDate = (dateString) => {
  const parts = dateString.split("-");
  if (parts.length === THREE) {
    if (parts[ONE].startsWith("0")) {
      parts[ONE] = parts[ONE].slice(ONE);
    }
    if (parts[TWO].startsWith("0")) {
      parts[TWO] = parts[TWO].slice(ONE);
    }
  }
  return parts.join("-");
};
