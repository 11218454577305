import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_VALUES } from "../../constants";
import {
  addTraveler,
  getTravelers,
  deleteTraveler,
  updateTraveler,
  getRateExchanges,
  updateUserProfile
} from "./profile.actions";
import { getCategorizedTravelers } from "../../helper";

const { EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;

const initialState = {
  travelerInfo: EMPTY_ARRAY,
  userInfo: null,
  countryInfo: null,
  updateTravelers: EMPTY_ARRAY,
  exchangeRates: EMPTY_OBJECT,
};

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setTravelerInfo(state, action) {
      state.travelerInfo = action.payload;
    },

    setCountryInfo(state, action) {
      state.countryInfo = { ...state.countryInfo, ...action.payload };
    },
    setUpdatedTravelers(state, action) {
      state.updateTravelers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTravelers.fulfilled, (state, action) => {
      if (!action.payload) return;
      const { user, travelers } = action.payload.output;
      state.travelerInfo = getCategorizedTravelers(travelers, user);
      state.userInfo = user;
    });
    builder.addCase(addTraveler.fulfilled, (state, action) => {
      if (!action.payload) return;
      const newTraveler = [action.payload.output];
      const formattedTraveler = getCategorizedTravelers(
        newTraveler,
        state.userInfo
      );
      state.travelerInfo = [...state.travelerInfo, ...formattedTraveler];
    });
    builder.addCase(updateTraveler.fulfilled, (state, action) => {
      if (!action.payload) return;
      const traveler = [action.payload.output];
      const formattedTraveler = getCategorizedTravelers(
        traveler,
        state.userInfo
      );
      const updatedTraveler = formattedTraveler.find(
        (eachTraveler) => eachTraveler.travelerId === action.payload.output.id
      );
      state.travelerInfo = state.travelerInfo.map((traveler) =>
        traveler.travelerId === updatedTraveler.travelerId
          ? updatedTraveler
          : traveler
      );
    });
    builder.addCase(deleteTraveler.fulfilled, (state, action) => {
      if (!action.meta.arg) return;
      state.travelerInfo = state.travelerInfo.filter(
        ({ travelerId }) => travelerId !== action.meta.arg
      );
    });
    builder.addCase(getRateExchanges.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.exchangeRates = action.payload.rates;
    });

    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      if (!action.payload) return;
       state.userInfo=action.payload.output;
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
