import airportInfo from "../assets/json/airportInfo.json";
import { DEFAULT_VALUES } from "../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;

const defaultInfo = {
  id: EMPTY_STRING,
  iata: EMPTY_STRING,
  cityName: EMPTY_STRING,
  airportName: EMPTY_STRING,
  country: EMPTY_STRING,
  countryCode: EMPTY_STRING,
  timeZone: EMPTY_STRING,
};

export const getAirportInfoByIata = (iataCode) =>
  airportInfo[iataCode] || defaultInfo;

export const getCityNameByIata = (iataCode) =>
  getAirportInfoByIata(iataCode).cityName;
