import { DEFAULT_VALUES, CURRENCY_SYMBOLS } from "../../../../constants";
const { EMPTY_OBJECT } = DEFAULT_VALUES;
const { INR } = CURRENCY_SYMBOLS;

const PricingDetailsSection = ({ pricingDetails = EMPTY_OBJECT }) => {
  const { basePrice, grandTotal } = pricingDetails;

  return (
    <>
      <h4 className="text-gray-900 font-bold mb-6 text-xl">Price breakdown</h4>
      <div className="border border-gray-300 rounded-lg mb-6">
        <ul className="flex flex-col text-sm divide-y">
          <li className="flex items-center gap-2 px-4 py-3">
            <span className="mr-auto text-gray-900">Fare</span>
            <span className="font-medium">
              {INR}
              {basePrice}
            </span>
          </li>
          <li className="flex items-center gap-2 px-4 py-3 bg-indigo-50/50">
            <span className="text-base font-bold mr-auto text-indigo-600">
              Grand Total
            </span>
            <span className="text-base font-bold text-indigo-600">
              {INR}
              {grandTotal}
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PricingDetailsSection;
