import get from "lodash/get";
import moment from "moment";
import { DEFAULT_VALUES } from "../constants";

const { EMPTY_STRING, EMPTY_ARRAY, ONE, ZERO } = DEFAULT_VALUES;

export const getLayoverDuration = (segments) => {
  const layoverDuration = segments.reduce((duration, segment, index) => {
    const arrival = get(segment, "arrival", EMPTY_STRING);
    if (!segments[index + ONE]) return duration;
    const departure = get(segments[index + ONE], "departure", EMPTY_STRING);

    const arrivalTimestamp = moment(
      `${arrival.date} ${arrival.time}`,
      "YYYY-MM-DD HH:mm"
    );
    const departureTimestamp = moment(
      `${departure.date} ${departure.time}`,
      "YYYY-MM-DD HH:mm"
    );

    const diff = moment.duration(departureTimestamp.diff(arrivalTimestamp));

    const days = diff.days();
    const hours = diff.hours();
    const minutes = diff.minutes();

    const layoverTime =
      (days > ZERO ? `${days}D ` : EMPTY_STRING) +
      (hours > ZERO ? `${hours}H ` : EMPTY_STRING) +
      (minutes > ZERO ? `${minutes}M` : EMPTY_STRING);

    return [...duration, ...(layoverTime && [layoverTime])];
  }, EMPTY_ARRAY);

  return layoverDuration;
};
