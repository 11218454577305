function pascalToCamelCase(str) {
  if (str.length === 0) {
    return str // handle empty string
  }
  return str.charAt(0).toLowerCase() + str.slice(1)
}

function transformObjectKeys(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj; // Return the value if it's not an object
  }

  if (Array.isArray(obj)) {
    return obj.map(item => transformObjectKeys(item)); // Handle arrays
  }

  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = pascalToCamelCase(key);
      newObj[newKey] = transformObjectKeys(obj[key]); // Recursively transform keys
    }
  }
  return newObj;
}

export const convertPascalToCamelCase= (input)=> {
  if (Array.isArray(input)) {
    return input.map(transformObjectKeys)
  } else if (typeof input === 'object' && input !== null) {
    return transformObjectKeys(input)
  } else {
    throw new Error('Input must be an object or an array of objects')
  }
}

const toPascalCase = (str) => {
  return str.replace(/(^\w|_\w)/g, match => match.toUpperCase().replace('_', ''));
}

const convertObjectKeysToPascalCase = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => convertObjectKeysToPascalCase(item));
  }

  const formattedObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const pascalCaseKey = toPascalCase(key);
      formattedObj[pascalCaseKey] = convertObjectKeysToPascalCase(obj[key]); // Recursively convert nested objects
    }
  }
  return formattedObj;
}
