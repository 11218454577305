import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal, { selectSelectedModal, setSelectedModal } from "../../Modal";
import { selectHotelSearchFilters } from "../../Search";
import { getQueryParams } from "../../../../helper";
import { Information, RenderSVG } from "../../../../assets/icons";
import { parseDescription } from "../../../../screens/HotelInfo/HotelInfo";
import { MODALS } from "../modals.constants";
import { ROUTES, DEFAULT_VALUES } from "../../../../constants";

const { HOTEL_RESULTS } = ROUTES;
const { HOTEL_DETAILS_CHANGE_MODAL } = MODALS;
const { ONE } = DEFAULT_VALUES;

const DetailsChangeModal = ({
  handleClose = () => {},
  title = "",
  message = "",
}) => {
  const navigate = useNavigate();
  const activeFilters = useSelector(selectHotelSearchFilters) || {};

  return (
    <div className="overflow-auto">
      <Modal shouldShowModalFromProps handleClose={handleClose} title={title}>
        <div className="flex justify-center items-center py-2 mt-2 sm:mt-4">
          <RenderSVG
            Svg={Information}
            alt="information-icon"
            className="w-[80px] h-[80px] md:w-[100px] md:h-[100px]"
          />
        </div>
        <div className="flex justify-center items-center py-4 p-4 sm:py-8 sm:p-10">
          <div className="text-sm md:text-base min-w-[65px] max-h-60 overflow-y-scroll px-4 sm:px-10">{message}</div>
        </div>
        <div className="flex justify-center items-center px-10 mb-4 gap-2 sm:gap-4 ">
          <div className="text-center py-1 sm:py-4">
            <button
              className="btn bg-transparent border border-contrast-400 hover:opacity-90"
              onClick={() =>
                navigate(`${HOTEL_RESULTS}?${getQueryParams(activeFilters)}`)
              }
            >
              Search Again
            </button>
          </div>
          <div className="py-4 text-center">
            <button className="btn btn-primary" onClick={() => handleClose()}>
              Agree, Continue
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const HotelDetailsChangeModal = ({
  havePoliciesChanged,
  hasPriceChanged,
  updatedPrice,
  updatedCancellationPolicy,
}) => {
  const dispatch = useDispatch();
  const selectedModal = useSelector(selectSelectedModal);
  const splittedHotelDesctiption = updatedCancellationPolicy;

  const handleModalClose = () => dispatch(setSelectedModal(null));

  return (
    selectedModal === HOTEL_DETAILS_CHANGE_MODAL && (
      <>
        {hasPriceChanged && (
          <DetailsChangeModal
            handleClose={handleModalClose}
            title={"Room Prices have updated, please review before proceeding."}
            message={`New price is: ${updatedPrice}`}
          />
        )}
        {havePoliciesChanged && (
          <DetailsChangeModal
            handleClose={handleModalClose}
            title="Hotel Policies updated, please review before proceeding."
            message={parseDescription(splittedHotelDesctiption)}
          />
        )}
      </>
    )
  );
};

export default HotelDetailsChangeModal;
