import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { isEmpty, get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  selectUserBookingInfo,
  selectIsGenerateVoucherInitiated,
  selectSelectedBooking,
} from "../index";
import HotelBookingInfo from "./HotelBookinginfo";
import FlightBookingInfo from "./FlightBookingInfo";
import {
  WINDOWS_EVENTS,
  ROUTES,
  SEARCH_SECTION,
  DEFAULT_VALUES,
} from "../../../constants";
import { getImageUrlByName } from "../../../helper";

const { POPSTATE } = WINDOWS_EVENTS;
const { USER_BOOKINGS, ADMIN_BOOKINGS } = ROUTES;
const { ZERO } = DEFAULT_VALUES;
const { HOTEL } = SEARCH_SECTION;
const ADMIN = "admin";

const UserBookingDetails = () => {
  const { division, id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const selectedUserBookingInfo = useSelector(selectUserBookingInfo);
  const selectedIsGenerateVoucherInitiated = useSelector(
    selectIsGenerateVoucherInitiated
  );
  const selectedBooking = useSelector(selectSelectedBooking);

  const [isSpinnerActive, setIsSpinnerActive] = useState(true);
  const [userBookingInfo, setUserBookingInfo] = useState({});

  useEffect(() => {
    if (!isEmpty(selectedUserBookingInfo))
      setUserBookingInfo(get(selectedUserBookingInfo, "result.0"));
  }, [selectedUserBookingInfo]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    const isAdmin = location.pathname.includes(ADMIN);
    let navigationRoute = USER_BOOKINGS
    if (isAdmin) navigationRoute = ADMIN_BOOKINGS;
    navigate(navigationRoute);
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener(POPSTATE, onBackButtonEvent);

    return () => window.removeEventListener(POPSTATE, onBackButtonEvent);
  }, []);

  return (
    <div>
      {!isEmpty(userBookingInfo) && (
        <div>
          {division === HOTEL ? (
            <HotelBookingInfo
              booking={userBookingInfo}
              isSpinnerActive={isSpinnerActive}
              isGenerateVoucherInitiated={selectedIsGenerateVoucherInitiated}
              selectedBooking={selectedBooking}
              setIsSpinnerActive={setIsSpinnerActive}
            />
          ) : (
            <FlightBookingInfo
              bookingId={id}
              isSpinnerActive={isSpinnerActive}
              setIsSpinnerActive={setIsSpinnerActive}
            />
          )}
        </div>
      )}
      {!isEmpty(selectedUserBookingInfo) &&
        selectedUserBookingInfo.result?.length === ZERO && (
          <div className="flex items-center justify-center p-5 mt-5">
            <div className="container bg-white border rounded-lg h-[360px] overflow-hidden px-5">
              <div className="text-center">
                <div className="flex justify-center">
                  <img
                    src={getImageUrlByName("misc/noResult.png")}
                    alt="no-result-found"
                    className="w-60 h-60"
                  />
                </div>
                <span className="text-md font-bold text-primary-700">
                  <div>
                    <p>{t("userBookings.noFlightWithId")}</p>
                  </div>
                </span>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default UserBookingDetails;
