import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectSelectedLCCBaggages, setSelectedLCCBaggages } from "../index";
import BaggageCard from "./BaggageCard";
import Modal, {
  selectSelectedModal,
  setSelectedModal,
} from "../../../../components/organisms/Modal";
import { MODALS } from "../../../../components/organisms/AppModals";
import { DEFAULT_VALUES, MODAL_SIZE, INDEX } from "../../../../constants";

const { ONE, EMPTY_ARRAY } = DEFAULT_VALUES;
const { MD } = MODAL_SIZE;
const { LAST } = INDEX;
const { BAGGAGE_SELECTION_MODAL } = MODALS;

const Footer = ({ handleModalClose, t }) => (
  <div className="py-4 px-6 w-full flex justify-end">
    <button
      className="bg-primary-600 text-primary-100 px-4 py-2 rounded-md"
      onClick={handleModalClose}
    >
      {t("baggageSelection.done")}
    </button>
  </div>
);

const BaggageSelectionModal = ({ baggageObject = {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const finalBaggagesSelection = useSelector(selectSelectedLCCBaggages);
  const selectedModal = useSelector(selectSelectedModal);
  const baggagesArray = baggageObject.baggageData?.toSorted((a, b) =>
    a.Price > b.Price ? 1 : -1
  );

  const handleModalClose = () => dispatch(setSelectedModal(null));
  const handleBaggageSelection = (baggageData, isAdd) => {
    const { FlightNumber, Code } = baggageData;
    const updatedSelection = [
      ...(finalBaggagesSelection[FlightNumber] || EMPTY_ARRAY),
    ];

    if (isAdd) {
      updatedSelection.push(baggageData);
    } else {
      const itemIndex = updatedSelection.findIndex(
        (baggage) => baggage.Code === Code
      );
      if (itemIndex !== LAST) updatedSelection.splice(itemIndex, ONE);
    }
    dispatch(
      setSelectedLCCBaggages({
        ...finalBaggagesSelection,
        [FlightNumber]: updatedSelection,
      })
    );
  };

  return (
    selectedModal === BAGGAGE_SELECTION_MODAL && (
      <Modal
        title={t("baggageSelection.title")}
        size={MD}
        handleClose={() => handleModalClose()}
        footer={<Footer handleModalClose={() => handleModalClose()} t={t} />}
      >
        <div className="font-semibold w-full text-center p-3">
          {baggageObject.title}
        </div>
        <div className="sm:max-h-[450px] md:max-h-[400px] overflow-y-scroll">
          {baggagesArray.map((baggage) => (
            <BaggageCard
              key={baggage.Code}
              data={baggage}
              handleBaggageSelection={handleBaggageSelection}
            />
          ))}
        </div>
      </Modal>
    )
  );
};

export default BaggageSelectionModal;
