import { DEFAULT_VALUES, FLIGHT_PROVIDERS } from "../constants";

const { ZERO, EMPTY_STRING } = DEFAULT_VALUES;
const { TBO } = FLIGHT_PROVIDERS;
const SEAT = "seat";

export const getTraveler = (passenger, source) => {
  const isTBO = source === TBO;
  const propertyMap = {
    title: ["Title", "title"],
    givenName: ["FirstName", "givenName"],
    familyname: ["LastName", "familyname"],
    dateOfBirth: ["DateOfBirth", "dateOfBirth"],
    phoneNumber: ["ContactNo", "phoneNumber"],
    gender: ["Gender", "gender"],
    email: ["Email", "email"],
    seat: ["SegmentAdditionalInfo[0].Seat", EMPTY_STRING],
  };

  return Object.fromEntries(
    Object.entries(propertyMap).map(([prop, [tboProp, amadeusProp]]) => [
      prop,
      prop === SEAT && isTBO
        ? passenger["SegmentAdditionalInfo"][ZERO]["Seat"]
        : isTBO
        ? passenger[tboProp]
        : passenger[amadeusProp],
    ])
  );
};
