import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { CACHE_KEYS, DEFAULT_LANGUAGE_CODE } from "../constants";
import { getFromLocalStorage } from "../helper";

const { USER } = CACHE_KEYS;
const storedLanguageCode = getFromLocalStorage("userLanguage")?.code;

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE_CODE,
  lng: storedLanguageCode || DEFAULT_LANGUAGE_CODE,
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    es: {
      translations: require("./locales/es/translations.json"),
    },
    hi: {
      translations: require("./locales/hi/translations.json"),
    },
    ta: {
      translations: require("./locales/ta/translations.json"),
    },
    si: {
      translations: require("./locales/si/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "es", "hi", "ta", "si"];

export default i18n;
