import { RenderSVG, SpinnerIcon } from "../../../assets/icons";

const Loader = ({ size = "w-8 h-6" }) => (
  <RenderSVG
    Svg={SpinnerIcon}
    className={`inline ${size} animate-spin`}
    alt="spinner-icon"
  />
);

export default Loader;
