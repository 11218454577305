export const QUALIFIERS = {
  BDA: "Refund before departure allowed",
  BDM: "Minimum Penalty amount with currency in which the ticket was originally sold.",
  BDX: "Maximum penality amount before departure",
  BDF: "Minimum Penalty amount with currency in which the penalty amount is filed or specified",
  BDG: "Maximum penalty amount with  currency in which the penalty amount is filed or specified",
  BDT: "Maximum penality amount for the ticket",
  BNA: "Reissue before departure when no show is allowed",
  BNM: "Minimum Penalty amount with currency in which the ticket was originally sold.",
  BNX: "Maximum penalty amount with currency in which the ticket was originally sold.",
  BNF: "Minimum Penalty amount with  currency in which the penalty amount is filed or specified",
  BNG: "Maximum penalty amount with  currency in which the penalty amount is filed or specified",
  BNT: "Maximum penality amount for the ticket before departure no show",
  ADA: "Refund after departure allowed",
  ADM: "Minimum Penalty amount with currency in which the ticket was originally sold.",
  ADX: "Maximum penalty amount with currency in which the ticket was originally sold.",
  ADF: "Minimum Penalty amount with  currency in which the penalty amount is filed or specified",
  ADG: "Maximum penalty amount with  currency in which the penalty amount is filed or specified",
  ADT: "Maximum penalty amount for the ticket",
  ANA: "Reissue after departure when no show allowed",
  ANM: "Minimum Penalty amount with currency in which the ticket was originally sold. after departure ",
  ANX: "Maximum penalty amount with currency in which the ticket was originally sold., After departure",
  ANF: "Minimum Penalty amount with  currency in which the penalty amount is filed or specified",
  ANG: "Maximum penalty amount with  currency in which the penalty amount is filed or specified",
  ANT: "Maximum penalty amount for the ticket",
  RVA: "Revalidation before departure is allowed",
  ADR: "Revalidation after departure is allowed",
  GUA: "Amount Guaranteed",
  WAI: "Penalties may be canceled in case of a passenger's or family member's death or illness",
  BNR: "Changes to a ticket before the flight departure, even in cases where a passenger is marked as a 'no-show'.",
  ANR: "Changes to the ticket after the flight has already left, especially if the passenger was marked as a 'no-show'",
};

export const DATE_TIME_QUALIFIERS = {
  BDV: "Reissue request, including failure to show at first flight, must be made prior to:",
  BNV: "Ticket validity date if passenger fails to show at first flight",
  ADV: "Reissue request, including failure to show at subsequent flight(s), must be made prior to:",
  ANV: "New travel dates must be prior to:",
};
