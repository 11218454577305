import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { createQueryString } from "../../helper";
import { REQUEST_METHODS } from "../../constants";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";

const { GET, POST, PATCH } = REQUEST_METHODS;
const { HOTEL_BOOK_CALLBACK, GENERATE_VOUCHER, HOTEL_BOOKING } = SPINNER_NAMES;

export const initiateHotelBooking = createAsyncThunk(
  "initiate-hotel-booking",
  async ({ requestBody }, thunkArgs) => {
    return await asyncAction({
      url: "hotel/book",
      methodType: POST,
      body: JSON.stringify(requestBody),
      spinner: HOTEL_BOOKING,
      showErrorToast: true,
      abortOnPageChange: false,
      errorMessage: "Failed to book hotel",
      ...thunkArgs,
    });
  }
);

export const hotelBookCallback = createAsyncThunk(
  "hotel-book-callback",
  async ({ queryParams }, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `/hotel/book-callback${queryString}`,
      methodType: GET,
      spinner: HOTEL_BOOK_CALLBACK,
      abortOnPageChange: false,
      errorMessage:
        "Unable to complete booking currently. Please try again later.",
      ...thunkArgs,
    });
  }
);

export const abortHotelBooking = createAsyncThunk(
  "abort-hotel-booking",
  async ({ requestBody, id }, thunkArgs) => {
    return await asyncAction({
      url: `hotels/bookings/${id}/abort`,
      methodType: PATCH,
      body: requestBody,
      abortOnPageChange: false,
      errorMessage:
        "Unable to abort the booking, please try again later.",
      ...thunkArgs,
    });
  }
);

export const generatePaymentVoucher = createAsyncThunk(
  "generate-payment-voucher",
  async ({ queryParams }, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `/hotel/generate-voucher${queryString}`,
      methodType: GET,
      spinner: GENERATE_VOUCHER,
      abortOnPageChange: false,
      errorMessage:
        "Unable to generate voucher currently. Please try again later.",
      ...thunkArgs,
    });
  }
);

export const fetchHotelPollingResponse = createAsyncThunk(
  "get-hotel-polling-response",
  async ({ requestBody, id }, thunkArgs) => {
    return await asyncAction({
      url: `/hotels/bookings/${id}/status`,
      methodType: GET,
      errorMessage: "Failed to fetch hotel polling response",
      ...thunkArgs,
    });
  }
);
