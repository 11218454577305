const createPolicyContent = (headerKey, bodyKeys) => {
  const body = bodyKeys.map((key) => ({
    title: `${headerKey}.body.${key}.title`,
    content: `${headerKey}.body.${key}.content`,
    subHeading: `${headerKey}.body.${key}.subHeading`,
  }));

  return {
    header: `${headerKey}.header`,
    body,
  };
};

export const COOKIE_POLICY_CONTENT = createPolicyContent("cookiePolicy", [
  "introduction",
  "purpose",
  "cookie",
]);

export const PAYMENT_SECURITY_CONTENT = createPolicyContent("paymentSecurity", [
  "introduction",
  "processing",
  "encryption",
  "pci",
  "storage",
  "fraud",
  "customer",
  "security",
  "paymentSecurity",
  "contact",
]);

export const PRIVACY_POLICY_CONTENT = createPolicyContent("privacyPolicy", [
  "users",
  "information",
  "useInformation",
  "keepInformation",
  "cookiesSession",
  "shareInformation",
  "contact",
  "cookies",
  "transmission",
  "dataSecurity",
  "dataRetenation",
  "ControllingPersonalInformation",
  "contactUs",
]);

export const CANCELLATION_AND_REFUND_POLICY_CONTENT = createPolicyContent(
  "refundPolicy",
  [
    "cancellationProcess",
    "cancellationTimeframe",
    "refundEligibility",
    "cancelByTraveler",
    "serviceProvider",
    "refundProcess",
    "initaiteRefund",
    "processingTime",
    "refundMethods",
    "paymentMethod",
    "chargebacks",
    "nonRefundable",
    "specialCase",
    "policyChanges",
  ]
);

export const TERMS_AND_CONDITION_CONTENT = createPolicyContent(
  "termsAndCondition",
  ["termsAndConditions"]
);

export const CONTACT_US_CONTENT = createPolicyContent("contactUs", ["contact"]);
export const SHIPPING_AND_DELIVERY_CONTENT = createPolicyContent(
  "shippingAndDelivery",
  ["shipping"]
);
