import { v4 as uuid } from "uuid";
import { CACHE_KEYS } from "../constants";

const { X_REQUEST_ID } = CACHE_KEYS;

export const getCorrelationId = () => {
  const existingCorrelationId = sessionStorage.getItem(X_REQUEST_ID);
  if (existingCorrelationId) return existingCorrelationId;
  else {
    const correlationId = uuid();
    sessionStorage.setItem(X_REQUEST_ID, correlationId);
    return correlationId;
  }
};
