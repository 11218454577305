import { priceFormatter } from "./priceFormatter";
import { CACHE_KEYS, DEFAULT_CURRENCY, REGEX } from "../constants";
import { getFromSessionStorage } from "./getDataFromBrowserStorage";

const { CURRENCY_REGEX } = REGEX;
const { CURRENCY_INFO } = CACHE_KEYS;

export const convertedPricesFromStrings = (text) => {
  const { symbol } = getFromSessionStorage(CURRENCY_INFO) || DEFAULT_CURRENCY;
  let convertedText = text;
  let match;

  while ((match = CURRENCY_REGEX.exec(text)) !== null) {
    const amount = parseFloat(match[1].replace(/,/g, ""));
    const convertedAmount = priceFormatter(amount);
    convertedText = convertedText.replace(
      match[0],
      `${symbol} ${convertedAmount}`
    );
  }

  return convertedText;
};
