import { toast } from "react-toastify";
import { RenderSVG, CopyToClipboard } from "../../../assets/icons";
import { DEFAULT_VALUES } from "../../../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;

const CopyToClipboardData = ({
  text = EMPTY_STRING,
  className = EMPTY_STRING,
}) => {
  const handleCopyToClipboard = () =>
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to Clipboard", { autoClose: 2000 });
    });

  return (
    <button
      className={className}
      onClick={handleCopyToClipboard}
      title="Copy to Clipboard"
    >
      <RenderSVG Svg={CopyToClipboard} className="h-4" />
    </button>
  );
};

export default CopyToClipboardData;
