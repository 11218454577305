import {
  CANCELLATION_CHARGE_TYPES,
  CURRENCY_SYMBOLS,
  DEFAULT_CURRENCY_CODE,
} from "../constants";

const { NIGHTS, AMOUNT, PERCENTAGE } = CANCELLATION_CHARGE_TYPES;

export const getCancellationCharges = (
  amount,
  chargeType,
  currencyCode = DEFAULT_CURRENCY_CODE
) => {
  switch (chargeType) {
    case PERCENTAGE:
      return `${amount}%`;
    case AMOUNT:
      return `${CURRENCY_SYMBOLS[currencyCode]}${amount}`;
    case NIGHTS:
      return `${amount} Nights`;
    default:
      return "";
  }
};
