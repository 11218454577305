import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { EditIcon, RenderSVG } from "../../../../assets/icons";
import { selectSpecialServices } from "../index";
import SelectedBaggageDetails from "./SelectedBaggageDetails";
import { setSelectedModal } from "../../../../components/organisms/Modal";
import BaggageSelectionModal from "./BaggageSelectionModal";
import { getSegmentCityName } from "../../../../helper";
import { MODALS } from "../../../../components/organisms/AppModals";
import { DEFAULT_VALUES } from "../../../../constants";

const { ZERO, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;
const { BAGGAGE_SELECTION_MODAL } = MODALS;

const TRANSITION_TIMEOUT = 1000;

const getTabs = (specialServices) => {
  let allTabs = [];
  specialServices.forEach(({ data: { baggage = [] } }, index) => {
    const segmentTabs = baggage.map((segment) => {
      const formattedTabs = segment && {
        id: segment[ZERO]?.FlightNumber,
        default: index === ZERO,
        title: getSegmentCityName(segment),
        baggage: segment,
      };
      return formattedTabs;
    });
    allTabs = [...allTabs, ...segmentTabs];
  });
  return allTabs;
};

const BaggageSelection = ({ selectionProgress, setSelectionProgress }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getCollapseProps, getToggleProps, setExpanded, isExpanded } =
    useCollapse({
      duration: 400,
      defaultExpanded: false,
    });

  const specialServices = useSelector(selectSpecialServices);

  const [shouldEaseOut, setShouldEaseOut] = useState(false);
  const [baggageData, setBaggageData] = useState({
    id: EMPTY_STRING,
    title: EMPTY_STRING,
    baggageData: EMPTY_ARRAY,
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [baggageTabs, setBaggageTabs] = useState(EMPTY_ARRAY);
  const { travelerSelection, seatSelection, mealSelection, baggageSelection } =
    selectionProgress;

  useEffect(() => {
    if (!specialServices) return;
    const tabs = getTabs(specialServices);
    setBaggageTabs(tabs);
  }, [specialServices]);

  useEffect(() => {
    if (shouldEaseOut)
      setTimeout(() => setShouldEaseOut(false), TRANSITION_TIMEOUT);
  }, [shouldEaseOut]);

  useEffect(() => {
    setExpanded(
      travelerSelection && seatSelection && mealSelection && !baggageSelection
    );
    setIsCompleted(
      travelerSelection && seatSelection && mealSelection && baggageSelection
    );
  }, [selectionProgress]);

  return (
    <div className="mt-4 gap-6 mb-[40px]">
      <div
        className={classNames(
          "text-contrast-900 text-lg font-bold p-6 border bg-white border-contrast-200 flex justify-between",
          {
            "rounded-lg": !isExpanded,
            "rounded-t-lg": isExpanded,
            "opacity-60": !isCompleted && !isExpanded,
            "opacity-100": isCompleted || isExpanded,
          }
        )}
      >
        {t("baggageSelection.title")}
        <button
          {...getToggleProps()}
          onClick={() =>
            setSelectionProgress({
              ...selectionProgress,
              baggageSelection: false,
            })
          }
        >
          {isCompleted && (
            <RenderSVG
              Svg={EditIcon}
              className="w-6 h-6"
              alt="Toggle dropdown"
            />
          )}
        </button>
      </div>
      {!isEmpty(baggageTabs) && (
        <div {...getCollapseProps()}>
          <div className="bg-white p-3 rounded-b-lg border border-contrast-200 flex gap-3 justify-between w-full flex-col md:flex-row lg:flex-col xl:flex-row">
            <div className="flex flex-col grow rounded">
              {baggageTabs.map(({ id, title, baggage }) => {
                return (
                  <div
                    key={id}
                    className="flex items-center justify-between rounded-lg my-1 border border-primary-100 p-4 "
                  >
                    <div>{title}</div>
                    <button
                      className="border border-primary-300 rounded-md px-2 py-1"
                      onClick={() => {
                        dispatch(setSelectedModal(BAGGAGE_SELECTION_MODAL));
                        setBaggageData({ id, baggageData: baggage, title });
                      }}
                    >
                      {t("travelerInfo.add")}
                    </button>
                  </div>
                );
              })}
            </div>
            <SelectedBaggageDetails tabs={baggageTabs} />
          </div>
        </div>
      )}
      <BaggageSelectionModal baggageObject={baggageData} />
    </div>
  );
};

export default BaggageSelection;
