import { get } from "lodash";
import { getFormattedDateObject, getFutureFormattedDateObject } from "./getDateTime";
import { getDayDiffrence } from "./getDayDiffrence";
import { DEFAULT_VALUES } from "../constants";

const { EMPTY_ARRAY, ZERO } = DEFAULT_VALUES

export const getHotelStayDates = (selectedHotelInfo, hotelDetails = {}) => {
    const { hotelRoomsDetails = [] } = hotelDetails;
    const dayRates = !!Object.keys(selectedHotelInfo).length
        ? get(
            selectedHotelInfo,
            "priceResult.hotelRoomsResult.hotelRoomsDetails[0].dayRates",
            EMPTY_ARRAY
        )
        : get(hotelRoomsDetails, "0.dayRates", EMPTY_ARRAY);
    const checkInDateObject = dayRates?.[ZERO];
    const checkOutDateObject = dayRates?.slice(-1)[ZERO];
    let checkIn;
    let checkOut;
    let totalDays;
    if ((checkInDateObject, checkOutDateObject)) {
        checkIn = getFormattedDateObject(checkInDateObject.date);
        checkOut = getFutureFormattedDateObject(checkOutDateObject.date);
        totalDays = getDayDiffrence(
            checkInDateObject.date,
            checkOutDateObject.date
        );
    }

    return { checkIn, checkOut, totalDays };
}
