import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { REQUEST_METHODS } from "../../constants";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";

const { POST, GET, PATCH, DELETE } = REQUEST_METHODS;
const { FETCH_USER, ADD_TRAVELER, DELETE_TRAVELER, UPDATE_TRAVELER } =
  SPINNER_NAMES;
const DEFAULT_RATE_EXCHANGE_URL =
  "https://z2hn59wxsj.execute-api.ap-south-1.amazonaws.com/prod";
const { REACT_APP_RATE_EXCHANGE_URL } = process.env;
const rateExchangeURL =
  REACT_APP_RATE_EXCHANGE_URL || DEFAULT_RATE_EXCHANGE_URL;

export const addTraveler = createAsyncThunk(
  "add-traveler",
  async ({ body, id }, thunkArgs) => {
    return await asyncAction({
      url: `users/${id}`,
      methodType: POST,
      body,
      spinner: ADD_TRAVELER,
      showErrorToast: true,
      errorMessage: "Failed to add traveler",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const getTravelers = createAsyncThunk(
  "get-traveler",
  async (id, thunkArgs) => {
    return await asyncAction({
      url: `users/${id}`,
      methodType: GET,
      spinner: FETCH_USER,
      showErrorToast: true,
      errorMessage: "Failed to fetch traveler",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const deleteTraveler = createAsyncThunk(
  "remove-traveler",
  async (id, thunkArgs) => {
    return await asyncAction({
      url: `travelers/${id}`,
      methodType: DELETE,
      spinner: DELETE_TRAVELER,
      showErrorToast: true,
      errorMessage: "Failed to remove traveler",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const updateTraveler = createAsyncThunk(
  "update-traveler",
  async ({ body, id }, thunkArgs) => {
    return await asyncAction({
      url: `travelers/${id}`,
      methodType: PATCH,
      spinner: UPDATE_TRAVELER,
      body,
      showErrorToast: true,
      errorMessage: "Failed to update traveler",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);

export const getRateExchanges = createAsyncThunk(
  "get-exchangeRates",
  async () => {
    return await asyncAction({
      url: `${rateExchangeURL}`,
      methodType: GET,
      errorMessage: "Failed to fetch exchangeRates",
      shouldAllowCustomHeaders: false,
    });
  }
);

export const updateUserProfile = createAsyncThunk(
  "update-user-profile",
  async ({ body, id }, thunkArgs) => {
    return await asyncAction({
      url: `users/${id}`,
      methodType: PATCH,
      spinner: UPDATE_TRAVELER,
      body,
      showErrorToast: true,
      errorMessage: "Failed to update profile user",
      isAuthRequired: true,
      ...thunkArgs,
    });
  }
);
