import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { cloneDeep, set } from "lodash";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import { createPopper } from "@popperjs/core";
import { RenderSVG, Users } from "../../../assets/icons";
import { DEFAULT_VALUES, WINDOWS_EVENTS, ROUTES } from "../../../constants";
import ErrorMessage from "../../atoms/ErrorMessage";

const { HOME, FLIGHT_RESULTS } = ROUTES;
const { ZERO, ONE, EMPTY_ARRAY, TEN, EMPTY_STRING } = DEFAULT_VALUES;
const { CLICK } = WINDOWS_EVENTS;
const MAX_AGE_OF_CHILDREN = 17;
const MAX_ROOM_CAN_BOOK = 6;
const MAX_ADULTS_PER_ROOM = 8;
const MAX_CHILDREN_PER_ROOM = 4;
const MAX_GUESTS_PER_ROOM = 10;
const NO_OF_CHILD = "noOfChild";
const NO_OF_ADULTS = "noOfAdults";
const SHOW_ERROR_DURATION = 3000;

const childrenAgeRange = Array.from({ length: MAX_AGE_OF_CHILDREN + ONE }).map(
  (_, index) => ({
    label: index.toString(),
    value: index,
  })
);

const roomsOptions = Array.from({ length: MAX_ROOM_CAN_BOOK }).map(
  (_, index) => ({
    id: index,
    label: `${index + ONE} Room${index !== ZERO ? "s" : ""}`,
    value: index + ONE,
  })
);

const adultCountOptions = Array.from({ length: MAX_ADULTS_PER_ROOM }).map(
  (_, index) => ({
    id: index,
    label: `${index + ONE} Adult${index !== ZERO ? "s" : ""}`,
    value: index + ONE,
  })
);

const HotelTravelersCount = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const guestsCountRef = useRef();
  const selectRef = useRef();
  const dropdownRef = useRef();
  const roomRef =useRef();
  const popperInstance = useRef(null);
  const [show, setShow] = useState(false);
  const [totalGuests, setTotalGuests] = useState(ONE);
  const [errorMessage, setErrorMessage] = useState(EMPTY_STRING);
  const { values, setFieldValue } = useFormikContext();

  const { noOfRooms = ONE, roomGuests = EMPTY_ARRAY } = values;

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        guestsCountRef.current &&
        !guestsCountRef.current.contains(e.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener(CLICK, checkIfClickedOutside);

    return () => {
      document.removeEventListener(CLICK, checkIfClickedOutside);
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [guestsCountRef.current]);

  useEffect(() => {
    const totalCount = roomGuests.reduce(
      (total, guestObject) =>
        total +
        parseInt(guestObject["noOfAdults"]) +
        parseInt(guestObject["noOfChild"]),
      ZERO
    );
    setTotalGuests(totalCount);
  }, [roomGuests]);

  useEffect(() => {
    if (show) {
      popperInstance.current = createPopper(
        selectRef.current,
        dropdownRef.current,
        {
          placement: "bottom",
        }
      );
    }

    return () => {
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [show]);

  const handleRoomCountChange = (e) => {
    const { value } = e.target;
    if (value > noOfRooms) {
      const updatedGuestsCount = Array.from({
        length: value - noOfRooms,
      }).map(() => ({
        noOfAdults: ONE,
        noOfChild: ZERO,
        childAge: [],
      }));
      setFieldValue("roomGuests", [...roomGuests, ...updatedGuestsCount]);
    } else if (value < noOfRooms) {
      const updatedGuestsCounts = roomGuests.slice(0, value);
      setFieldValue("roomGuests", updatedGuestsCounts);
    }
    setFieldValue("noOfRooms", value);
  };

  const showError = (error) => {
    setErrorMessage(error);
    setTimeout(() => setErrorMessage(EMPTY_STRING), SHOW_ERROR_DURATION);
  };

  const handleGuestCountChange = (value, roomIndex, type) => {
    const valueInt = parseInt(value);
    let updatedGuestsCounts = [...roomGuests];
    let updatedChildAge = [...updatedGuestsCounts[roomIndex].childAge];
    const totalNoOfAdults = parseInt(updatedGuestsCounts[roomIndex].noOfAdults);
    const totalNoOfChildren = parseInt(updatedGuestsCounts[roomIndex].noOfChild);
    
    const isGuestLimitExceeded = (type === NO_OF_ADULTS 
      ? totalNoOfChildren + valueInt > TEN 
      : totalNoOfAdults + valueInt > TEN);

  if (!isGuestLimitExceeded) {
    if (type === NO_OF_CHILD) {
      if (valueInt > updatedChildAge.length) {
        const extraAgesArr = Array(valueInt - updatedChildAge.length).fill(ONE);
        updatedChildAge = updatedChildAge.concat(extraAgesArr);
      } else if (valueInt < updatedChildAge.length) {
        updatedChildAge = updatedChildAge.slice(0, valueInt);
      }
      updatedGuestsCounts[roomIndex] = {
        ...updatedGuestsCounts[roomIndex],
        [type]: valueInt,
        childAge: updatedChildAge,
      };
    } else {
      updatedGuestsCounts[roomIndex] = {
        ...updatedGuestsCounts[roomIndex],
        [type]: valueInt,
      };
    }
  } else {
    const error = t("searchSection.roomLimitExceeded", {
      roomIndex: roomIndex + 1,
    }); 
    roomRef.current?.scrollIntoView({ behavior: "smooth" });
    showError(error);
  }

    setFieldValue("roomGuests", updatedGuestsCounts);
  };

  const handleChildAgeChange = (value, roomIndex, childIndex) => {
    let updatedGuestsCount = cloneDeep(roomGuests);
    set(updatedGuestsCount, `${roomIndex}.childAge[${childIndex}]`, value);
    setFieldValue("roomGuests", updatedGuestsCount);
  };

  const renderCountPlaceholder = () => {
    return totalGuests > ONE
      ? t("searchSection.guests")
      : t("searchSection.guest");
  };

  const isBottom = get(
    popperInstance,
    'current.state.placement.includes("bottom")',
    ""
  );

  return (
    <div ref={guestsCountRef}>
      <button
        ref={selectRef}
        type="button"
        onClick={() => setShow(!show)}
        className={classNames(
          "rounded-md w-full bg-white border shadow-sm border-contrast-300 flex items-center gap-2 p-3 py-3.5  hover:ring-2 hover:ring-primary-50",
          {
            "py-3": location.pathname === HOME,
            "py-3.5": location.pathname === FLIGHT_RESULTS,
          }
        )}
      >
        <RenderSVG Svg={Users} className="text-contrast-400" alt="User Icon" />
        <span className="text-gray-900 whitespace-nowrap text-ellipsis overflow-hidden text-xs sm:text-[17px]">
          {noOfRooms} Room | {`${totalGuests} ${renderCountPlaceholder()}`}
        </span>
      </button>
      {show && (
        <div
          ref={dropdownRef}
          className={classNames(
            "dropdown-menu absolute z-30 bg-white px-4 sm:px-6 py-4 rounded-lg border border-contrast-200 shadow-2xl my-2 w-80% sm:w-full md:min-w-[450px] md:right-0 !mt-3 max-h-60 sm:max-h-72  overflow-y-auto",
            {
              "top-full": !isBottom,
              "bottom-full": isBottom,
            }
          )}
        >
          <div ref={roomRef} >
          <ErrorMessage errorMessage={errorMessage} className={`text-start ${errorMessage ? 'py-2' : ''}`} />
          <div className="flex justify-between">
            <div className="font-semibold text-xs sm:text-xl">No. of Room(s)</div>
            <div className=" border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500">
              <select
                className="rounded-md border-0 block shadow-none text-[10px] w-20 px-4 py-0.5 sm:py-2 sm:w-32 sm:px-8 sm:text-sm"
                onChange={handleRoomCountChange}
                value={noOfRooms}
              >
                {roomsOptions.map((item) => (
                  <option key={item.id} value={item.value} className="text-[10px] sm:text-sm">
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {Array.from({ length: noOfRooms }).map((_, index) => {
            const childrenCountOptions = Array.from({
              length:
                Math.min(
                  MAX_CHILDREN_PER_ROOM,
                  MAX_GUESTS_PER_ROOM - roomGuests[index]?.noOfAdults
                ) + ONE,
            }).map((_, index) => ({
              id: index,
              label: `${index} ${index <= ONE ? "Child" : "Children"}`,
              value: index,
            }));

            return (
              <div key={uuid()}>
                <ul className="divide-y divide-contrast-200">
                  <li className="flex flex-col justify-between sm:py-2 sm:flex-row sm:items-center">
                    <div className="font-semibold text-xs sm:text-xl">Room {index + ONE} </div>
                    <div className="flex justify-between gap-2">
                      <div className="border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500">
                        <select
                          className="rounded-md border-0 block shadow-none text-[10px] w-20 px-4 sm:py-2 sm:w-32 sm:px-8 sm:text-sm"
                          onChange={(e) =>
                            handleGuestCountChange(
                              e.target.value,
                              index,
                              "noOfAdults"
                            )
                          }
                          value={roomGuests[index]?.noOfAdults}
                        >
                          {adultCountOptions.map(({ id, label, value }) => (
                            <option key={id} value={value} className="text-[10px] sm:text-sm">
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500">
                        <select
                          className="rounded-md border-0 block shadow-none text-[10px] w-20 px-4 sm:py-2 sm:w-32 sm:px-8 sm:text-sm"
                          onChange={(e) =>
                            handleGuestCountChange(
                              e.target.value,
                              index,
                              "noOfChild"
                            )
                          }
                          value={roomGuests[index]?.noOfChild}
                        >
                          {childrenCountOptions.map(({ id, label, value }) => (
                            <option key={id} value={value} className="text-[10px] sm:text-sm">
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                </ul>
                {roomGuests[index]?.childAge.length !== ZERO && (
                  <div className="max-h-60 overflow-y-auto no-scrollbar">
                    <div>
                      {roomGuests[index]?.childAge.map((age, id) => (
                        <div
                          key={uuid()}
                          className="flex gap-4 sm:gap-2 space-y-1 justify-between text-contrast-900 sm:items-center"
                        >
                          <label
                            className="text-[10px] sm:text-sm ml-auto"
                            htmlFor={`childAge-${id}`}
                          >
                            Child Age {id + ONE} (Years)
                          </label>
                          <div className="border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500">
                            <select
                              id={`childAge-${id}`}
                              className="rounded-md border-0 block shadow-none  text-[10px] w-20 px-4 sm:py-2 sm:w-32 sm:px-8 sm:text-sm"
                              onChange={(e) =>
                                handleChildAgeChange(e.target.value, index, id)
                              }
                              value={age}
                            >
                              {childrenAgeRange.map(({ label, value }) => (
                                <option
                                  key={label}
                                  value={value}
                                  className="text-[10px] sm:text-sm"
                                >
                                  {label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        </div>
      )}
    </div>
  );
};

export default HotelTravelersCount;
