import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sessionFlag: null,
};

const slice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSessionFlag(state, action) {
      state.sessionFlag = action.payload;
    },
  },
});

export default slice;

export const { name, reducer, actions } = slice;
