import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { DEFAULT_VALUES, SKELETON_COLOR } from "../../../constants";

const { ONE, THREE } = DEFAULT_VALUES;
const { BASE_COLOR, HIGHTLIGHT__COLOR} = SKELETON_COLOR;

const FareBreakDownSkeleton = () => (
  <SkeletonTheme baseColor={BASE_COLOR} highlightColor={HIGHTLIGHT__COLOR}>
    <div className="w-full">
      <Skeleton count={ONE} height={40} />
    </div>
  </SkeletonTheme>
);

export default FareBreakDownSkeleton;
