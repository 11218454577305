const getTboCancellationReq = (bookingInfo) => {
    const { pnr,endUserIp } = bookingInfo;

    return {
        endUserIp,
        pnr,
        remarks : "",
    };

};

export default getTboCancellationReq;
