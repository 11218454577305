import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { get, isEmpty, uniqueId } from "lodash";
import parse from "html-react-parser";
import { useCollapse } from "react-collapsed";
import Tabs from "../../Tabs/Tabs";
import {
  Cross,
  RenderSVG,
  ChevronDown,
  ChevronUp,
} from "../../../../assets/icons";
import { priceFormatter } from "../../../../helper";
import {
  selectAmadeusCancellationCharges,
  selectTBOCancellationCharges,
} from "../../../../screens/MyTrips";
import Spinner, { SPINNER_NAMES, selectActiveSpinners } from "../../Spinner";
import { CancellationModalSkeleton } from "../../AppSkeletons";
import { selectSelectedBooking } from "../../../../screens/MyTrips/booking.selector";
import { QUALIFIERS } from "../../../../ResponseMapper/FlightPriceMapping/qualifiers";
import { setSelectedModal } from "../../Modal";
import getFormattedAmount from "../../../../helper/getFormattedAmount";
import {
  DEFAULT_VALUES,
  BOOKING_CANCELLATION_STATES,
  CATEGORY_TITLES,
  CURRENCY_SYMBOLS,
  FLIGHT_SERVICE_TYPE,
} from "../../../../constants";
import { selectCountryInfo } from "../../../../screens/Profile";

const { ZERO, EMPTY_ARRAY, EMPTY_STRING } = DEFAULT_VALUES;
const { FLIGHT_CANCELLATION } = SPINNER_NAMES;
const { CONFIRM } = BOOKING_CANCELLATION_STATES;
const AMOUNT = "Amount";
const APPLICABLE = "Applicable";
const { TBO } = FLIGHT_SERVICE_TYPE;
const { INR } = CURRENCY_SYMBOLS;

const RenderFareRulesHTML = ({ htmlString }) => (
  <div className="flex-1 px-10 pt-4 pb-10 gap-2 justify-center">
    {parse(htmlString)}
  </div>
);

const RenderListOfMonetoryDetails = ({
  detailsList = EMPTY_ARRAY,
  category,
  restrictionsInfo = EMPTY_ARRAY,
}) => {
  const filteredData = detailsList.filter((item) => {
    return (
      parseInt(item.adult) !== ZERO &&
      (parseInt(item.child) !== ZERO || parseInt(item.infant) !== ZERO)
    );
  });
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  const renderTable = (columns, titleKey, tableData = []) => (
    <div className="">
      <div className="font-bold text-secondary-500 mb-2">
        {CATEGORY_TITLES[category]?.[titleKey]}
      </div>
      <div className={classNames(" border rounded")}>
        <table className="table-fixed w-full border-collapse border rounded">
          <thead className="sticky top-0 z-10 bg-secondary-200">
            <tr className="text-secondary-600">
              {columns.map(({ label, className }) => (
                <th key={label} className={className}>
                  {label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => (
              <tr key={uniqueId()} className="border-b">
                {columns.map(({ cellClassName, label, renderCell }) => (
                  <td key={uniqueId()} className={cellClassName}>
                    {(label === AMOUNT || label === APPLICABLE) && (
                      <div className="border rounded-lg border-gray-500 p-1 text-xs">
                        {row.adult && (
                          <div className="grid grid-cols-2 gap-2 text-center">
                            <div>Adult:</div>
                            <div>
                              {!row.currency
                                ? `${row.adult}`
                                : `${currencySymbol}${priceFormatter(
                                    parseFloat(row.adult)
                                  )}`}
                            </div>
                          </div>
                        )}
                        {row.child && (
                          <div className="grid grid-cols-2 gap-2 text-center">
                            <div>Child:</div>
                            <div>
                              {!row.currency
                                ? `${row.child}`
                                : `${currencySymbol}${priceFormatter(
                                    parseFloat(row.child)
                                  )}`}
                            </div>
                          </div>
                        )}
                        {row.infant && (
                          <div className="grid grid-cols-2 gap-2 text-center">
                            <div>Infant:</div>
                            <div>
                              {!row.currency
                                ? `${row.infant}`
                                : `${currencySymbol}${priceFormatter(
                                    parseFloat(row.infant)
                                  )}`}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {renderCell(row)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <>
      {renderTable(
        [
          {
            label: "Criteria",
            className: "w-1/2 text-left px-6 py-2",
            cellClassName: "text-xs px-6 py-2 font-bold",
            renderCell: (row) => QUALIFIERS[row.indicator] || row.indicator,
          },
          {
            label: "Amount",
            className: "px-6 py-2 justify-center flex",
            cellClassName: "text-xs px-6 py-2 justify-center flex",
            renderCell: () => {},
          },
        ],
        "penalityInfo",
        filteredData
      )}
      {renderTable(
        [
          {
            label: "Criteria",
            className: "w-1/2 text-left px-6 py-2",
            cellClassName: "text-xs px-6 py-2 font-bold",
            renderCell: (row) => QUALIFIERS[row.indicator] || row.indicator,
          },
          {
            label: "Applicable",
            className: "px-6 py-2 justify-center flex",
            cellClassName: "text-xs px-6 py-2 justify-center flex",
            renderCell: () => {},
          },
        ],
        "restrictionInfo",
        restrictionsInfo
      )}
    </>
  );
};

const filteredRestrictions = (restrictions) =>
  restrictions.filter(
    (each) => !["BNW", "FFT", "ADW", "ANW"].includes(each.indicator)
  );

const RenderFareRulesAmadeus = ({ refund }) => {
  const renderSection = (item) => {
    const { monetaryDetails, category, restrictionsInfo } = item;
    const restrictions = filteredRestrictions(restrictionsInfo);

    return (
      <div key={category} className="p-4">
        <div className="text-lg font-bold border-b border-secondary-200 text-gray-900 mb-5">
          {CATEGORY_TITLES[category]?.header}
        </div>

        <div className="flex flex-col gap-5">
          <RenderListOfMonetoryDetails
            detailsList={monetaryDetails}
            category={category}
            dateInfo={
              Array.isArray(item.dateInfo) ? item.dateInfo : [item.dateInfo]
            }
            restrictionsInfo={restrictions}
          />
        </div>
      </div>
    );
  };

  return <div className="flex-1 ">{renderSection(refund)}</div>;
};

const FareRulesBreakDown = () => {
  let defaultIndex = ZERO;
  const booking = useSelector(selectSelectedBooking);

  const source = get(booking, "provider", EMPTY_STRING);
  const isTBO = source === TBO;
  const fareRules = get(
    booking,
    "bookingJSON.journeyDetails[0].fareRules",
    EMPTY_ARRAY
  );

  const fareRulesTBO = get(
    booking,
    "bookingJSON.journeyDetails[0].fareRules",
    EMPTY_ARRAY
  );

  const tboFareRulesTab = fareRulesTBO.flatMap(({ FareRules }) => {
    let htmlString = EMPTY_STRING;
    return FareRules?.map(({ Origin, Destination, FareRuleDetail }) => {
      htmlString = FareRuleDetail.includes("Please refer above")
        ? get(FareRules, "0.FareRuleDetail", EMPTY_STRING)
        : FareRuleDetail;
      const isDefault = defaultIndex === ZERO;
      if (isDefault) defaultIndex++;

      return {
        id: `${Origin}-${Destination}`,
        title: `${Origin} - ${Destination}`,
        element: <RenderFareRulesHTML htmlString={htmlString} />,
        default: isDefault,
      };
    });
  });

  const amadeusFareRulesTab = fareRules.map(
    ({ origin, destination, refund }, index) => ({
      id: `${origin}-${destination}`,
      title: `${origin} - ${destination}`,
      default: index === ZERO,
      element: <RenderFareRulesAmadeus refund={refund} />,
    })
  );

  const fareRulesData = Array.isArray(source)
    ? [...tboFareRulesTab, ...amadeusFareRulesTab]
    : amadeusFareRulesTab;
  const fareRulesLength = Array.isArray(source)
    ? tboFareRulesTab.length + amadeusFareRulesTab.length
    : amadeusFareRulesTab.length;

  return (
    <Tabs
      tabs={isTBO ? tboFareRulesTab : fareRulesData}
      showCarousel={isTBO ? tboFareRulesTab : fareRulesLength}
      className="min-w-[35%]"
    />
  );
};

const CancelModal = ({ setCurrentModal }) => {
  const { t } = useTranslation();
  const booking = useSelector(selectSelectedBooking);
  const activeSpinners = useSelector(selectActiveSpinners);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const dispatch = useDispatch();
  const [refundAmount, setRefundAmount] = useState(EMPTY_STRING);
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);
  const cancellationSpinner = activeSpinners.some(
    (spinnerName) => spinnerName === FLIGHT_CANCELLATION
  );

  const handleClose = () => {
    setRefundAmount(EMPTY_STRING);
    dispatch(setSelectedModal(null));
  };

  const basePrice = get(
    booking,
    "bookingJSON.journeyDetails[0].price.basePrice",
    EMPTY_STRING
  );
  const grandTotal = get(
    booking,
    "bookingJSON.journeyDetails[0].price.grandTotal",
    EMPTY_STRING
  );
  const totalTax = get(
    booking,
    "bookingJSON.journeyDetails[0].travelerDetails",
    EMPTY_ARRAY
  ).reduce((total, each) => total + get(each, "Fare.Tax", ZERO), ZERO);

  const totalMeal = get(
    booking,
    "bookingJSON.journeyDetails[0].travelerDetails",
    EMPTY_ARRAY
  )
    .map((each) => get(each, "MealDynamic", EMPTY_ARRAY))
    .map((each) => get(each, "[0]", EMPTY_ARRAY))
    .reduce((total, meal) => total + get(meal, "Price", ZERO), ZERO);

  const totalBaggage = get(
    booking,
    "bookingJSON.journeyDetails[0].travelerDetails",
    EMPTY_ARRAY
  )
    .map((each) => get(each, "Baggage", EMPTY_ARRAY))
    .map((each) => get(each, "[0]", EMPTY_ARRAY))
    .reduce((total, each) => total + get(each, "Price", ZERO), ZERO);

  const otherServices = Number(totalMeal) + Number(totalBaggage);

  const calculateTotalRefundAM = (refundContractBundle) => {
    let refundAmount1 = ZERO;
    if (!Array.isArray(refundContractBundle.FunctionalData.ContractBundle)) {
      refundAmount1 = getFormattedAmount(
        refundContractBundle.FunctionalData.ContractBundle.RefundDetails[
          "att:Contracts"
        ]["att:Contract"]["att:Refundable"]["_attributes"]
      );
    } else {
      const contractBundle = refundContractBundle.FunctionalData.ContractBundle;
      for (const ticket of contractBundle) {
        let calculatedAmount = getFormattedAmount(
          ticket.RefundDetails["att:Contracts"]["att:Contract"][
            "att:Refundable"
          ]["_attributes"]
        );
        refundAmount1 += calculatedAmount;
      }
    }
    return refundAmount1.toString() || "";
  };

  const handleAction = () => {
    setCurrentModal(CONFIRM);
  };
  const selectedCancellationChargesAM = useSelector(
    selectAmadeusCancellationCharges
  );
  const selectedCancellationChargesTBO = useSelector(
    selectTBOCancellationCharges
  );
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 500,
    defaultExpanded: false,
  });
  useEffect(() => {
    if (!isEmpty(selectedCancellationChargesAM) && booking.provider === "AM") {
      setRefundAmount(
        calculateTotalRefundAM(
          selectedCancellationChargesAM?.pickedValue?.AMA_TicketInitRefundRS
        )
      );
    }
    if (!isEmpty(selectedCancellationChargesTBO) && booking.provider === TBO) {
      const cancellationChargesTBO = get(selectedCancellationChargesTBO, "output.charges.response.RefundAmount", EMPTY_STRING);
      setRefundAmount(cancellationChargesTBO);
    }
  }, [selectedCancellationChargesAM, selectedCancellationChargesTBO]);

  return (
    <div className="bg-white">
      <div className="w-full px-4 py-2 border-b border-contrast-200 justify-start items-center gap-4 inline-flex">
        <div className="grow shrink basis-0 text-contrast-900 text-xl font-bold font-['Inter'] leading-loose">
          {t("userBookings.cancel.refundModal.header")}
        </div>
        <button
          type="button"
          className="p-[9px] rounded-[19px] justify-center items-center flex  disabled:cursor-not-allowed"
          onClick={handleClose}
          disabled={cancellationSpinner}
        >
          <RenderSVG Svg={Cross} alt="cross" className="relative" />
        </button>
      </div>

      <div className="max-h-[300px] overflow-y-scroll text-contrast-500 text-sm font-normal leading-tight">
        <FareRulesBreakDown />
      </div>
      <div className="p-6 border-t border-gray-300 bg-gray-50/20">
        <div className="divide-y divide-gray-300">
          <div className="flex gap-4 flex-row py-3">
            <div className="text-contrast-900 text-sm font-medium w-64">
              {t("userBookings.cancel.refundModal.amount")}
            </div>
            <div className="text-contrast-900 text-sm font-medium  ms-auto text-end">
              <span className="text-base font-bold">
                {currencySymbol}
                &nbsp;
                {priceFormatter(parseFloat(grandTotal))}
              </span>
              <div className="flex items-center gap-2">
                <span className="text-xs text-indigo-700">
                  {" "}
                  {t("userBookings.cancel.refundModal.pricingBreakdown")}
                </span>
                <button
                  className="py-1 flex items-start justify-center bg-white hover:bg-contrast-50 active:bg-white "
                  {...getToggleProps()}
                >
                  <RenderSVG
                    Svg={isExpanded ? ChevronUp : ChevronDown}
                    alt="Toggle dropdown"
                  />
                </button>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col divide-y divide-gray-300"
            {...getCollapseProps()}
          >
            <div className="flex justify-between py-3">
              <div className="text-contrast-900 text-sm font-medium w-64">
                {t("userBookings.cancel.refundModal.taxes")}
              </div>
              <div className="text-contrast-900 text-sm font-medium">
                {currencySymbol}
                &nbsp;
                {priceFormatter(parseFloat(totalTax))}
              </div>
            </div>
            {otherServices != ZERO && (
              <div className="flex justify-between  py-3">
                <div className="text-contrast-900 text-sm font-medium w-64">
                  {t("userBookings.cancel.refundModal.otherServices")}
                </div>
                <div className="text-contrast-900 text-sm font-medium">
                  {currencySymbol}
                  &nbsp;
                  {priceFormatter(parseFloat(otherServices))}
                </div>
              </div>
            )}
            <div className="flex justify-between  py-3">
              <div className="text-contrast-900 text-sm font-medium w-64">
                {t("userBookings.cancel.refundModal.grandTotal")}
              </div>
              <div className="text-contrast-900 text-sm font-medium ">
                {currencySymbol}
                &nbsp;
                {priceFormatter(parseFloat(grandTotal))}
              </div>
            </div>

            <div className="flex justify-between  py-3">
              <div className="text-contrast-900 text-sm font-medium">
                {t("userBookings.cancel.refundModal.baseFare")}
              </div>
              <div className="text-contrast-900 text-sm font-medium">
                {currencySymbol}
                &nbsp;
                {priceFormatter(parseFloat(basePrice))}
              </div>
            </div>
          </div>
        </div>

        <div className="">
          {refundAmount || cancellationSpinner ? (
            <div className="font-bold text-sm text-contrast-900  flex gap-4 items-start justify-between py-3 bg-indigo-50 px-4 rounded-md">
              <p className="w-64">
                {" "}
                {t("userBookings.cancel.refundModal.refundAmount")}
              </p>

              <div>
                <Spinner
                  size="w-full"
                  name={FLIGHT_CANCELLATION}
                  showSkeleton={true}
                  loaderComponent={<CancellationModalSkeleton />}
                  persistSize
                  className="inline-block"
                >
                  <span className="">
                    {currencySymbol}
                    &nbsp;
                    {priceFormatter(parseFloat(refundAmount))}
                  </span>
                </Spinner>
              </div>
            </div>
          ) : (
            <p className="font-bold text-sm text-red-900 pt-5">
              {t("userBookings.cancel.refundModal.failure")}
            </p>
          )}
        </div>
      </div>

      <div className="w-full p-3 border-t border-contrast-200 justify-start items-center gap-2 inline-flex ">
        <div className="grow shrink basis-0 h-[38px] justify-end items-start gap-4 flex">
          <button
            type="button"
            className="px-[17px] py-[9px] bg-white rounded-md shadow border border-contrast-300 justify-center items-center flex hover:bg-zinc-300 disabled:cursor-not-allowed"
            onClick={handleClose}
            disabled={cancellationSpinner}
          >
            <div className="text-contrast-700 text-sm font-medium font-['Inter'] leading-tight ">
              {t("userBookings.close")}
            </div>
          </button>

          <button
            type="button"
            className="px-[17px] py-[9px] rounded-md shadow border border-contrast-300 justify-center items-center flex bg-red-600 hover:bg-red-700 disabled:cursor-not-allowed disabled:bg-red-300 disabled:text-gray-300"
            onClick={handleAction}
            disabled={cancellationSpinner || !refundAmount}
          >
            <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
              {t("userBookings.cancel.refundModal.proceedCancellation")}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelModal;
