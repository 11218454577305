export const stripeResponse = {
    "session": {
        "id": "c5eca33c-ac71-4c83-872f-4f9953f4c585",
        "currency": "inr",
        "amount": 635600,
        "bookingId": "e8722e30-0994-4083-bde8-71f36c92b63d",
        "session_status": "open",
        "session_id": "cs_test_a118qEBWlf07d2v3gyAm7AQZgzUxIsw6lIsvvAa85HaVFMgjXskF49n41P",
        "createdAt": 1698327092,
        "metadata": {
            "url": "http://localhost:3000/booking-confirmed"
        }
    }
}

export const razorpayResponse = {
    "session": {
        "id": "MOCK_RAZORPAY_ORDER_ID",
        "currency": "INR",
        "amount": 635600,
        "bookingId": "32280ef2-0302-4794-a916-056e779dbf82",
        "session_status": "created",
        "session_id": "order_Msr3zMyH7GaC4R",
        "createdAt": 1698327541,
        "metadata": null
    }
}

export const cardResponse = {
    "session": {
        "id": "bf673e5a-4a96-4b20-9649-4e58e54df078",
        "currency": "INR",
        "amount": 6356,
        "bookingId": "7054d65d-951c-4b9a-b8c2-04ed3f346ef1",
        "session_status": "created",
        "session_id": "order_Msr6ce0R0BF9U7",
        "createdAt": 1698327691,
        "metadata": {
            "razorpay_payment_id": "pay_FVmAstJWfsD3SO",
            "next": [
                {
                    "action": "otp_submit",
                    "url": "https://api.razorpay.com/v1/payments/pay_FVmAstJWfsD3SO/otp_submit/ac2d415a8be7595de09a24b41661729fd9028fdc?key_id=<YOUR_KEY_ID>"
                },
                {
                    "action": "otp_resend",
                    "url": "https://api.razorpay.com/v1/payments/pay_FVmAstJWfsD3SO/otp_resend/json?key_id=<YOUR_KEY_ID>"
                }
            ],
            "metadata": {
                "issuer": "HDFC",
                "network": "MC",
                "last4": "1111",
                "iin": "411111"
            }
        }
    }
}
