import * as Yup from "yup";
import { getRequiredErrorMessage } from "./getErrorMessage";
import { REGEX, DEFAULT_VALUES } from "../constants";
import { isEmpty } from "lodash";

const { NAME, PASSPORT_NUMBER, PANCARD_NUMBER } = REGEX;
const { ZERO, THREE, FOUR } = DEFAULT_VALUES;

const validatePanWithName = (panCardNumber, lastName) => {
  const validPanTypes = {
    'P': 'Individual',
    'F': 'Firm',
    'C': 'Company',
    'H': 'HUF',
    'A': 'Association',
    'T': 'Trust'
  };

  const normalizedLastName = lastName?.trim()?.toUpperCase();

  const panTypeForCharacter = validPanTypes[panCardNumber[THREE]];
  if (!panTypeForCharacter) {
    return `Invalid PAN type. Expected type for ${panCardNumber[THREE]}: ${panTypeForCharacter || 'Unknown'}`;
  }
  if (normalizedLastName) {
    if (panCardNumber[FOUR] !== normalizedLastName[ZERO]) {
      return "The 5th character of PAN doesn't match the first letter of the last name.";
    }
  }
  return null;
};

export const getPrimaryPaxValidationSchema = (t = () => { }) => ({
  firstName: Yup.string()
    .matches(NAME, t("profilePage.errors.nameFormat"))
    .required(getRequiredErrorMessage("profilePage.firstName", t))
    .min(2, t("profilePage.errors.minLength"))
    .max(25, t("profilePage.errors.maxLength")),
  middleName: Yup.string()
    .matches(NAME, t("profilePage.errors.nameFormat"))
    .max(25, t("profilePage.errors.maxLength")),
  lastName: Yup.string()
    .matches(NAME, t("profilePage.errors.nameFormat"))
    .required(getRequiredErrorMessage("profilePage.lastName", t))
    .min(2, "Last Name must have atleast 2 letters")
    .max(40, t("profilePage.errors.maxLength")),
});

export const getPassportValidationSchema = () => ({
  passportDetails: Yup.object().shape({
    passportNumber: Yup.string()
      .required("Passport Number is required")
      .matches(PASSPORT_NUMBER, "Invalid Passport Number"),
    issueCountry: Yup.object().shape({
      isoCode: Yup.string().required("Issuing Country is required"),
    }),
    issuedDate: Yup.string().required("Issuing Date is required"),
    expiryDate: Yup.string().required("Expiry Date is required"),
  }),
});

export const getPanValidationSchema = () => Yup.object().shape({
  panCardDetails: Yup.object().shape({
    lastName: Yup.string().default(''),
    panCardNumber: Yup.string().default('')
      .test(
        'is-pan-required',
        'PAN number is required',
        function (value) {
          const { isChild = false, isProfileTraveler = false } = this.parent || {};
          if (isChild === false && isProfileTraveler === false) {
            return !!value;
          }
          return true;
        }
      )
      .test('is-pan-valid', 'Invalid PAN number', function (value) {
        const { isChild = false } = this.parent || {};
        if (!value || isChild) return true;

        return PANCARD_NUMBER.test(value);
      })
      .test('validate-pan-with-name', 'Invalid PAN number', function () {
        const { panCardNumber, lastName, isChild } = this.parent;
        if (isEmpty(panCardNumber) || isChild) return true;
        return validatePanWithName(panCardNumber, lastName) === null;
      }),
  })
});

export const checkingGuestValidation = (validationSchema, travelers = []) => {
  const paxValidationStatus = travelers.reduce((acc, guest) => {
    const { id = EMPTY_STRING, isSelected = false } = guest;
    if (!isSelected) return acc;
    try {
      validationSchema.validateSync(guest);
    } catch (err) {
      acc[id] = err.message;
    }
    return acc;
  }, {});
  return paxValidationStatus;
};
