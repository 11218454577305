import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import classNames from "classnames";
import { login, sendOtp, signUp, verfiyOtp } from "../auth.actions";
import { actions as authActions } from "../auth.reducer";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import { PhoneNumberInput } from "../../../components/atoms/PhoneNumberInput";
import { InputField } from "../../../components/molecules";
import Modal, { setSelectedModal } from "../../../components/organisms/Modal";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import { MODALS } from "../../../components/organisms/AppModals/modals.constants";
import { ForgotPassword } from "./ForgotPassword";
import {
  Facebook,
  Google,
  Mail,
  Cross,
  Phone,
  RenderSVG,
} from "../../../assets/icons";
import {
  DEFAULT_VALUES,
  USER_AUTH_FIELDS,
  SSO_PROVIDER,
  ROUTES,
  REGEX,
  CACHE_KEYS,
  LOGO_WITH_BRAND,
} from "../../../constants";
import { getRequiredErrorMessage, setToLocalStorage } from "../../../helper";
import config from "../../../config.json";
import { clearSelectedValues } from "../../Booking/FlightBookings";

const { logo } = config;
const { EMPTY_STRING, EMPTY_OBJECT, ONE, SIX, TWO, ZERO } = DEFAULT_VALUES;
const { EMAIL, PASSWORD, PHONE, PIN, CONFIRMPASSWORD } = USER_AUTH_FIELDS;
const { AUTH: AUTH_SPINNER } = SPINNER_NAMES;
const { AUTH } = CACHE_KEYS;
const { FACEBOOK, GOOGLE } = SSO_PROVIDER;
const { WELCOME_CARD_MODAL, LOGIN_MODAL, SIGNUP_MODAL } = MODALS;
const APPROVED = "approved";
const PENDING = "pending";
const FACEBOOK_APP_ID = process.env.REACT_APP_FB_APP_ID || EMPTY_STRING;
const { ONLY_DIGITS, PHONE_NUMBER } = REGEX;
const { PRIVACY_POLICY, COOKIE_POLICY, TERMS_AND_CONDITION } = ROUTES;
const { setAuthInfo } = authActions;

const defaultinitialValues = {
  phoneNumber: EMPTY_STRING,
  pin: EMPTY_STRING,
  email: EMPTY_STRING,
  password: EMPTY_STRING,
  confirmPassword: EMPTY_STRING,
};

const resendTimerConst = 45;

const LogInAndSignup = ({ enroll }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [showEmailPassword, setShowEmailPassword] = useState(true);
  const [showVerifyPin, setShowVerifyPin] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState(ZERO);
  const [spinnerName, setSpinnerName] = useState(AUTH_SPINNER);
  const [resendTimer, setResendTimer] = useState(resendTimerConst);
  const [canResendOtp, setCanResendOtp] = useState(false);

  useEffect(() => {
    if (!showVerifyPin) {
      setResendTimer(resendTimerConst);
      setCanResendOtp(false);
      return;
    }
    if (resendTimer === ZERO) {
      setCanResendOtp(true);
    } else {
      const timer = setTimeout(() => {
        setResendTimer((prevTimer) => prevTimer - ONE);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [resendTimer, showVerifyPin]);

  const handleModalClose = useCallback(() => {
    dispatch(setSelectedModal(null));
  }, [dispatch]);

  const phoneValidationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(PHONE_NUMBER, t("profilePage.errors.phone"))
      .required(getRequiredErrorMessage("loginAndSignup.formFields.phone", t)),
  });

  const pinValidationSchema = Yup.object().shape({
    pin: Yup.string()
      .matches(ONLY_DIGITS, t("validationSchemas.loginAndSignup.mustBeDigits"))
      .min(SIX, t("validationSchemas.loginAndSignup.mustBeSixDigits"))
      .max(SIX, t("validationSchemas.loginAndSignup.mustBeSixDigits"))
      .required(getRequiredErrorMessage("loginAndSignup.formFields.pin", t)),
  });

  const emailPasswordValidationSchema = Yup.object({
    email: Yup.string()
      .email(t("validationSchemas.loginAndSignup.invalidEmail"))
      .required(getRequiredErrorMessage("loginAndSignup.formFields.email", t)),
    password: Yup.string()
      .min(8, t("validationSchemas.loginAndSignup.minPassword"))
      .max(20, t("validationSchemas.loginAndSignup.maxPassword"))
      .required(
        getRequiredErrorMessage("loginAndSignup.formFields.password", t)
      ),
    confirmPassword: enroll
      ? Yup.string()
          .oneOf(
            [Yup.ref("password")],
            t("validationSchemas.loginAndSignup.passwordMatch")
          )
          .oneOf([Yup.ref("newPassword"), null], "Password does not match")
          .required(
            getRequiredErrorMessage(
              "loginAndSignup.formFields.confirmPassword",
              t
            )
          )
      : Yup.mixed().notRequired(),
  });

  const getValidationSchema = () => {
    let validationSchema;
    if (!showVerifyPin && !showEmailPassword)
      validationSchema = phoneValidationSchema;
    else if (showVerifyPin && !showEmailPassword)
      validationSchema = pinValidationSchema;
    else validationSchema = emailPasswordValidationSchema;
    return validationSchema;
  };

  const getButtonText = () => {
    let buttonText;
    if (showEmailPassword) {
      buttonText = enroll
        ? "loginAndSignup.buttonText.signup"
        : "loginAndSignup.buttonText.login";
    } else buttonText = "loginAndSignup.buttonText.continue";
    return buttonText;
  };

  const authenticateUser = (authResponse, resetForm = () => {}) => {
    const userInfo = authResponse.payload;
    if (userInfo) {
      const user = get(userInfo, "output.response.userInfo", EMPTY_OBJECT);
      const status = get(userInfo, "output.response.status", EMPTY_STRING);
      const { accessToken, refreshToken, id } = user;
      const auth = { refreshToken, accessToken, id };
      const isSignUp = status === 201;
      if (accessToken && refreshToken) {
        setToLocalStorage(AUTH, auth);
        dispatch(setAuthInfo(auth));
        setShowVerifyPin(false);
        handleModalClose();
        isSignUp && dispatch(setSelectedModal(WELCOME_CARD_MODAL));
        dispatch(clearSelectedValues());
      } else {
        toast.error(t("loginAndSignup.unableToAuthenticate"));
      }
      resetForm();
    }
    else{
      toast.error(authResponse?.error?.message || t("loginAndSignup.unableToLogin"))
    }
  };

  const validateOtp = (values, resetForm) => {
    setSpinnerName(AUTH_SPINNER);
    const { phoneNumber, pin } = values;
    dispatch(verfiyOtp({ phone: `+${phoneNumber}`, code: String(pin) })).then(
      (otpRes) => {
        if (otpRes.payload?.output === APPROVED) {
          setIsOtpSent(false);
          dispatch(
            login({
              phone: phoneNumber,
              provider: PHONE,
            })
          ).then((loginRes) => {
            if (loginRes.payload) {
              authenticateUser(loginRes, resetForm);
              setShowVerifyPin(false);
              setShowEmailPassword(!showEmailPassword);
            } else {
              handleModalClose();
              toast.error(t("loginAndSignup.unableToAuthenticate"));
            }
          });
        } else if (otpRes.payload?.output === PENDING) {
          resetForm({ values: { ...values, pin: EMPTY_STRING } });
          toast.error(t("loginAndSignup.wrongOtp"));
        }
        setSpinnerName(EMPTY_STRING);
      }
    );
  };

  const handleSendOtp = (phoneNumber) => {
    dispatch(sendOtp({ provider: PHONE, phone: `+${phoneNumber}` })).then(
      (res) => {
        if (res.payload) {
          if (res.payload.output !== "OTP sent!") {
            toast.error(t("loginAndSignup.unableToSendOTP"));
            setResendTimer(resendTimerConst);
          } else {
            setSpinnerName(EMPTY_STRING);
            setIsOtpSent(true);
            setShowVerifyPin(true);
            setResendTimer(resendTimerConst);
          }
        }
      }
    );

    setCanResendOtp(false);
  };

  const handleOnSubmit = (values, { setSubmitting, resetForm }) => {
    let { email, password } = values;
    email = email.toLowerCase();
    const requestBody = { email, password, provider: EMAIL };

    if (showEmailPassword) {
      values.email = values.email.toLowerCase();
      setSpinnerName(AUTH_SPINNER);
      const action = enroll
        ? signUp({ ...requestBody, name: EMPTY_STRING })
        : login(requestBody);
      dispatch(action).then((res) => authenticateUser(res, resetForm));
    } else if (values.phoneNumber) {
      setSpinnerName(AUTH_SPINNER);
      if (!isOtpSent) {
        resetForm({ values: { ...values, pin: EMPTY_STRING } });
        handleSendOtp(values.phoneNumber);
      } else if (values.pin) {
        validateOtp(values, resetForm);
      }
    }
    setSubmitting(false);
  };

  const Fields = {
    email: [
      {
        id: EMAIL,
        type: "email",
        label: "loginAndSignup.formFields.email",
        placeholder: "loginAndSignup.placeholders.email",
      },
      {
        id: PASSWORD,
        type: "password",
        label: "loginAndSignup.formFields.password",
        placeholder: "loginAndSignup.placeholders.password",
      },
    ],
    phone: [
      {
        id: PHONE,
        type: "number",
        label: "loginAndSignup.formFields.phone",
        placeholder: "loginAndSignup.placeholders.phone",
      },
    ],
    pin: [
      {
        id: PIN,
        type: "text",
        label: "loginAndSignup.formFields.pin",
        placeholder: "loginAndSignup.placeholders.pin",
      },
    ],
  };

  const getFields = () => {
    const { phone, pin, email } = Fields;
    if (showEmailPassword) {
      if (enroll) {
        email.push({
          id: CONFIRMPASSWORD,
          type: "password",
          label: "loginAndSignup.formFields.confirmPassword",
          placeholder: "loginAndSignup.placeholders.confirmPassword",
        });
      }
      return email;
    }
    return showVerifyPin ? pin : phone;
  };

  const authenticationFields = getFields();

  const validateSocialSignup = (provider, user) => {
    const { name, email } = user;
    const values = {
      name,
      email,
      password: EMPTY_STRING,
      provider,
    };
    dispatch(login(values)).then((res) => authenticateUser(res));
  };

  const onResolve = useCallback((provider, data) => {
    if (provider && data) validateSocialSignup(provider, data);
  }, []);

  const onReject = useCallback((err) => {
    toast.error(t("loginAndSignup.unableToAuthenticate"));
  }, []);

  const onGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );
      onResolve(GOOGLE, userInfo.data);
    },
    onError: onReject,
  });

  const renderFieldError = (name, errors, touched) =>
    get(errors, name, false) &&
    get(touched, name, false) && (
      <ErrorMessage errorMessage={get(errors, name)} />
    );

  const authServices = [
    {
      name: FACEBOOK,
      component: (
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          onFail={onReject}
          onProfileSuccess={(response) => onResolve(FACEBOOK, response)}
          render={({ onClick }) => (
            <button
              className="py-2 px-4 w-full flex justify-center rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium"
              onClick={onClick}
              disabled
              data-tooltip-id="modal-tooltip"
              data-tooltip-content="Coming soon..."
            >
              <RenderSVG Svg={Facebook} alt="facebook" />
            </button>
          )}
        />
      ),
      enabled:false
    },
    {
      name: GOOGLE,
      component: (
        <button
          className="py-2 px-4 w-full flex justify-center rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium"
          onClick={onGoogleLogin}
        >
          <RenderSVG Svg={Google} alt="google" />
        </button>
      ),
      enabled:true
    },
  ];

  const renderHeaderText = () => {
    if (forgotPasswordStep === ONE)
      return t("loginAndSignup.resetYourPassword");
    else if (forgotPasswordStep === TWO)
      return t("loginAndSignup.setNewPassword");
    else if (showVerifyPin) return t("loginAndSignup.verifyPin");
    else return t("loginAndSignup.welcome.title");
  };

  return (
    <Modal handleClose={handleModalClose}>
      <Tooltip
        id="modal-tooltip"
        className="!bg-contrast-500 !rounded-lg max-w-xs"
      />
      <div className="flex gap-4 items-center px-10 py-6">
        <div className="flex h-9 w-36">
          <img src={LOGO_WITH_BRAND || logo} alt="site-logo"/>
        </div>
        <button
          data-dismiss="modal"
          className="close text-blue-contrast-600 ms-auto hover:opacity-80"
          onClick={() => {
            handleModalClose();
            setShowEmailPassword(true);
          }}
        >
          <RenderSVG Svg={Cross} alt="Cross Icon" />
        </button>
      </div>
      <div className="px-10 py-6">
        <h2
          className={classNames(
            "text-2xl lg:text-3xl font-bold text-contrast-900 mb-1",
            {
              "mb-6": !forgotPasswordStep,
            }
          )}
        >
          {renderHeaderText()}
        </h2>
        {showEmailPassword && forgotPasswordStep ? (
          <ForgotPassword
            forgotPasswordStep={forgotPasswordStep}
            setForgotPasswordStep={setForgotPasswordStep}
          />
        ) : (
          <Formik
            enableReinitialize
            validateOnMount={true}
            initialValues={defaultinitialValues}
            validationSchema={getValidationSchema()}
            onSubmit={handleOnSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              isValid,
              setSubmitting,
              resetForm,
            }) => (
              <Form>
                {authenticationFields.map(
                  ({ id, type, label, placeholder }) => (
                    <div key={id}>
                      {id === "phone" ? (
                        <div className="form-group mb-6">
                          <label className="block text-sm font-medium mb-1 text-contrast-900">
                            {t("loginAndSignup.formFields.phone")}
                          </label>
                          <div className="flex w-full rounded-lg flex-col">
                            <PhoneNumberInput
                              name={"phoneNumber"}
                              values={values}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              placeholder={placeholder}
                              handleSubmit={() =>
                                isValid &&
                                handleOnSubmit(values, {
                                  setSubmitting,
                                  resetForm,
                                })
                              }
                              t={t}
                            />
                            <div>
                              {renderFieldError("phoneNumber", errors, touched)}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <InputField
                          key={id}
                          id={id}
                          name={id}
                          type={type}
                          label={t(label)}
                          value={values[id]}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder={t(placeholder)}
                          errors={errors}
                          touched={touched}
                        />
                      )}
                    </div>
                  )
                )}
                {showVerifyPin && (
                  <div className="text-end">
                    <p className={classNames("pb-3", { hidden: !resendTimer })}>
                      {t("loginAndSignup.resendTimer", {
                        timer: resendTimer,
                      })}
                    </p>
                    <button
                      className={classNames(
                        "pb-3 text-sm text-primary-600 font-semibold disabled:cursor-not-allowed disabled:opacity-30",
                        { hidden: resendTimer }
                      )}
                      onClick={() => {
                        handleSendOtp(values.phoneNumber);
                      }}
                      disabled={!canResendOtp}
                    >
                      {t("loginAndSignup.resendOTP")}
                    </button>
                  </div>
                )}

                <button
                  type="submit"
                  className="py-3 px-4 w-full rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium disabled:cursor-not-allowed disabled:opacity-70"
                  disabled={isSpinnerActive || !isValid}
                >
                  <Spinner
                    name={spinnerName}
                    setIsSpinnerActive={setIsSpinnerActive}
                  >
                    {t(getButtonText())}
                  </Spinner>
                </button>

                {showVerifyPin && (
                  <div className="text-end mt-2">
                    <button
                      className="text-sm text-primary-600 font-semibold"
                      onClick={() => {
                        setIsOtpSent(false);
                        setShowVerifyPin(false);
                      }}
                    >
                      {t("loginAndSignup.buttonText.wrongNumber")}
                    </button>
                  </div>
                )}

                {showEmailPassword && !enroll && (
                  <div className="text-end mt-2">
                    <button
                      type="button"
                      className="text-sm text-primary-600 font-semibold"
                      onClick={() => setForgotPasswordStep(ONE)}
                    >
                      {t("loginAndSignup.buttonText.forgotPassword")}
                    </button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        )}
        <div className="flex items-center gap-2 my-4">
          <div className="border-b border-contrast-300 flex-1"></div>
          <span className="text-sm text-contrast-500">
            {enroll
              ? t("loginAndSignup.signupWith")
              : t("loginAndSignup.loginWith")}
          </span>
          <div className="border-b border-contrast-300 flex-1"></div>
        </div>

        <div className="grid grid-cols-12 gap-2">
          {authServices.filter(service=>service.enabled).map(({ component, name }) => (
            <div className="col-span-4" key={name}>
              {component}
            </div>
          ))}
          <div className="col-span-4">
            <button
              onClick={() => {
                setShowEmailPassword(!showEmailPassword);
                setIsOtpSent(false);
                setShowVerifyPin(false);
                setForgotPasswordStep(ZERO);
              }}
              className="py-2 px-4 w-full flex justify-center rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium"
            >
              <RenderSVG Svg={showEmailPassword ? Phone : Mail} />
            </button>
          </div>
        </div>
        <div className="mt-4 text-center">
          {enroll ? (
            <p className="text-sm text-contrast-700">
              {t("loginAndSignup.haveAnAccount")}&nbsp;
              <button
                className="text-primary-600 cursor-pointer"
                onClick={() => {
                  setForgotPasswordStep(ZERO);
                  dispatch(setSelectedModal(LOGIN_MODAL));
                }}
              >
                {t("loginAndSignup.login")}
              </button>
            </p>
          ) : (
            <p className="text-sm text-contrast-700">
              {t("loginAndSignup.dontHaveAnAccount")}&nbsp;
              <button
                className="text-primary-600 cursor-pointer"
                onClick={() =>{
                  setForgotPasswordStep(ZERO);
                  dispatch(setSelectedModal(SIGNUP_MODAL));
                }}
              >
                {t("loginAndSignup.signUp")}
              </button>
            </p>
          )}
        </div>
      </div>
      <div className="px-10 py-6 bg-contrast-100 text-center">
        <p className="text-xs text-contrast-500">
          {t("loginAndSignup.policyMsg")}
          <span
            className="text-contrast-900 hover:text-contrast-600 font-medium hover:cursor-pointer"
            onClick={() => window.open(TERMS_AND_CONDITION, "_blank")}
          >
            {t("loginAndSignup.terms")}
          </span>
          <span
            className="text-contrast-900 hover:text-contrast-600 font-medium hover:cursor-pointer"
            onClick={() => window.open(PRIVACY_POLICY, "_blank")}
          >
            {t("loginAndSignup.dataPolicy")}
          </span>
          {t("loginAndSignup.and")}
          <span
            className="text-contrast-900 hover:text-contrast-600 font-medium hover:cursor-pointer"
            onClick={() => window.open(COOKIE_POLICY, "_blank")}
          >
            {t("loginAndSignup.cookiesPolicy")}
          </span>
        </p>
      </div>
    </Modal>
  );
};

export default LogInAndSignup;
