import { RenderSVG } from "../../../assets/icons";

const AnalyticsCard = ({ cardData }) => {
  const { title, amount, description, icon } = cardData;

  return (
    <div className="col-span-12 lg:col-span-6 xl:col-span-3 p-6 bg-white shadow-md rounded-lg">
      <div className="flex flex-col">
        <div className="leading-7 text-sm font-semibold">{title}</div>
        <div className="flex justify-between items-center">
          <div className="text-2xl font-bold mr-2">{amount}</div>
          <RenderSVG Svg={icon} alt="icon" className="w-16 h-16"/>
        </div>
      </div>
      <div className="text-sm text-contrast-700">{description}</div>
    </div>
  );
};

export default AnalyticsCard;
