export const isEmptyObject = (obj) => {
  if (obj === null || obj === undefined) {
    return true;
  }
  // Check if obj is an object and not an array
  if (typeof obj === 'object' && !Array.isArray(obj)) {
    return Object.keys(obj).length === 0;
  }
  return false;
}
