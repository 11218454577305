import { DEFAULT_VALUES } from "../constants";

const { EMPTY_ARRAY } = DEFAULT_VALUES

export const getLeadGuestInfo = (primaryRoomPassengers) => {
    const { email, firstName, phoneno, title } = primaryRoomPassengers.find(passenger => passenger?.isLeadPassenger === true) || EMPTY_ARRAY;

    return {
        profileDetails: {
            title: title,
            email: email,
            phoneNumber: phoneno,
            firstName: firstName
        }
    }
}
