import { get, isEmpty, set } from "lodash";
import { getFormattedTBOResponse } from "./tboSearchRes.mapper";
import { DEFAULT_VALUES, FARE_TYPE_MAPPING, TRIP_TYPES } from "../../constants";
import {
  getAmadeusIsolatedResponse,
  getAmadeusPackagedResponse,
} from "./amadeusSearchRes.mapper.";

const AMADEUS = "AMADEUS";
const TBO = "TBO";
const { ZERO, ONE, TWO, EMPTY_ARRAY } = DEFAULT_VALUES;
const { ROUND_TRIP } = TRIP_TYPES;

export const getFormattedFlightSearchResponse = (
  previousResults,
  totalCounts,
  output,
  tripType,
  travelType,
  fareType
) => {
  const resultSource = output.source;
  switch (resultSource) {
    case TBO: {
      const isRoundTripDomesticResponse =
        output.result?.length === TWO && travelType === "domestic";
      const prevFlightsLen = isRoundTripDomesticResponse
        ? [
            get(totalCounts, "outbound", ZERO),
            get(totalCounts, "inbound", ZERO),
          ]
        : [get(totalCounts, "packages", ZERO)];
      const tboFlights = getFormattedTBOResponse(
        output,
        prevFlightsLen,
        FARE_TYPE_MAPPING[fareType]
      );

      const headers = {
        tokenId: output.token,
        traceId: output.traceId,
      };
      if (isRoundTripDomesticResponse) {
        let updatedResponse = { ...headers };
        const [outboundResponse = [], inboundResponse = []] = tboFlights;

        set(updatedResponse, "count.outbound", outboundResponse.length);
        set(updatedResponse, "count.inbound", inboundResponse.length);
        set(updatedResponse, "flights.isolated.outbound", [
          ...outboundResponse,
        ]);
        set(updatedResponse, "flights.isolated.inbound", [...inboundResponse]);
        return updatedResponse;
      } else {
        const count = prevFlightsLen[ZERO] + get(tboFlights, "0", []).length;
        const flightDetails = get(tboFlights, "0", []);

        const updatedResponse = {
          count: {
            packages: count,
          },
          flights: { packages: flightDetails },
          ...headers,
        };

        return updatedResponse;
      }
    }

    case AMADEUS: {
      const isRoundTripDomesticResponse =
        get(output, "result", EMPTY_ARRAY).filter((data) => !isEmpty(data))
          .length === TWO && travelType === "domestic";

      if (tripType === ROUND_TRIP && isRoundTripDomesticResponse) {
        const prevFlightsLen = [
          get(totalCounts, "outbound", ZERO),
          get(totalCounts, "inbound", ZERO),
        ];
        const isolatedFlights = getAmadeusIsolatedResponse(
          output,
          prevFlightsLen
        );

        let updatedResponse;
        if (isRoundTripDomesticResponse) {
          updatedResponse = previousResults
            ? {
                count: { ...totalCounts },
                flights: { ...previousResults },
              }
            : {};

          const prevOutboundRes = get(
            previousResults,
            "isolated.outbound",
            EMPTY_ARRAY
          );
          const prevInboundRes = get(
            previousResults,
            "isolated.inbound",
            EMPTY_ARRAY
          );

          set(
            updatedResponse,
            "count.outbound",
            prevOutboundRes.length + isolatedFlights[ZERO].length
          );
          set(
            updatedResponse,
            "count.inbound",
            prevInboundRes.length + isolatedFlights[ONE].length
          );
          set(updatedResponse, "flights.isolated.outbound", [
            ...prevOutboundRes,
            ...isolatedFlights[ZERO],
          ]);
          set(updatedResponse, "flights.isolated.inbound", [
            ...prevInboundRes,
            ...isolatedFlights[ONE],
          ]);
          return updatedResponse;
        }
      } else {
        const previousFlightsLength = get(totalCounts, "packages", ZERO);
        const amadeusFlights = getAmadeusPackagedResponse(
          output,
          previousFlightsLength
        );
        const count = previousFlightsLength + amadeusFlights.length;
        const previousFlights = get(previousResults, "packages", []);

        const updatedResponse = previousResults
          ? {
              count: {
                ...totalCounts,
                packages: count,
              },
              flights: {
                ...previousResults,
                packages: [...previousFlights, ...amadeusFlights],
              },
            }
          : {
              count: {
                packages: count,
              },
              flights: { packages: amadeusFlights },
            };
        return updatedResponse;
      }
    }
  }
};
